import { IPort } from '../../Domain/NauticalLocations/IPort'
import { IAppState } from '../../modules/App/interfaces/IAppState'
import { always } from '../../utils/functions'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'

import { CacheDefinition } from './CacheDefinition'

export const portsCacheDefinition = new CacheDefinition<IAppState, Record<string, unknown>, IPort[]>(
  'ports',
  fixedSizeLimiter(1, true),
  always(true),
  appState => appState.portsCache
)
