import { flatten } from 'lodash'

import { None, none } from '../../utils/strictNull'
import { CacheItem } from '../asyncSelector/CacheItem'

import { fixedSizeLimiter } from './fixedSizeLimiter'
import { Limiter } from './Limiter'

export function limiterPerPurpose<Key, Result, Purpose extends string | number | symbol>(
  o: { [K in Purpose]: Limiter<Key, Result, Purpose> },
  defaultLimiter: Limiter<Key, Result, Purpose> = fixedSizeLimiter(5)
): Limiter<Key, Result, Purpose> {
  return cacheItems => {
    // Group each `CacheItem<..>` by its purpose.
    const cacheItemsByPurpose: Map<Purpose | None, Array<CacheItem<Key, Result, Purpose>>> = new Map()
    cacheItems.forEach(cacheItem => {
      const purpose: Purpose | None = cacheItem.meta in o ? cacheItem.meta : none
      let cacheItemsForPurpose = cacheItemsByPurpose.get(purpose)
      if (cacheItemsForPurpose === undefined) {
        cacheItemsForPurpose = []
        cacheItemsByPurpose.set(purpose, cacheItemsForPurpose)
      }
      cacheItemsForPurpose.push(cacheItem)
    })

    // Limit the number of `CacheItem<..>`-instances for each
    // purpose separately.
    const limitedCacheItemsByPurpose: Map<Purpose | None, Array<CacheItem<Key, Result, Purpose>>> = new Map()
    cacheItemsByPurpose.forEach((value, key) => {
      const limiter = key === none ? defaultLimiter : o[key]
      const limited = limiter(value)
      limitedCacheItemsByPurpose.set(key, limited)
    })

    return flatten(Array.from(limitedCacheItemsByPurpose.values()))
  }
}
