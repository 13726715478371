import { parseISO } from 'date-fns'
import { head, last } from 'lodash'

import { TimeZone } from '../../ApplicationState/DateTime/TimeZone'
import { BerthVisit } from '../../Domain/BerthVisits/BerthVisit'
import { EventWithOptionalEventTime, hasEventType } from '../../Domain/Events/Event'
import { MostAccurates } from '../../Domain/Events/MostAccurates'
import { IPortcall } from '../../Domain/Portcall/IPortcall'
import { notFalse } from '../../utils/predicates'
import { None, none, StrictNull } from '../../utils/strictNull'
import { NOT_AVAILABLE } from '../constants'
import { IEventQualification, IEventContext, EventTypeId } from '../interfaces/event/IEvent'

import { dateFormat } from './datetime'

export function eventOfETA(visit: IPortcall | BerthVisit | undefined): EventWithOptionalEventTime | null {
  const mostAccuratesStart: EventWithOptionalEventTime[] | None =
    (visit && visit.mostAccurates && visit.mostAccurates.start) || none
  return StrictNull.fold(mostAccuratesStart, m => head(m) || null, null)
}

export function commencedOutbound(visit: IPortcall): boolean {
  return StrictNull.fold(visit.mostAccurates, m => m.end.some(hasEventType('port.atd.portAuthority')), false)
}

export function getMostAccurateArrivalBerth(visit: BerthVisit): EventWithOptionalEventTime | None {
  return head(visit.mostAccurates.start) || none
}

export function getMostAccurateDepartureBerth(visit: BerthVisit): EventWithOptionalEventTime | None {
  return head(visit.mostAccurates.end) || none
}

export function etaPbpAis(visit: IPortcall): EventWithOptionalEventTime | None {
  return StrictNull.fold(
    visit.mostAccurates,
    m => m.start.find(hasEventType('pilotBoardingPlace.eta.vessel')) || none,
    none
  )
}

export const mostAccurateArrivalPilotBoardingPlace = (visit: IPortcall): EventWithOptionalEventTime | None =>
  StrictNull.fold(
    visit.mostAccurates,
    m =>
      m.start.find(hasEventType('pilotBoardingPlace.ata.vessel')) ||
      m.start.find(hasEventType('pilotBoardingPlace.eta.agent')) ||
      m.start.find(hasEventType('pilotBoardingPlace.pta.portAuthority')) ||
      m.start.find(hasEventType('pilotBoardingPlace.eta.vessel')) ||
      none,
    none
  )

export const getMostAccurateArrivalPort = (visit: IPortcall): EventWithOptionalEventTime | None =>
  StrictNull.flatMap(
    visit.mostAccurates,
    m =>
      m.start.find(hasEventType('pilotBoardingPlace.ata.ais')) ||
      m.start.find(hasEventType('pilotBoardingPlace.ata.carrier')) ||
      m.start.find(hasEventType('pilotBoardingPlace.ata.vessel')) ||
      m.start.find(hasEventType('port.ata.agent')) ||
      m.start.find(hasEventType('port.ata.ais')) ||
      m.start.find(hasEventType('port.ata.carrier')) ||
      m.start.find(hasEventType('port.ata.portAuthority')) ||
      m.start.find(hasEventType('port.ata.vessel')) ||
      m.start.find(hasEventType('pilotBoardingPlace.eta.agent')) ||
      m.start.find(hasEventType('pilotBoardingPlace.eta.ais')) ||
      m.start.find(hasEventType('pilotBoardingPlace.eta.carrier')) ||
      m.start.find(hasEventType('pilotBoardingPlace.eta.portAuthority')) ||
      m.start.find(hasEventType('pilotBoardingPlace.eta.vessel')) ||
      m.start.find(hasEventType('port.eta.agent')) ||
      m.start.find(hasEventType('port.eta.carrier')) ||
      m.start.find(hasEventType('port.eta.portAuthority')) ||
      none
  )

export const mostAccurateActualDeparturePort = (visit: IPortcall): EventWithOptionalEventTime | None =>
  StrictNull.fold(
    visit.mostAccurates,
    m =>
      m.start.find(hasEventType('port.atd.agent')) ||
      m.start.find(hasEventType('port.atd.ais')) ||
      m.start.find(hasEventType('port.atd.carrier')) ||
      m.start.find(hasEventType('port.atd.portAuthority')) ||
      m.start.find(hasEventType('port.atd.vessel')) ||
      none,
    none
  )

export function getBollardsProviders(mostAccurates: MostAccurates) {
  const setProvider = (provider: string) => (ev: EventWithOptionalEventTime) => ({
    provider,
    bollards: getBollardsForDetails(ev.context || none),
  })

  const terminal: EventWithOptionalEventTime | None =
    mostAccurates.start.find(hasEventType('berth.ata.terminal')) ||
    mostAccurates.start.find(hasEventType('berth.pta.terminal')) ||
    mostAccurates.start.find(hasEventType('berth.eta.terminal')) ||
    none
  const agent: EventWithOptionalEventTime | None = mostAccurates.start.find(hasEventType('berth.eta.agent')) || none
  const portAuthority: EventWithOptionalEventTime | None =
    mostAccurates.start.find(hasEventType('berth.ata.portAuthority')) ||
    mostAccurates.start.find(hasEventType('berth.eta.portAuthority')) ||
    none

  return [
    StrictNull.map(terminal, setProvider('terminal')),
    StrictNull.map(agent, setProvider('agent')),
    StrictNull.map(portAuthority, setProvider('port authority')),
  ].filter(notFalse)
}

function getBollardsForDetails(context: IEventContext | None): string {
  const bollardFore = context && context.mooring && context.mooring.bollardFore
  const bollardAft = context && context.mooring && context.mooring.bollardAft

  return bollardFore && bollardAft ? `${bollardFore}-${bollardAft}` : '-'
}

export function portcallStartForVisit(portcall: IPortcall): Date | None {
  return portcall.timeframe ? parseISO(portcall.timeframe.start) : none
}

export function portcallEndForVisit(portcall: IPortcall): Date | None {
  return portcall.timeframe ? parseISO(portcall.timeframe.end) : none
}

export function getEventParty(eventType: string): string {
  return last(eventType.split('.')) || NOT_AVAILABLE
}

export function getEventQualification(e: EventWithOptionalEventTime): IEventQualification
export function getEventQualification(e: EventWithOptionalEventTime | None): IEventQualification | None
export function getEventQualification(e: EventWithOptionalEventTime | None): IEventQualification | None {
  return e ? (e.eventType.split('.')[1] as IEventQualification) : none
}

function getEventTime(e: EventWithOptionalEventTime | undefined | null): Date | None {
  return e ? e.eventTime : none
}

export function formatEventDateTime(e: EventWithOptionalEventTime | undefined | null, timeZone: TimeZone | None) {
  const eventTime = getEventTime(e)
  return (eventTime && dateFormat(eventTime, timeZone)) || NOT_AVAILABLE
}

export function getPortName(visit: IPortcall): string {
  if (!visit || !visit.port) {
    return ''
  }

  switch (visit.port) {
    case 'NLRTM':
      return 'Rotterdam'
    case 'ESALG':
      return 'Algeciras'
    case 'SEGOT':
      return 'Goteborg'
    case 'SGSIN':
      return 'Singapore'
    case 'BEANR':
      return 'Antwerp'
    default:
      return NOT_AVAILABLE
  }
}

export const findFirstEventOfType = (
  types: EventTypeId[],
  events: EventWithOptionalEventTime[]
): EventWithOptionalEventTime | undefined => events.find(e => types.some(eventType => eventType === e.eventType))
