import { AppAction } from '../../modules/App/App.actions'
import { LOGIN_EXPIRED, LOGOUT, SET_CLIENT_PREFERENCES } from '../actionTypes'

import { IAuth } from './IAuth'

export const defaultState: IAuth = {
  user: null,
}

const authReducers = (state = defaultState, action: AppAction): IAuth => {
  switch (action.type) {
    case LOGIN_EXPIRED:
    case LOGOUT:
      return defaultState
    case SET_CLIENT_PREFERENCES:
      if (state.user === null) {
        return state
      }
      return {
        ...state,
        user: {
          ...state.user,
          clientPreferences: action.preferences,
        },
      }
    default:
      return state
  }
}

export default authReducers
