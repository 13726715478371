import * as History from 'history'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction'
import * as storage from 'redux-storage'
import thunk from 'redux-thunk'
import { Observable, Observer } from 'rxjs'

import { errorLoggerMiddleware, onGlobalError } from '../middlewares/errorLogger'
import { websocketMiddleware } from '../middlewares/websocket'
import { handleWebsocketQueueMessages } from '../middlewares/WebsocketQueue/websocketQueue'
import { actionsToDispatch as notificationActionsToDispatch } from '../modules/NotificationsQueue/notificationsQueue'
import { actionsToDispatch as portcallActionsToDispatch } from '../modules/PortcallsQueue/portcallsQueue'
import { MINUTE } from '../shared/constants'
import { exhaustMapWithLatest } from '../utils/exhaustMapWithLatest'

import { loadPreferencesFromLocalStorage } from './preferences'
import { loadPreferencesAction } from './Preferences/LoadPreferencesAction'
import reducers from './reducers'
import { scheduleCacheUpdates } from './scheduleCacheUpdates'
import { prontoEngine, storageMiddleWare } from './storage'
import { ticker } from './ticker/tickerAction'

import type { IAppState } from '../modules/App/interfaces/IAppState'
import type { ProntoStore, ProntoDispatch } from './storeTypes'
import { IUser } from '../shared/interfaces/settings/Settings'
import { logoutMiddleware } from '../middlewares/logout'

// Create Router Middleware for routing actions
export const history = History.createBrowserHistory()

// Init Store
export const initStore = (user: IUser) => {
  const store: ProntoStore = setupStore(user)

  // Update cache
  scheduleCacheUpdates(store)

  const load = storage.createLoader<IAppState>(prontoEngine)
  load(store)
  store.dispatch(loadPreferencesAction(loadPreferencesFromLocalStorage()))

  /**
   * An action needs to be dispatched (at least) every minute, so the
   * `tickerSelector` will re-run.
   *
   * This action will (probably) not be handled anywhere. This doesn't mean
   * it can be removed!
   */
  setInterval(() => {
    store.dispatch(ticker())
  }, MINUTE)

  const appStateObservable = new Observable<IAppState>((observer: Observer<IAppState>) => {
    return store.subscribe(() => {
      observer.next(store.getState())
    })
  })

  appStateObservable.pipe(exhaustMapWithLatest(portcallActionsToDispatch)).subscribe({
    next: store.dispatch,
    error(e) {
      onGlobalError({
        error: e,
        reference: 'portcallActionsToDispatch',
      })
    },
  })
  appStateObservable.pipe(exhaustMapWithLatest(notificationActionsToDispatch)).subscribe({
    next: store.dispatch,
    error(e) {
      onGlobalError({
        error: e,
        reference: 'notificationActionsToDispatch',
      })
    },
  })

  store.dispatch(handleWebsocketQueueMessages)
  return store
}

export const setupStore = (user: IUser): ProntoStore => {
  // Enable Extension if extension exists on window object
  const composeEnhancers = composeWithDevTools({
    stateSanitizer: state => {
      // Filter out humongous state properties they crash Redux
      const { portcallsCache, portcallWithEventsCache, notificationsCache, ...filteredState } =
        state as unknown as IAppState
      return filteredState as any
    },
  })

  // Redux Thunk middleware to allow for handling async actions that return an action object
  // Useful for Api Requests
  // https://github.com/gaearon/redux-thunk
  // const thunkMiddleware = thunk.withExtraArgument({ apis })
  const thunkMiddleware = thunk

  const r = storage.reducer(reducers)

  const middlewares = composeEnhancers(
    applyMiddleware<ProntoDispatch, any>(
      errorLoggerMiddleware,
      websocketMiddleware,
      thunkMiddleware,
      storageMiddleWare,
      logoutMiddleware
    )
  )

  return createStore(r, { auth: { user } } as IAppState, middlewares)
}
