import { ACTUAL, PLANNED, NONE } from './TimelineItem'

import type { None } from '../../utils/strictNull'

/**
 * Respresents an end point of a timeline item
 * (either start or end) which is actual.
 */
export type ActualEventTime = Readonly<{
  type: typeof ACTUAL
  value: Date
}>

/**
 * Respresents an end point of a timeline item
 * (either start or end) which is planned.
 */
export type PlannedEventTime = Readonly<{
  type: typeof PLANNED
  value: Date
}>

// The timing that we receive from the back-end.
// Dates may be optional, and maybe actual or planned.
export type Timing = Readonly<{
  start: ActualEventTime | PlannedEventTime | None
  end: ActualEventTime | PlannedEventTime | None
}>

export const actualEventTime = (value: Date): ActualEventTime => ({ type: ACTUAL, value })
export const plannedEventTime = (value: Date): PlannedEventTime => ({ type: PLANNED, value })

export const toPlannedOrActualEventTime = (value: Date, isActual: boolean): ActualEventTime | PlannedEventTime =>
  isActual ? actualEventTime(value) : plannedEventTime(value)

type NoEventTime = Readonly<{ type: typeof NONE }>

export const noEventTime: EventTiming = { type: NONE }
export type EventTiming = NoEventTime | ActualEventTime | PlannedEventTime
