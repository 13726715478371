export const SHOW_ALL_EVENTS_IN_SERVICES_TIMELINE = 'SHOW_ALL_EVENTS_IN_SERVICES_TIMELINE'

export type ShowAllEventsInServicesTimelineAction = Readonly<{
  type: typeof SHOW_ALL_EVENTS_IN_SERVICES_TIMELINE
}>

export function showAllEventsInServicesTimelineAction(): ShowAllEventsInServicesTimelineAction {
  return {
    type: SHOW_ALL_EVENTS_IN_SERVICES_TIMELINE,
  }
}
