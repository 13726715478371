import { EventTypeId, isEstimate, isPlanned, isActual, isCancel } from '../../shared/interfaces/event/IEvent'

export enum EventTimeCertainty {
  Estimate,
  Planned,
  Actual,
  Cancel,
}

export type EventTimeCertaintyNotCancel = Exclude<EventTimeCertainty, EventTimeCertainty.Cancel>

export function getEventTimeCertaintyFromEventType(eventType: EventTypeId): EventTimeCertainty {
  if (isEstimate(eventType)) {
    return EventTimeCertainty.Estimate
  }
  if (isPlanned(eventType)) {
    return EventTimeCertainty.Planned
  }
  if (isActual(eventType)) {
    return EventTimeCertainty.Actual
  }
  if (isCancel(eventType)) {
    return EventTimeCertainty.Cancel
  }
  throw new Error(`Unknown ${eventType}`)
}
