import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { Timeline } from '../../Domain/Timelines/Timeline'
import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../lib/Limiter/fixedSizeLimiter'
import { stringsAreEqual } from '../../utils/equals'
import { IAppState } from '../App/interfaces/IAppState'

const TIMELINE_CACHE_ID = 'timeline'
const MAX_TIMELINE_CACHE_SIZE = 20

export const timelineCacheDefinition = new CacheDefinition<IAppState, EventPortcallId, Timeline | 404>(
  TIMELINE_CACHE_ID,
  fixedSizeLimiter(MAX_TIMELINE_CACHE_SIZE),
  stringsAreEqual,
  appState => appState.timelineCache
)
