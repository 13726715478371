import { Predicate } from './predicates'

function wait(delay: number): Promise<void> {
  return new Promise<void>(resolve => {
    setTimeout(resolve, delay)
  })
}

interface IExponentialBackOffOptions<A> {
  retryOnFailure: boolean
  retryOnSuccess: Predicate<A>
}

function defaultOptions<A>(): IExponentialBackOffOptions<A> {
  return {
    retryOnFailure: false,
    retryOnSuccess: () => false,
  }
}

export async function exponentialBackOff<A>(
  fn: () => Promise<A>,
  partialOptions: Partial<IExponentialBackOffOptions<A>> = {},
  delay: number,
  numberOfRetries: number
): Promise<A> {
  const options = { ...defaultOptions<A>(), ...partialOptions }

  async function retry() {
    await wait(delay)
    return exponentialBackOff(fn, partialOptions, delay * 2, numberOfRetries - 1)
  }

  const resultPromise = fn()
  return resultPromise
    .then(result => {
      if (options.retryOnSuccess(result) && numberOfRetries > 0) {
        return retry()
      }
      return result
    })
    .catch(e => {
      if (options.retryOnFailure && numberOfRetries > 0) {
        return retry()
      }
      return Promise.reject(e)
    })
}
