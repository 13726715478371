import { parseISO, isBefore, isEqual, max } from 'date-fns'

import { firstEventWithDefinedEventTime } from '../../Domain/Events/firstEventWithEventTime'
import { NONE, PLANNED, ACTUAL } from '../../Domain/Timelines/TimelineItem'
import {
  LazyActivityTimeFrame,
  activityTimeFrameWithoutStartOrEnd,
  activityTimeFrameWithoutStart,
  plannedActivityTimeFrame,
  predictedActivityTimeFrame,
  activityTimeFrameEndBeforeStart,
  activityTimeFrameEndEqualsStart,
  actualActivityTimeFrame,
  activityTimeFrameWithoutEnd,
} from '../../Domain/Timelines/TimelinePerPartyItem'
import {
  EventTiming,
  ActualEventTime,
  PlannedEventTime,
  actualEventTime,
  plannedEventTime,
  noEventTime,
} from '../../Domain/Timelines/Timing'
import { isActual as isActualEventType } from '../../shared/interfaces/event/IEvent'
import { always } from '../../utils/functions'
import { StrictNull } from '../../utils/strictNull'
import { throwUnreachable } from '../../utils/throwUnreachable'
import { PerPartyEventJson } from '../Event/EventJson'

import { fromActivityInfoJson } from './Activities/fromActivityJson'
import { TimelinePartyJson } from './ITimelineJson'

function fromActualStart(start: ActualEventTime, end: EventTiming): LazyActivityTimeFrame {
  switch (end.type) {
    case NONE: {
      // If the item has started, but no end time
      // is known, we assume that the item is ongoing
      // (and it's end coincides with the progress).
      return currentTime => predictedActivityTimeFrame(start.value, currentTime, currentTime)
    }
    case PLANNED: {
      // If the end is planned, we assume that the
      // item is ongoing. This also applies for the case
      // where the item should have ended already
      // (end < currentTime) but we didn't receive
      // and actual yet. In this case, `progress` would
      // be bigger than `end`.
      const [endIsEqualToStart, endIsBeforeStart] = [isEqual(end.value, start.value), isBefore(end.value, start.value)]

      if (endIsEqualToStart || endIsBeforeStart) {
        return currentTime => predictedActivityTimeFrame(start.value, currentTime, currentTime)
      }

      return currentTime => {
        const progress = currentTime
        const maxEnd = max([end.value, progress])
        return predictedActivityTimeFrame(start.value, maxEnd, progress)
      }
    }

    case ACTUAL: {
      const [endIsEqualToStart, endIsBeforeStart] = [isEqual(end.value, start.value), isBefore(end.value, start.value)]

      if (endIsEqualToStart) {
        return always(activityTimeFrameEndEqualsStart(start.value))
      }

      if (endIsBeforeStart) {
        return always(activityTimeFrameEndBeforeStart(start.value))
      }

      // If the end is actual, the item must be finished.
      return always(actualActivityTimeFrame(start.value, end.value))
    }
    default:
      return throwUnreachable(end)
  }
}

function fromPlannedStart(start: PlannedEventTime, end: EventTiming): LazyActivityTimeFrame {
  switch (end.type) {
    case NONE: {
      // If we don't know the end, use the maximum
      // end as substitute (if that's available):
      return () => activityTimeFrameWithoutEnd(start.value)
    }
    case PLANNED:
    case ACTUAL: {
      const [endIsEqualToStart, endIsBeforeStart] = [isEqual(end.value, start.value), isBefore(end.value, start.value)]

      if (endIsEqualToStart) {
        return always(activityTimeFrameEndEqualsStart(start.value))
      }

      if (endIsBeforeStart) {
        return always(activityTimeFrameEndBeforeStart(start.value))
      }

      // In all other cases, use the planned or actual end.
      return always(plannedActivityTimeFrame(start.value, end.value))
    }
    default:
      return throwUnreachable(end)
  }
}

function lazyActivityTimeFrame(start: EventTiming, end: EventTiming): LazyActivityTimeFrame {
  switch (start.type) {
    case NONE: {
      // start is leading, let's wrap it up
      if (end.type !== NONE) {
        return always(activityTimeFrameWithoutStart(end.value))
      }

      return always(activityTimeFrameWithoutStartOrEnd)
    }
    case ACTUAL: {
      return fromActualStart(start, end)
    }
    case PLANNED: {
      return fromPlannedStart(start, end)
    }
    default:
      return throwUnreachable(start)
  }
}

function toEventTiming({ eventTime, eventType }: { eventTime: string; eventType: string }) {
  const date = parseISO(eventTime)
  return isActualEventType(eventType) ? actualEventTime(date) : plannedEventTime(date)
}

export const toTimelineItemsPerParty = (items: TimelinePartyJson[] = []) => {
  const perPartySorted = items.reduce<{
    ais: TimelinePartyJson[]
    agent: TimelinePartyJson[]
    rest: TimelinePartyJson[]
  }>(
    (acc, item) => {
      switch (item.party.toUpperCase()) {
        case 'AIS': {
          return { ...acc, ais: [...acc.ais, item] }
        }
        case 'AGENT': {
          return { ...acc, agent: [...acc.agent, item] }
        }
        default: {
          return { ...acc, rest: [...acc.rest, item] }
        }
      }
    },
    {
      ais: [],
      agent: [],
      rest: [],
    }
  )

  // We decided on ordering the split lanes: AIS > Agent > Other sources
  const { ais, agent, rest } = perPartySorted
  const sortedItems = [...ais, ...agent, ...rest]

  return sortedItems.map(item => {
    return {
      ...item,
      activities: item.activities.map(activity => {
        const start: EventTiming = StrictNull.fold(
          firstEventWithDefinedEventTime<PerPartyEventJson>(activity.start),
          toEventTiming,
          noEventTime
        )
        const end: EventTiming = StrictNull.fold(
          firstEventWithDefinedEventTime<PerPartyEventJson>(activity.end),
          toEventTiming,
          noEventTime
        )

        return {
          ...activity,
          timingWithProgress: lazyActivityTimeFrame(start, end),
          info: fromActivityInfoJson(activity.info),
          start,
          end,
        }
      }),
    }
  })
}
