import { none, None } from './strictNull'

type LazyCons<A> = () => A

const Lazy = {
  pure<A>(a: A): LazyCons<A> {
    return () => a
  },
  create<A>(fn: () => A): LazyCons<A> {
    let memoized: { value: A } | false = false
    return (): A => {
      if (memoized === false) {
        const value = fn()
        memoized = { value }
        return value
      }
      return memoized.value
    }
  },
  map<A, B>(l: LazyCons<A>, fn: (a: A) => B): LazyCons<B> {
    return Lazy.create(() => fn(l()))
  },
}

export const LazyList = {
  head<A>(l: LazyListCons<A>): A | None {
    const evaluated = l()
    if (evaluated === none) {
      return none
    }
    return evaluated.head
  },
  nil<A>(): LazyListCons<A> {
    return Lazy.pure<None>(none)
  },
  singleton<A>(a: A): LazyListCons<A> {
    return Lazy.pure({ head: a, tail: LazyList.nil<A>() })
  },
  fromIterator<A>(iterator: Iterator<A>): LazyListCons<A> {
    return Lazy.create(() => {
      const result = iterator.next()
      if (result.done) {
        return result.value === undefined ? LazyList.nil<A>()() : LazyList.singleton<A>(result.value)()
      }
      return { head: result.value, tail: LazyList.fromIterator(iterator) }
    })
  },
  fromArray<A>(arr: A[]): LazyListCons<A> {
    return LazyList.fromIterator(arr[Symbol.iterator]())
  },
  toArray<A>(l: LazyListCons<A>): A[] {
    const result: A[] = []
    LazyList.forEach(l, a => {
      result.push(a)
    })
    return result
  },
  flatten<A>(l: LazyListCons<LazyListCons<A>>): LazyListCons<A> {
    return Lazy.map(l, (ll): ILazyCons<A> | None => {
      if (ll === none) {
        return none
      }
      const head = ll.head()
      if (head === none) {
        return LazyList.flatten<A>(ll.tail)()
      }
      const tail = { head: head.tail, tail: ll.tail }
      return { head: head.head, tail: LazyList.flatten<A>(Lazy.pure(tail)) }
    })
  },
  map<A, B>(ll: LazyListCons<A>, fn: (a: A) => B): LazyListCons<B> {
    return Lazy.map(ll, l => {
      if (l === none) {
        return none
      }
      return { head: fn(l.head), tail: LazyList.map(l.tail, fn) }
    })
  },
  flatMap<A, B>(l: LazyListCons<A>, fn: (a: A) => LazyListCons<B>): LazyListCons<B> {
    return LazyList.flatten(LazyList.map(l, fn))
  },
  filter<A>(l: LazyListCons<A>, fn: (a: A) => boolean): LazyListCons<A> {
    return Lazy.create(() => {
      let current = l()
      while (current !== none && !fn(current.head)) {
        current = current.tail()
      }
      if (current === none) {
        return none
      }
      return { head: current.head, tail: LazyList.filter(current.tail, fn) }
    })
  },

  /**
   * The function `filterMap(..)` is like a filter and a map in one. If a value is mapped to `none`, it is
   * removed. Otherwise, the transformed value is used.
   *
   * Let's illustrate how this guy works with an example:
   *
   * Let's say we have a `LazyList<number | undefined>` and we want to filter out the `undefined`s.
   * Doing a `filter` works, but `filter` won't change the type to `LazyList<number>`. In fact, `filter`
   * won't touch the type at all.
   *
   * In this case, we use `filterMap` and define a function that maps an `undefined` to `none` and a `number`
   * to a `number`. This function has the type `fn: (arg: number | undefined) => number | None`. Because of
   * how `filterMap` works, we'll end up with a `LazyList<number>` instead of a `LazyList<number | None>`.
   */
  filterMap<A, B>(l: LazyListCons<A>, fn: (a: A) => B | None): LazyListCons<B> {
    return Lazy.create(() => {
      let current = l()
      let head: B | None = none
      let tail: LazyListCons<A> = LazyList.nil<A>()
      while (current !== none) {
        head = fn(current.head)
        tail = current.tail
        if (head !== none) {
          break
        }
        current = current.tail()
      }
      if (head === none) {
        return none
      }
      return { head, tail: LazyList.filterMap(tail, fn) }
    })
  },
  forEach<A>(l: LazyListCons<A>, fn: (a: A) => void): void {
    let evaluated = l()
    while (evaluated !== none) {
      fn(evaluated.head)
      evaluated = evaluated.tail()
    }
  },
}

interface ILazyCons<A> {
  readonly head: A
  readonly tail: LazyListCons<A>
}

export type LazyListCons<A> = LazyCons<ILazyCons<A> | None>
