import { Limiter } from '../Limiter/Limiter'

import {
  IGenericAction,
  isAction,
  isAwaitingResultAction,
  isResultReceivedAction,
  isRequestCancelledAction,
  isClearCacheAction,
  isClearCacheItemAction,
} from './Action'
import { CacheItem, expireForKey, handleResult, cancelRequest, expire } from './CacheItem'
import { REQUEST_CANCELLED } from './consts'
import { awaitingResult } from './RequestState'

export const createCacheItemsReducer =
  <Key, Result, Meta>(
    resourceId: string,
    keysAreEqual: (left: Key, right: Key) => boolean,
    limiter: Limiter<Key, Result, Meta>
  ) =>
  (ci: Array<CacheItem<Key, Result, Meta>> = [], action: IGenericAction): Array<CacheItem<Key, Result, Meta>> => {
    if (!isAction<Key, Result, Meta>(action) || action.resourceId !== resourceId) {
      return ci
    }

    function handleAction(cacheItems: Array<CacheItem<Key, Result, Meta>>): Array<CacheItem<Key, Result, Meta>> {
      if (isAwaitingResultAction<Key, Meta>(action)) {
        return [
          {
            key: action.key,
            requestState: awaitingResult(action.requestId, action.currentTime),
            meta: action.meta,
          },
          ...expireForKey(cacheItems, keysAreEqual, action.key, action.currentTime),
        ]
      }
      if (isResultReceivedAction<Key, Result>(action)) {
        return cacheItems.map(cacheItem =>
          handleResult(cacheItem, action.key, action.requestId, action.result, action.currentTime, keysAreEqual)
        )
      }
      if (isRequestCancelledAction(action)) {
        return cacheItems.map(cacheItem => cancelRequest(cacheItem, action.requestId, action.currentTime))
      }
      if (isClearCacheAction(action)) {
        return cacheItems.map(cacheItem => expire(cacheItem, action.currentTime))
      }
      if (isClearCacheItemAction<Key>(action)) {
        return expireForKey(cacheItems, keysAreEqual, action.key, action.currentTime)
      }
      // This clause should never be reached. It's better to be safe than sorry, so we'll handle this case anyway.
      return cacheItems
    }

    function filterCancelledRequests(
      cacheItems: Array<CacheItem<Key, Result, Meta>>
    ): Array<CacheItem<Key, Result, Meta>> {
      return cacheItems.filter(cItems => cItems.requestState.type !== REQUEST_CANCELLED)
    }

    return [handleAction, filterCancelledRequests, limiter].reduce((x, f) => f(x), ci)
  }
