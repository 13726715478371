import { AnchorAreaVisit } from '../../Domain/AnchorAreaVisits/AnchorAreaVisit'
import { fromMostAccuratesJson } from '../Event/fromMostAccuratesJson'

import { AnchorAreaVisitJson } from './AnchorAreaVisitJson'

export function fromAnchorAreaVisitJson(json: AnchorAreaVisitJson): AnchorAreaVisit {
  return {
    anchorAreaName: json.anchorAreaName,
    mostAccurates: fromMostAccuratesJson(json.mostAccurates),
    notifications: json.notifications,
  }
}
