import { NotificationId } from '../InfoNotifications/InfoNotifications.interfaces'

export const PROCESS_UPDATE = 'PROCESS_UPDATE'

export interface IProcessUpdate {
  type: typeof PROCESS_UPDATE
  updateId: string
  notificationIds: NotificationId[]
}

export function processUpdate(updateId: string, notificationIds: NotificationId[]): IProcessUpdate {
  return {
    type: PROCESS_UPDATE,
    updateId,
    notificationIds,
  }
}
