const TICKER = 'TICKER'

export interface ITickerAction {
  type: typeof TICKER
}

export function ticker(): ITickerAction {
  return {
    type: TICKER,
  }
}
