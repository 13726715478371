import { clearCacheAction } from '../lib/asyncSelector/Action'
import { AppAction } from '../modules/App/App.actions'

import { ProntoStore } from './storeTypes'

const doEvery = (intervalInMilliseconds: number, delay?: number) => (action: () => void) => {
  setTimeout(() => {
    setInterval(() => {
      action()
    }, intervalInMilliseconds)
  }, delay || 0)
}

export const dispatchEvery =
  (store: ProntoStore, intervalInMilliseconds: number, delay?: number) => (action: AppAction) => {
    doEvery(intervalInMilliseconds, delay)(() => store.dispatch(action))
  }

export const clearEvery = (store: ProntoStore, intervalInMilliseconds: number, delay?: number) => (cacheId: string) =>
  doEvery(intervalInMilliseconds, delay)(() => store.dispatch(clearCacheAction(cacheId, new Date().valueOf())))
