import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { IPortcallWithEvents } from '../../Domain/Portcall/IPortcallWithEvents'
import { IAppState } from '../../modules/App/interfaces/IAppState'
import { stringsAreEqual } from '../../utils/equals'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'

import { CacheDefinition } from './CacheDefinition'

const PORTCALL_WITH_EVENTS_CACHE_ID = 'portcall-with-events'
const MAX_PORTCALL_WITH_EVENTS_CACHE_SIZE = 5

export const portcallWithEventsCacheDefinition = new CacheDefinition<
  IAppState,
  EventPortcallId,
  IPortcallWithEvents | 404
>(
  PORTCALL_WITH_EVENTS_CACHE_ID,
  fixedSizeLimiter(MAX_PORTCALL_WITH_EVENTS_CACHE_SIZE),
  stringsAreEqual,
  appState => appState.portcallWithEventsCache
)
