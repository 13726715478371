/**
 * Because Javascript engines (generally) don't support tail-recursive functions
 * we'll use a trampoline in the cases that recursive functions are easier to
 * understand.
 *
 * See https://blog.logrocket.com/using-trampolines-to-manage-large-recursive-loops-in-javascript-d8c9db095ae3
 * for an introduction to trampolines.
 */

const TRAMPOLINE_DONE = 'TRAMPOLINE_DONE'
const TRAMPOLINE_RUNNING = 'TRAMPOLINE_RUNNING'

export type Trampoline<Result> = ITrampolineDone<Result> | ITrampolineRunning<Result>

interface ITrampolineDone<Result> {
  type: typeof TRAMPOLINE_DONE
  result: Result
}

interface ITrampolineRunning<Result> {
  type: typeof TRAMPOLINE_RUNNING
  next: () => Trampoline<Result>
}

export function done<Result>(result: Result): ITrampolineDone<Result> {
  return {
    type: TRAMPOLINE_DONE,
    result,
  }
}

export function running<Result>(next: () => Trampoline<Result>): ITrampolineRunning<Result> {
  return {
    type: TRAMPOLINE_RUNNING,
    next,
  }
}

export function run<Result>(fn: () => Trampoline<Result>): Result {
  let trampoline: Trampoline<Result> = running(fn)
  while (trampoline.type !== TRAMPOLINE_DONE) {
    trampoline = trampoline.next()
  }
  return trampoline.result
}
