import { AppAction } from '../../modules/App/App.actions'
import { isEmpty } from '../../utils/arr'

import { isPushToQueueAction, isRemoveFromQueueAction, isPushToQueueIfEmptyAction } from './queueActions'

export const createQueueReducer =
  <Message>(cacheId: string) =>
  (queue: Message[] = [], action: AppAction): Message[] => {
    if (isPushToQueueAction<Message>(action) && action.cacheId === cacheId) {
      const updatedPortcallsQueue = [...queue, action.message]
      return updatedPortcallsQueue
    }
    if (isPushToQueueIfEmptyAction<Message>(action) && action.cacheId === cacheId) {
      if (isEmpty(queue)) {
        const updatedPortcallsQueue = [...queue, action.message]
        return updatedPortcallsQueue
      }
      return queue
    }
    if (isRemoveFromQueueAction(action) && action.cacheId === cacheId) {
      const restOfQueue = queue.filter(m => m !== action.message)
      return restOfQueue
    }
    return queue
  }
