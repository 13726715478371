import { LOGIN_EXPIRED, LOGOUT, SET_CLIENT_PREFERENCES } from '../actionTypes'
import { ILoginExpiredAction, ISetClientPreferencesAction } from './auth.interfaces'

import type { ClientPreferences } from '../../Domain/Users/ClientPreferences/ClientPreferences'
import type { ProntoAction, ProntoDispatch } from '../storeTypes'

export const setLoginExpired = (): ILoginExpiredAction => ({
  type: LOGIN_EXPIRED,
})

export const prontoLogout = (): ProntoAction => (dispatch: ProntoDispatch) => {
  dispatch({ type: LOGOUT })
}

export const setClientPreferences = (preferences: ClientPreferences): ISetClientPreferencesAction => ({
  type: SET_CLIENT_PREFERENCES,
  preferences,
})
