// As requested by Pronto AIS team for:
//
// * Better performance
// * Better measurability
// * Better guarantees
export const MAX_NUMBER_OF_VESSELS_IN_REQUESTS = 20
export const MAX_NUMBER_OF_VESSELS_IN_STATUS_REQUESTS = 500

// As requested by Pronto AIS team:
export const HISTORY_LIMIT_IN_DAYS = 30
