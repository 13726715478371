import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { IPortcallWithEvents } from '../../Domain/Portcall/IPortcallWithEvents'
import { CacheItem, expireForKey } from '../../lib/asyncSelector/CacheItem'
import { portcallWithEventsCacheDefinition } from '../../lib/asyncSelector/portcallWithEventsCacheDefinition'
import { WEBSOCKET_VISIT_UPDATED_EVENT_IDS, WEBSOCKET_VISIT_DELETED_EVENT_IDS } from '../../store/actionTypes'
import { removeWhere } from '../../utils/arr'
import { AppAction } from '../App/App.actions'

export function portcallWithEventsCacheReducer(
  state: Array<CacheItem<EventPortcallId, IPortcallWithEvents | 404>> = [],
  action: AppAction
) {
  const afterCacheActions = portcallWithEventsCacheDefinition.reducer(state, action)

  switch (action.type) {
    case WEBSOCKET_VISIT_UPDATED_EVENT_IDS:
      return action.portcallIds.reduce(
        (acc, portcallId) =>
          expireForKey(acc, portcallWithEventsCacheDefinition.keysAreEqual, portcallId, action.currentTime),
        afterCacheActions
      )
    case WEBSOCKET_VISIT_DELETED_EVENT_IDS:
      return action.portcallIds.reduce(
        (acc, portcallId) =>
          removeWhere(acc, cacheItem => portcallWithEventsCacheDefinition.keysAreEqual(cacheItem.key, portcallId)),
        afterCacheActions
      )
    default:
      return afterCacheActions
  }
}
