import { combineReducers } from 'redux'

import { berthsCacheDefinition } from '../lib/asyncSelector/berthsCacheDefinition'
import { nauticalLocationCacheDefinition } from '../lib/asyncSelector/nauticalLocationsCacheDefinition'
import { notificationsCacheDefinition } from '../lib/asyncSelector/notificationsCacheDefinition'
import { portcallsCacheDefinition } from '../lib/asyncSelector/portcallsCacheDefinition'
import { portsCacheDefinition } from '../lib/asyncSelector/portsCacheDefinition'
import { createQueueReducer } from '../lib/asyncSelector/queueReducer'
import { schedulesCacheDefinition } from '../lib/asyncSelector/schedulesCacheDefinition'
import { shipLocationsCacheDefinition } from '../lib/asyncSelector/shipLocationsCacheDefinition'
import { terminalsCacheDefinition } from '../lib/asyncSelector/terminalsCacheDefinition'
import { vesselDetailsCacheDefinition } from '../lib/asyncSelector/vesselDetailsCacheDefinition'
import { AppAction } from '../modules/App/App.actions'
import { IAppState } from '../modules/App/interfaces/IAppState'
import { fuelSavingsCacheDefinition } from '../modules/BunkerAndCO2/fuelSavingsCacheDefinition'
import { actualHydrometeoObservationsCacheDefinition } from '../modules/HydrometeoObservations/Actual/actualHydrometeoObservationsCacheDefinition'
import { predictedWindDirectionCacheDefinition } from '../modules/HydrometeoObservations/Predicted/Wind/predictedWindDirectionCacheDefinition'
import { predictedWindSpeedCacheDefinition } from '../modules/HydrometeoObservations/Predicted/Wind/predictedWindSpeedCacheDefinition'
import { notificationsCacheReducer } from '../modules/InfoNotificationsPopup/infoNotificationsCacheReducer'
import { subscriptionConfigurationReducer } from '../modules/NotificationConfiguration/subscription/SubscriptionConfiguration.reducer'
import { notificationUpdatesReducer } from '../modules/NotificationUpdate/notificationUpdate.reducer'
import { portcallIdsCacheReducer } from '../modules/portcallIdsCache/portcallIdsCacheReducer'
import { portcallIdsForScheduleCacheReducer } from '../modules/portcallIdsCache/portcallIdsForScheduleCacheReducer'
import { portcallWithEventsCacheReducer } from '../modules/PortcallWithEvents/portcallWithEventsCacheReducer'
import { timeMachineCacheDefinition } from '../modules/TimeMachine/timeMachineCacheDefinition'
import { vesselDetailsCacheDefinition as vesselDetailsForVesselIdCacheDefinition } from '../modules/VesselDetails/vesselDetailsCacheDefinition'

import authReducers from './auth/auth.reducers'
import configReducers from './config/config.reducers'
import errorReducers from './error/error.reducers'
import filtersReducers from './filters/filters.reducers'
import { portNotificationsReducer } from './PortNotifications/portNotificationsReducer'
import { preferencesReducer } from './Preferences/preferencesReducer'
import { portcallScheduleCacheDefinition } from './schedules/portcallScheduleCacheDefinition'
import { selectedDetailsReducer } from './selectedDetails/selectedDetails.reducer'
import { CURRENT_VERSION, debugVersioning } from './storage'
import systemStatusMessagesReducer from './systemStatusMessages/systemStatusMessages.reducer'
import { timelineCacheReducer } from './timeline/timelineCache.reducer'
import { traceCacheDefinition } from './traces/traceCacheDefinition'
import userInterfaceReducer from './ui/userInterface.reducer'

function versionReducer(state: number = CURRENT_VERSION): number {
  if (state !== CURRENT_VERSION) {
    debugVersioning(`Version ${state} received where ${CURRENT_VERSION} was expected. Ignoring error.`)
  }
  return state
}

// Combining Reducers into a root reducer for use with store
const reducers = combineReducers<IAppState, AppAction>({
  version: versionReducer,
  auth: authReducers,
  selectedDetails: selectedDetailsReducer,
  config: configReducers,
  preferences: preferencesReducer,
  filters: filtersReducers,
  ui: userInterfaceReducer,
  error: errorReducers,
  systemStatusMessages: systemStatusMessagesReducer,
  subscriptionConfiguration: subscriptionConfigurationReducer,
  portcallIdsCache: portcallIdsCacheReducer,
  portcallIdsForScheduleCache: portcallIdsForScheduleCacheReducer,
  terminalsCache: terminalsCacheDefinition.reducer,
  berthsCache: berthsCacheDefinition.reducer,
  portcallMessagesQueue: createQueueReducer(portcallsCacheDefinition.cacheId),
  portcallsCache: portcallsCacheDefinition.reducer,
  portcallWithEventsCache: portcallWithEventsCacheReducer,
  shipLocationsCache: shipLocationsCacheDefinition.reducer,
  vesselDetailsCache: vesselDetailsCacheDefinition.reducer,
  vesselDetailsForVesselIdCache: vesselDetailsForVesselIdCacheDefinition.reducer,
  fuelSavingsCache: fuelSavingsCacheDefinition.reducer,
  notificationUpdates: notificationUpdatesReducer,
  timeMachineCache: timeMachineCacheDefinition.reducer,
  traceCache: traceCacheDefinition.reducer,
  notificationMessagesQueue: createQueueReducer(notificationsCacheDefinition.cacheId),
  notificationsCache: notificationsCacheReducer,
  timelineCache: timelineCacheReducer,
  portsCache: portsCacheDefinition.reducer,
  schedulesCache: schedulesCacheDefinition.reducer,
  nauticalLocationsCache: nauticalLocationCacheDefinition.reducer,
  actualHydrometeoObservationsCache: actualHydrometeoObservationsCacheDefinition.reducer,
  predictedWindSpeedCache: predictedWindSpeedCacheDefinition.reducer,
  predictedWindDirectionCache: predictedWindDirectionCacheDefinition.reducer,
  portcallSchedules: portcallScheduleCacheDefinition.reducer,
  portNotifications: portNotificationsReducer,
})

export default reducers
