import { saveDefaultFilters } from '../../shared/rest/preferences'
import { APPLY_FILTERS, RELOAD_FILTERS } from '../actionTypes'
import { setClientPreferences } from '../auth/auth.actions'
import { setError } from '../error/error.actions'

import type { DefaultFilters } from '../../Domain/Users/ClientPreferences/DefaultFilters'
import type { IApplyFiltersAction, IReloadFiltersAction } from '../../modules/Filters/Filters.actions'
import type { IFilters } from '../../shared/interfaces/filters/IFilters'
import type { IUser } from '../../shared/interfaces/settings/Settings'
import type { ProntoDispatch } from '../storeTypes'

export const applyFilters = (filters: IFilters): IApplyFiltersAction => ({
  type: APPLY_FILTERS,
  filters,
})
export const resetActiveFiltersForUser = (user: IUser): IReloadFiltersAction => ({
  type: RELOAD_FILTERS,
  user,
})

export const saveFiltersAsDefault = (filters: DefaultFilters) => async (dispatch: ProntoDispatch) => {
  try {
    // first update default filters in backend
    await saveDefaultFilters(filters)

    // and update local state afterwards
    dispatch(
      setClientPreferences({
        defaultFilters: filters,
      })
    )
  } catch (e) {
    dispatch(setError(e))
  }
}
