import { AppAction } from '../../App/App.actions'

import { SAVE_CONFIGURATION, UPDATE_CONFIGURATION } from './SubscriptionConfiguration.actions'
import { SubscriptionConfiguration } from './SubscriptionConfiguration.interfaces'

export function subscriptionConfigurationReducer(state: SubscriptionConfiguration = {}, action: AppAction) {
  switch (action.type) {
    case UPDATE_CONFIGURATION:
      return {
        ...state,
        [action.subscriptionType]: action.update,
      }
    case SAVE_CONFIGURATION:
      return action.configuration.reduce((acc, curr) => {
        return {
          ...acc,
          [curr.type]: curr.config,
        }
      }, state)
    default:
      return state
  }
}
