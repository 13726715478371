import React, { useCallback, useState } from 'react'
import { CURRENT_VERSION_LEGAL_TERMS } from '../../constants'
import { onGlobalError } from '../../middlewares/errorLogger'
import { acceptLegalTerms } from '../../rest/acceptLegalTerms'
import { IUser } from '../../shared/interfaces/settings/Settings'
import { userHasAcceptedLegalTerms } from './LegalTermsPage.selector'

const LegalTermsPage = React.lazy(() => import('../../components/LegalTermsPage/LegalTermsPage'))

export const HasAcceptedLegalTerms: React.FC<{ user: IUser }> = ({ children, user }) => {
  const [hasAccepted, setHasAccepted] = useState(false)

  const onAcceptCallback = useCallback(() => {
    acceptLegalTerms(CURRENT_VERSION_LEGAL_TERMS)
      .then(() => setHasAccepted(true))
      .catch(e => {
        onGlobalError({
          error: e,
          reference: 'acceptLegalTermsAndNotifyBackend',
        })
      })
  }, [])

  if (userHasAcceptedLegalTerms(user) || hasAccepted) {
    return <>{children}</>
  }

  return <LegalTermsPage onAccept={onAcceptCallback} />
}
