import { mapValues } from './obj'
import { None, none } from './strictNull'
import { validated, Validated } from './Validated'

export function verifyObject(v: any): Validated<None, Record<string | number | symbol, any>> {
  if (typeof v === 'object') {
    return validated.ok(v)
  }
  return validated.error(none)
}

export const verifyPrimitiveLiteral =
  <Primitive extends boolean | string | number | null | undefined>(literal: Primitive) =>
  (v: any): Validated<None, Primitive> => {
    return literal === v ? validated.ok(literal) : validated.error(none)
  }

export function verifyNumber(v: any): Validated<None, number> {
  if (typeof v === 'number') {
    return validated.ok(v)
  }
  return validated.error(none)
}

export function verifyBoolean(v: any): Validated<None, boolean> {
  if (typeof v === 'boolean') {
    return validated.ok(v)
  }
  return validated.error(none)
}

const verifyObjectShape =
  <O extends Record<string, unknown>>(shape: { [K in keyof O]: (v: any) => Validated<None, O[K]> }) =>
  (o: Record<string | number | symbol, any>): Validated<None, O> => {
    const validatedValues = mapValues(shape, (v, k) => {
      const valueFromObject = o[k]
      return shape[k](valueFromObject)
    }) as { [K in keyof O]: Validated<None, O[K]> }
    return validated.all(validatedValues)
  }

export const verifyObjectWithShape =
  <O extends Record<string, unknown>>(shape: { [K in keyof O]: (v: any) => Validated<None, O[K]> }) =>
  (v: any): Validated<None, O> => {
    return verifyObject(v).flatMap(verifyObjectShape(shape))
  }
