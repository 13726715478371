import { RESULT_RECEIVED, AWAITING_RESULT, RESULT_EXPIRED } from '../../lib/asyncSelector/consts'

type ExpirableStatus = typeof AWAITING_RESULT | typeof RESULT_RECEIVED | typeof RESULT_EXPIRED

export interface IExpirable<A> {
  status: ExpirableStatus
  result: A
}

export function getStatus(expirable: IExpirable<any>): ExpirableStatus {
  return expirable.status
}

export function getResult<A>(expirable: IExpirable<A>): A {
  return expirable.result
}

export function toExpirable<A>(result: A): IExpirable<A> {
  return {
    status: RESULT_RECEIVED,
    result,
  }
}
