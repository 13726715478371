import { ServiceType } from '../../Domain/ServiceVessels/ServiceType'
import { ServiceVessel } from '../../Domain/ServiceVessels/ServiceVessel'
import { IShipId } from '../../Domain/VesselDetails/IVesselDetails'
import { None, none } from '../../utils/strictNull'
import { EventJson } from '../Event/EventJson'
import { eventTimeFromEventJson } from '../Event/eventTimeFromEventJson'

const SERVICE_TYPE_PILOT = 'Pilot'
const SERVICE_TYPE_TUG = 'Tug'
const SERVICE_TYPE_LINESMAN = 'Linesman'
const SERVICE_TYPE_OTHER = 'Other'

type ServiceTypeJson =
  | typeof SERVICE_TYPE_PILOT
  | typeof SERVICE_TYPE_TUG
  | typeof SERVICE_TYPE_LINESMAN
  | typeof SERVICE_TYPE_OTHER

const servicesTypesByServiceTypeJson: Record<ServiceTypeJson, ServiceType> = {
  [SERVICE_TYPE_PILOT]: ServiceType.Pilot,
  [SERVICE_TYPE_TUG]: ServiceType.Tug,
  [SERVICE_TYPE_LINESMAN]: ServiceType.Linesman,
  [SERVICE_TYPE_OTHER]: ServiceType.Other,
}

export type ServiceVesselJson = Readonly<{
  ship: IShipId
  name?: string
  start?: EventJson
  end?: EventJson
  serviceType: ServiceTypeJson
}>

export function fromServiceVesselJson(json: ServiceVesselJson): ServiceVessel | None {
  if (json.ship.mmsi === undefined) {
    return none
  }

  return {
    mmsi: json.ship.mmsi,
    name: json.ship.name === undefined ? none : json.ship.name,
    type: servicesTypesByServiceTypeJson[json.serviceType],
    from: json.start === undefined ? none : eventTimeFromEventJson(json.start),
    to: json.end === undefined ? none : eventTimeFromEventJson(json.end),
  }
}
