import { parseISO } from 'date-fns'

import { NOT_AVAILABLE } from '../../constants'
import { IVesselDetails, IShipId, VESSEL_DETAILS_EMPTY } from '../../Domain/VesselDetails/IVesselDetails'
import { none } from '../../utils/strictNull'

import { IVesselMasterDataJson } from './IVesselMasterDataJson'

export const SPIRE_VESSEL_TYPE_UNKNOWN = 'Barges & Others' as const

export const vesselDetailsFromVesselMasterDataJson = (json: IVesselMasterDataJson): IVesselDetails => ({
  ship: toShipId(json),
  name: json.name || NOT_AVAILABLE,
  callSign: json.callSign || none,
  shipType: json.shipType || none,
  maxDraughtDerived: json.maxDraughtDerived || none,
  lengthOverall: json.lengthOverall || none,
  beam: json.beam || none,
  aisPositionAllowed: json.aisPositionAllowed || json.imo !== undefined,
  outOfService: json.outOfService || VESSEL_DETAILS_EMPTY(none).outOfService,
  isDeepSeaVessel: json.isDeepSeaVessel || false,
  photoUrl: json.photoUrl || none,
  id: json.id,
  imo: json.imo || none,
  mmsi: json.mmsi || none,
  eni: json.eni || none,
  uscg: json.uscg || none,
  flag: json.flag || none,
  statCodeDerived: json.statCodeDerived || none,
  airDraught: json.airDraught || none,
  aisPositionAllowedReason: json.aisPositionAllowedReason || none,
  aisPositionSensitive: json.aisPositionSensitive || none,
  invalid: json.invalid || none,
  commercialOwner: json.commercialOwner || none,
  spireVesselType: json.spireVesselType || SPIRE_VESSEL_TYPE_UNKNOWN,
  spireSubtype: json.spireSubtype || none,
  iceClass: json.iceClass || none,
  vesselNameDate: json.vesselNameDate ? parseISO(json.vesselNameDate) : none,
  builtYear: json.builtYear || none,
  builder: json.builder || none,
  classSociety: json.classSociety || none,
  dwt: json.dwt || none,
  grossTonnage: json.grossTonnage || none,
  netTonnage: json.netTonnage || none,
  tpc: json.tpc || none,
  depth: json.depth || none,
  displacement: json.displacement || none,
  liquidCapacity98Pcnt: json.liquidCapacity98Pcnt || none,
  grainCapacity: json.grainCapacity || none,
  mainEngineDesigner: json.mainEngineDesigner || none,
  mainEngine: json.mainEngine || none,
  engineCapacity: json.engineCapacity || none,
  capacityContainers: json.capacityContainers || none,
})

const toShipId = (json: IVesselMasterDataJson): IShipId => {
  return json || {}
}
