import type { Valid } from './Valid'
import type { Validated } from './Validated'

export class Invalid<E, T> {
  public static readonly type: 'invalid' = 'invalid'

  public readonly type = Invalid.type

  public readonly errorType: E = null as any as E

  public readonly valueType: T = null as any as T

  constructor(public readonly errors: E[]) {}

  public isValid(): this is Valid<E, T> {
    return false
  }

  public map<U>(_fn: (t: T) => U): Validated<E, U> {
    return new Invalid<E, U>(this.errors)
  }

  public flatMap<U, F = E>(_fn: (t: T) => Validated<F, U>): Validated<E | F, U> {
    return new Invalid<E, U>(this.errors)
  }

  public fold<U>(ok: (v: Valid<E, T>) => U, error: (v: Invalid<E, T>) => U): U {
    return error(this)
  }

  public orElse(alternative: Validated<E, T>): Validated<E, T> {
    return alternative
  }

  public getOrElse<Alternative>(alternative: Alternative): T | Alternative {
    return alternative
  }

  public coerce<U>(): Validated<E, U> {
    return new Invalid<E, U>(this.errors)
  }
}
