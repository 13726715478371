import { Notification } from '../../Domain/Notifications/INotification'
import { update, CacheItem } from '../../lib/asyncSelector/CacheItem'
import { notificationsCacheDefinition } from '../../lib/asyncSelector/notificationsCacheDefinition'
import { leftJoinInPairs } from '../../lib/maps/synchronize'
import { none } from '../../utils/strictNull'
import { AppAction } from '../App/App.actions'
import { IExpirable } from '../CacheQueue/IExpirable'
import { PROCESS_UPDATE } from '../NotificationUpdate/notificationUpdate.actions'
import { INotificationUpdate, applyUpdate } from '../NotificationUpdate/NotificationUpdate.interfaces'

function handleProcessUpdate(
  expirableNotifications: Array<IExpirable<Notification>>,
  updatesToProcess: INotificationUpdate[]
): Array<IExpirable<Notification>> {
  return leftJoinInPairs(
    expirableNotifications,
    updatesToProcess,
    cn => cn.result.id,
    nu => nu.notificationId
  ).map((o): IExpirable<Notification> => {
    if (o.right === none) {
      return o.left
    }
    return { ...o.left, result: applyUpdate(o.left.result, o.right.value) }
  })
}

export function notificationsCacheReducer(
  cacheItems: Array<CacheItem<string[], Array<IExpirable<Notification>>>> | undefined,
  action: AppAction
) {
  const afterCacheActions = notificationsCacheDefinition.reducer(cacheItems, action)

  switch (action.type) {
    case PROCESS_UPDATE:
      return update(
        afterCacheActions,
        expirableNotifications => handleProcessUpdate(expirableNotifications, action.notificationUpdates),
        action.timeStamp,
        notificationsCacheDefinition.limiter
      )

    default:
      return afterCacheActions
  }
}
