import { CacheItem, forKey } from '../../lib/asyncSelector/CacheItem'
import { RESULT_RECEIVED, RESULT_EXPIRED, REQUEST_CANCELLED } from '../../lib/asyncSelector/consts'
import { LazyList } from '../../utils/lazy'
import { none } from '../../utils/strictNull'

import { FetchAllJob } from './FetchAllJob'
import { FetchExpiredJob } from './FetchExpiredJob'
import { IExpirable } from './IExpirable'
import { IFetchJob, idleFetchJob } from './IFetchJob'
import { IRequestAll } from './IRequestAll'

export function requestAllMessageToJob<Key extends string, Result, Meta>(
  message: IRequestAll<Key, Meta>,
  cacheItems: Array<CacheItem<Key[], Array<IExpirable<Result>>, Meta>>,
  toKey: (result: Result) => Key,
  keysAreEqual: (left: Key[], right: Key[]) => boolean
): IFetchJob<Key, Result, Meta> {
  const cacheItemsForKey = forKey(cacheItems, message.keys, keysAreEqual)
  const firstCacheItemForKey = LazyList.head(cacheItemsForKey)
  if (firstCacheItemForKey === none) {
    return new FetchAllJob(message.keys, message.meta, cacheItems, message.cacheId, toKey)
  }
  const { requestState } = firstCacheItemForKey
  if (requestState.type === RESULT_RECEIVED) {
    return new FetchExpiredJob(message.cacheId, requestState.result, toKey)
  }
  if (requestState.type === RESULT_EXPIRED || requestState.type === REQUEST_CANCELLED) {
    return new FetchAllJob(message.keys, message.meta, cacheItems, message.cacheId, toKey)
  }
  return idleFetchJob()
}
