import { EventPortcallId } from '../../Domain/Portcall/IPortcall'

export const OPEN_PORTBASE_DIALOG = 'OPEN_PORTBASE_DIALOG'

export type OpenPortbaseDialogAction = Readonly<{
  type: typeof OPEN_PORTBASE_DIALOG
  eventPortcallId: EventPortcallId
}>

export function openPortbaseDialogAction(eventPortcallId: EventPortcallId): OpenPortbaseDialogAction {
  return {
    type: OPEN_PORTBASE_DIALOG,
    eventPortcallId,
  }
}
