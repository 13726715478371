import { Activity, ActivityInfo } from '../../../Domain/Timelines/Activities/Activity'
import { StrictNull } from '../../../utils/strictNull'
import { fromEventJson } from '../../Event/fromEventJson'

import { ActivityJson, ActivityInfoJson } from './ActivityJson'

export const fromActivityInfoJson = ({ ship, shipName, location, reportedNumber }: ActivityInfoJson): ActivityInfo => ({
  ship: StrictNull.fromUndefined(ship),
  shipName: StrictNull.fromUndefined(shipName),
  location: StrictNull.fromUndefined(location),
  reportedNumber: StrictNull.fromUndefined(reportedNumber),
})

export function fromActivityJson(json: ActivityJson): Activity {
  return {
    start: json.start.map(fromEventJson),
    end: json.end.map(fromEventJson),
    info: fromActivityInfoJson(json.info),
  }
}
