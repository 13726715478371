import {
  getConflictingSubscriptionRecords,
  groupBySubscriptions,
  SubscriptionConfigurationsGroupedBySubscriptionType,
  toSubscriptions,
} from '../Domain/Subscriptions/SubscriptionConverter'
import { BACKEND_URL } from '../shared/constants'
import { createHeaders } from '../shared/utils/createHeaders'
import { handleJSONResponse } from '../shared/utils/rest'
import { getAuthToken } from '../utils/auth/authClientObservable'
import { always } from '../utils/functions'
import { None, none } from '../utils/strictNull'

import type { IBackendFacingSubscription } from '../Domain/Subscriptions/IBackendFacingSubscription'
import type { ISubscription } from '../Domain/Subscriptions/ISubscription'
import type { ISubscriptionConfiguration } from '../Domain/Subscriptions/ISubscriptionConfiguration'

export async function saveSubscriptionsNotifications(
  subscription: IBackendFacingSubscription,
  userEmail: string | None
): Promise<None> {
  const authToken = await getAuthToken()

  const url = `${BACKEND_URL}/api/users/me/notifications/subscriptions/save`
  const configWithUserEmail: ISubscriptionConfiguration = {
    ...subscription.config,
    email: userEmail === none ? false : subscription.config.email,
    emailAddress: userEmail || '',
  }
  const subscriptionWithUserEmail: IBackendFacingSubscription = {
    type: subscription.type,
    config: configWithUserEmail,
  }
  const options = {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify(subscriptionWithUserEmail),
  }
  return fetch(url, options)
    .then(response => {
      if (response.status === 204) {
        return Promise.resolve(none)
      }
      return handleJSONResponse(options, response)
    })
    .then(always(none))
}

export async function fetchNotificationSubscriptions(): Promise<ISubscription[]> {
  const authToken = await getAuthToken()

  const url = `${BACKEND_URL}/api/users/me/notifications/subscriptions/retrieve`
  const options = {
    method: 'GET',
    headers: createHeaders(authToken),
  }
  return fetch(url, options)
    .then(response => handleJSONResponse(options, response))
    .catch(() => [])
    .then(ensureSubscriptionsUpToDateFactory()) as Promise<ISubscription[]>
}

function ensureSubscriptionsUpToDateFactory() {
  return async (subscriptionJsons: IBackendFacingSubscription[]): Promise<ISubscription[]> => {
    const subscriptionRecords: SubscriptionConfigurationsGroupedBySubscriptionType =
      groupBySubscriptions(subscriptionJsons)
    const subscriptionsRequiringUpdate = getConflictingSubscriptionRecords(subscriptionRecords)
    if (subscriptionsRequiringUpdate.length > 0) {
      subscriptionsRequiringUpdate.forEach(subscriptionRequiringUpdate => {
        saveSubscriptionsNotifications(subscriptionRequiringUpdate, subscriptionRequiringUpdate.config.emailAddress)
      })
      return fetchNotificationSubscriptions()
    }

    return toSubscriptions(subscriptionJsons) as ISubscription[]
  }
}
