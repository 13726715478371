import { AppAction } from '../App/App.actions'

import { ADD_NOTIFICATION_UPDATES, REMOVE_NOTIFICATION_UPDATE } from './notificationUpdate.actions'
import { INotificationUpdate } from './NotificationUpdate.interfaces'

export function notificationUpdatesReducer(
  updates: INotificationUpdate[] = [],
  action: AppAction
): INotificationUpdate[] {
  switch (action.type) {
    case ADD_NOTIFICATION_UPDATES:
      return [...updates, ...action.updates]
    case REMOVE_NOTIFICATION_UPDATE:
      return updates.filter(u => action.updateId !== u.updateId)
    default:
      return updates
  }
}
