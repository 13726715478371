import { CLEAR_ERROR, SET_ERROR } from '../actionTypes'

import type { IAppError } from '../../shared/interfaces/errors/IAppError'
import type { IClearErrorAction, ISetErrorAction } from './error.interfaces'

export const setError = (error: IAppError): ISetErrorAction => ({
  type: SET_ERROR,
  error,
})

export const clearError = (): IClearErrorAction => {
  return {
    type: CLEAR_ERROR,
  }
}
