import { CURRENT_VERSION_LEGAL_TERMS } from '../../constants'
import { IUser } from '../../shared/interfaces/settings/Settings'

export function userHasAcceptedLegalTerms(user: IUser): boolean {
  const {
    acceptedTerms,
    company: { acceptedTerms: userCompanyAcceptedTerms },
  } = user

  // No need to show Terms if user.company has accepted them for their employee
  if (userCompanyAcceptedTerms) {
    return true
  }

  if (acceptedTerms === undefined) {
    return false
  }

  // Both variables contain a date in the form of a string with format 'yyyy-MM-dd'.
  // We use lexicographical order to determine which is smaller than the other.
  // This will achieve the same (and is more efficient) as parsing the dates and
  // comparing them (given that both are valid dates).
  return acceptedTerms >= CURRENT_VERSION_LEGAL_TERMS
}
