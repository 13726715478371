import { onGlobalError } from '../../middlewares/errorLogger'
import { fetchNotifications } from '../../modules/InfoNotifications/fetchNotifications'
import { fetchPortNotifications } from '../../modules/InfoNotifications/fetchPortNotifications'

import { loadingPortNotifications, setPortNotifications, setPortNotificationError } from './portNotifications.actions'

import type { Unlocode } from '../../Domain/NauticalLocations/IPort'
import type { PortNotification } from '../../Domain/Notifications/INotification'
import type { AppAction } from '../../modules/App/App.actions'
import type { NotificationId } from '../../modules/InfoNotifications/InfoNotifications.interfaces'
import type { ProntoDispatch } from '../storeTypes'

export const loadPortNotificationsForPorts = (ports: Unlocode[]) => async (dispatch: ProntoDispatch) => {
  try {
    dispatch(loadingPortNotifications())
    const portNotifications = (await fetchPortNotifications(ports)) as PortNotification[]
    dispatch(setPortNotifications(portNotifications))
  } catch (err) {
    onGlobalError({
      error: err,
      reference: 'loadPortNotificationsForPorts',
    })
    dispatch(setPortNotificationError(err))
  }
}

export const loadPortNotification =
  (id: NotificationId, actionAfterFetch: (notification: PortNotification) => AppAction) =>
  async (dispatch: ProntoDispatch) => {
    try {
      const portNotifications = (await fetchNotifications([id])) as PortNotification[]
      dispatch(actionAfterFetch(portNotifications[0]))
    } catch (err) {
      onGlobalError({
        error: err,
        reference: 'loadPortNotification',
      })
      dispatch(setPortNotificationError(err))
    }
  }
