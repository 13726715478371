import { createSelector } from 'reselect'

import { SystemOfMeasurement } from '../../Domain/Users/ClientPreferences/SystemOfMeasurement'

import type { IAppState } from '../../modules/App/interfaces/IAppState'
import type { IUser } from '../../shared/interfaces/settings/Settings'

// The `user` can be `null` too. In normal circumstances, the
// `user` will only be used when user is logged in and it is
// definitely *not* `null`. Therefore it is safe enough to pretend
// that it's `IUser` here.
export const userSelector = (state: IAppState): IUser => {
  const { user } = state.auth
  if (!user) {
    throw new Error('Attempting to use user when it is undefined')
  }
  return user
}

export const safeUserSelector = (state: IAppState): IUser | null => state.auth.user

const getUserAgent = (user: IUser) => user.agents

const getUserCompanyId = (user: IUser) => user.company.id

const getUserCompanyAcceptedTerms = (user: IUser) => user.company.acceptedTerms

const getUserPreferredMeasurementSystem = (user: IUser) => user.measurementUnit || SystemOfMeasurement.Metric

export const userAgentsSelector = createSelector(userSelector, getUserAgent)

export const userCompanyIdSelector = createSelector(userSelector, getUserCompanyId)

export const userCompanyAcceptedTerms = createSelector(userSelector, getUserCompanyAcceptedTerms)

export const userMeasurementSystemSelector = createSelector(userSelector, getUserPreferredMeasurementSystem)
