import { AppAction } from '../../modules/App/App.actions'
import { IProntoConfig } from '../../shared/interfaces/settings/Settings'
import { REDUX_STORAGE_LOAD, SET_CONFIG } from '../actionTypes'

const defaultState: IProntoConfig = {
  carriers: [],
  agentsInfo: [],
  allowedEventTypes: [],
  atdBerthPortEventName: null,
  atsCargoPortEventName: null,
  ataPilotBoardingPlacePortEventName: null,
  etdBerthPortEventName: null,
  etsCargoPortEventName: null,
  etaPilotBoardingPlacePortEventName: null,
  eventTypeById: null,
  notificationTypePortWhitelist: {},
  eventTypes: [],
  locations: [],
  notificationTypes: [],
  userRoles: [],
  supportedLocales: [],
}

const configReducers = (state = defaultState, action: AppAction) => {
  switch (action.type) {
    case REDUX_STORAGE_LOAD:
      return {
        ...state,
        ...action.payload.config,
      }
    case SET_CONFIG:
      return {
        ...state,
        ...action.config,
      }
    default:
      return state
  }
}

export default configReducers
