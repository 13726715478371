export type Predicate<A> = (a: A) => boolean

// A type guard is a special kind of predicate that indicates
// (by returning `true` or `false`) whether the `A` is actually a `B`.
export type TypeGuard<A, B extends A> = (a: A) => a is B

export const not =
  <A>(p: Predicate<A>): Predicate<A> =>
  (a: A) =>
    !p(a)

export const and =
  <A>(p1: Predicate<A>, p2: Predicate<A>): Predicate<A> =>
  (a: A) =>
    p1(a) && p2(a)

export const notFalse = <A>(a: A | false): a is A => a !== false

export const notUndefined = <T>(input: T | undefined): input is T => input !== undefined
