import { Preferences } from '../ApplicationState/Preferences'
import { verifyObjectWithShape, verifyBoolean, verifyPrimitiveLiteral } from '../utils/verifyShape'

type PreferencesJson = Readonly<{
  version: 1
  showAllEventsInServicesTimeline: boolean
}>

export function preferencesFromJson(json: PreferencesJson): Preferences {
  return {
    showAllEventsInServicesTimeline: json.showAllEventsInServicesTimeline,
  }
}

export function preferencesToJson(preferences: Preferences): PreferencesJson {
  return {
    version: 1,
    showAllEventsInServicesTimeline: preferences.showAllEventsInServicesTimeline,
  }
}

export const verifyPreferencesJson = verifyObjectWithShape<PreferencesJson>({
  version: verifyPrimitiveLiteral(1),
  showAllEventsInServicesTimeline: verifyBoolean,
})
