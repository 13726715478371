import { none, None } from './strictNull'

export type Comparator<A> = (left: A, right: A) => number

export function composeComparators<A>(...comparators: Array<Comparator<A>>): Comparator<A> {
  return (left: A, right: A) => comparators.map(comparator => comparator(left, right)).find(ord => ord !== 0) || 0
}

export function reversed<A>(comparator: Comparator<A>): Comparator<A> {
  return (left: A, right: A) => {
    const compared = comparator(left, right)
    return compared === 0 ? 0 : -compared
  }
}

export function alternativeAtTheFront<A>(comparator: Comparator<A>, alternative: A): Comparator<A> {
  return (left: A, right: A) => {
    if (left === alternative && right === alternative) {
      return 0
    }

    if (left === alternative) {
      return -1
    }

    if (right === alternative) {
      return 1
    }

    return comparator(left, right)
  }
}

export function noneAtTheEnd<A>(comparator: Comparator<A>): Comparator<A | None> {
  return (left: A | None, right: A | None) => {
    if (left === none && right === none) {
      return 0
    }

    if (left === none) {
      return 1
    }

    if (right === none) {
      return -1
    }

    return comparator(left, right)
  }
}

export function undefinedAtTheEnd<A>(comparator: Comparator<A>): Comparator<A | undefined> {
  return (left: A | undefined, right: A | undefined) => {
    if (left === undefined && right === undefined) {
      return 0
    }

    if (left === undefined) {
      return 1
    }

    if (right === undefined) {
      return -1
    }

    return comparator(left, right)
  }
}

export function compareDatesAsc(left: Date, right: Date): number {
  return left.valueOf() - right.valueOf()
}

export function compareNumbersAsc(left: number, right: number): number {
  return left - right
}

export function compareStringsAsc(left: string, right: string): number {
  return left.localeCompare(right)
}

export function compareBy<A, B>(selector: (a: A) => B, comparator: Comparator<B>): Comparator<A> {
  return (left: A, right: A) => {
    return comparator(selector(left), selector(right))
  }
}

export function createComparatorFromRecord<K extends string | number | symbol>(
  record: Record<K, number>
): Comparator<K> {
  return compareBy(k => record[k], undefinedAtTheEnd(compareNumbersAsc))
}

export function createComparatorFromMap<K>(map: Map<K, number>): Comparator<K> {
  return compareBy(k => map.get(k), undefinedAtTheEnd(compareNumbersAsc))
}
