import { parseISO, isBefore } from 'date-fns'

import { Timeline } from '../../Domain/Timelines/Timeline'
import { DAY } from '../../shared/constants'
import { IInterval } from '../../shared/interfaces/shipvisit/IBerthVisit'

import { fromActivityJson } from './Activities/fromActivityJson'
import { ITimelineJson } from './ITimelineJson'
import { toActivityTimeFrame, toTimelineItems } from './toTimelineItems'
import { toTimelineItemsPerParty } from './toTimelineItemsPerParty'

export const timelineFromJson = (json: ITimelineJson): Timeline => {
  const items = toTimelineItems(json)
  const activityStartAndEnds = toActivityTimeFrame(json.alongside.map(fromActivityJson))
  const outboundStartAndEnds = toActivityTimeFrame(json.outbound === undefined ? [] : [fromActivityJson(json.outbound)])
  const start = parseISO(json.info.start)
  const end = parseISO(json.info.end)
  const endBeforeStart = isBefore(end, start)
  const itemsPerParty = toTimelineItemsPerParty(json.perParty)

  return {
    items,
    itemsPerParty,
    alongsideStarts: activityStartAndEnds.map(act => act.start),
    alongsideEnds: activityStartAndEnds.map(act => act.end),
    outboundEnds: outboundStartAndEnds.map(out => out.end),
    start: endBeforeStart ? end : start,
    end: endBeforeStart ? start : end,
  }
}

export const createEmptyTimeline = (timeframe?: IInterval): Timeline => ({
  items: [],
  itemsPerParty: [],
  alongsideStarts: [],
  alongsideEnds: [],
  outboundEnds: [],
  start: timeframe ? parseISO(timeframe.start) : new Date(Date.now() - DAY),
  end: timeframe ? parseISO(timeframe.end) : new Date(Date.now() + DAY),
})
