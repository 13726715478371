import { none, None } from '../../utils/strictNull'
import { IWebSocketMessage } from '../interfaces/websocket/IWebSocketMessage'

import { fetchWebsocketTicket } from './fetchWebsocketTicket'

interface IWebSocketClientConfig {
  url: string
  maxMessageInterval: number
  reconnectTimeout: number

  onMessage: (msg: IWebSocketMessage) => void
  onOpen?: (ev: Event) => void
  onClose?: (ev: CloseEvent) => void
  onError?: (ev: Event) => void

  debug?: ((msg: string) => void) | null
}

export class WebSocketClient {
  private config: IWebSocketClientConfig

  private socket: WebSocket | null = null

  private reconnectAttempts = 0

  private maxReconnectAttempts = 10

  private timeLastMessage: number | None = none

  private isOffline = false

  private checkConnectionInterval: number | None = none

  constructor(config: IWebSocketClientConfig) {
    this.config = {
      ...config,
      debug: config.debug || null,
    }

    this.createSocket()
    this.setupListeners()
  }

  // Close connection and remove event listeners for current instance
  public destroy() {
    this.closeSocketIfOpen()

    window.removeEventListener('offline', this.connectionOffline)
    window.removeEventListener('online', this.connectionOnline)
  }

  private closeSocketIfOpen() {
    if (this.checkConnectionInterval) {
      clearInterval(this.checkConnectionInterval)
    }

    if (this.socket) {
      this.socket.close()
      this.socket = null
    }
  }

  private async createSocket() {
    this.closeSocketIfOpen()

    try {
      const ticket = await this.getWebsocketTicket()

      this.socket = new WebSocket(`${this.config.url}?ticket=${ticket}`)
      this.socket.onmessage = (ev: MessageEvent) => this.onMessage(ev)
      this.socket.onclose = (ev: CloseEvent) => this.onClose(ev)
      this.socket.onopen = (ev: Event) => this.onOpen(ev)
      this.socket.onerror = (ev: Event) => this.onError(ev)
    } finally {
      this.checkConnectionInterval = window.setInterval(
        () => this.onConnectionInterval(),
        this.config.maxMessageInterval
      )
    }
  }

  private async getWebsocketTicket() {
    return fetchWebsocketTicket()
  }

  // Create connection event listeners to set websocket status
  private setupListeners() {
    window.addEventListener('offline', this.connectionOffline.bind(this))
    window.addEventListener('online', this.connectionOnline.bind(this))
  }

  private onConnectionInterval() {
    const isHealthy = this.checkConnectionHealth()

    if (!isHealthy) {
      this.debug(`No WS msg in the last ${this.config.maxMessageInterval / 1000} seconds. Reconnecting...`)
      this.reconnect(true)
    }
  }

  private onMessage(event: MessageEvent) {
    this.timeLastMessage = Date.now()
    try {
      const message = JSON.parse(event.data) as IWebSocketMessage
      this.config.onMessage(message)
    } catch (err) {
      if (this.config.onError) {
        this.config.onError(err)
      }
    }
  }

  private onClose(event: CloseEvent) {
    if (!event.wasClean && !this.isOffline) {
      this.reconnect(true)

      if (this.config.onClose) {
        this.config.onClose(event)
      }
    }
  }

  private onOpen(event: Event) {
    this.reconnectAttempts = 0

    if (this.config.onOpen) {
      this.config.onOpen(event)
    }
  }

  private onError(event: Event) {
    if (this.config.onError) {
      this.config.onError(event)
    }
  }

  private checkConnectionHealth() {
    const timeSinceLastMessage = this.timeLastMessage ? Date.now() - this.timeLastMessage : Date.now()

    return this.socket && this.socket.readyState === 1 && timeSinceLastMessage < this.config.maxMessageInterval
  }

  private connectionOffline() {
    this.debug('Connection offline')

    this.isOffline = true
    this.closeSocketIfOpen()
  }

  private connectionOnline() {
    this.debug('Connection online')

    this.isOffline = false
    this.reconnect()
  }

  private reconnect(withInterval = false) {
    this.closeSocketIfOpen()

    if (withInterval) {
      this.reconnectAttempts++
    }

    if (this.reconnectAttempts > this.maxReconnectAttempts) {
      this.debug('Max reconnect attempts reached!')
      return
    }

    const reconnectTimeout = this.reconnectAttempts * (this.reconnectAttempts * this.config.reconnectTimeout)

    this.debug(`reconnect in ${reconnectTimeout / 1000} seconds...`)

    // @TODO: we should investigate whether this timeout will cause a memory leak
    // (because it maintains a reference to `this`). I'm not really sure this entire
    // class is memory leak-safe...
    window.setTimeout(() => this.createSocket(), reconnectTimeout)
  }

  private debug(msg: string) {
    if (this.config.debug) {
      this.config.debug(msg)
    }
  }
}
