export const EXPIRE = 'EXPIRE'

export interface IExpire<Key> {
  type: typeof EXPIRE
  cacheId: string
  keys: Key[]
}

export function expire<Key>(cacheId: string, keys: Key[]): IExpire<Key> {
  return {
    type: EXPIRE,
    cacheId,
    keys,
  }
}
