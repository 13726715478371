import { MooringInfo } from '../../Domain/Mooring/MooringInfo'
import { Orientation } from '../../Domain/Mooring/Orientation'
import { none } from '../../utils/strictNull'

export type MooringInfoJson = Readonly<{
  bollardFore?: number
  bollardAft?: number
  doubleBanked?: boolean
  orientation?: Orientation
}>

export function mooringInfoFromJson(json: MooringInfoJson): MooringInfo {
  return {
    bollardFore: json.bollardFore === undefined ? none : json.bollardFore,
    bollardAft: json.bollardAft === undefined ? none : json.bollardAft,
    doubleBanked: json.doubleBanked === undefined ? false : json.doubleBanked,
    orientation: json.orientation === undefined ? none : json.orientation,
  }
}
