import { NO_RESULT_AVAILABLE, AWAITING_RESULT, RESULT_RECEIVED, RESULT_EXPIRED } from '../../lib/asyncSelector/consts'
import { pushToQueueIfEmpty } from '../../lib/asyncSelector/queueActions'
import { LazyList, LazyListCons } from '../../utils/lazy'
import { none } from '../../utils/strictNull'

import { IExpirable, getStatus, getResult } from './IExpirable'
import { requestAll } from './IRequestAll'

import type { CacheItem } from '../../lib/asyncSelector/CacheItem'
import type { AppAction } from '../App/App.actions'
import type { ThunkAction } from 'redux-thunk'

function getKeysToFetchAgain<Key, Result>(expirables: Array<IExpirable<Result>>, toKey: (result: Result) => Key) {
  return expirables
    .filter(expirable => getStatus(expirable) === RESULT_EXPIRED)
    .map(getResult)
    .map(toKey)
}

export function getAsyncResultFromCache<AppState, Key, Result, Meta>(
  cacheId: string,
  toKey: (result: Result) => Key,
  meta: Meta
) {
  return (
    key: Key[],
    cacheItemsForKey: LazyListCons<CacheItem<Key[], Array<IExpirable<Result>>, Meta>>
  ): Array<ThunkAction<Promise<any>, AppState, void, AppAction>> => {
    // Early return if the state of the store changed between creating and dispatching the action.
    // This can happen when, for instance, two components both request the same `AsyncResult<..>` and
    // (therefore) dispatch the same actions. The first `AsyncResult<..>` that is handled will change
    // to store state so that the action from the second `AsyncResult<..>` doesn't need to be handled.
    const firstCacheItem = LazyList.head(cacheItemsForKey)
    const currentAsyncResultType = firstCacheItem === none ? NO_RESULT_AVAILABLE : firstCacheItem.requestState.type
    if (currentAsyncResultType === AWAITING_RESULT) {
      return []
    }

    const thunk: ThunkAction<Promise<any>, AppState, void, AppAction> = dispatch => {
      const message = requestAll(cacheId, key, meta)
      dispatch(pushToQueueIfEmpty(cacheId, message))
      return Promise.resolve(null)
    }

    // Normally, we would do nothing when we get a `RESULT_RECEIVED`. In the case of `IExpirable<..>`-instances, though,
    // individual results could also be expired. If this is the case, request fresh results. If all individual
    // results are valid, dispatch nothing.
    if (!!firstCacheItem && firstCacheItem.requestState.type === RESULT_RECEIVED) {
      const notificationIdsWithPortcallIdsToFetchAgain = getKeysToFetchAgain(firstCacheItem.requestState.result, toKey)
      if (notificationIdsWithPortcallIdsToFetchAgain.length > 0) {
        return [thunk]
      }
      return []
    }

    return [thunk]
  }
}
