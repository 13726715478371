import { Invalid } from './Invalid'

import type { Validated } from './Validated'

export class Valid<E, T> {
  public static readonly type: 'valid' = 'valid'

  public readonly type = Valid.type

  public readonly errorType: E = null as any as E

  public readonly valueType: T = null as any as T

  constructor(public readonly value: T) {}

  public isValid(): this is Valid<E, T> {
    return true
  }

  public map<U>(fn: (t: T) => U): Validated<E, U> {
    return new Valid<E, U>(fn(this.value))
  }

  public flatMap<U, F = E>(fn: (t: T) => Validated<F, U>): Validated<E | F, U> {
    return fn(this.value)
  }

  public fold<U>(ok: (v: Valid<E, T>) => U, _error: (v: Invalid<E, T>) => U): U {
    return ok(this)
  }

  public orElse(_alternative: Validated<E, T>): Validated<E, T> {
    return this
  }

  public getOrElse<Alternative>(_alternative: Alternative): T | Alternative {
    return this.value
  }
}
