import { Unlocode } from '../../../Domain/NauticalLocations/IPort'

export const VISIBILITY_FILTER_PORTS_SHOW_ALL = 'VISIBILITY_FILTER_PORTS_SHOW_ALL'
export type VisiblePorts = Unlocode[] | typeof VISIBILITY_FILTER_PORTS_SHOW_ALL

// These props filter the already available data.
// They differ from the IFilters, a change on them doesn't change the data selection neither requests more data
export type VisibilityFilters = Readonly<{
  ports: VisiblePorts
  showMapTraffic: boolean
  hiddenVesselTypes: string[]
}>
