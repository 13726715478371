export function isUnsupportedBrowser() {
  if (/rv:11.0/i.test(navigator.userAgent)) {
    // This is internet explorer 11
    return true
  }
  if (/MSIE 10/i.test(navigator.userAgent)) {
    // This is internet explorer 10
    return true
  }
  if (/MSIE 9/i.test(navigator.userAgent)) {
    // This is internet explorer 9
    return true
  }
  return false
}
