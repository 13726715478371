import { fetchSystemStatusMessages } from '../../rest/fetchSystemStatusMessages'
import { SET_SYSTEM_NOTIFICATIONS } from '../actionTypes'
import { ActionMenuItem } from '../ui/interfaces/IUIState'
import { setActionMenuOpen } from '../ui/userInterface.actions'

import type { ISystemStatusMessage } from '../../Domain/SystemStatusMessages/ISystemStatusMessage'
import type { AppAction } from '../../modules/App/App.actions'
import type { ProntoDispatch } from '../storeTypes'

export interface ISetSystemStatusMessagesAction {
  type: typeof SET_SYSTEM_NOTIFICATIONS
  systemStatusMessages: ISystemStatusMessage[]
}

export const setSystemStatusMessages = (
  systemStatusMessages: ISystemStatusMessage[]
): ISetSystemStatusMessagesAction => ({
  type: SET_SYSTEM_NOTIFICATIONS,
  systemStatusMessages,
})

export const updateSystemStatusNotifications = () => {
  return async (dispatch: ProntoDispatch) => {
    const systemNotifications = await fetchSystemStatusMessages()

    dispatch(setSystemStatusMessages(systemNotifications))
  }
}

export const setSystemStatusMessagesAndOpenList = (systemStatusMessages: ISystemStatusMessage[]): AppAction[] =>
  systemStatusMessages.length
    ? [setSystemStatusMessages(systemStatusMessages), setActionMenuOpen(ActionMenuItem.SystemStatusMessages)]
    : [setSystemStatusMessages(systemStatusMessages)]
