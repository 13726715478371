import { IPortcall, EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { SECOND } from '../../shared/constants'
import { fetchPortcallsByEventIds } from '../../shared/rest/portcalls'
import { exponentialBackOff } from '../../utils/exponentialBackOff'
import { IExpirable, toExpirable } from '../CacheQueue/IExpirable'
import { IFetcher } from '../CacheQueue/IFetcher'

// Will try again 4 times over a period of about 7.5 minutes.
const INITIAL_WAIT_TIME_BACK_OFF = 30 * SECOND
const NUMBER_OF_RETRIES_BACK_OFF = 4

export class PortcallsFetcher implements IFetcher<EventPortcallId, IPortcall> {
  public fetch(portcallIds: EventPortcallId[]): Promise<Array<IExpirable<IPortcall>>> {
    return exponentialBackOff(
      () => fetchPortcallsByEventIds(portcallIds),
      { retryOnFailure: true },
      INITIAL_WAIT_TIME_BACK_OFF,
      NUMBER_OF_RETRIES_BACK_OFF
    ).then(portcalls => portcalls.map(toExpirable))
  }
}
