// Time formats
export const TIME_FORMAT = 'HH:mm'
export const TIME_FORMAT_PLACEHOLDER = '--:--'

// Date formats
export const SHORT_DATE_FORMAT = 'dd/MM'
export const MEDIUM_DATE_FORMAT = 'MMM d'
export const LONG_DATE_FORMAT = 'dd/MM/yyyy'
export const DATE_FORMAT_PLACEHOLDER = '--/--'

// Date/time formats
export const MEDIUM_DATE_TIME_FORMAT = `${MEDIUM_DATE_FORMAT}, ${TIME_FORMAT}`
export const LONG_DATE_TIME_FORMAT = `dd MMM - ${TIME_FORMAT}`
export const DATE_TIME_FORMAT_SHORT_YEAR = `dd/MM/yy ${TIME_FORMAT}`
export const DATE_TIME_FORMAT_LONG_YEAR = `${LONG_DATE_FORMAT} ${TIME_FORMAT}`
export const DATE_TIME_FORMAT_NO_YEAR = `${SHORT_DATE_FORMAT} ${TIME_FORMAT}`

// Portflix specific format
export const PORTFLIX_DATE_FORMAT = 'dd MMM yyyy'
export const PORTFLIX_TIME_FORMAT = TIME_FORMAT
