import { parseISO } from 'date-fns'

import { getEventTimeCertaintyFromEventType } from '../../Domain/Events/EventTimeCertainty'
import { OptionalEventTimeWithCertainty } from '../../Domain/Events/EventTimeWithCertainty'
import { none } from '../../utils/strictNull'

import { EventJson } from './EventJson'

export function eventTimeFromEventJson(eventJson: EventJson): OptionalEventTimeWithCertainty {
  const eventTimeType = getEventTimeCertaintyFromEventType(eventJson.eventType)
  return {
    certainty: eventTimeType,
    time: eventJson.eventTime !== undefined ? parseISO(eventJson.eventTime) : none,
  }
}
