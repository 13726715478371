import { LazyList, LazyListCons } from '../../utils/lazy'
import { none, None } from '../../utils/strictNull'

import { CacheItem } from './CacheItem'
import { AWAITING_RESULT, REQUEST_CANCELLED, RESULT_EXPIRED, RESULT_RECEIVED, NO_RESULT_AVAILABLE } from './consts'
import { flatten } from './utils'

import type { AppAction } from '../../modules/App/App.actions'
import type { ThunkAction } from 'redux-thunk'

type AsyncResultType =
  | typeof NO_RESULT_AVAILABLE
  | typeof AWAITING_RESULT
  | typeof REQUEST_CANCELLED
  | typeof RESULT_RECEIVED
  | typeof RESULT_EXPIRED
export type AsyncResultWithResultType = typeof RESULT_RECEIVED | typeof RESULT_EXPIRED

export class AsyncResult<AppState, Result> {
  public static pure<AppState, Result>(
    result: Result,
    timeStamp: number | false = false
  ): AsyncResult<AppState, Result> {
    return new AsyncResult(
      LazyList.singleton<IResultWithTimeStamp<Result>>({ result, type: RESULT_RECEIVED, timeStamp }),
      []
    )
  }

  public static noResult<AppState, Result>() {
    return new AsyncResult<AppState, Result>(LazyList.nil<IResultWithTimeStamp<Result>>(), [])
  }

  public static withLatest<AppState, A>(asyncResults: Array<AsyncResult<AppState, A>>): AsyncResult<AppState, A[]> {
    if (asyncResult.length === 0) {
      return AsyncResult.pure([])
    }

    const resultsWithTimeStamps = withLatest(asyncResults.map(ar => ar.resultsWithTimeStamps))
    const actions = flatten(asyncResults.map(ar => ar.actions))
    return new AsyncResult(resultsWithTimeStamps, actions)
  }

  constructor(
    readonly resultsWithTimeStamps: LazyListCons<IResultWithTimeStamp<Result>>,
    readonly actions: Array<ThunkAction<Promise<any>, AppState, void, AppAction>>
  ) {}

  public map<MappedResult>(fn: (result: Result) => MappedResult): AsyncResult<AppState, MappedResult> {
    const mappedResultsWithTimeStamps = LazyList.map(
      this.resultsWithTimeStamps,
      ({ result, type, timeStamp }): IResultWithTimeStamp<MappedResult> => ({
        result: fn(result),
        type,
        timeStamp,
      })
    )
    return new AsyncResult(mappedResultsWithTimeStamps, this.actions)
  }

  public flatMap<FlatMappedResult>(
    fn: (result: Result) => AsyncResult<AppState, FlatMappedResult>
  ): AsyncResult<AppState, FlatMappedResult> {
    const mappedResults = LazyList.map(
      this.resultsWithTimeStamps,
      ({
        result,
        type: previousType,
        timeStamp: previousTimeStamp,
      }): {
        mappedResult: AsyncResult<AppState, FlatMappedResult>
        previousType: AsyncResultWithResultType
        previousTimeStamp: number | false
      } => ({
        mappedResult: fn(result),
        previousType,
        previousTimeStamp,
      })
    )

    const resultsWithTimeStamps = LazyList.flatten<IResultWithTimeStamp<FlatMappedResult>>(
      LazyList.map(mappedResults, ({ mappedResult, previousType, previousTimeStamp }) =>
        LazyList.map(
          mappedResult.resultsWithTimeStamps,
          ({ result, type: currentType, timeStamp: currentTimeStamp }): IResultWithTimeStamp<FlatMappedResult> => ({
            result,
            type: min(currentType, previousType),
            timeStamp: latestTimeStamp(currentTimeStamp, previousTimeStamp),
          })
        )
      )
    )

    let { actions } = this
    if (this.actions.length === 0) {
      const firstMappedResult = LazyList.head(mappedResults)
      actions = firstMappedResult === none ? [] : firstMappedResult.mappedResult.actions
    }

    return new AsyncResult(resultsWithTimeStamps, actions)
  }

  public orElse<Alternative>(
    alternative: AsyncResult<AppState, Alternative>
  ): AsyncResult<AppState, Result | Alternative> {
    const firstResultWithTimeStamp = LazyList.head(this.resultsWithTimeStamps)
    if (firstResultWithTimeStamp === none || firstResultWithTimeStamp.type === RESULT_EXPIRED) {
      return alternative
    }
    return this
  }
}

export function min(left: AsyncResultWithResultType, right: AsyncResultWithResultType): AsyncResultWithResultType
export function min(left: AsyncResultType, right: AsyncResultType): AsyncResultType
export function min(left: AsyncResultType, right: AsyncResultType): AsyncResultType {
  const ordering: Record<AsyncResultType, number> = {
    [NO_RESULT_AVAILABLE]: 0,
    [REQUEST_CANCELLED]: 1,
    [RESULT_EXPIRED]: 2,
    [AWAITING_RESULT]: 3,
    [RESULT_RECEIVED]: 4,
  }

  return ordering[left] < ordering[right] ? left : right
}

function latestTimeStamp(left: number | false, right: number | false): number | false {
  if (left === false) {
    return right
  }
  if (right === false) {
    return left
  }
  return Math.max(left, right)
}

export function asyncResult<AppState, Key, Result, Meta>(
  cacheItemsForKey: LazyListCons<CacheItem<Key, Result, Meta>>,
  actions: Array<ThunkAction<Promise<Result>, AppState, void, AppAction>>
): AsyncResult<AppState, Result> {
  return new AsyncResult(getResultsWithTimestamps(cacheItemsForKey), actions)
}

export function getType<Key, Result, Meta>(
  cacheItemsForKey: LazyListCons<CacheItem<Key, Result, Meta>>
): AsyncResultType {
  const firstCacheItem = LazyList.head(cacheItemsForKey)
  return firstCacheItem === none ? NO_RESULT_AVAILABLE : firstCacheItem.requestState.type
}

function getResultsWithTimestamps<Key, Result, Meta>(
  cacheItemsForKey: LazyListCons<CacheItem<Key, Result, Meta>>
): LazyListCons<IResultWithTimeStamp<Result>> {
  const results = LazyList.filterMap(
    cacheItemsForKey,
    (curr: CacheItem<Key, Result, Meta>): IResultWithTimeStamp<Result> | None => {
      if (curr.requestState.type === RESULT_RECEIVED || curr.requestState.type === RESULT_EXPIRED) {
        const resultWithTimeStamp: IResultWithTimeStamp<Result> = {
          result: curr.requestState.result,
          type: curr.requestState.type,
          timeStamp: curr.requestState.resultReceivedAt,
        }
        return resultWithTimeStamp
      }
      return none
    }
  )

  return results
}

export interface IResultWithTimeStamp<Result> {
  result: Result
  type: AsyncResultWithResultType
  timeStamp: number | false
}

function maxTimeStamp(left: number | None, right: number | None): number | None {
  if (left === none) {
    return right
  }

  if (right === none) {
    return left
  }

  return Math.max(left, right)
}

interface IteratorState<A> {
  index: number
  head: IResultWithTimeStamp<A>
  tail: LazyListCons<IResultWithTimeStamp<A>>
}

function toIteratorState<A>(s: LazyListCons<IResultWithTimeStamp<A>>, index: number): IteratorState<A> | None {
  const evaluated = s()
  if (evaluated === none) {
    return none
  }
  return {
    index,
    head: evaluated.head,
    tail: evaluated.tail,
  }
}

function getLatestIteratorState<A>(...iteratorStates: Array<IteratorState<A>>): IteratorState<A> {
  return iteratorStates.reduce((acc, curr) => {
    if (acc.head.timeStamp === none) {
      return curr
    }

    if (curr.head.timeStamp === none) {
      return acc
    }

    return acc.head.timeStamp > curr.head.timeStamp ? acc : curr
  })
}

function toResult<A>(iteratorStates: Array<IteratorState<A>>): IResultWithTimeStamp<A[]> {
  return {
    result: iteratorStates.map(is => is.head.result),
    timeStamp: iteratorStates.map(is => is.head.timeStamp).reduce(maxTimeStamp),
    type: iteratorStates.map(is => is.head.type).reduce(min),
  }
}

// eslint-disable-next-line consistent-return
function* withLatestGenerator<A>(
  resultsWithTimeStampArray: Array<LazyListCons<IResultWithTimeStamp<A>>>
): IterableIterator<IResultWithTimeStamp<A[]>> {
  if (resultsWithTimeStampArray.length === 0) {
    return {
      result: [],
      timeStamp: none,
      type: RESULT_RECEIVED,
    }
  }

  const acc: Array<IteratorState<A>> = []
  const iteratorStates = resultsWithTimeStampArray.map(toIteratorState)
  if (iteratorStates.some(is => is === none)) {
    return undefined
  }
  iteratorStates.forEach(is => {
    if (is === none) {
      return
    }

    acc[is.index] = is
  })

  yield toResult(acc)

  while (iteratorStates.every(is => is !== none)) {
    const latestIteratorState = getLatestIteratorState(...acc)
    const nextIteratorState = toIteratorState(latestIteratorState.tail, latestIteratorState.index)
    if (nextIteratorState === none) {
      return undefined
    }
    acc[nextIteratorState.index] = nextIteratorState

    yield toResult(acc)
  }
}

/**
 * What is this going to be used for? To fetch all portcalls
 * for a filter, but store them each in a single cache item.
 * This way, we have more fine grained control over what portcalls
 * need to be fetched. Of course, when storing them separately,
 * we need a way to combine these portcalls into a single
 * `AsyncResult` again. This is what this function is for.
 */
export function withLatest<A>(
  resultsWithTimeStampArray: Array<LazyListCons<IResultWithTimeStamp<A>>>
): LazyListCons<IResultWithTimeStamp<A[]>> {
  return LazyList.fromIterator(withLatestGenerator(resultsWithTimeStampArray))
}
