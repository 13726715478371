import { Notification } from '../../Domain/Notifications/INotification'
import { NotificationId } from '../InfoNotifications/InfoNotifications.interfaces'

export interface INotificationUpdate {
  updateId: string
  notificationId: NotificationId
  isRead?: boolean
  isSnoozed?: boolean
}

export function applyUpdate<N extends Notification>(notification: N, update: INotificationUpdate): N {
  const copy = { ...(notification as any) } as N
  if (update.isRead !== undefined) {
    copy.isRead = update.isRead
  }
  if (update.isSnoozed !== undefined) {
    copy.isSnoozed = update.isSnoozed
  }
  return copy
}
