import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { CacheItem, update } from '../../lib/asyncSelector/CacheItem'
import { RESULT_EXPIRED, AWAITING_RESULT } from '../../lib/asyncSelector/consts'
import { includes } from '../../utils/arr'
import { none } from '../../utils/strictNull'

import {
  isAction,
  EXPIRE_EXPIRABLE,
  AWAITING_RESULT_FOR_EXPIRABLES,
  RESULT_RECEIVED_FOR_EXPIRABLES,
  REQUEST_CANCELLED_FOR_EXPIRABLES,
  NO_RESULT_RECEIVED_FOR_EXPIRABLES,
} from './Actions'
import { getNonExpiredExpirables } from './getNonExpiredExpirables'
import { IExpirable, getResult, getStatus } from './IExpirable'

import type { AnyAction } from 'redux'

function handleExpireExpirable<Key, Result>(
  expirables: Array<IExpirable<Result>>,
  keysToExpire: Key[],
  toKey: (result: Result) => Key
): Array<IExpirable<Result>> {
  return expirables.map<IExpirable<Result>>(expirable => {
    if (includes(keysToExpire, toKey(getResult(expirable)))) {
      return { status: RESULT_EXPIRED, result: expirable.result }
    }
    return expirable
  })
}

function handleAwaitingResultForExpirables<Key, Result>(
  expirables: Array<IExpirable<Result>>,
  awaitingResultForKeys: Key[],
  toKey: (result: Result) => Key
): Array<IExpirable<Result>> {
  return expirables.map<IExpirable<Result>>(expirable => {
    if (includes(awaitingResultForKeys, toKey(getResult(expirable)))) {
      return { status: AWAITING_RESULT, result: expirable.result }
    }
    return expirable
  })
}

function handleRequestCancelledForExpirables<Key, Result>(
  expirables: Array<IExpirable<Result>>,
  keysToCancelRequestFor: Key[],
  toKey: (result: Result) => Key
): Array<IExpirable<Result>> {
  return expirables.map<IExpirable<Result>>(expirable => {
    if (getStatus(expirable) === AWAITING_RESULT && includes(keysToCancelRequestFor, toKey(getResult(expirable)))) {
      return { status: RESULT_EXPIRED, result: expirable.result }
    }
    return expirable
  })
}

function handleResultReceivedForExpirables<Key, Result>(
  expirables: Array<IExpirable<Result>>,
  receivedExpirables: Array<IExpirable<Result>>,
  toKey: (result: Result) => Key
): Array<IExpirable<Result>> {
  return expirables.map<IExpirable<Result>>(expirable => {
    const receivedExpirableWithSameKey =
      receivedExpirables.find(
        receivedExpirable => toKey(getResult(receivedExpirable)) === toKey(getResult(expirable))
      ) || none
    return receivedExpirableWithSameKey === none ? expirable : receivedExpirableWithSameKey
  })
}

function handleNoResultReceivedForExpirables<Key, Result>(
  expirables: Array<IExpirable<Result>>,
  notReceivedForKeys: Key[],
  toKey: (result: Result) => Key
): Array<IExpirable<Result>> {
  return expirables.filter(expirable => {
    const shouldRemove =
      getStatus(expirable) === AWAITING_RESULT && includes(notReceivedForKeys, toKey(getResult(expirable)))
    return !shouldRemove
  })
}

export const createReducer =
  <AppState, Key extends string, Result, Meta>(
    toKey: (result: Result) => Key,
    cacheDefinition: CacheDefinition<AppState, Key[], Array<IExpirable<Result>>, Meta>
  ) =>
  (cacheItems: Array<CacheItem<Key[], Array<IExpirable<Result>>, Meta>> | undefined, action: AnyAction) => {
    const afterCacheActions = cacheDefinition.reducer(cacheItems, action)

    if (!isAction<Key, Result, Meta>(action) || action.resourceId !== cacheDefinition.cacheId) {
      return afterCacheActions
    }

    switch (action.type) {
      case EXPIRE_EXPIRABLE: {
        // First, determine if we should update the store. It's probably cheaper to first check this,
        // and never update the store if there is nothing to do. This is because selectors compare inputs
        // by references and it pays off to prevent changes to the references if they are not needed.
        const nonExpiredExpirables = getNonExpiredExpirables(afterCacheActions)
        const shouldUpdate =
          nonExpiredExpirables.filter(expirable => includes(action.keys, toKey(getResult(expirable)))).length > 0

        // If necessary, perform the update:
        if (shouldUpdate) {
          return update(
            afterCacheActions,
            expirables => handleExpireExpirable(expirables, action.keys, toKey),
            action.timeStamp,
            cacheDefinition.limiter
          )
        }
        return afterCacheActions
      }
      case AWAITING_RESULT_FOR_EXPIRABLES:
        return update(
          afterCacheActions,
          expirables => handleAwaitingResultForExpirables(expirables, action.keys, toKey),
          action.timeStamp,
          cacheDefinition.limiter
        )
      case RESULT_RECEIVED_FOR_EXPIRABLES:
        return update(
          afterCacheActions,
          expirables => handleResultReceivedForExpirables(expirables, action.results, toKey),
          action.timeStamp,
          cacheDefinition.limiter
        )
      case NO_RESULT_RECEIVED_FOR_EXPIRABLES:
        return update(
          afterCacheActions,
          expirables => handleNoResultReceivedForExpirables(expirables, action.keys, toKey),
          action.timeStamp,
          cacheDefinition.limiter
        )
      case REQUEST_CANCELLED_FOR_EXPIRABLES:
        return update(
          afterCacheActions,
          expirables => handleRequestCancelledForExpirables(expirables, action.keys, toKey),
          action.timeStamp,
          cacheDefinition.limiter
        )
      default:
        return afterCacheActions
    }
  }
