import { None, none, StrictNull } from './strictNull'

// Why should we use this over `A | None`?
// Sometimes, we might not know what `A` is when we create a `Maybe<A>`
// in a function (when that function is generic in `A` for instance).
// The user of that function might specify that `A` is `boolean | None`
// for example. In this case `None` is a valid value for `A` and we don't
// want to confuse that with the *absence* of an `A`. This type will help.
export type MaybeCons<A> = { value: A } | None

export const Maybe = {
  just<A>(value: A): MaybeCons<A> {
    return { value }
  },
  toNoneable<A>(maybe: MaybeCons<A>): A | None {
    return maybe === none ? none : maybe.value
  },
  fromNoneable<A>(noneable: A | None): MaybeCons<A> {
    return StrictNull.fold(noneable, Maybe.just, none)
  },
  fold<A, B, Alternative>(maybe: MaybeCons<A>, fn: (a: A) => B, alternative: Alternative): B | Alternative {
    return StrictNull.fold(maybe, j => fn(j.value), alternative)
  },
}
