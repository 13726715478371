import { flatMap } from 'lodash'

import { CacheItem } from '../../lib/asyncSelector/CacheItem'
import { RESULT_RECEIVED, RESULT_EXPIRED } from '../../lib/asyncSelector/consts'

import { IExpirable, getStatus } from './IExpirable'

export function getNonExpiredExpirables<Key, Result, Meta>(
  cacheItems: Array<CacheItem<Key[], Array<IExpirable<Result>>, Meta>>
): Array<IExpirable<Result>> {
  return flatMap(cacheItems, ci => (ci.requestState.type === RESULT_RECEIVED ? ci.requestState.result : [])).filter(
    expirable => getStatus(expirable) !== RESULT_EXPIRED
  )
}
