import { none } from '../../utils/strictNull'
import {
  SET_UI_ACTION_MENU_OPEN,
  SET_UI_MAIN_MENU_OPEN,
  UI_CLOSE_WARNING_NOTIFICATIONS_DRAWER,
  UI_OPEN_WARNING_NOTIFICATIONS_DRAWER,
  UI_SET_DISPLAY_TIMELINE_BY_PARTY,
  UI_SET_VISIBILITY_FILTER_PORTS,
  UI_TOGGLE_SNACKBAR_CONTENT,
  UI_TOGGLE_SHOW_MAP_TRAFFIC,
  UI_SET_VISIBILITY_FILTER_VESSEL_TYPES,
} from '../actionTypes'

import { ActionMenuItem } from './interfaces/IUIState'
import { VisiblePorts } from './interfaces/VisibilityFilters'
import {
  CloseWarningNotificationsDrawerAction,
  ISetUiActionMenuOpenAction,
  ISetUiMainMenuOpenAction,
  IToggleSnackBarContent,
  OpenWarningNotificationsDrawerAction,
  SetDisplayTimelineByPartyAction,
  SetVisibilityFilterPortsAction,
  SetVisibilityFilterVesselTypesAction,
  IToggleShowMapTraffic,
} from './userInterface.interfaces'

import type { BerthVisitId, EventPortcallId } from '../../Domain/Portcall/IPortcall'
import type { IAppState } from '../../modules/App/interfaces/IAppState'
import type { None } from '../../utils/strictNull'
import type { ProntoDispatch } from '../storeTypes'

const setMainMenuOpen = (value: boolean): ISetUiMainMenuOpenAction => ({
  type: SET_UI_MAIN_MENU_OPEN,
  value,
})

export const toggleShowMapTraffic = (): IToggleShowMapTraffic => ({
  type: UI_TOGGLE_SHOW_MAP_TRAFFIC,
})

export const setVisibilityFiltersPorts = (ports: VisiblePorts): SetVisibilityFilterPortsAction => ({
  type: UI_SET_VISIBILITY_FILTER_PORTS,
  ports,
})

export const setVisibilityFiltersVesselTypes = (vesselTypes: string[]): SetVisibilityFilterVesselTypesAction => ({
  type: UI_SET_VISIBILITY_FILTER_VESSEL_TYPES,
  vesselTypes,
})

export const setDisplayTimelineByParty = (payload: boolean): SetDisplayTimelineByPartyAction => ({
  type: UI_SET_DISPLAY_TIMELINE_BY_PARTY,
  payload,
})

export function openWarningNotificationsDrawer(
  portcallIdToShowWarningNotificationsFor: EventPortcallId | None = none,
  berthVisitIdToShowWarningNotificationsFor: BerthVisitId | None = none
): OpenWarningNotificationsDrawerAction {
  return {
    type: UI_OPEN_WARNING_NOTIFICATIONS_DRAWER,
    portcallIdToShowWarningNotificationsFor,
    berthVisitIdToShowWarningNotificationsFor,
  }
}

export function closeWarningNotificationsDrawer(): CloseWarningNotificationsDrawerAction {
  return {
    type: UI_CLOSE_WARNING_NOTIFICATIONS_DRAWER,
  }
}

export const setActionMenuOpen = (value: ActionMenuItem): ISetUiActionMenuOpenAction => {
  return {
    type: SET_UI_ACTION_MENU_OPEN,
    value,
  }
}

export const toggleMainMenu = () => {
  return (dispatch: ProntoDispatch, getState: () => IAppState) => {
    const state = getState()
    dispatch(setMainMenuOpen(!state.ui.isMainMenuOpen))
  }
}

export const toggleSnackbarContent = (): IToggleSnackBarContent => ({
  type: UI_TOGGLE_SNACKBAR_CONTENT,
})
