import { Preferences, defaultPreferences } from '../../ApplicationState/Preferences'
import { AppAction } from '../../modules/App/App.actions'

import { HIDE_ALL_EVENTS_IN_SERVICES_TIMELINE } from './HideAllEventsInServicesTimelineAction'
import { LOAD_PREFERENCES } from './LoadPreferencesAction'
import { SHOW_ALL_EVENTS_IN_SERVICES_TIMELINE } from './ShowAllEventsInServicesTimelineAction'

export function preferencesReducer(preferences: Preferences = defaultPreferences, action: AppAction): Preferences {
  switch (action.type) {
    case LOAD_PREFERENCES:
      return action.preferences
    case SHOW_ALL_EVENTS_IN_SERVICES_TIMELINE:
      return {
        showAllEventsInServicesTimeline: true,
      }
    case HIDE_ALL_EVENTS_IN_SERVICES_TIMELINE:
      return {
        showAllEventsInServicesTimeline: false,
      }
    default:
      return preferences
  }
}
