import { RESULT_EXPIRED } from '../../lib/asyncSelector/consts'

import { IExpirable, getStatus, getResult } from './IExpirable'

export function getKeysToFetchAgain<Key, Result>(
  expirables: Array<IExpirable<Result>>,
  getKey: (result: Result) => Key
): Key[] {
  return expirables
    .filter(expirable => getStatus(expirable) === RESULT_EXPIRED)
    .map(getResult)
    .map(getKey)
}
