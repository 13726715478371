import { shipIdsAreEqual } from '../../utils/equals'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'

import { CacheDefinition } from './CacheDefinition'

import type { IVesselDetails, IShipId } from '../../Domain/VesselDetails/IVesselDetails'
import type { IAppState } from '../../modules/App/interfaces/IAppState'

const VESSEL_DETAILS_CACHE_ID = 'vessel-details'
const MAX_VESSEL_DETAILS_CACHE_SIZE = 5

export const vesselDetailsCacheDefinition = new CacheDefinition<IAppState, IShipId, IVesselDetails>(
  VESSEL_DETAILS_CACHE_ID,
  fixedSizeLimiter(MAX_VESSEL_DETAILS_CACHE_SIZE),
  shipIdsAreEqual,
  appState => appState.vesselDetailsCache
)
