import { first, last } from 'lodash'

import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { Neighbour } from '../../Domain/ServiceVessels/ServiceType'
import { ServiceVessel } from '../../Domain/ServiceVessels/ServiceVessel'
import { IShipId } from '../../Domain/VesselDetails/IVesselDetails'
import { IInterval } from '../../shared/interfaces/shipvisit/IBerthVisit'
import { None, none, StrictNull } from '../../utils/strictNull'
import { eventTimeFromEventJson } from '../Event/eventTimeFromEventJson'
import { MostAccuratesJson } from '../Event/MostAccuratesJson'

export type NeighbourBerthVisitJson = Readonly<{
  eventId: EventPortcallId
  ship: IShipId
  name: string
  length?: number
  timeframeAlongside?: IInterval
  mostAccurates: MostAccuratesJson
}>

export function fromNeighbourBerthVisitJson<T extends Neighbour>(
  json: NeighbourBerthVisitJson,
  type: T
): ServiceVessel<T> | None {
  if (json.ship.mmsi === undefined) {
    return none
  }

  const mostAccurates = json.mostAccurates || none

  const firstStartEvent = StrictNull.flatMap(mostAccurates, ma => last(ma.start) || none)
  const firstEndEvent = StrictNull.flatMap(mostAccurates, ma => first(ma.end) || none)

  const firstStartEventTime = StrictNull.map(firstStartEvent, fse => eventTimeFromEventJson(fse.event))
  const firstEndEventTime = StrictNull.map(firstEndEvent, fee => eventTimeFromEventJson(fee.event))

  return {
    mmsi: json.ship.mmsi,
    name: json.ship.name === undefined ? none : json.ship.name,
    type,
    from: firstStartEventTime,
    to: firstEndEventTime,
  }
}
