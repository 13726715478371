import { none, None } from '../strictNull'

import { BoundingBoxCoordinates } from './BoundingBoxCoordinates'

export function getBoundingBoxCoordinates(coordinates: number[][]): BoundingBoxCoordinates | None {
  if (coordinates.length === 0) {
    return none
  }
  const longs = coordinates.map(([long]) => long)
  const lats = coordinates.map(([, lat]) => lat)
  const minLong = Math.min(...longs)
  const maxLong = Math.max(...longs)
  const minLat = Math.min(...lats)
  const maxLat = Math.max(...lats)
  return { minLong, minLat, maxLong, maxLat }
}
