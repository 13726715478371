import { parseISO } from 'date-fns'

import { BerthId } from '../../Domain/NauticalLocations/IBerth'
import { NotificationFilterFields } from '../../Domain/Notifications/NotificationFilterFields'
import { Uuid } from '../../Domain/Uuid/Uuid'
import { IShipId } from '../../Domain/VesselDetails/IVesselDetails'
import { AgentName } from '../../shared/interfaces/filters/IAgent'

export type NotificationFilterFieldsJson = Readonly<{
  time: string // ISO-string
  berths: BerthId[]
  agents: AgentName[]
  ship: IShipId
  nauticalLocations?: Uuid[]
}>

export function notificationFilterFieldsFromJson(json: NotificationFilterFieldsJson): NotificationFilterFields {
  return {
    time: parseISO(json.time),
    berths: json.berths,
    agents: json.agents,
    ship: json.ship,
    nauticalLocations: json.nauticalLocations !== undefined ? json.nauticalLocations : [],
  }
}
