import { isEqual } from 'lodash'

import { CacheDefinition, SelectorResult } from '../../lib/asyncSelector/CacheDefinition'

import { createReducer } from './createReducer'
import { getAsyncResultFromCache } from './getAsyncResultFromCache'

import type { CacheItem } from '../../lib/asyncSelector/CacheItem'
import type { Limiter } from '../../lib/Limiter/Limiter'
import type { LazyListCons } from '../../utils/lazy'
import type { None } from '../../utils/strictNull'
import type { AppAction } from '../App/App.actions'
import type { IExpirable } from './IExpirable'
import type { Reducer, AnyAction } from 'redux'
import type { ThunkAction } from 'redux-thunk'

export class QueuedCacheDefinition<AppState, Key extends string, Result, Message, Meta = None> {
  public readonly requestAllAction: (
    meta: Meta
  ) => (
    key: Key[],
    cacheItemsForKey: LazyListCons<CacheItem<Key[], Array<IExpirable<Result>>, Meta>>
  ) => Array<ThunkAction<Promise<any>, AppState, void, AppAction>>

  public readonly reducer: Reducer<Array<CacheItem<Key[], Array<IExpirable<Result>>, Meta>>, AnyAction>

  public readonly selector: (appState: AppState) => SelectorResult<AppState, Key[], Array<IExpirable<Result>>, Meta>

  public readonly keysAreEqual: (left: Key[], right: Key[]) => boolean

  constructor(
    public readonly cacheId: string,
    public readonly limiter: Limiter<Key[], Array<IExpirable<Result>>, Meta>,
    public readonly cacheSelector: (state: AppState) => Array<CacheItem<Key[], Array<IExpirable<Result>>, Meta>>,
    public readonly queueSelector: (state: AppState) => Message[],
    public readonly toKey: (result: Result) => Key
  ) {
    this.keysAreEqual = (l, r) => isEqual(l, r)
    const cacheDefinition = new CacheDefinition<AppState, Key[], Array<IExpirable<Result>>, Meta>(
      this.cacheId,
      this.limiter,
      this.keysAreEqual,
      this.cacheSelector
    )
    this.requestAllAction = (meta: Meta) => getAsyncResultFromCache(this.cacheId, this.toKey, meta)
    this.reducer = createReducer<AppState, Key, Result, Meta>(this.toKey, cacheDefinition)
    this.selector = cacheDefinition.selector
  }
}
