import { BoundingBoxCoordinates } from './BoundingBoxCoordinates'

export function getCenterOfBoundingBoxCoordinates({
  minLong,
  maxLong,
  minLat,
  maxLat,
}: BoundingBoxCoordinates): [number, number] {
  const centerLong = (minLong + maxLong) / 2
  const centerLat = (minLat + maxLat) / 2
  return [centerLong, centerLat]
}
