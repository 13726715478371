import { PortNotification } from '../../Domain/Notifications/INotification'
import { NotificationId } from '../../modules/InfoNotifications/InfoNotifications.interfaces'

export const LOADING_PORT_NOTIFICATIONS = 'LOADING_PORT_NOTIFICATIONS'
export const SET_PORT_NOTIFICATIONS = 'SET_PORT_NOTIFICATIONS'
export const ADD_PORT_NOTIFICATION = 'ADD_PORT_NOTIFICATION'
export const UPDATE_PORT_NOTIFICATION = 'UPDATE_PORT_NOTIFICATION'
export const REMOVE_PORT_NOTIFICATION = 'REMOVE_PORT_NOTIFICATION'
export const SET_PORT_NOTIFICATIONS_ERROR = 'SET_PORT_NOTIFICATIONS_ERROR'
export const MARK_PORT_NOTIFICATIONS_READ = 'MARK_PORT_NOTIFICATIONS_RED'

export type LoadingPortNotificationsAction = Readonly<{
  type: typeof LOADING_PORT_NOTIFICATIONS
}>
export const loadingPortNotifications = (): LoadingPortNotificationsAction => {
  return {
    type: LOADING_PORT_NOTIFICATIONS,
  }
}

export type SetPortNotificationsAction = Readonly<{
  type: typeof SET_PORT_NOTIFICATIONS
  notifications: PortNotification[]
}>
export const setPortNotifications = (notifications: PortNotification[]): SetPortNotificationsAction => {
  return {
    type: SET_PORT_NOTIFICATIONS,
    notifications,
  }
}

export type AddPortNotificationAction = Readonly<{
  type: typeof ADD_PORT_NOTIFICATION
  notification: PortNotification
}>
export const addPortNotification = (notification: PortNotification): AddPortNotificationAction => {
  return {
    type: ADD_PORT_NOTIFICATION,
    notification,
  }
}

export type UpdatePortNotificationAction = Readonly<{
  type: typeof UPDATE_PORT_NOTIFICATION
  notification: PortNotification
}>
export const updatePortNotification = (notification: PortNotification): UpdatePortNotificationAction => {
  return {
    type: UPDATE_PORT_NOTIFICATION,
    notification,
  }
}

export type RemovePortNotificationAction = Readonly<{
  type: typeof REMOVE_PORT_NOTIFICATION
  id: NotificationId
}>
export const removePortNotification = (id: NotificationId): RemovePortNotificationAction => {
  return {
    type: REMOVE_PORT_NOTIFICATION,
    id,
  }
}

export type SetPortNotificationErrorAction = Readonly<{
  type: typeof SET_PORT_NOTIFICATIONS_ERROR
  error: string
}>
export const setPortNotificationError = (error: string): SetPortNotificationErrorAction => {
  return {
    type: SET_PORT_NOTIFICATIONS_ERROR,
    error,
  }
}

export type MarkPortNotificationReadAction = Readonly<{
  type: typeof MARK_PORT_NOTIFICATIONS_READ
}>
export const markPortNotificationsRead = (): MarkPortNotificationReadAction => {
  return {
    type: MARK_PORT_NOTIFICATIONS_READ,
  }
}
