import { flatMap } from 'lodash'

import { replaceWhere } from '../../utils/arr'
import { LazyList, LazyListCons } from '../../utils/lazy'

import { AWAITING_RESULT, RESULT_RECEIVED, RESULT_EXPIRED } from './consts'
import {
  RequestState,
  resultReceived,
  requestCancelled,
  resultExpired,
  update as updateRequestState,
} from './RequestState'

import type { None } from '../../utils/strictNull'
import type { Limiter } from '../Limiter/Limiter'

export type CacheItem<Key, Result, Meta = None> = Readonly<{
  key: Key
  requestState: RequestState<Result>
  meta: Meta
}>

export function handleResult<Key, Result, Meta>(
  cacheItem: CacheItem<Key, Result, Meta>,
  key: Key,
  requestId: string,
  result: Result,
  currentTime: number,
  keysAreEqual: (left: Key, right: Key) => boolean
): CacheItem<Key, Result, Meta> {
  if (
    keysAreEqual(cacheItem.key, key) &&
    cacheItem.requestState.type === AWAITING_RESULT &&
    cacheItem.requestState.requestId === requestId
  ) {
    return {
      key: cacheItem.key,
      requestState: resultReceived(result, currentTime),
      meta: cacheItem.meta,
    }
  }
  return cacheItem
}

export function cancelRequest<Key, Result, Meta>(
  cacheItem: CacheItem<Key, Result, Meta>,
  requestId: string,
  currentTime: number
): CacheItem<Key, Result, Meta> {
  if (cacheItem.requestState.type === AWAITING_RESULT) {
    return {
      key: cacheItem.key,
      requestState: requestCancelled(currentTime),
      meta: cacheItem.meta,
    }
  }
  return cacheItem
}

export function expire<Key, Result, Meta>(
  cacheItem: CacheItem<Key, Result, Meta>,
  currentTime: number
): CacheItem<Key, Result, Meta> {
  if (cacheItem.requestState.type === RESULT_RECEIVED) {
    return {
      key: cacheItem.key,
      requestState: resultExpired(cacheItem.requestState.result, cacheItem.requestState.resultReceivedAt, currentTime),
      meta: cacheItem.meta,
    }
  }
  if (cacheItem.requestState.type === AWAITING_RESULT) {
    return {
      key: cacheItem.key,
      requestState: requestCancelled(currentTime),
      meta: cacheItem.meta,
    }
  }
  return cacheItem
}

export function expireForKey<Key, Result, Meta>(
  cacheItems: Array<CacheItem<Key, Result, Meta>>,
  keysAreEqual: (left: Key, right: Key) => boolean,
  key: Key,
  currentTime: number
) {
  return replaceWhere(
    cacheItems,
    cacheItem => keysAreEqual(cacheItem.key, key),
    cacheItem => expire(cacheItem, currentTime)
  )
}

export function forKey<Key, Result, Meta>(
  cacheItems: Array<CacheItem<Key, Result, Meta>>,
  key: Key,
  keysAreEqual: (left: Key, right: Key) => boolean
): LazyListCons<CacheItem<Key, Result, Meta>> {
  return LazyList.filter(LazyList.fromArray(cacheItems), cacheItem => keysAreEqual(cacheItem.key, key))
}

export function update<Key, Result, Meta>(
  cacheItems: Array<CacheItem<Key, Result, Meta>>,
  fn: (result: Result) => Result,
  now: number,
  limiter: Limiter<Key, Result, Meta>
): Array<CacheItem<Key, Result, Meta>> {
  const updatedCacheItems = flatMap(cacheItems, cacheItem => {
    if (cacheItem.requestState.type === RESULT_RECEIVED || cacheItem.requestState.type === RESULT_EXPIRED) {
      const expiredOriginalRequestState = resultExpired(
        cacheItem.requestState.result,
        cacheItem.requestState.resultReceivedAt,
        now
      )
      const updatedRequestState = updateRequestState(cacheItem.requestState, fn, now)
      const expiredOriginalCacheItem = {
        key: cacheItem.key,
        requestState: expiredOriginalRequestState,
        meta: cacheItem.meta,
      }
      const updatedCacheItem = { key: cacheItem.key, requestState: updatedRequestState, meta: cacheItem.meta }
      return [updatedCacheItem, expiredOriginalCacheItem]
    }
    return [cacheItem]
  })

  return limiter(updatedCacheItems)
}
