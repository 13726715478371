import { PredictedWindDirectionObservation } from '../../../../Domain/HydrometeoObservation/Observation'
import { Uuid } from '../../../../Domain/Uuid/Uuid'
import { CacheDefinition } from '../../../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../../../lib/Limiter/fixedSizeLimiter'
import { tripleEquals } from '../../../../utils/equals'
import { IAppState } from '../../../App/interfaces/IAppState'

export const PREDICTED_WIND_DIRECTION_CACHE_KEY = 'predictedWindDirection'
export const predictedWindDirectionCacheDefinition = new CacheDefinition<
  IAppState,
  Uuid,
  PredictedWindDirectionObservation[]
>(
  PREDICTED_WIND_DIRECTION_CACHE_KEY,
  fixedSizeLimiter(2),
  tripleEquals,
  appState => appState.predictedWindDirectionCache
)
