export const REQUEST_ALL = 'REQUEST_ALL'

export interface IRequestAll<Key, Meta> {
  type: typeof REQUEST_ALL
  cacheId: string
  keys: Key[]
  meta: Meta
}

export function requestAll<Key, Meta>(cacheId: string, keys: Key[], meta: Meta): IRequestAll<Key, Meta> {
  return {
    type: REQUEST_ALL,
    cacheId,
    keys,
    meta,
  }
}
