import { matchPath } from 'react-router-dom'
import { createEventPortcallId, EventPortcallId } from '../Domain/Portcall/IPortcall'
import { VesselId } from '../Domain/VesselDetails/VesselId'
import { getQueryParameters } from '../modules/Dashboards/OperationalDashboard/queryParameters'
import { None, none } from '../utils/strictNull'
import { selectedPortcall, selectedSchedule } from '../View/Pages/Map/Selected'
import { selectedToQueryParameters } from '../View/Pages/Map/selectedToQueryParameters'

export const HOME_PAGE = 'HOME_PAGE'
export const PLANNING_PAGE = 'TERMINAL'
export const MAP_PAGE = 'MAP'
export const HELP_PAGE = 'HELP'
export const OPERATIONAL_DASHBOARD_PAGE = 'OPERATIONAL_DASHBOARD'
export const ANALYSIS_DASHBOARD_PAGE = 'ANALYSIS_DASHBOARD'
export const LEGAL_TERMS_PAGE = 'LEGAL_TERMS'
export const SETTINGS_PAGE = 'SETTINGS'
export const USERS_MANAGEMENT_PAGE = 'USERS_MANAGEMENT'
export const COMPANY_MANAGEMENT_PAGE = 'COMPANY_MANAGEMENT'
export const STAKEHOLDER_MANAGEMENT_PAGE = 'STAKEHOLDER_MANAGEMENT'
export const PORTFLIX_PAGE = 'PORTFLIX'
export const LIST_PAGE = 'LIST'
export const SET_FILTERS_PAGE = 'SET_FILTERS'
export const TERMINAL_MASTER_DATA_PAGE = 'TERMINAL_MASTER_DATA'
export const DEVELOPER_PORTAL_PAGE = 'DEVELOPER_PORTAL_PAGE'

export type Page =
  | typeof HOME_PAGE
  | typeof PLANNING_PAGE
  | typeof MAP_PAGE
  | typeof HELP_PAGE
  | typeof OPERATIONAL_DASHBOARD_PAGE
  | typeof ANALYSIS_DASHBOARD_PAGE
  | typeof LEGAL_TERMS_PAGE
  | typeof SETTINGS_PAGE
  | typeof USERS_MANAGEMENT_PAGE
  | typeof COMPANY_MANAGEMENT_PAGE
  | typeof STAKEHOLDER_MANAGEMENT_PAGE
  | typeof PORTFLIX_PAGE
  | typeof LIST_PAGE
  | typeof SET_FILTERS_PAGE
  | typeof TERMINAL_MASTER_DATA_PAGE
  | typeof DEVELOPER_PORTAL_PAGE

export function selectedPortcallUrl(portcallId: EventPortcallId): string {
  return `/portcall/${portcallId}`
}

export function selectedMapPortcallUrl(portcallId: EventPortcallId): string {
  return `${PAGES[MAP_PAGE]}/portcall/${portcallId}`
}

export function selectedMapVesselUrl(vesselId: VesselId): string {
  return `${PAGES[MAP_PAGE]}/vessel/${vesselId}`
}

export function operationalDashboardUrl(portcallId: EventPortcallId | None = none) {
  const queryParameters = getQueryParameters(portcallId)
  return PAGES[OPERATIONAL_DASHBOARD_PAGE] + queryParameters
}

export function mapViewUrlForSchedule(portcallIdForSchedule: EventPortcallId) {
  return PAGES[MAP_PAGE] + selectedToQueryParameters(selectedSchedule(portcallIdForSchedule))
}

export function mapViewUrlForPortcall(portcallId: EventPortcallId) {
  return PAGES[MAP_PAGE] + selectedToQueryParameters(selectedPortcall(portcallId))
}

export const PAGES: Record<Page, string> = {
  [HOME_PAGE]: '/',
  [PLANNING_PAGE]: '/planning',
  [MAP_PAGE]: '/map',
  [HELP_PAGE]: '/help',
  [PORTFLIX_PAGE]: '/tv-mode',
  [OPERATIONAL_DASHBOARD_PAGE]: '/operational-dashboard',
  [LIST_PAGE]: '/list',
  [ANALYSIS_DASHBOARD_PAGE]: '/analysis-dashboard',
  [LEGAL_TERMS_PAGE]: '/legal-terms',
  [SETTINGS_PAGE]: '/settings',
  [USERS_MANAGEMENT_PAGE]: '/users-management',
  [COMPANY_MANAGEMENT_PAGE]: '/company-management',
  [STAKEHOLDER_MANAGEMENT_PAGE]: '/data-sharing-management',
  [SET_FILTERS_PAGE]: '/set-filters',
  [TERMINAL_MASTER_DATA_PAGE]: '/terminal-master-data',
  [DEVELOPER_PORTAL_PAGE]: '/developer-portal',
}

export const DEFAULT_PAGE = PAGES[MAP_PAGE]

type LegacyPath = string
type RedirectTo = string | ((params: Record<string, string>) => string)
export const LEGACY_PAGES: Record<LegacyPath, RedirectTo> = {
  '/agent': PAGES[MAP_PAGE],
  '/dashboard/:id': ({ id }) => {
    const portcallId = createEventPortcallId(id)
    return PAGES[OPERATIONAL_DASHBOARD_PAGE] + getQueryParameters(portcallId)
  },
  '/terminal': PAGES[PLANNING_PAGE],
  '/portcall/:id': ({ id }) => {
    const portcallId = createEventPortcallId(id)
    return PAGES[MAP_PAGE] + selectedToQueryParameters(selectedPortcall(portcallId))
  },
  '/tv_mode': PAGES[PORTFLIX_PAGE],
  '/legal_terms': PAGES[LEGAL_TERMS_PAGE],
}

export const MAP_VESSEL_PATH = `${PAGES[MAP_PAGE]}/vessel/:vesselId`
export const MAP_PORTCALL_PATH = `${PAGES[MAP_PAGE]}/portcall/:portcallId`

export const isValidReturnToPath = (pathname: string) =>
  Object.values(PAGES).includes(pathname) ||
  [matchPath(pathname, { path: MAP_VESSEL_PATH }), matchPath(pathname, { path: MAP_PORTCALL_PATH })].some(
    m => m !== null
  )
