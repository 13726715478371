import { isEmpty } from './arr'
import { toKeyValues } from './obj'

export function parseQueryParameters(search: string): Record<string, string> {
  return search
    .substring(1)
    .split('&')
    .map(keyValue => {
      const [key, value] = keyValue.split('=')
      return { key, value }
    })
    .reduce<Record<string, string>>((acc, { key, value }) => {
      acc[key] = value
      return acc
    }, {})
}

export function stringifyQueryParameters(queryParameters: Record<string, string>): string {
  const keyValues = toKeyValues(queryParameters)
  if (isEmpty(keyValues)) {
    return ''
  }

  return `?${keyValues.map(({ key, value }) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&')}`
}
