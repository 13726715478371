import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { Timeline } from '../../Domain/Timelines/Timeline'
import { CacheItem, expireForKey } from '../../lib/asyncSelector/CacheItem'
import { AppAction } from '../../modules/App/App.actions'
import { timelineCacheDefinition } from '../../modules/ServicesTimeline/timelineCacheDefinition'
import { removeWhere } from '../../utils/arr'
import { WEBSOCKET_VISIT_UPDATED_EVENT_IDS, WEBSOCKET_VISIT_DELETED_EVENT_IDS } from '../actionTypes'

export const timelineCacheReducer = (
  timelineCacheItems: Array<CacheItem<EventPortcallId, Timeline | 404>> | undefined,
  action: AppAction
) => {
  const afterCacheActions = timelineCacheDefinition.reducer(timelineCacheItems, action)

  switch (action.type) {
    case WEBSOCKET_VISIT_UPDATED_EVENT_IDS:
      return action.portcallIds.reduce(
        (acc, portcallId) => expireForKey(acc, timelineCacheDefinition.keysAreEqual, portcallId, action.currentTime),
        afterCacheActions
      )
    case WEBSOCKET_VISIT_DELETED_EVENT_IDS:
      return action.portcallIds.reduce(
        (acc, portcallId) =>
          removeWhere(acc, cacheItem => timelineCacheDefinition.keysAreEqual(cacheItem.key, portcallId)),
        afterCacheActions
      )
    default:
      return afterCacheActions
  }
}
