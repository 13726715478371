import createDebug from 'debug'

import { leftJoin } from '../../lib/maps/synchronize'
import { filterMap } from '../../utils/arr'
import { identity } from '../../utils/functions'
import { none } from '../../utils/strictNull'

import {
  Action,
  awaitingResultForExpirables,
  resultReceivedForExpirables,
  requestCancelledForExpirables,
  noResultReceivedForExpirables,
} from './Actions'
import { getKeysToFetchAgain } from './getKeysToFetchAgain'
import { IExpirable, getResult } from './IExpirable'
import { IFetchJob } from './IFetchJob'

const debug = createDebug('pronto:FetchExpiredJob')

/**
 * Fetch only those notifications that are expired.
 */
export class FetchExpiredJob<Key extends string, Result, Meta> implements IFetchJob<Key, Result, Meta> {
  public actionsToDispatchDirectly: Array<Action<Key, Result, Meta>>

  public keysToFetch: Key[]

  constructor(
    private readonly cacheId: string,
    expirables: Array<IExpirable<Result>>,
    private readonly toKey: (result: Result) => Key
  ) {
    // Determine which notifications we need to fetch:
    this.keysToFetch = getKeysToFetchAgain(expirables, toKey)
    debug(`Going to fetch [${this.keysToFetch.join(', ')}] from server`)

    // If there is nothing to request, don't dispatch any action:
    this.actionsToDispatchDirectly =
      this.keysToFetch.length > 0 ? [awaitingResultForExpirables(cacheId, this.keysToFetch, Date.now())] : []
  }

  public actionsToDispatchWhenResultsArrive(results: Array<IExpirable<Result>>): Array<Action<Key, Result, Meta>> {
    // If there was nothing to request, don't dispatch any action:
    if (this.keysToFetch.length === 0) {
      return []
    }

    const resultsForKeysToFetch = leftJoin(this.keysToFetch, results, identity, er => this.toKey(getResult(er)))

    const relevantResults = filterMap(resultsForKeysToFetch, o => o.rights[0] || none)
    debug(
      `Received [${relevantResults
        .map(relevantResult => this.toKey(getResult(relevantResult)))
        .join(', ')}] results from server`
    )

    const missingResults = filterMap(resultsForKeysToFetch, o => (o.rights.length > 0 ? none : o.left))
    if (missingResults.length > 0) {
      debug(`Did not receive [${missingResults.join(', ')}] results from server`)
    }

    const actions: Array<Action<Key, Result, Meta>> = []
    if (relevantResults.length > 0) {
      actions.push(resultReceivedForExpirables(this.cacheId, relevantResults, Date.now()))
    }
    if (missingResults.length > 0) {
      actions.push(noResultReceivedForExpirables(this.cacheId, missingResults, Date.now()))
    }
    return actions
  }

  public actionsToDispatchWhenRequestFails(): Array<Action<Key, Result, Meta>> {
    debug(`Something went wrong. Cancelling requests for [${this.keysToFetch.join(', ')}]`)
    return [requestCancelledForExpirables(this.cacheId, this.keysToFetch, Date.now())]
  }
}
