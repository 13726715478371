import { ActualHydrometeoObservation } from '../../../Domain/HydrometeoObservation/Observation'
import { PhenomenonType } from '../../../Domain/HydrometeoObservation/Phenomenon'
import { CacheDefinition } from '../../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../../lib/Limiter/fixedSizeLimiter'
import { haveSameStringElements } from '../../../utils/equals'
import { IAppState } from '../../App/interfaces/IAppState'

export const ACTUAL_HYDROMETEO_OBSERVATIONS_CACHE_ID = 'actualHydrometeoObservations'

export const actualHydrometeoObservationsCacheDefinition = new CacheDefinition<
  IAppState,
  PhenomenonType[],
  ActualHydrometeoObservation[]
>(
  ACTUAL_HYDROMETEO_OBSERVATIONS_CACHE_ID,
  fixedSizeLimiter(2),
  haveSameStringElements,
  appState => appState.actualHydrometeoObservationsCache
)
