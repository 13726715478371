export const BERTH_PROSPECTS_LOADING = 'BERTH_PROSPECTS_LOADING'

// Authentication
export const LOGIN_EXPIRED = 'LOGIN_EXPIRED'
export const LOGOUT = 'LOGOUT'
export const LEGAL_TERMS_ACCEPTED = 'LEGAL_TERMS_ACCEPTED'

// User
export const SET_CLIENT_PREFERENCES = 'SET_CLIENT_PREFERENCES'

// System Notifications
export const SET_SYSTEM_NOTIFICATIONS = 'SET_SYSTEM_NOTIFICATIONS'

// Filters
export const APPLY_FILTERS = 'APPLY_FILTERS'
export const RELOAD_FILTERS = 'RELOAD_FILTERS'

// Single PortCall
export const UPDATE_PORTCALLS = 'UPDATE_PORTCALLS'

// Configuration
export const SET_CONFIG = 'SET_CONFIG'

// User Interface
export const SET_UI_ACTION_MENU_OPEN = 'SET_UI_ACTION_MENU_OPEN'
export const SET_UI_MAIN_MENU_OPEN = 'SET_UI_MAIN_MENU_OPEN'
export const UI_TOGGLE_SHOW_MAP_TRAFFIC = 'UI_TOGGLE_SHOW_MAP_TRAFFIC'
export const UI_OPEN_WARNING_NOTIFICATIONS_DRAWER = 'UI_OPEN_WARNING_NOTIFICATIONS_DRAWER'
export const UI_CLOSE_WARNING_NOTIFICATIONS_DRAWER = 'UI_CLOSE_WARNING_NOTIFICATIONS_DRAWER'
export const UI_TOGGLE_SNACKBAR_CONTENT = 'UI_TOGGLE_SNACKBAR_CONTENT'
export const UI_SET_VISIBILITY_FILTER_PORTS = 'UI_SET_VISIBILITY_FILTER_PORTS'
export const UI_SET_VISIBILITY_FILTER_VESSEL_TYPES = 'UI_SET_VISIBILITY_FILTER_VESSEL_TYPES'
export const UI_SET_DISPLAY_TIMELINE_BY_PARTY = 'UI_SET_DISPLAY_TIMELINE_BY_PARTY'

// Selected details
export const SELECT_BERTH_VISIT_DETAILS = 'SELECT_BERTH_VISIT_DETAILS'
export const CLOSE_DETAILS = 'CLOSE_DETAILS'
export const SET_SHIP_LOCATION_FOR_DETAILS = 'SET_SHIP_LOCATION_FOR_DETAILS'
export const SET_DETAILS_CONTENT_TYPE = 'SET_DETAILS_CONTENT_TYPE'

// Error
export const CLEAR_ERROR = 'CLEAR_ERROR'
export const SET_ERROR = 'SET_ERROR'

// Store
export const REDUX_STORAGE_SAVE = 'REDUX_STORAGE_SAVE'
export const REDUX_STORAGE_LOAD = 'REDUX_STORAGE_LOAD'

// Websocket
export const WEBSOCKET_OPEN = 'WS_OPEN'
export const WEBSOCKET_OPENED = 'WS_OPENED'
export const WEBSOCKET_CLOSE = 'WS_CLOSE'
export const WEBSOCKET_CLOSED = 'WS_CLOSED'
export const WEBSOCKET_ERROR = 'WS_ERROR'

export const WEBSOCKET_VISIT_UPDATED_EVENT_IDS = 'WS_VISIT_UPDATED_EVENT_ID'
export const WEBSOCKET_VISIT_DELETED_EVENT_IDS = 'WS_VISIT_DELETED_EVENT_ID'
