import { groupBy as groupBySingle, flatten, isEqualWith, first as _first, last as _last, nth as _nth } from 'lodash'

import { values } from './obj'
import { not, notFalse, Predicate, TypeGuard } from './predicates'
import { None, none } from './strictNull'

import type { Comparator } from './ordering'
import type { Equality } from './shared'

/**
 * The strongly-typed variant of Lodash's `isEqual`.
 */
export function isEqual<A>(left: A[], right: A[], eq: Equality<A> = (a, b) => a === b): boolean {
  return isEqualWith(left, right, eq)
}

export function replaceWhere<A>(
  haystack: A[],
  selector: (a: A, index: number) => boolean,
  replacer: (previous: A) => A
): A[] {
  if (haystack.some(selector)) {
    return haystack.map((a, index) => {
      if (selector(a, index)) {
        return replacer(a)
      }
      return a
    })
  }
  // For efficiency reasons, return the same reference, so that memoization may do its work.
  return haystack
}

export function removeWhere<A>(haystack: A[], selector: (a: A) => boolean): A[] {
  if (haystack.some(selector)) {
    return haystack.filter(not(selector))
  }
  // For efficiency reasons, return the same reference, so that memoization may do its work.
  return haystack
}

export function createUniformArray<A>(value: A, n: number): A[] {
  const result: A[] = []
  for (let i = 0; i < n; ++i) {
    result.push(value)
  }
  return result
}

export function groupBy<A>(
  arr: A[],
  ...propertyGetters: Array<(a: A) => string | number | boolean | null | undefined>
): A[][] {
  if (propertyGetters.length === 0) {
    return [arr]
  }

  const [firstPropertyGetter, ...otherPropertyGetters] = propertyGetters
  const grouped = values(groupBySingle(arr, firstPropertyGetter))
  return flatten(grouped.map(group => groupBy(group, ...otherPropertyGetters)))
}

export function includes<A extends B, B>(haystack: A[], needle: B): needle is A {
  return haystack.includes(needle as A)
}

// `Array#sort` is not stable (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort)
// The implementation below is, because it uses the index of the elements to stabilize the sorting.
export function stableSort<A>(arr: A[], comparator: (left: A, right: A) => number): A[] {
  return arr
    .map((item, index): [A, number] => [item, index])
    .sort(([left, leftIndex], [right, rightIndex]) => {
      const compared = comparator(left, right)
      return compared === 0 ? leftIndex - rightIndex : compared
    })
    .map(([elem]) => elem)
}

export function filterMap<A, B>(arr: A[], fn: (a: A, i: number) => B | None): B[] {
  return arr.map(fn).filter(notFalse)
}

export const lift =
  <A, B>(fn: (a: A) => B) =>
  (ar: A[]): B[] => {
    return ar.map(fn)
  }

export const empty: never[] = []

export const singleton = <A>(a: A): A[] => [a]

export function filter<A, B extends A>(predicate: TypeGuard<A, B>): (arr: A[]) => B[]
export function filter<A>(predicate: Predicate<A>): (arr: A[]) => A[]
export function filter<A>(predicate: Predicate<A> | TypeGuard<A, any>) {
  return (arr: A[]) => arr.filter(predicate)
}

export function isEmpty<A>(arr: A[]): arr is [] {
  return arr.length === 0
}

export function nonEmpty<A>(arr: A[]): boolean {
  return !isEmpty(arr)
}

export function hasMultiple<A>(arr: A[]): boolean {
  return arr.length > 1
}

export function first<A>(arr: A[]): A | None {
  return _first(arr) || none
}

export function last<A>(arr: A[]): A | None {
  return _last(arr) || none
}

export function nth<A>(arr: A[], n?: number): A | None {
  return _nth(arr, n) || none
}

export function getAt<A>(arr: A[], index: number): A | None {
  if (index < 0) {
    return none
  }
  if (index >= arr.length) {
    return none
  }
  return arr[index]
}

export type Occurances<Discriminator> = Readonly<{ discriminator: Discriminator; count: number }>

/**
 * This function counts occurances of elements (`Elem`) by using a discriminator.
 * The one from Lodash doesn't allow you to configure the discriminator, so in
 * this case this function might be handy.
 *
 * Example:
 *
 *     countBy(['Aap', 'Noot', 'Mies'], s => s.length)
 *
 * gives
 *
 *     [{discriminator: 3, count: 1}, {discriminator: 4, count: 2}]
 */
export function countBy<Elem, Discriminator>(
  arr: Elem[],
  getDiscriminator: (a: Elem) => Discriminator
): Array<Occurances<Discriminator>> {
  const accumulator: Map<Discriminator, Elem[]> = new Map()
  arr.forEach(elem => {
    const discriminator = getDiscriminator(elem)
    let elems = accumulator.get(discriminator)
    if (elems === undefined) {
      elems = []
      accumulator.set(discriminator, elems)
    }
    elems.push(elem)
  })
  return Array.from(accumulator.entries()).map(([discriminator, elems]) => ({ discriminator, count: elems.length }))
}

/**
 * Is O(N * log(N))
 */
export function groupByWithComparator<A>(arr: A[], comparator: Comparator<A>): A[][] {
  const result: A[][] = []

  const sorted = stableSort(arr, comparator)

  let groupLast: A | None = none
  let group: A[] = []
  sorted.forEach(elem => {
    if (groupLast === none) {
      groupLast = elem
      group = [elem]
    } else {
      const ord = comparator(groupLast, elem)
      if (ord === 0) {
        group.push(elem)
        groupLast = elem
      } else {
        // We're done with this group:
        result.push(group)

        // Start a new group:
        groupLast = elem
        group = [elem]
      }
    }
  })
  if (group.length > 0) {
    result.push(group)
  }

  return result
}
