import { Browser } from 'detect-browser'

import { PRODUCT_NAME } from './config'
import { HOUR } from './shared/constants'
import { Permission } from './shared/interfaces/settings/Settings'
import { weeksToHours } from './utils/DateTime/weeksToHours'

export const CURRENT_VERSION_LEGAL_TERMS = '2018-06-25'

export const SUPPORT_EMAIL = 'support@port-xchange.com'
const subject = encodeURIComponent(`Access to ${PRODUCT_NAME}`)
export const PRONTO_REQUEST_ACCESS_EMAIL_URL = `mailto:${SUPPORT_EMAIL}?subject=${subject}`
export const DEVELOPER_PORTAL_EXAMPLES_URL =
  'https://github.com/portxchange/developer-documentation/tree/master/resources'
export const DEVELOPER_PORTAL_DOCUMENTATION_URL = 'https://github.com/portxchange/developer-documentation/'
export const DEVELOPER_PORTAL_GETTING_STARTED_URL =
  'https://github.com/portxchange/developer-documentation/blob/master/index.md'
export const DEVELOPER_PORTAL_EXCHANGE_API_URL = 'https://portxchange.github.io/exchange-api-docs/'

export const DATE_RANGE_START_BASE_OPTIONS = [
  { value: 0, label: 'Current time' },
  { value: -12, label: '- 12H' },
]

export const DATE_RANGE_OFFSET_BASE_OPTIONS = [{ value: 12, label: '+ 12H' }]

export const DATE_RANGE_START_OPTIONS = [
  ...DATE_RANGE_START_BASE_OPTIONS,
  { value: -24, label: '- 24H' },
  { value: -48, label: '- 48H' },
  { value: -72, label: '- 72H' },
  { value: weeksToHours(-1), label: '- 1W' },
  { value: weeksToHours(-2), label: '- 2W' },
  { value: weeksToHours(-3), label: '- 3W' },
]

export const DATE_RANGE_OFFSET_OPTIONS = [
  ...DATE_RANGE_OFFSET_BASE_OPTIONS,
  { value: 24, label: '+ 24H' },
  { value: 48, label: '+ 48H' },
  { value: 72, label: '+ 72H' },
  { value: weeksToHours(1), label: '+ 1W' },
  { value: weeksToHours(2), label: '+ 2W' },
  { value: weeksToHours(4), label: '+ 4W' },
  { value: weeksToHours(6), label: '+ 6W' },
]

export const REFRESH_AUTH_TOKEN_INTERVAL = HOUR

export const PERMISSIONS: Record<string, Permission> = {
  FILTER_AGENTS: 'filter:agents',
  FILTER_BERTHS: 'filter:berths',
  FILTER_CARRIER: 'filter:carriers',
  FILTER_SCHEDULES: 'filter:schedules',
  FILTER_EXTENDED_DATERANGE: 'filter:extended-daterange',
  PORTBASE_MAILSHIP: 'portbase:mailship',
  PORTBASE_UPDATE: 'portbase:update',
}

export const MODULE_VIEW_PERMISSIONS = {
  DASHBOARD: 'moduleView:dashboard',
  PLANNING: 'moduleView:planning',
  JIT: 'moduleView:JIT',
  NOTIFICATION: 'moduleView:notification',
} as const

export const SPEED_COLOR = [
  { red: 66, green: 154, blue: 209 }, // blue
  { red: 0, green: 255, blue: 255 }, // light blue
  { red: 39, green: 210, blue: 66 }, // green
  { red: 255, green: 255, blue: 0 }, // yellow
  { red: 255, green: 165, blue: 0 }, // orange
  { red: 255, green: 0, blue: 0 }, // red
]

export const NOT_AVAILABLE = 'N/A'

/**
 * These constants require some explanation.
 * In the map, you sometimes want to center around a ship.
 * These constants represent the distance from the top of the map to that ship (relative to the entire height of the map).
 * Normally, this would be 0.5 (which would put the ship in the exact center of the map).
 *
 * +-----+  +
 * |     |  | 0.5
 * |  X  |  +
 * |     |
 * +-----+
 *
 * However, when the bottom half the screen is occupied by the snackbar with the ship details
 * you don't want to put the ship in the middle of the map anymore. The snackbar might be overlapping
 * the ship completely. Instead, we put the ship a little higher:
 *
 * +-----+
 * |  X  |  <--- Visible part of the map
 * |-----|
 * |/////|  <--- Snackbar
 * +-----+
 *
 * This constant defines that "higher". It says that the ship should be located at 25% of the map's height
 * from the top. If the snackbar takes up 50% of the map's height, then the ship will be in the exact center
 * of the part of the map that remains visible.
 */
const DEFAULT_VERTICAL_OFFSET_FRACTION = 0.5
export const VERTICAL_OFFSET_FRACTION_NO_BOTTOM_DRAWER_OPEN = DEFAULT_VERTICAL_OFFSET_FRACTION
export const VERTICAL_OFFSET_FRACTION_FOR_BOTTOM_DRAWER_OPEN = 0.25

const DEFAULT_HORIZONTAL_OFFSET_FRACTION = 0.5
export const HORIZONTAL_OFFSET_FRACTION_NO_LEFT_DRAWER_OPEN = DEFAULT_HORIZONTAL_OFFSET_FRACTION
export const HORIZONTAL_OFFSET_FRACTION_FOR_LEFT_DRAWER_OPEN = 0.6

export const SUPER_CLUSTER_MAX_ZOOM = 10

// Planning view axis marker constants
export const MINIMUM_NUMBER_OF_PIXELS_PER_STEP = 100
export const MINIMUM_NUMBER_OF_STEPS = 2

// Hubspot portal ID
export const HUBSPOT_PORTAL_ID = '7914683'
export enum HubspotFormIds {
  BugReport = '39953c77-4e59-4af7-b4d2-dded2a3a77ef',
}

export enum Key {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
  Escape = 'Escape',
}

export const AUTH_SUPPORTED_BROWSERS: Browser[] = ['chrome', 'firefox', 'edge', 'safari']
