import { EventPortcallId } from '../../Domain/Portcall/IPortcall'

export const PORTCALL_UPDATE = 'PORTCALL_UPDATE'

export interface IPortcallUpdate {
  type: typeof PORTCALL_UPDATE
  portcallId: EventPortcallId
}

export function portcallUpdate(portcallId: EventPortcallId): IPortcallUpdate {
  return {
    type: PORTCALL_UPDATE,
    portcallId,
  }
}
