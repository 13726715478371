import { BerthVisit } from '../../Domain/BerthVisits/BerthVisit'
import { BerthVisitLocationRef } from '../../Domain/BerthVisits/LocationRef'
import { createMovementId } from '../../Domain/Movements/Movement'
import { BerthId } from '../../Domain/NauticalLocations/IBerth'
import { BerthVisitId } from '../../Domain/Portcall/IPortcall'
import { ServiceType } from '../../Domain/ServiceVessels/ServiceType'
import { NotificationId } from '../../modules/InfoNotifications/InfoNotifications.interfaces'
import { IBerthVisitStatuses, IInterval } from '../../shared/interfaces/shipvisit/IBerthVisit'
import { none, StrictNull } from '../../utils/strictNull'
import { fromMostAccuratesJson } from '../Event/fromMostAccuratesJson'
import { MostAccuratesJson } from '../Event/MostAccuratesJson'
import { mooringInfoFromJson, MooringInfoJson } from '../MooringInfo/MooringInfoJson'
import { fromServiceActivityJson } from '../ServiceActivities/fromServiceActivityJson'
import { ServiceActivityJson } from '../ServiceActivities/ServiceActivityJson'
import { fromNeighbourBerthVisitJson, NeighbourBerthVisitJson } from '../ServiceVessels/NeighbourBerthVisitJson'

export type BerthVisitJson = Readonly<{
  id: BerthVisitId // uuid
  berthId?: BerthId
  berthName?: string
  locationRefs?: BerthVisitLocationRef[]
  mooring?: MooringInfoJson
  berthReached: boolean
  timeframeAlongside?: IInterval
  notifications: NotificationId[]
  previousVessel?: NeighbourBerthVisitJson
  nextVessel?: NeighbourBerthVisitJson
  inboundMovement?: string
  outboundMovement?: string
  mostAccurates: MostAccuratesJson
  statuses: IBerthVisitStatuses
  services: ServiceActivityJson[]
  stakeholderCompanies?: string[]
  neighbourCompanies?: string[]
  startDraft?: number
  endDraft?: number
}>

export function berthVisitFromJson(json: BerthVisitJson): BerthVisit {
  return {
    id: json.id,
    berthId: json.berthId === undefined ? none : json.berthId,
    berthName: json.berthName === undefined ? none : json.berthName,
    locationRefs: json.locationRefs === undefined ? [] : json.locationRefs,
    mooring: json.mooring === undefined ? none : mooringInfoFromJson(json.mooring),
    berthReached: json.berthReached,
    timeframeAlongside: json.timeframeAlongside,
    notifications: json.notifications,
    previousVessel: StrictNull.flatMap(json.previousVessel || none, pv =>
      fromNeighbourBerthVisitJson(pv, ServiceType.PreviousNeighbour)
    ),
    nextVessel: StrictNull.flatMap(json.nextVessel || none, pv =>
      fromNeighbourBerthVisitJson(pv, ServiceType.NextNeighbour)
    ),
    inboundMovement: json.inboundMovement === undefined ? none : createMovementId(json.inboundMovement),
    outboundMovement: json.outboundMovement === undefined ? none : createMovementId(json.outboundMovement),
    mostAccurates: fromMostAccuratesJson(json.mostAccurates),
    statuses: json.statuses,
    services: json.services.map(fromServiceActivityJson),
    stakeholderCompanies: json.stakeholderCompanies || none,
    neighbourCompanies: json.neighbourCompanies || none,
    startDraft: json.startDraft || none,
    endDraft: json.endDraft || none,
  }
}
