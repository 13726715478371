import { ISystemStatusMessage } from '../../Domain/SystemStatusMessages/ISystemStatusMessage'
import { AppAction } from '../../modules/App/App.actions'
import { SET_SYSTEM_NOTIFICATIONS } from '../actionTypes'

export const defaultState: ISystemStatusMessage[] = []

const systemStatusMessagesReducer = (state = defaultState, action: AppAction): ISystemStatusMessage[] => {
  switch (action.type) {
    case SET_SYSTEM_NOTIFICATIONS:
      return action.systemStatusMessages
    default:
      return state
  }
}

export default systemStatusMessagesReducer
