import { Subject, of, merge, BehaviorSubject } from 'rxjs'
import { first, switchMap, auditTime } from 'rxjs/operators'

import { SECOND } from '../../shared/constants'

import { WebsocketStatus, connectionClosed as connectionClosedStatus, lastUpdateReceivedAt } from './WebsocketStatus'

const connectionClosedSubject = new BehaviorSubject<WebsocketStatus>(connectionClosedStatus())
const lastUpdateReceivedAtSubject = new Subject<WebsocketStatus>()

export function connectionClosed() {
  connectionClosedSubject.next(connectionClosedStatus())
}

export function messageReceived(at: Date) {
  lastUpdateReceivedAtSubject.next(lastUpdateReceivedAt(at))
}

// This looks rather complicated, what does this do?
// We write the `ConnectionClosed` as 'X'
// We write the `LastUpdateReceivedAt` as 'O'
//
// We have the two Subjects:
// --X-----------X--------------
// -----O--O--O-----O-----------
//
// We `switchMap` with the following:
// --X-----------X--------------
//    \           \
//     -X|         -X|
//     --O|        --O|
//     --O--O--O-| --O----------
//
// The last line is used with `auditTime` to
// only retain the last one every N seconds,
// so we have instead:
// --X-----------X--------------
//    \           \
//     -X|         -X|
//     --O|        --O|
//     --------O-| -------O-----
//
// We arrive at:
// --X--O-----O--X--O-----O-----
//
// We get both the 'X' right when they happen,
// the 'O' directly when the are preceded by a
// 'X' and some `auditTime`-ed 'O's.
export const websocketStatusObservable = connectionClosedSubject.pipe(
  switchMap(cc => {
    const lastUpdateReceivedAtObservable = lastUpdateReceivedAtSubject.asObservable()
    const firstLastUpdateReceivedAtObservable = lastUpdateReceivedAtObservable.pipe(first())
    const connectionClosedObservable = of(cc)
    return merge(
      connectionClosedObservable,
      firstLastUpdateReceivedAtObservable,
      lastUpdateReceivedAtObservable.pipe(auditTime(30 * SECOND))
    )
  })
)
