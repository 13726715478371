import { differenceInHours } from 'date-fns'
import { format, formatInTimeZone, getTimezoneOffset } from 'date-fns-tz'

import { TimeZone, timeZoneLocal, timeZoneUTC } from '../../ApplicationState/DateTime/TimeZone'
import { none, None } from '../strictNull'

export function formatDateWithOptionalTimeZone(
  date: Date,
  formatting: string,
  timeZone: TimeZone | None,
  appendUtcIndicator = true
): string {
  if (timeZone === timeZoneLocal) {
    return format(date, formatting)
  }

  if (timeZone === none) {
    const withoutUtcIndicator = formatInTimeZone(date, timeZoneUTC, formatting)
    return appendUtcIndicator ? `${withoutUtcIndicator} UTC` : withoutUtcIndicator
  }

  return formatInTimeZone(date, timeZone, formatting)
}

const showUTCOffset = (offset: number | undefined) =>
  offset === undefined ? '' : `(UTC${offset > 0 ? '+' : ''}${offset})`

const toString = (date: Date, timeZone = 'UTC', offset?: number) =>
  `${Intl.DateTimeFormat('default', {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date)} LT ${showUTCOffset(offset)}`

export const formatDateWithTimeZone = (date: Date, timeZone?: string) =>
  toString(date, timeZone, timeZone ? getTimezoneOffset(timeZone, date) / 3600_000 : undefined)

export const formatDateWithUTCOffset = (zulu: Date, local?: Date) =>
  toString(local ?? zulu, undefined, local ? differenceInHours(local, zulu) : undefined)
