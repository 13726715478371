import { PRODUCT_NAME } from '../../config'
import { ErrorPage } from '../ErrorPage/ErrorPage'
import { RootComponent } from '../Root/Root'

const BrowserUnsupportedPage = () => (
  <RootComponent>
    <ErrorPage
      detail={`But this browser is not supported by ${PRODUCT_NAME}`}
      suggestion={
        <p>
          Please download{' '}
          <a target="_blank" href="https://www.google.nl/chrome/index.html" rel="noreferrer noopener">
            Chrome
          </a>{' '}
          or{' '}
          <a target="_blank" href="https://www.mozilla.org/nl/firefox/new/" rel="noreferrer noopener">
            Firefox
          </a>
        </p>
      }
    />
  </RootComponent>
)

export default BrowserUnsupportedPage
