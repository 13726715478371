import { parseISO } from 'date-fns'

import { TimeZone, createTimeZone } from '../../ApplicationState/DateTime/TimeZone'
import { MEDIUM_DATE_TIME_FORMAT } from '../../constants/dateFormat'
import { formatDateWithOptionalTimeZone } from '../../utils/DateTime/formatDateWithOptionalTimeZone'
import { None, none, StrictUndefined, StrictNull } from '../../utils/strictNull'

// ISO8601 local date: 2018-01-01
export type ILocalDate = string

export type IDateTimeInput = number | string | Date
type TimeUnit = 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds'

export type IParsedDuration = Record<TimeUnit, number>

export type DateTimeIANA = Readonly<{
  datetime: Date
  iana: TimeZone | None
  offset: string | None
}>

export function parseDuration(duration: string): IParsedDuration | null {
  if (!duration) {
    return null
  }

  const regex =
    /P((([0-9]*\.?[0-9]*)Y)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)W)?(([0-9]*\.?[0-9]*)D)?)?(T(([0-9]*\.?[0-9]*)H)?(([0-9]*\.?[0-9]*)M)?(([0-9]*\.?[0-9]*)S)?)?/
  const matches = duration.match(regex)
  if (!matches) {
    return null
  }

  const years = (matches[3] && parseFloat(matches[3])) || 0
  const months = (matches[5] && parseFloat(matches[5])) || 0
  const weeks = (matches[7] && parseFloat(matches[7])) || 0
  const days = (matches[9] && parseFloat(matches[9])) || 0
  const hours = (matches[12] && parseFloat(matches[12])) || 0
  const minutes = (matches[14] && parseFloat(matches[14])) || 0
  const seconds = (matches[16] && parseFloat(matches[16])) || 0

  return {
    years,
    months,
    weeks,
    days,
    hours,
    minutes,
    seconds,
  }
}

export function dateFormat(
  date: Date,
  timeZone: TimeZone | None,
  formatString?: string,
  appendUtcIndicator?: boolean
): string
export function dateFormat(
  date: Date | null | undefined,
  timeZone: TimeZone | None,
  formatString?: string,
  appendUtcIndicator?: boolean
): string | null
export function dateFormat(
  date: Date | null | undefined,
  timeZone: TimeZone | None,
  formatString: string = MEDIUM_DATE_TIME_FORMAT,
  appendUtcIndicator = true
): string | null {
  return date ? formatDateWithOptionalTimeZone(date, formatString, timeZone, appendUtcIndicator) : null
}

export function isTimeBetweenTimes(currentTime: number, startTime: number, endTime: number): boolean {
  return startTime <= currentTime && currentTime <= endTime
}

export function isoString(date: Date): string
export function isoString(date: Date | None | null | undefined): string | null
export function isoString(date: Date | None | null | undefined): string | null {
  return (date && date.toISOString()) || null
}

const timezonePattern = /(.*?)((?:\+|-)\d\d:\d\d|Z) ?(\[(.*)\])?/

/** Parses a Java-style ISO timestamp.
 *
 * Java-style ISO timestamp (from `ZonedDateTime`) are in the following format:
 *
 *     2019-10-22T11:50:19.453+02:00[Europe/Berlin]
 *
 * `date-fns`'s `parseISO()` function drops the whole `+02:00` part of a timestamp
 * when there is no space between it and the IANA (`[Europe/Amsterdam`]), so we need to manually
 * split those, and keep the IANA in an object for future use.
 *
 * @param timestamp Java-style ISO timestamp (e.g. `2019-10-22T12:05:13.123+02:00[Europe/Amsterdam]`)
 */
export function parseBackendISO(timestamp: string): DateTimeIANA {
  const groups = timezonePattern.exec(timestamp)

  if (groups) {
    const iana = StrictNull.fromUndefined(StrictUndefined.map(groups[4], i => createTimeZone(i)))
    const datetime = parseISO(groups[1] + groups[2])
    const offset = StrictNull.fromUndefined(StrictUndefined.map(groups[2], o => (o === 'Z' ? '+00:00' : o)))

    return { datetime, iana, offset }
  }

  return { datetime: parseISO(timestamp), iana: none, offset: none }
}
