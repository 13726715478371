import { AppAction } from '../../modules/App/App.actions'
import { CLEAR_ERROR, SET_ERROR } from '../actionTypes'

import { ErrorState } from './ErrorState'

export const defaultState: ErrorState = null

const errorReducers = (state: ErrorState = defaultState, action: AppAction): ErrorState => {
  switch (action.type) {
    case SET_ERROR:
      return action.error
    case CLEAR_ERROR:
      return defaultState
    default:
      return state
  }
}

export default errorReducers
