import { StrictUndefined } from '../../utils/strictNull'

import type { JitSummary } from '../../Domain/Portcall/IPortcall'

export enum JitSummaryStatus {
  AVAILABLE = 'Available',
  NOT_AVAILABLE = 'NotAvailable',
  NOT_SUPPORTED = 'NotSupported',
  NOT_RELEVANT_ANYMORE = 'NotRelevantAnymore',
}

export type JitSummaryJson = Readonly<{
  status?: JitSummaryStatus
  shipArrivedAtPort?: boolean
  deviatesTooMuchFromSuggestedSpeed?: boolean
}>

export function fromPortcallJitSummaryJson(summaryJson: JitSummaryJson): JitSummary {
  const altStatus = StrictUndefined.fold(
    summaryJson.shipArrivedAtPort,
    // If we know the ship's port status, check if it has arrived
    shipAtPort => (shipAtPort ? JitSummaryStatus.NOT_RELEVANT_ANYMORE : JitSummaryStatus.AVAILABLE),
    // Otherwise, JIT status is not available
    JitSummaryStatus.NOT_AVAILABLE
  )

  return {
    // If we have a summary status, return that. Otherwise, return the alternative status
    status: summaryJson.status || altStatus,
    deviatesTooMuchFromSuggestedSpeed: !!summaryJson.deviatesTooMuchFromSuggestedSpeed,
  }
}
