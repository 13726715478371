import { parseJSON } from 'date-fns'
import { MAX_NUMBER_OF_VESSELS_IN_STATUS_REQUESTS } from '../Api/Ais/Constants'
import { TrafficVesselJson } from '../Domain/Vessel/Vessel'
import { AIS_URL } from '../shared/constants'
import { IShipLocation } from '../shared/interfaces/shipLocation/IShipLocation'
import { createHeaders } from '../shared/utils/createHeaders'
import { handleJSONResponse } from '../shared/utils/rest'
import { getAuthToken } from '../utils/auth/authClientObservable'
import { batchedProcess } from '../utils/batchedProcess'

export const fromJson = <V extends { mmsi: number; eta?: string; etaLocal?: string }>(v: V) => ({
  ...v,
  mmsi: `${v.mmsi}`,
  eta: v.eta ? parseJSON(v.eta) : undefined,
  etaLocal: v.etaLocal ? parseJSON(v.etaLocal) : undefined,
})

export const fetchShipLocation = (mmsi: string): Promise<TrafficVesselJson | 404> =>
  getAuthToken().then(token =>
    fetch(`${AIS_URL}/vessel/${mmsi}/status`, {
      headers: createHeaders(token),
    })
      .then(resp => (resp.status === 404 ? 404 : resp.json()))
      .then(res => (res === 404 ? res : fromJson(res)))
  )

/**
 * WARNING! This guy doesn't use the cache. You might consider using some of the functions in
 * `fetchShipLocationsEpic.ts` which do.
 */
export async function fetchShipLocationsByMmsis(mmsis: string[]): Promise<IShipLocation[]> {
  const authToken = await getAuthToken()
  if (mmsis.length === 0) {
    return Promise.resolve([])
  }

  return batchedProcess(mmsis, MAX_NUMBER_OF_VESSELS_IN_STATUS_REQUESTS, 2, (batch): Promise<IShipLocation[]> => {
    const url = `${AIS_URL}/vessels/status`
    const options = {
      method: 'POST',
      headers: createHeaders(authToken),
      body: JSON.stringify(batch),
    }

    return fetch(url, options).then(response => handleShipLocationJSONResponse(options, response))
  })
}

// We use custom error handling here because a 404 response for one of those API calls
// means that we cannot show the ship location and just return nothing.
// Rejected Promises with the value `null` will swallow the actual error message.
async function handleShipLocationJSONResponse(request: RequestInit, response: Response): Promise<any> {
  if (response.status === 404) {
    return Promise.reject(null)
  }

  const result = await handleJSONResponse(request, response)
  if (Array.isArray(result)) {
    return result.map(fromJson)
  }

  return result
}
