import { difference } from 'lodash'

import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { CacheItem, update } from '../../lib/asyncSelector/CacheItem'
import { IFilters } from '../../shared/interfaces/filters/IFilters'
import { WEBSOCKET_VISIT_DELETED_EVENT_IDS } from '../../store/actionTypes'
import { AppAction } from '../App/App.actions'

import { portcallIdsCacheDefinition } from './portcallIdsCacheDefinition'

export function portcallIdsCacheReducer(
  cacheItems: Array<CacheItem<IFilters, EventPortcallId[]>> | undefined,
  action: AppAction
): Array<CacheItem<IFilters, EventPortcallId[]>> {
  const afterCacheActions = portcallIdsCacheDefinition.reducer(cacheItems, action)

  switch (action.type) {
    case WEBSOCKET_VISIT_DELETED_EVENT_IDS:
      return update(
        afterCacheActions,
        eventPortcallIds => difference(eventPortcallIds, action.portcallIds),
        action.currentTime,
        portcallIdsCacheDefinition.limiter
      )
    default:
      return afterCacheActions
  }
}
