import { getMostAccurateStart } from '../../Domain/Events/getMostAccurateStart'
import { MostAccurates } from '../../Domain/Events/MostAccurates'
import { Movement } from '../../Domain/Movements/Movement'
import { StrictUndefined, none, StrictNull, None } from '../../utils/strictNull'
import { fromEventJson } from '../Event/fromEventJson'
import { fromMostAccuratesJson } from '../Event/fromMostAccuratesJson'
import { fromServiceActivityJson } from '../ServiceActivities/fromServiceActivityJson'

import { MovementJson } from './MovementJson'

export function fromMovementJson(json: MovementJson): Movement {
  const mostAccurates: MostAccurates | None = StrictUndefined.fold(json.mostAccurates, fromMostAccuratesJson, none)
  const mostAccurateStartFromMostAccurates = StrictNull.flatMap(mostAccurates, getMostAccurateStart)
  const mostAccurateStart = StrictUndefined.fold(
    json.mostAccurateStart,
    fromEventJson,
    mostAccurateStartFromMostAccurates
  )

  return {
    id: json.id,
    movementType: json.movementType,
    mostAccurates,
    mostAccurateStart,
    timeframe: json.timeframe,
    notifications: json.notifications,
    services: json.services.map(fromServiceActivityJson),
  }
}
