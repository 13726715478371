import { BerthVisitId, EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { IShipLocation } from '../../shared/interfaces/shipLocation/IShipLocation'
import { IVessel } from '../../shared/interfaces/shipvisit/IVessel'
import { None, none } from '../../utils/strictNull'

export enum SelectedDetailsType {
  SHIP_DETAILS_VIEW = 'SHIP_DETAILS_VIEW',
  VISIT_DETAILS_VIEW = 'VISIT_DETAILS_VIEW',
  SERVICES_VIEW = 'SERVICES_VIEW',
  SPEED_VIEW = 'SPEED_VIEW',
  EVENTS_VIEW = 'EVENTS_VIEW',
  ITINERARY = 'ITINERARY',
  NOTIFICATIONS_VIEW = 'NOTIFICATIONS_VIEW',
  WARNINGS_VIEW = 'WARNINGS_VIEW',
  CLARIFICATIONS_VIEW = 'CLARIFICATIONS_VIEW',
  STAKEHOLDERS_VIEW = 'STAKEHOLDERS_VIEW',
}

// note: the order below also determines the order of the tabs shown in the UI
export const ALL_TABS = [
  SelectedDetailsType.SHIP_DETAILS_VIEW,
  SelectedDetailsType.ITINERARY,
  SelectedDetailsType.VISIT_DETAILS_VIEW,
  SelectedDetailsType.SERVICES_VIEW,
  SelectedDetailsType.SPEED_VIEW,
  SelectedDetailsType.EVENTS_VIEW,
  SelectedDetailsType.NOTIFICATIONS_VIEW,
  SelectedDetailsType.WARNINGS_VIEW,
  SelectedDetailsType.STAKEHOLDERS_VIEW,
]

export const SELECTED_NOTHING = 'SELECTED_NOTHING'
export const SELECTED_BERTH_VISIT = 'SELECTED_BERTH_VISIT'
export const SELECTED_PORTCALL = 'SELECTED_PORTCALL'
export const SELECTED_QUICK_SEARCH_VESSEL = 'SELECTED_QUICK_SEARCH_VESSEL'

interface ISelectedNothing {
  type: typeof SELECTED_NOTHING
}

export const detailsNotSelected: ISelectedNothing = {
  type: SELECTED_NOTHING,
}

export const SELECTED_SHIP_LOCATION_AVAILABLE = 'SELECTED_SHIP_LOCATION_AVAILABLE'
export const SELECTED_SHIP_LOCATION_UNKNOWN = 'SELECTED_SHIP_LOCATION_UNKNOWN'

interface IShipLocationAvailable {
  type: typeof SELECTED_SHIP_LOCATION_AVAILABLE
  value: IShipLocation
}

interface INoShipLocationKnown {
  type: typeof SELECTED_SHIP_LOCATION_UNKNOWN
}

export type SelectedShipLocation = IShipLocationAvailable | INoShipLocationKnown

export function shipLocationToSelectedShipLocation(shipLocation: IShipLocation | None): SelectedShipLocation {
  return shipLocation === none ? shipLocationUnknown : { type: SELECTED_SHIP_LOCATION_AVAILABLE, value: shipLocation }
}

export const shipLocationUnknown: INoShipLocationKnown = {
  type: SELECTED_SHIP_LOCATION_UNKNOWN,
}

interface ISelectedBerthVisit {
  type: typeof SELECTED_BERTH_VISIT
  portcallId: EventPortcallId
  berthVisitId: BerthVisitId
  selectedDetailsType: SelectedDetailsType
  location: SelectedShipLocation
}

export interface ISelectedPortcall {
  type: typeof SELECTED_PORTCALL
  portcallId: EventPortcallId
  selectedDetailsType: SelectedDetailsType
  location: SelectedShipLocation
}

// Used for quick search
export interface ISelectedQuickShipFinderVessel {
  type: typeof SELECTED_QUICK_SEARCH_VESSEL
  // @TODO @EasyMap Remove property `vessel` when switched to Easy Map.
  vessel: IVessel
  portcallId: EventPortcallId | None
  selectedDetailsType: SelectedDetailsType
  location: SelectedShipLocation
}

export type SelectedDetailsState =
  | ISelectedNothing
  | ISelectedBerthVisit
  | ISelectedPortcall
  | ISelectedQuickShipFinderVessel
