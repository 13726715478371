import { Observable, OperatorFunction, defer, concat, EMPTY } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

// Behaves a bit like `exhaustMap`.
// Instead of processing one `Outer` and ignoring every
// other `Outer` that is received while processing the fist,
// this operator collects the last `Outer` received and also
// lets you map that to `Observable<Inner>`:
//
// input:    --a--b--c--d
// fn:       x => --------X|
// expected: ----------A--------C--------D

export const exhaustMapWithLatest =
  <Outer, Inner>(fn: (outer: Outer) => Observable<Inner>): OperatorFunction<Outer, Inner> =>
  (source: Observable<Outer>): Observable<Inner> => {
    return defer(() => {
      let subscribedToInner = false
      let last: { value: Outer } | null = null
      function nextOuter(outer: Outer): Observable<Inner> {
        if (subscribedToInner) {
          last = { value: outer }
          return EMPTY
        }
        subscribedToInner = true
        last = null
        return concat(
          fn(outer),
          defer(() => {
            subscribedToInner = false
            return last === null ? EMPTY : nextOuter(last.value)
          })
        )
      }
      return source.pipe(mergeMap(nextOuter))
    })
  }
