import type { EventTypeId, IEventLocation, IEventContext } from '../../shared/interfaces/event/IEvent'
import type { None } from '../../utils/strictNull'
import type { EventPortcallId } from '../Portcall/IPortcall'
import type { IShipId } from '../VesselDetails/IVesselDetails'

export type Event<EventTime = Date> = Readonly<{
  uuid: string
  eventType: EventTypeId
  source: string
  recordTime: Date
  eventTime: EventTime
  ship: IShipId
  portcallId: EventPortcallId | None
  previousPort: string | None
  location: IEventLocation | None
  context: IEventContext | None
}>

export type EventWithOptionalEventTime = Event<Date | None>

export const hasEventType = (eventType: EventTypeId) => (event: EventWithOptionalEventTime) =>
  event.eventType === eventType

export const hasEventTypeIn =
  (...eventTypes: EventTypeId[]) =>
  (event: EventWithOptionalEventTime) => {
    return eventTypes.some(eventType => hasEventType(eventType)(event))
  }

export function getRecordTime<EventTime>(event: Event<EventTime>) {
  return event.recordTime
}

export function getEventTime<EventTime>(event: Event<EventTime>) {
  return event.eventTime
}

export function getSource(event: EventWithOptionalEventTime) {
  return event.source
}

export function getContextAttribute<EventTime, K extends keyof IEventContext>(
  event: Event<EventTime>,
  attributeName: K
): IEventContext[K] | undefined {
  if (!event.context) {
    return undefined
  }

  return event.context[attributeName]
}
