import { concat, of } from 'rxjs'

import { clearCacheAction } from '../../lib/asyncSelector/Action'
import { CacheItem } from '../../lib/asyncSelector/CacheItem'
import { removeFromQueue } from '../../lib/asyncSelector/queueActions'
import { throwUnreachable } from '../../utils/throwUnreachable'

import { Action, expireExpirable } from './Actions'
import { IExpirable } from './IExpirable'
import { EXPIRE } from './IExpire'
import { EXPIRE_ALL } from './IExpireAll'
import { IFetcher } from './IFetcher'
import { IFetchJob, toObservable } from './IFetchJob'
import { REQUEST_ALL } from './IRequestAll'
import { Message } from './Message'
import { requestAllMessageToJob } from './requestAllMessageToJob'

export function handleMessage<Key extends string, Result, ErrorAction, Meta>(
  message: Message<Key, Meta>,
  cacheItems: Array<CacheItem<Key[], Array<IExpirable<Result>>, Meta>>,
  cacheId: string,
  fetcher: IFetcher<Key, Result>,
  toKey: (result: Result) => Key,
  keysAreEqual: (left: Key[], right: Key[]) => boolean,
  errorHandler: (e: any) => ErrorAction[]
) {
  switch (message.type) {
    case REQUEST_ALL: {
      const job: IFetchJob<Key, Result, Meta> = requestAllMessageToJob(message, cacheItems, toKey, keysAreEqual)
      return concat(toObservable(job, fetcher, errorHandler), of(removeFromQueue(cacheId, message)))
    }
    case EXPIRE:
      return concat(
        of<ErrorAction | Action<Key, Result, Meta>>(expireExpirable(cacheId, message.keys, Date.now())),
        of(removeFromQueue(cacheId, message))
      )
    case EXPIRE_ALL:
      return concat(
        of<ErrorAction | Action<Key, Result, Meta>>(clearCacheAction(cacheId, Date.now())),
        of(removeFromQueue(cacheId, message))
      )
    default:
      return throwUnreachable(message)
  }
}
