import { AWAITING_RESULT, REQUEST_CANCELLED, RESULT_EXPIRED, RESULT_RECEIVED } from './consts'

type AwaitingResult = Readonly<{
  type: 'AWAITING_RESULT'
  requestId: string
  updatedAt: number // `Date` as a `number`.
}>

type RequestCancelled = Readonly<{
  type: 'REQUEST_CANCELLED'
  updatedAt: number // `Date` as a `number`.
}>

type ResultReceived<Result> = Readonly<{
  type: 'RESULT_RECEIVED'
  result: Result
  resultReceivedAt: number
  updatedAt: number // `Date` as a `number`.
}>

type ResultExpired<Result> = Readonly<{
  type: 'RESULT_EXPIRED'
  result: Result
  resultReceivedAt: number
  updatedAt: number // `Date` as a `number`.
}>

export type RequestState<Result> = AwaitingResult | RequestCancelled | ResultReceived<Result> | ResultExpired<Result>

// Used to order `RequestState<...>`-instances.
const typeIndex: Record<RequestState<any>['type'], number> = {
  [AWAITING_RESULT]: 0,
  [RESULT_RECEIVED]: 1,
  [RESULT_EXPIRED]: 2,
  [REQUEST_CANCELLED]: 3,
}

export function ordering(left: RequestState<any>, right: RequestState<any>): number {
  const typeOrdering = typeIndex[left.type] - typeIndex[right.type]
  if (typeOrdering === 0) {
    return right.updatedAt - left.updatedAt
  }
  return typeOrdering
}

export function awaitingResult(requestId: string, now: number): AwaitingResult {
  return {
    type: AWAITING_RESULT,
    requestId,
    updatedAt: now,
  }
}

export function requestCancelled(now: number): RequestCancelled {
  return {
    type: REQUEST_CANCELLED,
    updatedAt: now,
  }
}

export function resultReceived<Result>(result: Result, now: number): ResultReceived<Result> {
  return {
    type: RESULT_RECEIVED,
    result,
    resultReceivedAt: now,
    updatedAt: now,
  }
}

export function resultExpired<Result>(result: Result, resultReceivedAt: number, now: number): ResultExpired<Result> {
  return {
    type: RESULT_EXPIRED,
    result,
    resultReceivedAt,
    updatedAt: now,
  }
}

export function update<Result>(
  requestState: RequestState<Result>,
  fn: (result: Result) => Result,
  now: number
): RequestState<Result> {
  switch (requestState.type) {
    case AWAITING_RESULT:
    case REQUEST_CANCELLED:
      return requestState
    case RESULT_RECEIVED:
      return resultReceived(fn(requestState.result), now)
    case RESULT_EXPIRED:
      return resultExpired(fn(requestState.result), requestState.resultReceivedAt, now)
    default:
      throw new Error(requestState)
  }
}
