import { None, none } from '../../utils/strictNull'

export function getSpeedOverGround(shipLocation: { speedOverGround?: number | null }): number | None {
  // 102.3 is the magical number indicating that a speed is now known.
  // See http://catb.org/gpsd/AIVDM.html#_types_1_2_and_3_position_report_class_a
  // for the explanation.
  if (
    shipLocation.speedOverGround === undefined ||
    shipLocation.speedOverGround === null ||
    shipLocation.speedOverGround === 102.3
  ) {
    return none
  }
  return shipLocation.speedOverGround
}
