import { IPortcall, DEFAULT_JIT_SUMMARY } from '../../Domain/Portcall/IPortcall'
import { PortcallWithTimeline } from '../../Domain/Portcall/PortcallWithTimeline'
import { VESSEL_DETAILS_EMPTY } from '../../Domain/VesselDetails/IVesselDetails'
import { lift } from '../../utils/arr'
import { notFalse } from '../../utils/predicates'
import { none, StrictUndefined } from '../../utils/strictNull'
import { fromAnchorAreaVisitJson } from '../AnchorAreaVisits/fromAnchorAreaVisitJson'
import { berthVisitFromJson } from '../BerthVisits/BerthVisitJson'
import { fromArrivalOrDepartureEventJson } from '../Event/fromEventJson'
import { fromMostAccuratesJson } from '../Event/fromMostAccuratesJson'
import { fromMovementJson } from '../Movements/fromMovementJson'
import { fromServiceVesselJson } from '../ServiceVessels/ServiceVesselJson'
import { timelineFromJson, createEmptyTimeline } from '../Timelines/timelineFromJson'
import { vesselDetailsFromVesselMasterDataJson as shipDetailsFromMasterData } from '../VesselMasterData/vesselDetailsFromVesselMasterDataJson'

import { fromPortcallJitSummaryJson } from './IPortcallJitSummaryJson'
import { fromPortcallStatusJson } from './IPortcallStatusJson'
import { IPortcallWithTimelineJson } from './IPortcallWithTimelineJson'
import { PortcallJson } from './PortcallJson'

export const fromPortcallJson = (json: PortcallJson): IPortcall => {
  const serviceVesselJsons = json.services || []
  const arrivalEvent = json?.arrival?.event
  const departureEvent = json?.departure?.event

  return {
    eventId: json.eventId,
    arrival:
      arrivalEvent && arrivalEvent.eventTime
        ? fromArrivalOrDepartureEventJson(arrivalEvent.eventType, arrivalEvent.eventTime)
        : undefined,
    departure:
      departureEvent && departureEvent.eventTime
        ? fromArrivalOrDepartureEventJson(departureEvent.eventType, departureEvent.eventTime)
        : undefined,
    berthVisits: json.berthVisits.map(berthVisitFromJson),
    anchorAreaVisits: json.anchorAreaVisits.map(fromAnchorAreaVisitJson),
    localPortcallId: json.localPortcallId,
    timeframe: json.timeframe,
    vesselDetails:
      (json.vesselMasterData && shipDetailsFromMasterData(json.vesselMasterData)) || VESSEL_DETAILS_EMPTY(json.ship),
    mostAccurates: json.mostAccurates ? fromMostAccuratesJson(json.mostAccurates) : none,
    port: json.port,
    movements: json.movements.map(fromMovementJson),
    vesselAgents: json.vesselAgents,
    backOfficeAgents: json.backOfficeAgents,
    carriers: json.carriers,
    startDraft: json.startDraft,
    endDraft: json.endDraft,

    jitSummary: StrictUndefined.fold(json.jitSummary, fromPortcallJitSummaryJson, DEFAULT_JIT_SUMMARY),
    portcallStatus: fromPortcallStatusJson(json.portcallStatus),
    prevPorts: json.prevPorts,
    nextPorts: json.nextPorts,
    prevPortEnd: json.prevPortEnd,
    services: serviceVesselJsons.map(fromServiceVesselJson).filter(notFalse),
    notifications: json.notifications || [],
    scheduleDescription: json.schedule === undefined ? none : json.schedule,
    timeZone: StrictUndefined.orElse(json.timeZone, none),
  }
}

export const fromPortcallsJson = lift(fromPortcallJson)

export const fromPortcallWithTimelineJson = (json: IPortcallWithTimelineJson): PortcallWithTimeline => ({
  ...fromPortcallJson(json),
  timeline: json.timeline ? timelineFromJson(json.timeline) : createEmptyTimeline(json.timeframe),
})
