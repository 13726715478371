export const EXPIRE_ALL = 'EXPIRE_ALL'

export interface IExpireAll {
  type: typeof EXPIRE_ALL
  cacheId: string
}

export function expireAll(cacheId: string): IExpireAll {
  return {
    type: EXPIRE_ALL,
    cacheId,
  }
}
