import createDebug from 'debug'
import { Dispatch, Middleware } from 'redux'

import { SOURCE_VERSION } from '../config'
import { EventPortcallId } from '../Domain/Portcall/IPortcall'
import { websocketClosed, websocketError, websocketOpened } from '../lib/websocket/websocket.actions'
import {
  connectionClosed as websocketStatusObservableConnectionClosed,
  messageReceived as websocketStatusObservableMessageReceived,
} from '../lib/websocket/websocketStatusObservable'
import { AppAction } from '../modules/App/App.actions'
import { NotificationId } from '../modules/InfoNotifications/InfoNotifications.interfaces'
import { WEBSOCKET_URL, MINUTE } from '../shared/constants'
import { IWebSocketMessage } from '../shared/interfaces/websocket/IWebSocketMessage'
import { WebSocketClient } from '../shared/rest/websocket'
import { LOGIN_EXPIRED, LOGOUT, WEBSOCKET_OPEN } from '../store/actionTypes'
import {
  addPortNotification,
  removePortNotification,
  updatePortNotification,
} from '../store/PortNotifications/portNotifications.actions'
import { loadPortNotification } from '../store/PortNotifications/portNotifications.thunks'
import { ProntoDispatch } from '../store/storeTypes'
import { updateSystemStatusNotifications } from '../store/systemStatusMessages/systemStatusMessages.actions'
import { includes } from '../utils/arr'
import { getAuthToken } from '../utils/auth/authClientObservable'

import { notificationUpdateOrRemove } from './WebsocketQueue/INotificationUpdateOrRemove'
import { portcallRemove } from './WebsocketQueue/IPortcallRemove'
import { portcallUpdate } from './WebsocketQueue/IPortcallUpdate'
import { pushWebsocketQueue } from './WebsocketQueue/websocketQueue'

const debug = createDebug('pronto:websocketMiddleware')
let websocketClient: WebSocketClient | null = null

export const websocketMiddleware: Middleware = () => (dispatch: Dispatch<AppAction>) => (action: any) => {
  switch (action.type) {
    case WEBSOCKET_OPEN:
      setupWebsocket(dispatch)
      break
    case LOGIN_EXPIRED:
    case LOGOUT:
      // close websocket connection when login expired or logged out
      websocketClient?.destroy()
      break
    default:
      break
  }

  return dispatch(action)
}

const setupWebsocket = async (dispatch: Dispatch<AppAction>): Promise<void> => {
  const token = await getAuthToken()

  websocketClient = new WebSocketClient({
    url: WEBSOCKET_URL,
    maxMessageInterval: MINUTE / 2,
    reconnectTimeout: 5000,

    // pass the debug function for debugging the websocket client
    debug,

    onMessage: (msg: IWebSocketMessage) => {
      if (token) {
        dispatchMessage(dispatch, token, msg)
      }
    },
    onOpen: (ev: Event) => {
      dispatch(websocketOpened(ev))
    },
    onError: (ev: Event) => {
      dispatch(websocketError(ev))
    },
    onClose: (ev: CloseEvent) => {
      websocketStatusObservableConnectionClosed()
      dispatch(websocketClosed(ev))
    },
  })
}

const dispatchMessage = (dispatch: ProntoDispatch, authToken: string, message: IWebSocketMessage) => {
  debug('WS msg:', message.name, message.data)

  switch (message.name) {
    case 'ping':
    case 'refresh-on-command':
      // Ignore ping and refresh commands
      break
    default:
      // Every other message received indicates the socket is actively sending updates
      websocketStatusObservableMessageReceived(new Date())
  }

  switch (message.name) {
    // Portcall notifications/warnings
    case 'notification-update':
    case 'notification-remove':
      pushWebsocketQueue(notificationUpdateOrRemove(message.data as NotificationId))
      break

    // System notifications
    case 'new-system-notification':
    case 'system-notification-remove':
      dispatch(updateSystemStatusNotifications())
      break

    // Port notifications
    case 'new-port-notification':
      dispatch(loadPortNotification((message.data as any).notificationId as NotificationId, addPortNotification))
      break
    case 'update-port-notification':
      dispatch(loadPortNotification((message.data as any).notificationId as NotificationId, updatePortNotification))
      break
    case 'close-port-notification':
      dispatch(removePortNotification((message.data as any).notificationId as NotificationId))
      break

    // Updated portcalls
    case 'visit-updated-event-id':
      pushWebsocketQueue(portcallUpdate(message.data as EventPortcallId))
      break
    case 'visit-deleted-event-id':
      pushWebsocketQueue(portcallRemove(message.data as EventPortcallId))
      break

    // Force browser refresh
    case 'refresh-on-command':
      refreshOnCommand(message.data)
      break
    default:
      debug('Unknown message type', message)
  }
}

function refreshOnCommand(data: any) {
  if (Array.isArray(data)) {
    // If we are sent an array, we will interpret this as an
    // array of SHA1 commit hashes. If our current front-end
    // is *not* one of these hashes, refresh.
    if (includes(data, SOURCE_VERSION)) {
      window.location.reload()
    }
  } else {
    window.location.reload()
  }
}
