import { Notification } from '../../Domain/Notifications/INotification'
import { SECOND } from '../../shared/constants'
import { exponentialBackOff } from '../../utils/exponentialBackOff'
import { IExpirable, toExpirable } from '../CacheQueue/IExpirable'
import { IFetcher } from '../CacheQueue/IFetcher'
import { fetchNotifications } from '../InfoNotifications/fetchNotifications'
import { NotificationId } from '../InfoNotifications/InfoNotifications.interfaces'

// Will try again 4 times over a period of about 7.5 minutes.
const INITIAL_WAIT_TIME_BACK_OFF = 30 * SECOND
const NUMBER_OF_RETRIES_BACK_OFF = 4

export class NotificationsFetcher implements IFetcher<NotificationId, Notification> {
  public fetch(notificationIds: NotificationId[]): Promise<Array<IExpirable<Notification>>> {
    return exponentialBackOff(
      () => fetchNotifications(notificationIds),
      { retryOnFailure: true },
      INITIAL_WAIT_TIME_BACK_OFF,
      NUMBER_OF_RETRIES_BACK_OFF
    ).then(notifications => notifications.map(toExpirable))
  }
}
