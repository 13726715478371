import { throwUnreachable } from '../../utils/throwUnreachable'

import { PortStatusState } from './NotificationData'

export const formatPortStatusState = (state: PortStatusState): string => {
  switch (state) {
    case 'OneWayInbound':
      return 'has one way inbound'
    case 'OneWayOutbound':
      return 'has one way outbound'
    case 'OneWayTraffic':
      return 'has one way traffic'
    case 'OperationsResumed':
      return 'resumed operations'
    case 'OperationsSuspended':
      return 'suspended operations'
    case 'PortOpened':
      return 'opened'
    default:
      return throwUnreachable(state)
  }
}
