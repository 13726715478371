import { concat, from, Observable, ObservableInput, Observer } from 'rxjs'

import { Action } from './Actions'
import { IExpirable } from './IExpirable'
import { IFetcher } from './IFetcher'

/**
 * Represents a "unit of work" in which a number of values will be fetched
 * and placed in the cache.
 */
export interface IFetchJob<Key, Result, Meta> {
  keysToFetch: Key[]
  actionsToDispatchDirectly: Array<Action<Key, Result, Meta>>
  actionsToDispatchWhenResultsArrive(results: Array<IExpirable<Result>>): Array<Action<Key, Result, Meta>>
  actionsToDispatchWhenRequestFails(): Array<Action<Key, Result, Meta>>
}

/**
 * A job that causes no work to be done.
 */
export function idleFetchJob<Key, Result, Meta>(): IFetchJob<Key, Result, Meta> {
  return {
    keysToFetch: [],
    actionsToDispatchDirectly: [],
    actionsToDispatchWhenResultsArrive(): Array<Action<Key, Result, Meta>> {
      return []
    },
    actionsToDispatchWhenRequestFails(): Array<Action<Key, Result, Meta>> {
      return []
    },
  }
}

export function toObservable<Key, Result, ErrorAction, Meta>(
  job: IFetchJob<Key, Result, Meta>,
  fetcher: IFetcher<Key, Result>,
  errorHandler: (e: any) => ErrorAction[]
): Observable<ErrorAction | Action<Key, Result, Meta>> {
  const actionsToDispatchDirectlyObservable = from<ObservableInput<Action<Key, Result, Meta>>>(
    job.actionsToDispatchDirectly
  )
  const actionsToDispatchLaterObservable = new Observable(
    (observer: Observer<ErrorAction | Action<Key, Result, Meta>>) => {
      const promise: Promise<Array<IExpirable<Result>>> =
        job.keysToFetch.length === 0 ? Promise.resolve<Array<IExpirable<Result>>>([]) : fetcher.fetch(job.keysToFetch)

      promise
        .then(cns => {
          const actions = job.actionsToDispatchWhenResultsArrive(cns)
          actions.forEach(action => observer.next(action))
          observer.complete()
        })
        .catch(error => {
          const actions = job.actionsToDispatchWhenRequestFails()
          actions.forEach(action => observer.next(action))
          errorHandler(error).forEach(action => observer.next(action))
          observer.complete()
        })
    }
  )

  return concat(actionsToDispatchDirectlyObservable, actionsToDispatchLaterObservable)
}
