import { IGenericAction } from './Action'

const PUSH_TO_QUEUE = 'PUSH_TO_QUEUE'

export type PushToQueueAction<Message> = Readonly<{
  type: typeof PUSH_TO_QUEUE
  cacheId: string
  message: Message
}>

export function pushToQueue<Message>(cacheId: string, message: Message): PushToQueueAction<Message> {
  return {
    type: PUSH_TO_QUEUE,
    cacheId,
    message,
  }
}

export function isPushToQueueAction<Message>(action: IGenericAction): action is PushToQueueAction<Message> {
  return action.type === PUSH_TO_QUEUE
}

const PUSH_TO_QUEUE_IF_EMPTY = 'PUSH_TO_QUEUE_IF_EMPTY'

export type PushToQueueIfEmptyAction<Message> = Readonly<{
  type: typeof PUSH_TO_QUEUE_IF_EMPTY
  cacheId: string
  message: Message
}>

export function pushToQueueIfEmpty<Message>(cacheId: string, message: Message): PushToQueueIfEmptyAction<Message> {
  return {
    type: PUSH_TO_QUEUE_IF_EMPTY,
    cacheId,
    message,
  }
}

export function isPushToQueueIfEmptyAction<Message>(
  action: IGenericAction
): action is PushToQueueIfEmptyAction<Message> {
  return action.type === PUSH_TO_QUEUE_IF_EMPTY
}

const REMOVE_FROM_QUEUE = 'REMOVE_FROM_QUEUE'

export type RemoveFromQueueAction<Message> = Readonly<{
  type: typeof REMOVE_FROM_QUEUE
  cacheId: string
  message: Message
}>

export function removeFromQueue<Message>(cacheId: string, message: Message): RemoveFromQueueAction<Message> {
  return {
    type: REMOVE_FROM_QUEUE,
    cacheId,
    message,
  }
}

export function isRemoveFromQueueAction<Message>(action: IGenericAction): action is RemoveFromQueueAction<Message> {
  return action.type === REMOVE_FROM_QUEUE
}
