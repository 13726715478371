import { parseISO } from 'date-fns'

import { getEventTimeCertaintyFromEventType } from '../../Domain/Events/EventTimeCertainty'
import { Unlocode } from '../../Domain/NauticalLocations/IPort'
import {
  IAgentNotDeclaredBerthVisitWarningNotification,
  IAgentNotDeclaredVisitWarningNotification,
  IArrivedAtAnchorAreaNotification,
  IArrivedAtBerthNotification,
  IArrivedAtPortNotification,
  IBunkerBargeNotArrivedWhenExpectedWarningNotification,
  IBunkerPlannedTooCloseToDepartureWarningNotification,
  ICargoCompletionUpdatedNotification,
  ICargoNotStartedWhenExpectedWarningNotification,
  ICargoPlannedTooCloseToDepartureWarningNotification,
  ICargoStartedNotification,
  ICommonProperties,
  IDepartedFromBerthNotification,
  IDepartureNotPlannedWarningNotification,
  IDeparturePlannedNotification,
  ILastLineSecuredNotification,
  IMooringTerminalAndAgentNotSameWarningNotification,
  INoSpaceOnBerthShortBeforeArrivalWarningNotification,
  INotDepartedWhenExpectedWarningNotification,
  IPortcallNotificationCommonProperties,
  IOngoingBunkerWhenVesselIsPlannedToDepartWarningNotification,
  IOverlappingBerthWindowsWarningNotification,
  IPilotNotOnBoardWhenExpectedWarningNotification,
  IPilotOnBoardNotification,
  IPilotPlannedNotification,
  IPilotsCapacityArrangementWarningNotification,
  IPlanningEndTerminalAndAgentAreNotSameWarningNotification,
  IPlanningEndTerminalAndAgentAreFarApartWarningNotification,
  IPlanningEndTerminalAndPortAuthorityAreNotSameWarningNotification,
  IPlanningStartTerminalAndAgentAreNotSameWarningNotification,
  IPlanningStartTerminalAndAgentAreFarApartWarningNotification,
  ISkipperUpdatedArrivalNotification,
  IStationaryVisitWindow,
  ITerminalUpdatedArrival,
  ITerminalUpdatedDeparture,
  ITravelTimeTooShortWarningNotification,
  IVesselCanBeEarlierOrLaterAtBerthWarningNotification,
  IVesselDepartedFromPreviousPortNotification,
  IVesselEarlyOrLateAtPilotBoardingPlaceWarningNotification,
  IVesselLaterAtBerthThanTerminalWarningNotification,
  IVesselNotDepartedFromPreviousPortWarningNotification,
  Notification,
  IPortStatusNotification,
  IPortNotificationCommonProperties,
  IArrivedAtPilotBoardingPlaceNotification,
} from '../../Domain/Notifications/INotification'
import {
  AGENT_NOT_DECLARED_BERTH_VISIT,
  AGENT_NOT_DECLARED_VISIT,
  ARRIVED_AT_ANCHOR_AREA,
  ARRIVED_AT_BERTH,
  ARRIVED_AT_PILOT_BOARDING_PLACE,
  ARRIVED_AT_PORT,
  BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED,
  BUNKER_PLANNED_TOO_CLOSE_TO_DEPARTURE,
  CARGO_COMPLETION_UPDATED,
  CARGO_NOT_STARTED_WHEN_EXPECTED,
  CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE,
  CARGO_STARTED,
  DEPARTED_FROM_BERTH,
  DEPARTURE_NOT_PLANNED,
  DEPARTURE_PLANNED,
  DEPRECATED_PILOT_HAS_ARRIVED,
  DEPRECATED_PILOT_PLANNED_FOR_DEPARTURE,
  DEPRECATED_PILOT_UPDATED_ARRIVAL,
  LAST_LINE_SECURED,
  MOORING_TERMINAL_AND_AGENT_NOT_SAME,
  NO_SPACE_ON_BERTH_SHORT_BEFORE_ARRIVAL,
  NOT_DEPARTED_WHEN_EXPECTED,
  ONGOING_BUNKER_WHEN_VESSEL_IS_PLANNED_TO_DEPART,
  OVERLAPPING_BERTH_WINDOWS,
  PILOT_NOT_ON_BOARD_WHEN_EXPECTED,
  PILOT_ON_BOARD,
  PILOT_PLANNED,
  PILOTS_CAPACITY_ARRANGEMENT,
  PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART,
  PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME,
  PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART,
  SKIPPER_UPDATED_ARRIVAL,
  TERMINAL_UPDATED_ARRIVAL,
  TERMINAL_UPDATED_DEPARTURE,
  TRAVEL_TIME_TOO_SHORT,
  VESSEL_CAN_BE_EARLIER_AT_BERTH,
  VESSEL_CAN_BE_LATER_AT_BERTH,
  VESSEL_DEPARTED_FROM_PREVIOUS_PORT,
  VESSEL_EARLY_AT_PILOT_BOARDING_PLACE,
  VESSEL_LATE_AT_PILOT_BOARDING_PLACE,
  VESSEL_LATER_AT_BERTH_THAN_TERMINAL,
  VESSEL_NOT_DEPARTED_FROM_PREVIOUS_PORT,
  PORT_STATUS,
} from '../../Domain/Notifications/NotificationType'
import { parseBackendISO, parseDuration } from '../../shared/utils/datetime'
import { lift } from '../../utils/arr'
import { none, None, StrictNull, StrictUndefined } from '../../utils/strictNull'
import { EventJson } from '../Event/EventJson'
import { fromEventJson } from '../Event/fromEventJson'
import { mooringInfoFromJson } from '../MooringInfo/MooringInfoJson'

import { formatPortStatusState } from './formatPortStatusState'
import { INotificationHistoryJson } from './INotificationHistoryJson'
import { IUserNotificationJson } from './IUserNotificationJson'
import { notificationFilterFieldsFromJson } from './NotificationFilterFieldsJson'

const unknownNotificationTypesReported: Set<string> = new Set()

export type ErrorLogger = (description: string, payload: unknown, error?: Error) => void

export const notificationFromJson =
  (errorLogger: ErrorLogger) =>
  (json: IUserNotificationJson): Notification | None => {
    function getNotificationCommonProperties(eventJson: EventJson): IPortcallNotificationCommonProperties | None {
      try {
        const event = fromEventJson(eventJson)
        return {
          eventType: event.eventType,
          eventTime: event.eventTime,
          recordTime: event.recordTime,
          source: event.source,
        }
      } catch (err) {
        errorLogger('Could not parse fields from portcall notification event', eventJson, err)
        return none
      }
    }

    function getPortNotificationCommonProperties(
      notification: INotificationHistoryJson
    ): IPortNotificationCommonProperties | None {
      try {
        return {
          recordTime: parseISO(notification.lastUpdated),
        }
      } catch (err) {
        errorLogger('Could not parse fields from port notification', notification, err)
        return none
      }
    }

    try {
      const commonFields: ICommonProperties = {
        id: json.notification.notification.id,
        portcallId: json.notification.notification.portcallId,
        berthVisitId: json.notification.notification.berthVisitId || none,
        filterFields: notificationFilterFieldsFromJson(json.notification.notification.filterFields),
        anchorAreaName: json.notification.notification.anchorAreaName || none,
        berthName: json.notification.notification.berthName || none,
        vesselName: json.notification.notification.shipName || none,
        isSnoozed: json.snoozed,
        isClosed: json.notification.closed,
        isRead: json.read,
        creationTime: parseISO(json.notification.creationTime),
        lastUpdated: parseISO(json.notification.lastUpdated),
        closingTime: StrictNull.map(json.notification.closingTime || none, parseISO),
      }

      switch (json.notification.notification.notificationType) {
        // ------------------
        // Info Notifications
        // (They all have an event in data that has to be parsed for properties)
        case ARRIVED_AT_PORT: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: IArrivedAtPortNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            ata: parseBackendISO(json.notification.notification.data.ata),
            portName: json.notification.notification.data.portName,
            source: json.notification.notification.data.event.source,
          }
          return n
        }
        case ARRIVED_AT_ANCHOR_AREA: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: IArrivedAtAnchorAreaNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            ata: parseBackendISO(json.notification.notification.data.ata),
            anchorAreaVisitId: json.notification.notification.data.anchorAreaVisitId,
          }
          return n
        }
        case ARRIVED_AT_PILOT_BOARDING_PLACE: {
          if (!('event' in json.notification.notification.data)) {
            return none
          }

          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)

          if (infoNotificationFields === none) {
            return none
          }

          const n: IArrivedAtPilotBoardingPlaceNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            ata: parseBackendISO(json.notification.notification.data.arrivedTime),
          }

          return n
        }
        case ARRIVED_AT_BERTH: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: IArrivedAtBerthNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            ata: parseBackendISO(json.notification.notification.data.ata),
            source: json.notification.notification.data.source,
          }
          return n
        }
        case DEPARTED_FROM_BERTH: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: IDepartedFromBerthNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            atd: parseBackendISO(json.notification.notification.data.atd),
            source: json.notification.notification.data.source,
          }
          return n
        }
        case DEPARTURE_PLANNED: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: IDeparturePlannedNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            etd: parseBackendISO(json.notification.notification.data.etd),
            previousEtd: StrictNull.mapFromUndefined(json.notification.notification.data.previousEtd, parseBackendISO),
          }
          return n
        }
        case SKIPPER_UPDATED_ARRIVAL: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: ISkipperUpdatedArrivalNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            eta: parseBackendISO(json.notification.notification.data.eta),
          }
          return n
        }
        case PILOT_ON_BOARD: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: IPilotOnBoardNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            at: parseBackendISO(json.notification.notification.data.at),
            movementType: json.notification.notification.data.movementType || none,
            location: json.notification.notification.data.location || none,
            targetLocation: json.notification.notification.data.targetLocation || none,
            targetEt: StrictNull.mapFromUndefined(json.notification.notification.data.targetEt, parseBackendISO),
            targetDataSource: json.notification.notification.data.targetDataSource || none,
          }
          return n
        }
        case PILOT_PLANNED: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: IPilotPlannedNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            et: parseBackendISO(json.notification.notification.data.et),
            previousEt: StrictNull.mapFromUndefined(json.notification.notification.data.previousEt, parseBackendISO),
            movementType: json.notification.notification.data.movementType || none,
            location: json.notification.notification.data.location || none,
            targetLocation: json.notification.notification.data.targetLocation || none,
            targetEt: StrictNull.mapFromUndefined(json.notification.notification.data.targetEt, parseBackendISO),
            targetDataSource: json.notification.notification.data.targetDataSource || none,
          }
          return n
        }
        case CARGO_STARTED: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: ICargoStartedNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            ats: parseBackendISO(json.notification.notification.data.ats),
            source: json.notification.notification.data.source,
          }
          return n
        }

        case CARGO_COMPLETION_UPDATED: {
          if (json.notification.notification.data.previousEts === undefined) {
            return none
          }
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: ICargoCompletionUpdatedNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            ets: parseBackendISO(json.notification.notification.data.ets),
            previousEts: parseBackendISO(json.notification.notification.data.previousEts),
          }
          return n
        }
        case TERMINAL_UPDATED_DEPARTURE: {
          if (json.notification.notification.data.previousEtd === undefined) {
            return none
          }
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: ITerminalUpdatedDeparture = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            etd: parseBackendISO(json.notification.notification.data.etd),
            previousEtd: parseBackendISO(json.notification.notification.data.previousEtd),
          }
          return n
        }
        case TERMINAL_UPDATED_ARRIVAL: {
          // @TODO: Change back to non-undefined version when backend has proper support for the `event` property
          const infoNotificationFields = StrictNull.map(
            StrictNull.fromUndefined(json.notification.notification.data.event),
            event => getNotificationCommonProperties(event)
          )

          if (infoNotificationFields === none) {
            return none
          }
          const n: ITerminalUpdatedArrival = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            eta: parseBackendISO(json.notification.notification.data.eta),
            previousEta: parseBackendISO(json.notification.notification.data.previousEta),
          }
          return n
        }
        case VESSEL_DEPARTED_FROM_PREVIOUS_PORT: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: IVesselDepartedFromPreviousPortNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: json.notification.notification.notificationType,
            previousPort: json.notification.notification.data.previousPort,
            nextPort: json.notification.notification.data.nextPort || none,
            portAtd: parseBackendISO(json.notification.notification.data.portAtd),
          }
          return n
        }
        case LAST_LINE_SECURED: {
          const infoNotificationFields = getNotificationCommonProperties(json.notification.notification.data.event)
          if (infoNotificationFields === none) {
            return none
          }
          const n: ILastLineSecuredNotification = {
            ...commonFields,
            ...infoNotificationFields,
            type: LAST_LINE_SECURED,
            at: parseBackendISO(json.notification.notification.data.at),
            berthName: json.notification.notification.data.berthName || none,
          }
          return n
        }
        case PORT_STATUS: {
          const portNotificationFields = getPortNotificationCommonProperties(json.notification)
          if (portNotificationFields === none) {
            return none
          }
          const n: IPortStatusNotification = {
            ...commonFields,
            ...portNotificationFields,
            type: PORT_STATUS,
            port: json.notification.notification.data.port,
            state: formatPortStatusState(json.notification.notification.data.state),
            from: parseBackendISO(json.notification.notification.data.from),
          }
          return n
        }
        // --------
        // Warnings
        case DEPARTURE_NOT_PLANNED: {
          const terminalEndTime = parseBackendISO(json.notification.notification.data.terminalEndTime)
          const w: IDepartureNotPlannedWarningNotification = {
            ...commonFields,
            type: DEPARTURE_NOT_PLANNED,
            terminalEndTime,
          }
          return w
        }
        case VESSEL_NOT_DEPARTED_FROM_PREVIOUS_PORT: {
          const previousPort: Unlocode | None = json.notification.notification.data.previousPort || none
          const portEtd = StrictNull.mapFromUndefined(json.notification.notification.data.portEtd, parseBackendISO)
          const portEta = StrictNull.mapFromUndefined(json.notification.notification.data.portEta, parseBackendISO)
          const shipName: string | None = json.notification.notification.data.shipName || none

          const n: IVesselNotDepartedFromPreviousPortWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
            previousPort,
            portEtd,
            portEta,
            shipName,
          }
          return n
        }
        case TRAVEL_TIME_TOO_SHORT: {
          const minimumTravelTime = parseDuration(json.notification.notification.data.minimumTravelTime)
          const timeDifference = StrictNull.mapFromUndefined(
            json.notification.notification.data.timeDifference,
            td => parseDuration(td) || none
          )

          if (!minimumTravelTime) {
            throw new Error("Can't parse duration")
          }

          const w: ITravelTimeTooShortWarningNotification = {
            ...commonFields,
            type: TRAVEL_TIME_TOO_SHORT,
            previousPort: json.notification.notification.data.previousPort,
            port: json.notification.notification.data.port,
            prevPortEnd: parseBackendISO(json.notification.notification.data.prevPortEnd),
            prevPortEndCertainty: getEventTimeCertaintyFromEventType(
              json.notification.notification.data.prevPortEndEventType
            ),
            portStart: parseBackendISO(json.notification.notification.data.portStart),
            minimumTravelTime,
            timeDifference,
          }
          return w
        }
        case BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED: {
          const w: IBunkerBargeNotArrivedWhenExpectedWarningNotification = {
            ...commonFields,
            type: BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED,
            etsBunker: parseBackendISO(json.notification.notification.data.etsBunker),
          }
          return w
        }

        case BUNKER_PLANNED_TOO_CLOSE_TO_DEPARTURE: {
          const w: IBunkerPlannedTooCloseToDepartureWarningNotification = {
            ...commonFields,
            type: BUNKER_PLANNED_TOO_CLOSE_TO_DEPARTURE,
            berthVisitEnd: parseBackendISO(json.notification.notification.data.berthVisitEnd),
            bunkerEnd: parseBackendISO(json.notification.notification.data.bunkerEnd),
            bunkerBarge: json.notification.notification.data.bunkerBarge || none,
          }
          return w
        }
        case ONGOING_BUNKER_WHEN_VESSEL_IS_PLANNED_TO_DEPART: {
          const w: IOngoingBunkerWhenVesselIsPlannedToDepartWarningNotification = {
            ...commonFields,
            type: ONGOING_BUNKER_WHEN_VESSEL_IS_PLANNED_TO_DEPART,
            terminalEnd: parseBackendISO(json.notification.notification.data.terminalEnd),
            bunkerBarge: json.notification.notification.data.bunkerBarge || none,
          }
          return w
        }
        case CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE: {
          const w: ICargoPlannedTooCloseToDepartureWarningNotification = {
            ...commonFields,
            type: CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE,
            berthVisitEnd: parseBackendISO(json.notification.notification.data.berthVisitEnd),
            cargoEnd: parseBackendISO(json.notification.notification.data.cargoEnd),
          }
          return w
        }
        case MOORING_TERMINAL_AND_AGENT_NOT_SAME: {
          const w: IMooringTerminalAndAgentNotSameWarningNotification = {
            ...commonFields,
            type: MOORING_TERMINAL_AND_AGENT_NOT_SAME,
            agentMooring: mooringInfoFromJson(json.notification.notification.data.agentMooring),
            terminalMooring: mooringInfoFromJson(json.notification.notification.data.terminalMooring),
          }
          return w
        }
        case PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME: {
          // NOTE: Perform Side FX
          const w: IPlanningEndTerminalAndAgentAreNotSameWarningNotification = {
            ...commonFields,
            type: PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME,
            terminalEnd: parseBackendISO(json.notification.notification.data.terminalEnd),
            agentEnd: parseBackendISO(json.notification.notification.data.agentEnd),
          }
          return w
        }
        case PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART: {
          // NOTE: Perform Side FX
          const w: IPlanningEndTerminalAndAgentAreFarApartWarningNotification = {
            ...commonFields,
            type: PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART,
            terminalEnd: parseBackendISO(json.notification.notification.data.terminalEnd),
            agentEnd: parseBackendISO(json.notification.notification.data.agentEnd),
          }
          return w
        }
        case PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME: {
          const w: IPlanningEndTerminalAndPortAuthorityAreNotSameWarningNotification = {
            ...commonFields,
            type: PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME,
            terminalEnd: parseBackendISO(json.notification.notification.data.terminalEnd),
            portAuthorityEnd: parseBackendISO(json.notification.notification.data.portAuthorityEnd),
          }
          return w
        }
        case PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME: {
          const w: IPlanningStartTerminalAndAgentAreNotSameWarningNotification = {
            ...commonFields,
            type: PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME,
            terminalStart: parseBackendISO(json.notification.notification.data.terminalStart),
            agentStart: parseBackendISO(json.notification.notification.data.agentStart),
          }
          return w
        }
        case PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART: {
          const w: IPlanningStartTerminalAndAgentAreFarApartWarningNotification = {
            ...commonFields,
            type: PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART,
            terminalStart: parseBackendISO(json.notification.notification.data.terminalStart),
            agentStart: parseBackendISO(json.notification.notification.data.agentStart),
          }
          return w
        }
        case VESSEL_CAN_BE_EARLIER_AT_BERTH:
        case VESSEL_CAN_BE_LATER_AT_BERTH: {
          const w: IVesselCanBeEarlierOrLaterAtBerthWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
            etaBerthTime: parseBackendISO(json.notification.notification.data.etaBerthTime),
            ptaBerthTime: parseBackendISO(json.notification.notification.data.ptaBerthTime),
          }
          return w
        }
        case VESSEL_EARLY_AT_PILOT_BOARDING_PLACE:
        case VESSEL_LATE_AT_PILOT_BOARDING_PLACE: {
          const difference = parseDuration(json.notification.notification.data.difference)

          if (!difference) {
            throw new Error("Can't parse duration")
          }

          const w: IVesselEarlyOrLateAtPilotBoardingPlaceWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
            pbpTimeFromAgent: parseBackendISO(json.notification.notification.data.pbpTimeFromAgent),
            predictedPbpTime: parseBackendISO(json.notification.notification.data.predictedPbpTime),
            difference,
          }
          return w
        }
        case NOT_DEPARTED_WHEN_EXPECTED: {
          const w: INotDepartedWhenExpectedWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
            ptd: parseBackendISO(json.notification.notification.data.ptd),
          }
          return w
        }
        case PILOT_NOT_ON_BOARD_WHEN_EXPECTED: {
          const w: IPilotNotOnBoardWhenExpectedWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
            pilotET: parseBackendISO(json.notification.notification.data.pilotET),
            movementType: json.notification.notification.data.movementType || none,
          }
          return w
        }
        case OVERLAPPING_BERTH_WINDOWS: {
          // Try to parse `IStationaryVisitWindow`-instances. They could be from
          // property `overlappingBerthWindows` (legacy) or `overlappingWindows` (current).
          // We try both. If both are unavailable, we return `None`.
          const fromOverlappingWindows = StrictUndefined.map(
            json.notification.notification.data.overlappingWindows,
            lift(
              (overlappingWindowJson): IStationaryVisitWindow => ({
                source: overlappingWindowJson.source || none,
                locationType: overlappingWindowJson.locationType,
                locationName: overlappingWindowJson.locationName || none,
                start: parseBackendISO(overlappingWindowJson.start),
                end: parseBackendISO(overlappingWindowJson.end),
              })
            )
          )
          const fromOverlappingBerthWindows = StrictUndefined.map(
            json.notification.notification.data.overlappingBerthWindows,
            lift(
              (overlappingBerthWindowJson): IStationaryVisitWindow => ({
                source: overlappingBerthWindowJson.source || none,
                locationType: 'berth',
                locationName: overlappingBerthWindowJson.berthName || none,
                start: parseBackendISO(overlappingBerthWindowJson.start),
                end: parseBackendISO(overlappingBerthWindowJson.end),
              })
            )
          )
          return StrictUndefined.fold(
            StrictUndefined.orElse(fromOverlappingWindows, fromOverlappingBerthWindows),
            (overlappingWindows): IOverlappingBerthWindowsWarningNotification => ({
              ...commonFields,
              type: OVERLAPPING_BERTH_WINDOWS,
              overlappingWindows,
            }),
            none
          )
        }
        case AGENT_NOT_DECLARED_VISIT: {
          const w: IAgentNotDeclaredVisitWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
          }
          return w
        }
        case AGENT_NOT_DECLARED_BERTH_VISIT: {
          const w: IAgentNotDeclaredBerthVisitWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
          }
          return w
        }
        case NO_SPACE_ON_BERTH_SHORT_BEFORE_ARRIVAL: {
          const w: INoSpaceOnBerthShortBeforeArrivalWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
            previousShip: json.notification.notification.data.previousShip,
            departureTime: parseBackendISO(json.notification.notification.data.departureTime),
            previousShipMooring: StrictNull.map(
              json.notification.notification.data.previousShipMooring || none,
              mooringInfoFromJson
            ),
            shipMooring: StrictNull.map(json.notification.notification.data.shipMooring || none, mooringInfoFromJson),
          }
          return w
        }
        case VESSEL_LATER_AT_BERTH_THAN_TERMINAL: {
          const { notification } = json.notification
          const { carrierEta, terminalEta } = json.notification.notification.data
          const w: IVesselLaterAtBerthThanTerminalWarningNotification = {
            ...commonFields,
            type: notification.notificationType,
            carrierEta: parseBackendISO(carrierEta),
            terminalEta: parseBackendISO(terminalEta),
          }
          return w
        }
        case PILOTS_CAPACITY_ARRANGEMENT: {
          const w: IPilotsCapacityArrangementWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
          }
          return w
        }
        case DEPRECATED_PILOT_HAS_ARRIVED:
        case DEPRECATED_PILOT_PLANNED_FOR_DEPARTURE:
        case DEPRECATED_PILOT_UPDATED_ARRIVAL: {
          return none
        }
        case CARGO_NOT_STARTED_WHEN_EXPECTED: {
          const w: ICargoNotStartedWhenExpectedWarningNotification = {
            ...commonFields,
            type: json.notification.notification.notificationType,
            eventTime: parseBackendISO(json.notification.notification.data.eventTime),
          }
          return w
        }
        default: {
          const exhaustive: never = json.notification.notification

          // If we're here, `json.notification.notification` is `never` according to the
          // type system, but according to the runtime its something else. We need to erase
          // the knowledge of the `never` to prevent weird compiler errors if we try to handle
          // this "impossible" value:
          const nonExhaustiveJson: IUserNotificationJson = json
          if (!unknownNotificationTypesReported.has(nonExhaustiveJson.notification.notification.notificationType)) {
            unknownNotificationTypesReported.add(nonExhaustiveJson.notification.notification.notificationType)
            errorLogger('Encountered unknown type of notification', exhaustive)
          }
          return none
        }
      }
    } catch (err) {
      errorLogger('Could not parse fields from notification', json.notification.notification.data, err)
      return none
    }
  }
