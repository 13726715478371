import createDebug from 'debug'
import { Observable, ObservableInput, from, EMPTY } from 'rxjs'

import { Notification } from '../../Domain/Notifications/INotification'
import { notificationsCacheDefinition } from '../../lib/asyncSelector/notificationsCacheDefinition'
import { removeFromQueue } from '../../lib/asyncSelector/queueActions'
import { setLoginExpired } from '../../store/auth/auth.actions'
import { setError } from '../../store/error/error.actions'
import { first } from '../../utils/arr'
import { none, None } from '../../utils/strictNull'
import { throwUnreachable } from '../../utils/throwUnreachable'
import { AppAction } from '../App/App.actions'
import { IAppState } from '../App/interfaces/IAppState'
import { handleMessage } from '../CacheQueue/createHandleMessage'
import { EXPIRE } from '../CacheQueue/IExpire'
import { EXPIRE_ALL } from '../CacheQueue/IExpireAll'
import { REQUEST_ALL } from '../CacheQueue/IRequestAll'
import { NotificationId } from '../InfoNotifications/InfoNotifications.interfaces'
import {
  removeNotificationUpdate,
  processUpdate as processUpdateAction,
} from '../NotificationUpdate/notificationUpdate.actions'
import { INotificationUpdate } from '../NotificationUpdate/NotificationUpdate.interfaces'
import { notificationUpdatesSelector } from '../NotificationUpdate/notificationUpdatesSelector'

import { PROCESS_UPDATE } from './IProcessUpdate'
import { NotificationsFetcher } from './NotificationsFetcher'

const debug = createDebug('pronto:notificationsQueue')

export const actionsToDispatch = (appState: IAppState): Observable<AppAction> => {
  const queue = appState.notificationMessagesQueue
  const firstMessage = first(queue)
  if (firstMessage === none) {
    return EMPTY
  }

  debug('Handling message %o', firstMessage)
  switch (firstMessage.type) {
    case EXPIRE:
    case EXPIRE_ALL:
    case REQUEST_ALL: {
      const cacheItems = notificationsCacheDefinition.cacheSelector(appState)
      const notificationsFetcher = new NotificationsFetcher()
      return handleMessage<NotificationId, Notification, AppAction, None>(
        firstMessage,
        cacheItems,
        notificationsCacheDefinition.cacheId,
        notificationsFetcher,
        n => n.id,
        notificationsCacheDefinition.keysAreEqual,
        error => {
          if (error && error.type === 'login-expired') {
            return [setLoginExpired()]
          }
          return [setError(error)]
        }
      )
    }
    case PROCESS_UPDATE: {
      const allNotificationUpdates = notificationUpdatesSelector(appState)
      const notificationUpdates: INotificationUpdate[] = allNotificationUpdates.filter(
        nu => nu.updateId === firstMessage.updateId
      )
      return from<ObservableInput<AppAction>>([
        processUpdateAction(notificationUpdates, Date.now()),
        removeNotificationUpdate(firstMessage.updateId),
        removeFromQueue(notificationsCacheDefinition.cacheId, firstMessage),
      ])
    }
    default:
      return throwUnreachable(firstMessage)
  }
}
