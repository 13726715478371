import { ScheduleDescription } from '../../Domain/Schedule/ScheduleId'
import { IAppState } from '../../modules/App/interfaces/IAppState'
import { always } from '../../utils/functions'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'

import { CacheDefinition } from './CacheDefinition'

export const schedulesCacheDefinition = new CacheDefinition<IAppState, Record<string, unknown>, ScheduleDescription[]>(
  'schedules',
  fixedSizeLimiter(1, true),
  always(true),
  appState => appState.schedulesCache
)
