import { EventPortcallId } from '../../Domain/Portcall/IPortcall'

export const PORTCALL_REMOVE = 'PORTCALL_REMOVE'

export interface IPortcallRemove {
  type: typeof PORTCALL_REMOVE
  portcallId: EventPortcallId
}

export function portcallRemove(portcallId: EventPortcallId): IPortcallRemove {
  return {
    type: PORTCALL_REMOVE,
    portcallId,
  }
}
