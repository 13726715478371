import { MooringInfoJson } from '../../../Api/MooringInfo/MooringInfoJson'
import { LocationVisitId } from '../../../Domain/NauticalLocations/LocationVisitId'
import { IShipId } from '../../../Domain/VesselDetails/IVesselDetails'

import { IPortEvent } from './IPortEvent'

export interface IEventType {
  id: EventTypeId
  party: EventParty
  portEvent: IPortEvent
  confidenceLevel: ConfidenceLevel
}

export type ConfidenceLevel = 'AbsoluteConfidence' | 'HighConfidence' | 'MediumConfidence' | 'LowConfidence'

type EventParty =
  | 'agent'
  | 'bunkerService'
  | 'pilot'
  | 'portAuthority'
  | 'predictor'
  | 'vessel'
  | 'tugService'
  | 'terminal'
  | 'linesmen'
  | 'mooringService'

export type IEventQualification =
  // estimates
  | 'eta'
  | 'etd'
  | 'ets'
  | 'etc'
  // planned
  | 'pta'
  | 'ptd'
  // actuals
  | 'ata'
  | 'atd'
  | 'ats'
  | 'atc'
  | 'at'
  // other
  | 'cancel'
  | 'n/a'

export interface IEventLocation {
  type: EventLocationId
  name?: string
}

export interface ISchedule {
  id: string
  name?: string
}

export interface IPredictionDetails {
  travelTime: number
  fromLocation: IEventLocation
}

export interface IEventContext {
  // For now: hamis/king "code" without spaces in all caps
  vesselAgent?: string
  backOfficeAgent?: string
  // Distance the ship was to the event location at record time
  distanceToLocationNM?: number
  mooring?: MooringInfoJson // @TODO Shouldn't rely on back-end data structure
  movementId?: string
  serviceProviderCompany?: string
  serviceShip?: IShipId
  serviceId?: string
  berthVisitId?: string
  locationVisitId?: LocationVisitId
  organisationPortcallId?: string
  clearance?: boolean
  reportedNumber?: number
  draught?: number
  predictionDetails?: IPredictionDetails
  lowAccuracy?: boolean
  schedule?: ISchedule
  stakeholders?: string[]
}

export type LocationBerth = 'berth'
export type LocationAnchorArea = 'anchorArea'

// export these from scala: sbt console on pronto-domain and
// println(TheEnum.values.map(_.entryName).map(s => s""""$s"""").sorted.mkString(" | \n"))

type EventLocationId =
  | 'anchorArea'
  | 'approachArea'
  | 'berth'
  | 'fairway'
  | 'pilotBoardingPlace'
  | 'port'
  | 'portBasin'
  | 'port-1'
  | 'port-2'
  | 'tugArea'
  | 'vtsArea'

export type EventTypeId =
  | '120nm.ata.vessel'
  | '12nm.ata.vessel'
  | '240nm.ata.vessel'
  | '60nm.ata.vessel'
  | 'anchorArea.ata.ais'
  | 'anchorArea.ata.portAuthority'
  | 'anchorArea.ata.vessel'
  | 'anchorArea.atd.ais'
  | 'anchorArea.atd.portAuthority'
  | 'anchorArea.atd.vessel'
  | 'anchorArea.eta.agent'
  | 'anchorArea.eta.carrier'
  | 'anchorArea.eta.portAuthority'
  | 'anchorArea.etd.agent'
  | 'anchorArea.etd.carrier'
  | 'anchorArea.etd.portAuthority'
  | 'berth.ata.agent'
  | 'berth.ata.ais'
  | 'berth.ata.carrier'
  | 'berth.ata.portAuthority'
  | 'berth.ata.terminal'
  | 'berth.ata.vessel'
  | 'berth.atd.agent'
  | 'berth.atd.ais'
  | 'berth.atd.carrier'
  | 'berth.atd.portAuthority'
  | 'berth.atd.terminal'
  | 'berth.atd.vessel'
  | 'berth.cancel.agent'
  | 'berth.cancel.carrier'
  | 'berth.cancel.portAuthority'
  | 'berth.cancel.terminal'
  | 'berth.eta.agent'
  | 'berth.eta.ais'
  | 'berth.eta.captain'
  | 'berth.eta.carrier'
  | 'berth.eta.historical'
  | 'berth.eta.pilot'
  | 'berth.eta.portAuthority'
  | 'berth.eta.predictor'
  | 'berth.eta.terminal'
  | 'berth.eta.vessel'
  | 'berth.etd.agent'
  | 'berth.etd.carrier'
  | 'berth.etd.derived'
  | 'berth.etd.historical'
  | 'berth.etd.pilot'
  | 'berth.etd.portAuthority'
  | 'berth.etd.predictor'
  | 'berth.etd.terminal'
  | 'berth.pta.terminal'
  | 'berth.ptd.portAuthority'
  | 'berth.ptd.terminal'
  | 'bunkerPW.atc.ais'
  | 'bunkerPW.atc.vessel'
  | 'bunkerPW.ats.ais'
  | 'bunkerPW.ats.vessel'
  | 'bunkerService.atc.ais'
  | 'bunkerService.atc.bunkerService'
  | 'bunkerService.atc.portAuthority'
  | 'bunkerService.atc.vessel'
  | 'bunkerService.ats.ais'
  | 'bunkerService.ats.bunkerService'
  | 'bunkerService.ats.portAuthority'
  | 'bunkerService.ats.vessel'
  | 'bunkerService.cancel.bunkerService'
  | 'bunkerService.cancel.portAuthority'
  | 'bunkerService.etc.bunkerService'
  | 'bunkerService.etc.predictor'
  | 'bunkerService.ets.bunkerService'
  | 'cargoOperations.atc.derived'
  | 'cargoOperations.atc.terminal'
  | 'cargoOperations.ats.derived'
  | 'cargoOperations.ats.terminal'
  | 'cargoOperations.etc.derived'
  | 'cargoOperations.etc.predictor'
  | 'cargoOperations.etc.terminal'
  | 'cargoOperations.ets.derived'
  | 'cargoOperations.ets.predictor'
  | 'cargoOperations.ets.terminal'
  | 'customs.atc.ais'
  | 'customs.atc.vessel'
  | 'customs.ats.ais'
  | 'customs.ats.vessel'
  | 'fairway.ata.vessel'
  | 'firstLineReleased.at.ais'
  | 'firstLineReleased.at.linesmen'
  | 'firstLineReleased.at.vessel'
  | 'firstLineSecured.at.ais'
  | 'firstLineSecured.at.linesmen'
  | 'firstLineSecured.at.vessel'
  | 'floatingCrane.atc.ais'
  | 'floatingCrane.atc.vessel'
  | 'floatingCrane.ats.ais'
  | 'floatingCrane.ats.vessel'
  | 'immigration.atc.ais'
  | 'immigration.atc.vessel'
  | 'immigration.ats.ais'
  | 'immigration.ats.vessel'
  | 'lastLineReleased.at.ais'
  | 'lastLineReleased.at.linesmen'
  | 'lastLineReleased.at.vessel'
  | 'lastLineSecured.at.ais'
  | 'lastLineSecured.at.linesmen'
  | 'lastLineSecured.at.vessel'
  | 'pilotBoardingPlace.ata.ais'
  | 'pilotBoardingPlace.ata.carrier'
  | 'pilotBoardingPlace.ata.vessel'
  | 'pilotBoardingPlace.atd.ais'
  | 'pilotBoardingPlace.atd.carrier'
  | 'pilotBoardingPlace.atd.vessel'
  | 'pilotBoardingPlace.eta.agent'
  | 'pilotBoardingPlace.eta.ais'
  | 'pilotBoardingPlace.eta.carrier'
  | 'pilotBoardingPlace.eta.derived'
  | 'pilotBoardingPlace.eta.historical'
  | 'pilotBoardingPlace.eta.pilot'
  | 'pilotBoardingPlace.eta.portAuthority'
  | 'pilotBoardingPlace.eta.predictor'
  | 'pilotBoardingPlace.eta.vessel'
  | 'pilotBoardingPlace.etd.carrier'
  | 'pilotBoardingPlace.etd.historical'
  | 'pilotBoardingPlace.etd.predictor'
  | 'pilotBoardingPlace.pta.portAuthority'
  | 'pilotBoardingPlace.pta.derived'
  | 'pilotDisembarked.at.ais'
  | 'pilotDisembarked.at.pilot'
  | 'pilotDisembarked.at.portAuthority'
  | 'pilotDisembarked.at.vessel'
  | 'pilotOnBoard.at.ais'
  | 'pilotOnBoard.at.pilot'
  | 'pilotOnBoard.at.portAuthority'
  | 'pilotOnBoard.at.vessel'
  | 'pilotOnBoard.et.pilot'
  | 'port-1.ata.vessel'
  | 'port-1.atd.vessel'
  | 'port-2.ata.vessel'
  | 'port-2.atd.vessel'
  | 'port.ata.agent'
  | 'port.ata.ais'
  | 'port.ata.carrier'
  | 'port.ata.portAuthority'
  | 'port.ata.vessel'
  | 'port.atd.agent'
  | 'port.atd.ais'
  | 'port.atd.carrier'
  | 'port.atd.portAuthority'
  | 'port.atd.vessel'
  | 'port.cancel.agent'
  | 'port.cancel.carrier'
  | 'port.cancel.portAuthority'
  | 'port.clearance.portAuthority'
  | 'port.eta.agent'
  | 'port.eta.carrier'
  | 'port.eta.portAuthority'
  | 'port.etd.agent'
  | 'port.etd.carrier'
  | 'port.etd.portAuthority'
  | 'port.etd.predictor'
  | 'portAuthority.atc.ais'
  | 'portAuthority.atc.vessel'
  | 'portAuthority.ats.ais'
  | 'portAuthority.ats.vessel'
  | 'portBasin.ata.vessel'
  | 'provision.atc.ais'
  | 'provision.atc.vessel'
  | 'provision.ats.ais'
  | 'provision.ats.vessel'
  | 'repairService.cancel.agent'
  | 'repairService.etc.agent'
  | 'repairService.ets.agent'
  | 'safeAccessToShoreOpen.et.derived'
  | 'safeAccessToShoreOpen.et.pilot'
  | 'slops.atc.ais'
  | 'slops.atc.vessel'
  | 'slops.ats.ais'
  | 'slops.ats.vessel'
  | 'surveyor.cancel.serviceProvider'
  | 'surveyor.etc.serviceProvider'
  | 'surveyor.ets.serviceProvider'
  | 'tender.atc.ais'
  | 'tender.atc.vessel'
  | 'tender.ats.ais'
  | 'tender.ats.vessel'
  | 'tugArea.ata.vessel'
  | 'tugArea.atd.vessel'
  | 'tugArea.eta.pilot'
  | 'tugArea.eta.predictor'
  | 'tugArea.etd.predictor'
  | 'tugsFromBerth.reportnumber.agent'
  | 'tugsFromBerth.reportnumber.portAuthority'
  | 'tugsNoMoreStandby.at.ais'
  | 'tugsNoMoreStandby.at.portAuthority'
  | 'tugsNoMoreStandby.at.tugService'
  | 'tugsNoMoreStandby.at.vessel'
  | 'tugsNoMoreStandby.et.portAuthority'
  | 'tugsNoMoreStandby.et.tugService'
  | 'tugsStandby.at.ais'
  | 'tugsStandby.at.portAuthority'
  | 'tugsStandby.at.tugService'
  | 'tugsStandby.at.vessel'
  | 'tugsStandby.et.portAuthority'
  | 'tugsStandby.et.tugService'
  | 'tugsToBerth.reportnumber.agent'
  | 'tugsToBerth.reportnumber.portAuthority'
  | 'vtsArea.ata.vessel'
  | 'vtsArea.atd.vessel'
  | 'waste.atc.ais'
  | 'waste.atc.vessel'
  | 'waste.ats.ais'
  | 'waste.ats.vessel'

export function isEstimate(eventType: EventTypeId): boolean {
  const actualRegex = /\.et[acds]?\./
  return actualRegex.test(eventType)
}

export function isPlanned(eventType: EventTypeId): boolean {
  const actualRegex = /\.pt[ad]?\./
  return actualRegex.test(eventType)
}

const isActualRegex = /\.at[acds]?\./
export const isActual = isActualRegex.test.bind(isActualRegex)

export function isCancel(eventType: EventTypeId): boolean {
  const actualRegex = /\.cancel\./
  return actualRegex.test(eventType)
}
