import { BrandedCovariant } from '../../utils/branded'

export type Uuid = BrandedCovariant<string, 'uuid'>

export const nilUuid = '00000000-0000-0000-0000-000000000000' as const
export type NilUuid = typeof nilUuid

export function createUuid(s: string): Uuid {
  return s as Uuid
}

export function uuidAsString(uuid: Uuid): string {
  return uuid as string
}
