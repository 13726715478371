import { MostAccurates } from '../../Domain/Events/MostAccurates'

import { fromEventJson } from './fromEventJson'
import { MostAccuratesJson } from './MostAccuratesJson'

export function fromMostAccuratesJson(json: MostAccuratesJson): MostAccurates {
  return {
    start: json.start.map(({ event }) => event).map(fromEventJson),
    end: json.end.map(({ event }) => event).map(fromEventJson),
  }
}
