import { Notification } from '../../Domain/Notifications/INotification'
import { IAppState } from '../../modules/App/interfaces/IAppState'
import { QueuedCacheDefinition } from '../../modules/CacheQueue/QueuedCacheDefinition'
import { NotificationId } from '../../modules/InfoNotifications/InfoNotifications.interfaces'
import { Message } from '../../modules/NotificationsQueue/Message'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'

const NOTIFICATIONS_CACHE_ID = 'async-notifications'
const MAX_NOTIFICATIONS_CACHE_SIZE = 5

export const notificationsCacheDefinition = new QueuedCacheDefinition<IAppState, NotificationId, Notification, Message>(
  NOTIFICATIONS_CACHE_ID,
  fixedSizeLimiter(MAX_NOTIFICATIONS_CACHE_SIZE),
  appState => appState.notificationsCache,
  appState => appState.notificationMessagesQueue,
  n => n.id
)
