import { BACKEND_URL } from '../shared/constants'
import { createHeaders } from '../shared/utils/createHeaders'
import { handleJSONResponse } from '../shared/utils/rest'
import { getAuthToken } from '../utils/auth/authClientObservable'

import type { ISystemStatusMessage } from '../Domain/SystemStatusMessages/ISystemStatusMessage'

export async function fetchSystemStatusMessages(): Promise<ISystemStatusMessage[]> {
  const authToken = await getAuthToken()

  const url = `${BACKEND_URL}/api/system-notifications`
  const options = {
    method: 'GET',
    headers: createHeaders(authToken),
  }
  return fetch(url, options)
    .then<ISystemStatusMessage[]>(response => handleJSONResponse(options, response))
    .then(systemStatusMessages =>
      systemStatusMessages.filter(systemStatusMessage => systemStatusMessage.type !== 'InboundVLCC')
    )
}
