import { getAuthToken } from '../../utils/auth/authClientObservable'
import { BACKEND_URL } from '../constants'
import { createHeaders } from '../utils/createHeaders'
import { handleJSONResponse } from '../utils/rest'

export async function fetchWebsocketTicket(): Promise<string> {
  const authToken = await getAuthToken()
  const options: RequestInit = {
    method: 'GET',
    headers: createHeaders(authToken),
  }
  return fetch(`${BACKEND_URL}/api/websocket/ticket`, options)
    .then(r => handleJSONResponse(options, r))
    .then(res => res.ticket)
}
