import { stringifyQueryParameters } from '../../../utils/queryParameters'
import { none, None } from '../../../utils/strictNull'

import type { EventPortcallId } from '../../../Domain/Portcall/IPortcall'

const PORTCALL_ID_QUERY_PARAMETER = 'portcallId'

export function getQueryParameters(selectedPortcallId: EventPortcallId | None) {
  const queryParameters: Record<string, string> = {}
  if (selectedPortcallId !== none) {
    queryParameters[PORTCALL_ID_QUERY_PARAMETER] = selectedPortcallId
  }
  return stringifyQueryParameters(queryParameters)
}
