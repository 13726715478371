import { defaultFiltersToJson } from '../../Api/Users/ClientPreferences/DefaultFiltersJson'
import { getAuthToken } from '../../utils/auth/authClientObservable'
import { always } from '../../utils/functions'
import { none, None } from '../../utils/strictNull'
import { BACKEND_URL } from '../constants'
import { createHeaders } from '../utils/createHeaders'
import { handleJSONResponse } from '../utils/rest'

import type { DefaultFilters } from '../../Domain/Users/ClientPreferences/DefaultFilters'

/*
GET /api/users/me/clientPreferences
GET /api/users/me/clientPreferences/x
PUT /api/users/me/clientPreferences/x
 */

export async function saveDefaultFilters(value: DefaultFilters): Promise<None> {
  const authToken = await getAuthToken()

  const url = `${BACKEND_URL}/api/users/me/clientPreferences/defaultFilters`
  const options = {
    method: 'PUT',
    headers: createHeaders(authToken),
    body: JSON.stringify(defaultFiltersToJson(value)),
  }

  return fetch(url, options).then(response => {
    if (response.status === 204) {
      return Promise.resolve(none)
    }
    return handleJSONResponse(options, response).then(always(none))
  })
}
