import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { NotificationId } from '../../modules/InfoNotifications/InfoNotifications.interfaces'
import { throwUnreachable } from '../../utils/throwUnreachable'

import { NOTIFICATION_UPDATE_OR_REMOVE } from './INotificationUpdateOrRemove'
import { PORTCALL_REMOVE } from './IPortcallRemove'
import { PORTCALL_UPDATE } from './IPortcallUpdate'
import { Message } from './Message'

interface IMessageBatch {
  notificationIdsToUpdateOrRemove: NotificationId[]
  portcallIdsToUpdate: EventPortcallId[]
  portcallIdsToRemove: EventPortcallId[]
}

export function createBatch(messages: Message[]): IMessageBatch {
  const emptyBatch: IMessageBatch = {
    notificationIdsToUpdateOrRemove: [],
    portcallIdsToUpdate: [],
    portcallIdsToRemove: [],
  }
  return messages.reduce((acc, curr) => {
    switch (curr.type) {
      case NOTIFICATION_UPDATE_OR_REMOVE:
        acc.notificationIdsToUpdateOrRemove.push(curr.notificationId)
        break
      case PORTCALL_UPDATE:
        acc.portcallIdsToUpdate.push(curr.portcallId)
        break
      case PORTCALL_REMOVE:
        acc.portcallIdsToRemove.push(curr.portcallId)
        break
      default:
        throwUnreachable(curr)
    }
    return acc
  }, emptyBatch)
}
