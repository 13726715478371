import { parseISO } from 'date-fns'

import { EventWithOptionalEventTime } from '../../Domain/Events/Event'
import { createEventPortcallId } from '../../Domain/Portcall/IPortcall'
import { EventTypeId } from '../../shared/interfaces/event/IEvent'
import { none } from '../../utils/strictNull'

import { EventJson } from './EventJson'

export function fromEventJson(json: EventJson): EventWithOptionalEventTime {
  return {
    uuid: json.uuid,
    eventType: json.eventType,
    source: json.source,
    recordTime: parseISO(json.recordTime),
    eventTime: json.eventTime !== undefined ? parseISO(json.eventTime) : none,
    ship: json.ship,
    portcallId: json.portcallId !== undefined ? createEventPortcallId(json.portcallId) : none,
    previousPort: json.previousPort !== undefined ? json.previousPort : none,
    location: json.location !== undefined ? json.location : none,
    context: json.context !== undefined ? json.context : none,
  }
}

export const fromArrivalOrDepartureEventJson = (eventType: EventTypeId, eventTime: string) => ({
  eventType,
  eventTime: parseISO(eventTime),
})
