import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../lib/Limiter/fixedSizeLimiter'
import { mmsisAreEqual } from '../../utils/equals'

import type { MMSI } from '../../Domain/VesselDetails/IVesselDetails'
import type { IAppState } from '../../modules/App/interfaces/IAppState'
import type { Trace } from '../../shared/interfaces/shipvisit/IShipTraceDataPoint'

export const TRACE_CACHE_ID = 'trace'
const MAX_TRACE_CACHE_SIZE = 10

enum TraceGranularityLevel {
  NONE = 0,
  SMALL = 250,
  MEDIUM = 500,
  LARGE = 5000,
  XLARGE = 25000,
}

export const getTraceGranularityLevel = (zoomLevel: number): TraceGranularityLevel => {
  if (zoomLevel <= 3) {
    return TraceGranularityLevel.XLARGE
  }
  if (zoomLevel <= 6) {
    return TraceGranularityLevel.LARGE
  }
  if (zoomLevel <= 10) {
    return TraceGranularityLevel.MEDIUM
  }
  if (zoomLevel <= 12) {
    return TraceGranularityLevel.SMALL
  }
  return TraceGranularityLevel.NONE
}

export function granularityLevelToZoom(level: TraceGranularityLevel) {
  switch (level) {
    case TraceGranularityLevel.XLARGE:
      return 3
    case TraceGranularityLevel.LARGE:
      return 6
    case TraceGranularityLevel.MEDIUM:
      return 10
    case TraceGranularityLevel.SMALL:
      return 12
    case TraceGranularityLevel.NONE:
      return 20
  }
}

export type TraceCacheKey = Readonly<{
  mmsis: MMSI[]
  includingIntermediatePortcalls: boolean
  zoomLevel?: number
}>

const traceCacheKeysAreEqual = (left: TraceCacheKey, right: TraceCacheKey) => {
  return (
    left.includingIntermediatePortcalls === right.includingIntermediatePortcalls &&
    mmsisAreEqual(left.mmsis, right.mmsis) &&
    left.zoomLevel === right.zoomLevel
  )
}

export const traceCacheDefinition = new CacheDefinition<IAppState, TraceCacheKey, Record<MMSI, Trace>>(
  TRACE_CACHE_ID,
  fixedSizeLimiter(MAX_TRACE_CACHE_SIZE),
  traceCacheKeysAreEqual,
  appState => appState.traceCache
)
