import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import {
  WEBSOCKET_ERROR,
  WEBSOCKET_CLOSED,
  WEBSOCKET_OPENED,
  WEBSOCKET_OPEN,
  WEBSOCKET_CLOSE,
  WEBSOCKET_VISIT_UPDATED_EVENT_IDS,
  WEBSOCKET_VISIT_DELETED_EVENT_IDS,
} from '../../store/actionTypes'

import {
  IWebsocketOpenAction,
  IWebsocketClosedAction,
  IWebsocketErrorAction,
  IWebsocketOpenedAction,
  IWebsocketCloseAction,
  IWebsocketVisitUpdatedEventIdsAction,
  IWebsocketVisitDeletedEventIdsAction,
} from './websocket.interfaces'

export const visitUpdatedEventIds = (
  portcallIds: EventPortcallId[],
  currentTime: number
): IWebsocketVisitUpdatedEventIdsAction => ({
  type: WEBSOCKET_VISIT_UPDATED_EVENT_IDS,
  portcallIds,
  currentTime,
})

export const visitDeletedEventIds = (
  portcallIds: EventPortcallId[],
  currentTime: number
): IWebsocketVisitDeletedEventIdsAction => ({
  type: WEBSOCKET_VISIT_DELETED_EVENT_IDS,
  portcallIds,
  currentTime,
})

export const openWebsocket = (): IWebsocketOpenAction => ({
  type: WEBSOCKET_OPEN,
})
export const websocketOpened = (event: Event): IWebsocketOpenedAction => ({
  type: WEBSOCKET_OPENED,
  event,
})

export const closeWebsocket = (): IWebsocketCloseAction => ({
  type: WEBSOCKET_CLOSE,
})

export const websocketClosed = (event: CloseEvent): IWebsocketClosedAction => ({
  type: WEBSOCKET_CLOSED,
  event,
})

export const websocketError = (event: Event): IWebsocketErrorAction => ({
  type: WEBSOCKET_ERROR,
  event,
})
