import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../lib/Limiter/fixedSizeLimiter'
import { portcallIdsAreEqual } from '../../utils/equals'

import type { PortcallSchedule } from '../../ApplicationState/Schedule/PortcallSchedule'
import type { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import type { IAppState } from '../../modules/App/interfaces/IAppState'

const PORTCALL_SCHEDULE_CACHE_ID = 'portcallSchedule'
const MAX_CACHE_SIZE = 10

export type PortcallScheduleCacheKey = EventPortcallId

export const portcallScheduleCacheDefinition = new CacheDefinition<
  IAppState,
  PortcallScheduleCacheKey,
  PortcallSchedule
>(
  PORTCALL_SCHEDULE_CACHE_ID,
  fixedSizeLimiter(MAX_CACHE_SIZE),
  portcallIdsAreEqual,
  appState => appState.portcallSchedules
)
