export const HIDE_ALL_EVENTS_IN_SERVICES_TIMELINE = 'HIDE_ALL_EVENTS_IN_SERVICES_TIMELINE'

export type HideAllEventsInServicesTimelineAction = Readonly<{
  type: typeof HIDE_ALL_EVENTS_IN_SERVICES_TIMELINE
}>

export function hideAllEventsInServicesTimelineAction(): HideAllEventsInServicesTimelineAction {
  return {
    type: HIDE_ALL_EVENTS_IN_SERVICES_TIMELINE,
  }
}
