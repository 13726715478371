import { NotificationId } from '../../modules/InfoNotifications/InfoNotifications.interfaces'

export const NOTIFICATION_UPDATE_OR_REMOVE = 'NOTIFICATION_UPDATE_OR_REMOVE'

export interface INotificationUpdateOrRemove {
  type: typeof NOTIFICATION_UPDATE_OR_REMOVE
  notificationId: NotificationId
}

export function notificationUpdateOrRemove(notificationId: NotificationId): INotificationUpdateOrRemove {
  return {
    type: NOTIFICATION_UPDATE_OR_REMOVE,
    notificationId,
  }
}
