import { AppAction } from '../../modules/App/App.actions'
import {
  DELAY_TERMINAL_DATA_DIALOG_CLOSED,
  DELAY_TERMINAL_DATA_DIALOG_OPENED,
  DELAY_TERMINAL_DATA_DIALOG_ERROR,
} from '../../modules/DelayTerminalDataDialog/DelayTerminalDataDialog.interfaces'
import {
  PORTBASE_DIALOG_CLOSED,
  PORTBASE_DIALOG_FEEDBACK,
  PORTBASE_DIALOG_LOADING,
  PORTBASE_DIALOG_OPENED,
  PORTBASE_DIALOG_SET_DATA,
} from '../../modules/PortbaseInputDialog/PortbaseInputDialog.interfaces'
import { none } from '../../utils/strictNull'
import {
  SET_UI_ACTION_MENU_OPEN,
  SET_UI_MAIN_MENU_OPEN,
  BERTH_PROSPECTS_LOADING,
  UI_TOGGLE_SNACKBAR_CONTENT,
  UI_OPEN_WARNING_NOTIFICATIONS_DRAWER,
  UI_CLOSE_WARNING_NOTIFICATIONS_DRAWER,
  UI_SET_VISIBILITY_FILTER_PORTS,
  UI_SET_VISIBILITY_FILTER_VESSEL_TYPES,
  UI_SET_DISPLAY_TIMELINE_BY_PARTY,
  UI_TOGGLE_SHOW_MAP_TRAFFIC,
} from '../actionTypes'
import { OPEN_PORTBASE_DIALOG } from '../Portbase/OpenPortbaseDialogAction'

import { ActionMenuItem, IViewAgnosticUiState } from './interfaces/IUIState'
import { VISIBILITY_FILTER_PORTS_SHOW_ALL } from './interfaces/VisibilityFilters'

export const defaultViewAgnostic: IViewAgnosticUiState = {
  loadingBerthProspects: null,
  loggingIn: false,
  isMainMenuOpen: false,
  isActionMenuOpen: ActionMenuItem.None,
  isDisplayingTimelineByParty: false,
  visibilityFilters: {
    ports: VISIBILITY_FILTER_PORTS_SHOW_ALL,
    showMapTraffic: false,
    hiddenVesselTypes: [],
  },
  warningNotifications: {
    portcallIdToShowWarningNotificationsFor: none,
    berthVisitIdToShowWarningNotificationsFor: none,
    warningNotificationsDrawerOpen: false,
  },
  portbaseInputDialog: {
    type: PORTBASE_DIALOG_CLOSED,
    isLoading: false,
    feedbackMessage: undefined,
  },
  delayTerminalDataDialog: {
    type: DELAY_TERMINAL_DATA_DIALOG_CLOSED,
  },
  isSystemStatusMessagesBarOpen: false,
  isSnackbarContentOpen: true,
  portbaseDialogOpenFor: none,
}

export const viewAgnosticReducer = (
  state: IViewAgnosticUiState = defaultViewAgnostic,
  action: AppAction
): IViewAgnosticUiState => {
  switch (action.type) {
    case SET_UI_ACTION_MENU_OPEN:
      return {
        ...state,
        isActionMenuOpen: action.value,
      }
    case UI_OPEN_WARNING_NOTIFICATIONS_DRAWER:
      return {
        ...state,
        warningNotifications: {
          portcallIdToShowWarningNotificationsFor: action.portcallIdToShowWarningNotificationsFor,
          berthVisitIdToShowWarningNotificationsFor: action.berthVisitIdToShowWarningNotificationsFor,
          warningNotificationsDrawerOpen: true,
        },
      }
    case UI_CLOSE_WARNING_NOTIFICATIONS_DRAWER:
      return {
        ...state,
        warningNotifications: {
          ...state.warningNotifications,
          warningNotificationsDrawerOpen: false,
        },
      }
    case SET_UI_MAIN_MENU_OPEN:
      return {
        ...state,
        isMainMenuOpen: action.value,
        isActionMenuOpen: ActionMenuItem.None,
      }

    case UI_TOGGLE_SHOW_MAP_TRAFFIC:
      return {
        ...state,
        visibilityFilters: {
          ...state.visibilityFilters,
          showMapTraffic: !state.visibilityFilters.showMapTraffic,
        },
      }
    case UI_SET_VISIBILITY_FILTER_PORTS:
      return {
        ...state,
        visibilityFilters: {
          ...state.visibilityFilters,
          ports: action.ports,
        },
      }
    case UI_SET_VISIBILITY_FILTER_VESSEL_TYPES:
      return {
        ...state,
        visibilityFilters: {
          ...state.visibilityFilters,
          hiddenVesselTypes: action.vesselTypes,
        },
      }
    case UI_SET_DISPLAY_TIMELINE_BY_PARTY:
      return { ...state, isDisplayingTimelineByParty: action.payload }

    case UI_TOGGLE_SNACKBAR_CONTENT:
      return {
        ...state,
        isSnackbarContentOpen: !state.isSnackbarContentOpen,
      }
    case PORTBASE_DIALOG_CLOSED:
    case PORTBASE_DIALOG_OPENED:
      return {
        ...state,
        portbaseInputDialog: {
          // reset portbaseInputDialog to default when opening or closing
          ...defaultViewAgnostic.portbaseInputDialog,
          type: action.type,
        },
      }
    case PORTBASE_DIALOG_LOADING:
      return {
        ...state,
        portbaseInputDialog: {
          ...state.portbaseInputDialog,
          isLoading: action.loading,
        },
      }
    case PORTBASE_DIALOG_FEEDBACK:
      return {
        ...state,
        portbaseInputDialog: {
          ...state.portbaseInputDialog,
          feedbackMessage: action.message,
        },
      }
    case PORTBASE_DIALOG_SET_DATA:
      return {
        ...state,
        portbaseInputDialog: {
          ...state.portbaseInputDialog,
          data: {
            visit: action.visit,
            portbaseVisitStatus: action.portbaseVisitStatus,
          },
        },
      }
    case BERTH_PROSPECTS_LOADING:
      return {
        ...state,
        loadingBerthProspects: action.loadingFor,
      }
    case OPEN_PORTBASE_DIALOG:
      return {
        ...state,
        portbaseDialogOpenFor: action.eventPortcallId,
      }
    case DELAY_TERMINAL_DATA_DIALOG_OPENED: {
      return {
        ...state,
        delayTerminalDataDialog: {
          type: action.type,
          portcallId: action.id,
        },
      }
    }
    case DELAY_TERMINAL_DATA_DIALOG_CLOSED: {
      return {
        ...state,
        delayTerminalDataDialog: {
          type: action.type,
        },
      }
    }
    case DELAY_TERMINAL_DATA_DIALOG_ERROR: {
      return {
        ...state,
        delayTerminalDataDialog: {
          type: action.type,
          error: 'Sorry, something went wrong, please try again later.',
        },
      }
    }
    default:
      return state
  }
}

export default viewAgnosticReducer
