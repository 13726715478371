import { PREDICTED_DATA_POINT } from '../../shared/constants'
import { IShipLocation } from '../../shared/interfaces/shipLocation/IShipLocation'
import { TraceDataPoint } from '../../shared/interfaces/shipvisit/IShipTraceDataPoint'

type GetRotationOps<AbstractShipLocation> = Readonly<{
  getHeading(shipLocation: AbstractShipLocation): number | undefined
  getCourseOverGround(shipLocation: AbstractShipLocation): number | undefined
}>

const COURSE_OVER_GROUND_NOT_AVAILABLE = 360

export function pickPreferredRotation(heading: number | undefined, courseOverGround: number | undefined) {
  if (heading !== undefined) {
    return heading
  }

  if (courseOverGround !== undefined && courseOverGround !== COURSE_OVER_GROUND_NOT_AVAILABLE) {
    return courseOverGround
  }

  return 0
}

export const abstractGetRotation =
  <AbstractShipLocation>(ops: GetRotationOps<AbstractShipLocation>) =>
  (shipLocation: AbstractShipLocation) => {
    return pickPreferredRotation(ops.getHeading(shipLocation), ops.getCourseOverGround(shipLocation))
  }

export const getRotation = abstractGetRotation<IShipLocation | TraceDataPoint>({
  getHeading: sl => (sl.type !== PREDICTED_DATA_POINT ? sl.heading : undefined),
  getCourseOverGround: sl => sl.courseOverGround,
})
