import { BerthVisitId, EventPortcallId } from '../../../Domain/Portcall/IPortcall'
import { IDelayTerminalDataDialogState } from '../../../modules/DelayTerminalDataDialog/DelayTerminalDataDialog.interfaces'
import { IPortbaseInputDialogState } from '../../../modules/PortbaseInputDialog/PortbaseInputDialog.interfaces'
import { None } from '../../../utils/strictNull'

import { VisibilityFilters } from './VisibilityFilters'

interface IWarningNotificationsDrawerUiState {
  warningNotificationsDrawerOpen: boolean
  portcallIdToShowWarningNotificationsFor: EventPortcallId | None
  berthVisitIdToShowWarningNotificationsFor: BerthVisitId | None
}

export enum ActionMenuItem {
  None,
  QuickSearch,
  SystemStatusMessages,
  InfoNotifications,
  Filters,
}

export interface IViewAgnosticUiState {
  loadingBerthProspects: EventPortcallId | null
  loggingIn: boolean
  isActionMenuOpen: ActionMenuItem
  isMainMenuOpen: boolean
  isSnackbarContentOpen: boolean
  visibilityFilters: VisibilityFilters
  warningNotifications: IWarningNotificationsDrawerUiState
  portbaseInputDialog: IPortbaseInputDialogState
  delayTerminalDataDialog: IDelayTerminalDataDialogState
  isSystemStatusMessagesBarOpen: boolean
  isDisplayingTimelineByParty: boolean
  portbaseDialogOpenFor: EventPortcallId | None
}
