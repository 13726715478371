import { flatMap } from 'lodash'

import { keys } from '../../utils/obj'
import { notFalse } from '../../utils/predicates'
import { None, none } from '../../utils/strictNull'
import { NotificationType } from '../Notifications/INotification'
import {
  AGENT_NOT_DECLARED_VISIT,
  ARRIVED_AT_BERTH,
  ARRIVED_AT_PORT,
  BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED,
  BUNKER_PLANNED_TOO_CLOSE_TO_DEPARTURE,
  CARGO_STARTED,
  CARGO_COMPLETION_UPDATED,
  CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE,
  DEPARTED_FROM_BERTH,
  DEPARTURE_NOT_PLANNED,
  DEPARTURE_PLANNED,
  INBOUND_VLCC,
  LAST_LINE_SECURED,
  MOORING_TERMINAL_AND_AGENT_NOT_SAME,
  NO_SPACE_ON_BERTH_SHORT_BEFORE_ARRIVAL,
  NOT_DEPARTED_WHEN_EXPECTED,
  ONGOING_BUNKER_WHEN_VESSEL_IS_PLANNED_TO_DEPART,
  OVERLAPPING_BERTH_WINDOWS,
  PILOT_NOT_ON_BOARD_WHEN_EXPECTED,
  PILOT_ON_BOARD,
  PILOT_PLANNED,
  PILOTS_CAPACITY_ARRANGEMENT,
  PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART,
  PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME,
  PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART,
  TERMINAL_UPDATED_DEPARTURE,
  TERMINAL_UPDATED_ARRIVAL,
  VESSEL_CAN_BE_EARLIER_AT_BERTH,
  VESSEL_CAN_BE_LATER_AT_BERTH,
  VESSEL_DEPARTED_FROM_PREVIOUS_PORT,
  VESSEL_EARLY_AT_PILOT_BOARDING_PLACE,
  VESSEL_LATE_AT_PILOT_BOARDING_PLACE,
  VESSEL_LATER_AT_BERTH_THAN_TERMINAL,
  VESSEL_NOT_DEPARTED_FROM_PREVIOUS_PORT,
  SKIPPER_UPDATED_ARRIVAL,
  TRAVEL_TIME_TOO_SHORT,
  CARGO_NOT_STARTED_WHEN_EXPECTED,
  AGENT_NOT_DECLARED_BERTH_VISIT,
  PORT_STATUS,
  ARRIVED_AT_ANCHOR_AREA,
  ARRIVED_AT_PILOT_BOARDING_PLACE,
} from '../Notifications/NotificationType'

import { IBackendFacingSubscription } from './IBackendFacingSubscription'
import { ISubscription } from './ISubscription'
import { ISubscriptionConfiguration } from './ISubscriptionConfiguration'
import { SubscriptionType } from './SubscriptionType'
import { BUNKERS_TOO_CLOSE_TO_DEPARTURE } from './WarningNotificationSubscriptionType'

export const notificationTypesBySubscriptionType: Record<SubscriptionType, NotificationType[]> = {
  // Warnings
  [BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED]: [BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED],
  [BUNKERS_TOO_CLOSE_TO_DEPARTURE]: [
    BUNKER_PLANNED_TOO_CLOSE_TO_DEPARTURE,
    ONGOING_BUNKER_WHEN_VESSEL_IS_PLANNED_TO_DEPART,
  ],
  [CARGO_NOT_STARTED_WHEN_EXPECTED]: [CARGO_NOT_STARTED_WHEN_EXPECTED],
  [CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE]: [CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE],
  [MOORING_TERMINAL_AND_AGENT_NOT_SAME]: [MOORING_TERMINAL_AND_AGENT_NOT_SAME],
  [PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME]: [PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME],
  [PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART]: [PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART],
  [PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME]: [PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME],
  [PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME]: [PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME],
  [PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART]: [PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART],
  [VESSEL_CAN_BE_EARLIER_AT_BERTH]: [VESSEL_CAN_BE_EARLIER_AT_BERTH],
  [VESSEL_CAN_BE_LATER_AT_BERTH]: [VESSEL_CAN_BE_LATER_AT_BERTH],
  [VESSEL_EARLY_AT_PILOT_BOARDING_PLACE]: [VESSEL_EARLY_AT_PILOT_BOARDING_PLACE],
  [VESSEL_LATE_AT_PILOT_BOARDING_PLACE]: [VESSEL_LATE_AT_PILOT_BOARDING_PLACE],
  [AGENT_NOT_DECLARED_VISIT]: [AGENT_NOT_DECLARED_VISIT],
  [AGENT_NOT_DECLARED_BERTH_VISIT]: [AGENT_NOT_DECLARED_BERTH_VISIT],
  [DEPARTURE_NOT_PLANNED]: [DEPARTURE_NOT_PLANNED],
  [NOT_DEPARTED_WHEN_EXPECTED]: [NOT_DEPARTED_WHEN_EXPECTED],
  [PILOT_NOT_ON_BOARD_WHEN_EXPECTED]: [PILOT_NOT_ON_BOARD_WHEN_EXPECTED],
  [OVERLAPPING_BERTH_WINDOWS]: [OVERLAPPING_BERTH_WINDOWS],
  [NO_SPACE_ON_BERTH_SHORT_BEFORE_ARRIVAL]: [NO_SPACE_ON_BERTH_SHORT_BEFORE_ARRIVAL],
  [VESSEL_NOT_DEPARTED_FROM_PREVIOUS_PORT]: [VESSEL_NOT_DEPARTED_FROM_PREVIOUS_PORT],
  [VESSEL_LATER_AT_BERTH_THAN_TERMINAL]: [VESSEL_LATER_AT_BERTH_THAN_TERMINAL],
  [TRAVEL_TIME_TOO_SHORT]: [TRAVEL_TIME_TOO_SHORT],
  [PILOTS_CAPACITY_ARRANGEMENT]: [PILOTS_CAPACITY_ARRANGEMENT],

  // Info
  [ARRIVED_AT_BERTH]: [ARRIVED_AT_BERTH],
  [ARRIVED_AT_PORT]: [ARRIVED_AT_PORT],
  [ARRIVED_AT_PILOT_BOARDING_PLACE]: [ARRIVED_AT_PILOT_BOARDING_PLACE],
  [ARRIVED_AT_ANCHOR_AREA]: [ARRIVED_AT_ANCHOR_AREA],
  [CARGO_STARTED]: [CARGO_STARTED],
  [CARGO_COMPLETION_UPDATED]: [CARGO_COMPLETION_UPDATED],
  [DEPARTED_FROM_BERTH]: [DEPARTED_FROM_BERTH],
  [DEPARTURE_PLANNED]: [DEPARTURE_PLANNED],
  [TERMINAL_UPDATED_DEPARTURE]: [TERMINAL_UPDATED_DEPARTURE],
  [TERMINAL_UPDATED_ARRIVAL]: [TERMINAL_UPDATED_ARRIVAL],
  [LAST_LINE_SECURED]: [LAST_LINE_SECURED],
  [PILOT_ON_BOARD]: [PILOT_ON_BOARD],
  [PILOT_PLANNED]: [PILOT_PLANNED],
  [INBOUND_VLCC]: [],
  [VESSEL_DEPARTED_FROM_PREVIOUS_PORT]: [VESSEL_DEPARTED_FROM_PREVIOUS_PORT],
  [SKIPPER_UPDATED_ARRIVAL]: [SKIPPER_UPDATED_ARRIVAL],
  [PORT_STATUS]: [PORT_STATUS],
}

export const allSubscriptionTypes: SubscriptionType[] = keys(notificationTypesBySubscriptionType)

const mutualExclusiveSubscriptionTypes: Partial<Record<NotificationType, SubscriptionType>> = {
  [PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME]: PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART,
  [PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART]: PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  [PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME]: PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART,
  [PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART]: PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME,
}

export const getMutualExclusiveSubscriptionType = (subscriptionType: SubscriptionType) => {
  const mutualExclusiveSubscriptionType = keys(mutualExclusiveSubscriptionTypes).find(x => x === subscriptionType)
  return mutualExclusiveSubscriptionType ? mutualExclusiveSubscriptionTypes[mutualExclusiveSubscriptionType] : undefined
}

const subscriptionTypeByNotificationType: Partial<Record<NotificationType, SubscriptionType>> = (() => {
  const result: Partial<Record<NotificationType, SubscriptionType>> = {}
  keys(notificationTypesBySubscriptionType).forEach(warningSubscriptionType => {
    const warningNotificationTypes = notificationTypesBySubscriptionType[warningSubscriptionType]
    warningNotificationTypes.forEach(warningNotificationType => {
      result[warningNotificationType] = warningSubscriptionType
    })
  })
  return result
})()

export type SubscriptionConfigurationsGroupedBySubscriptionType = Partial<
  Record<SubscriptionType, IBackendFacingSubscription[]>
>

export function toSubscriptions(subscriptionJsons: IBackendFacingSubscription[]): ISubscription[] {
  const subscriptionRecords: SubscriptionConfigurationsGroupedBySubscriptionType =
    groupBySubscriptions(subscriptionJsons)
  return subscriptionRecordsToSubscriptions(subscriptionRecords)
}

/**
 * When a user never subscribed to a given notification type, the back-end will never return a `IBackendFacingSubscription`
 * for that. We need one, to be able to check if it is consistent with the rest of the bundle, so this function add those.
 */
function addMissingBackendFacingSubscriptions(
  notificationTypes: NotificationType[],
  backendFacingSubscriptions: IBackendFacingSubscription[]
) {
  return notificationTypes.map<IBackendFacingSubscription>(notificationType => {
    const correspondingBackendFacingSubscription = backendFacingSubscriptions.find(bfs => bfs.type === notificationType)
    if (correspondingBackendFacingSubscription === undefined) {
      return {
        type: notificationType,
        config: {
          emailAddress: '',
          web: false,
          pronto: false,
          email: false,
        },
      }
    }
    return correspondingBackendFacingSubscription
  })
}

function getCorrections(
  target: ISubscriptionConfiguration,
  backendFacingSubscriptions: IBackendFacingSubscription[]
): IBackendFacingSubscription[] {
  return backendFacingSubscriptions
    .filter(backendFacingSubscription => !isSameSubscriptionConfiguration(backendFacingSubscription.config, target))
    .map<IBackendFacingSubscription>(generateBackendFacingSubscriptionUpdate(target))
}

export function getConflictingSubscriptionRecords(
  subscriptionRecords: SubscriptionConfigurationsGroupedBySubscriptionType
): IBackendFacingSubscription[] {
  const subscriptionRecordKeys = keys(subscriptionRecords)
  const possibleDeviatingRecordKeys = subscriptionRecordKeys.filter(
    key => notificationTypesBySubscriptionType[key].length > 1
  )

  return flatMap(possibleDeviatingRecordKeys, possibleDeviatingSubscriptionType => {
    const backendFacingSubscriptions = subscriptionRecords[possibleDeviatingSubscriptionType] || []
    const notificationTypes = notificationTypesBySubscriptionType[possibleDeviatingSubscriptionType]
    const withMissingBackendFacingSubscriptions = addMissingBackendFacingSubscriptions(
      notificationTypes,
      backendFacingSubscriptions
    )
    const targetConfig = backendFacingSubscriptions.map(bfs => bfs.config).reduce(mergeSubscriptionConfigurations)
    return getCorrections(targetConfig, withMissingBackendFacingSubscriptions)
  })
}

function generateBackendFacingSubscriptionUpdate(targetConfig: ISubscriptionConfiguration) {
  return (backendFacingSubscription: IBackendFacingSubscription) => {
    return {
      type: backendFacingSubscription.type,
      config: targetConfig,
    }
  }
}

function isSameSubscriptionConfiguration(a: ISubscriptionConfiguration, b: ISubscriptionConfiguration): boolean {
  return keys({ ...a, ...b })
    .map(key => a[key] === b[key])
    .reduce((x, y) => x && y)
}

export function groupBySubscriptions(
  subscriptionJsons: IBackendFacingSubscription[]
): SubscriptionConfigurationsGroupedBySubscriptionType {
  const result: SubscriptionConfigurationsGroupedBySubscriptionType = {}
  subscriptionJsons.forEach(backendFacingSubscription => {
    const subscriptionType: SubscriptionType | undefined =
      subscriptionTypeByNotificationType[backendFacingSubscription.type]
    if (subscriptionType) {
      const previousSubscriptionConfigurations: IBackendFacingSubscription[] = result[subscriptionType] || []
      result[subscriptionType] = [...previousSubscriptionConfigurations, backendFacingSubscription]
    }
  })
  return result
}

/**
 * Convert {#SubscriptionConfigurationsGroupedBySubscriptionType} to an array of {#ISubscription}'s
 * @param subscriptionRecords The {#SubscriptionConfigurationsGroupedBySubscriptionType}
 */
export function subscriptionRecordsToSubscriptions(
  subscriptionRecords: SubscriptionConfigurationsGroupedBySubscriptionType
): ISubscription[] {
  return keys(subscriptionRecords)
    .map<ISubscription | None>(key => {
      const subscriptions = subscriptionRecords[key]
      if (subscriptions === undefined) {
        return none
      }
      return {
        type: key,
        config: subscriptions.map(bfs => bfs.config).reduce(mergeSubscriptionConfigurations),
      }
    })
    .filter(notFalse)
}

export function mergeSubscriptionConfigurations(
  sourceA: ISubscriptionConfiguration,
  sourceB: ISubscriptionConfiguration
): ISubscriptionConfiguration {
  return {
    email: sourceA.email || sourceB.email || false,
    emailAddress: sourceA.emailAddress || sourceB.emailAddress,
    pronto: sourceA.pronto || sourceB.pronto || false,
    web: sourceA.web || sourceB.web || false,
  }
}
