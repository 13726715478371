import { first } from 'lodash'

import { None, StrictNull } from '../../utils/strictNull'

import { EventWithOptionalEventTime } from './Event'
import { MostAccurates } from './MostAccurates'

export function getMostAccurateStart(mostAccurates: MostAccurates | None): EventWithOptionalEventTime | None {
  return StrictNull.map(mostAccurates, m => StrictNull.fromUndefined(first(m.start)))
}
