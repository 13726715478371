import { PortcallWithTimeline } from '../../Domain/Portcall/PortcallWithTimeline'
import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../lib/Limiter/fixedSizeLimiter'
import { stringsAreEqual } from '../../utils/equals'
import { IAppState } from '../App/interfaces/IAppState'

export const timeMachineCacheDefinition = new CacheDefinition<IAppState, string, PortcallWithTimeline | 404>(
  'time-machine',
  fixedSizeLimiter(1, true),
  stringsAreEqual,
  appState => appState.timeMachineCache
)
