import { clientPreferencesFromJson } from '../../Api/Users/ClientPreferences/clientPreferencesFromJson'
import { ClientPreferencesJson } from '../../Api/Users/ClientPreferences/ClientPreferencesJson'
import { BACKEND_URL } from '../constants'
import { IUser } from '../interfaces/settings/Settings'
import { createHeaders } from '../utils/createHeaders'
import { handleJSONResponse } from '../utils/rest'

export function fetchProntoUser(idToken: string, authToken: string): Promise<IUser> {
  const url = `${BACKEND_URL}/api/users/me?withCredentials=true`
  const options = {
    method: 'GET',
    headers: {
      ...createHeaders(authToken),
      // Append Auth0 ID Token into headers
      'X-Id-Token': idToken,
    },
  }

  return fetch(url, options)
    .then(response => handleJSONResponse(options, response))
    .then(
      (userJson: IUser<ClientPreferencesJson>): IUser => ({
        ...userJson,
        clientPreferences: clientPreferencesFromJson(userJson.clientPreferences),
      })
    )
}
