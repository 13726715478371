import * as React from 'react'

import { LogoSynchronizer } from '../Icons/LogoSynchronizer/LogoSynchronizer'

import styles from './ErrorPage.module.scss'

type ErrorPageProps = Readonly<{
  detail: string
  suggestion?: React.ReactNode
}>

export const ErrorPage = (props: ErrorPageProps) => (
  <div className={styles.errorPage}>
    <div className={styles.content}>
      <LogoSynchronizer height={48} />
      <h5>We are very sorry...</h5>
      <p className={styles.detail}>{props.detail}</p>
      {props.suggestion}
    </div>
  </div>
)
