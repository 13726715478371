import { None, StrictNull } from '../../utils/strictNull'
import { EventWithOptionalEventTime } from '../Events/Event'
import { firstEventWithEventTime } from '../Events/firstEventWithEventTime'

import { ActivityInfo } from './Activities/Activity'

// Get a validated event time (or an error if we cannot find it).
export function eventTime(events: EventWithOptionalEventTime[] | undefined): Date | None {
  return StrictNull.map(firstEventWithEventTime(events), e => e.eventTime)
}

export const showShipName =
  (s: string) =>
  ({ info }: { info: ActivityInfo }) =>
    info.shipName ? `${info.shipName} / ${s}` : s
export const many = <A>(a: A[]): A[] => (a !== null && a !== undefined ? a : [])
