import { isEqual } from 'lodash'

import { SYSTEM_DEFAULT_FILTER_STATE } from '../../modules/Filters/Filters.constants'
import { IFilters } from '../../shared/interfaces/filters/IFilters'
import { IUser } from '../../shared/interfaces/settings/Settings'

import { defaultFiltersForUser } from './defaultFiltersForUser'

export const getActiveFiltersForUser = (user: IUser, filters: IFilters): IFilters => {
  // If the current filters are the empty default state (SYSTEM_DEFAULT_FILTER_STATE), we use the default filters for the user.
  // If they're not empty (from localStorage), we use those. This allows for restoring of the filters after authentication.
  return isEqual(filters, SYSTEM_DEFAULT_FILTER_STATE) ? defaultFiltersForUser(user) : filters
}
