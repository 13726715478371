import { Preferences, defaultPreferences } from '../ApplicationState/Preferences'
import { verifyPreferencesJson, preferencesFromJson, preferencesToJson } from '../LocalStorage/PreferencesJson'

const PREFERENCES_LOCAL_STORAGE_KEY = 'preferences'

export function loadPreferencesFromLocalStorage(): Preferences {
  if (!window.localStorage || !window.localStorage.getItem) {
    return defaultPreferences
  }

  const localStorageState: string | null = window.localStorage.getItem(PREFERENCES_LOCAL_STORAGE_KEY)
  if (localStorageState === null) {
    return defaultPreferences
  }

  let json: any
  try {
    json = JSON.parse(localStorageState)
  } catch {
    return defaultPreferences
  }

  return verifyPreferencesJson(json).map(preferencesFromJson).getOrElse(defaultPreferences)
}

export function storePreferencesInLocalStorage(preferences: Preferences): void {
  if (!window.localStorage || !window.localStorage.getItem) {
    return
  }

  const preferencesJson = preferencesToJson(preferences)
  window.localStorage.setItem(PREFERENCES_LOCAL_STORAGE_KEY, JSON.stringify(preferencesJson))
}
