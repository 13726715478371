export const CONNECTION_CLOSED = 'CONNECTION_CLOSED'

type ConnectionClosed = Readonly<{
  type: typeof CONNECTION_CLOSED
}>

export function connectionClosed(): ConnectionClosed {
  return { type: CONNECTION_CLOSED }
}

export const LAST_UPDATE_RECEIVED_AT = 'LAST_UPDATE_RECEIVED_AT'

type LastUpdateReceivedAt = Readonly<{
  type: typeof LAST_UPDATE_RECEIVED_AT
  at: Date
}>

export function lastUpdateReceivedAt(at: Date): LastUpdateReceivedAt {
  return { type: LAST_UPDATE_RECEIVED_AT, at }
}

export type WebsocketStatus = ConnectionClosed | LastUpdateReceivedAt
