import { AppAction } from '../../modules/App/App.actions'
import {
  CLOSE_DETAILS,
  SELECT_BERTH_VISIT_DETAILS,
  SET_SHIP_LOCATION_FOR_DETAILS,
  SET_DETAILS_CONTENT_TYPE,
} from '../actionTypes'

import {
  SelectedDetailsState,
  SelectedDetailsType,
  detailsNotSelected,
  shipLocationUnknown,
  SELECTED_BERTH_VISIT,
  SELECTED_NOTHING,
} from './SelectedDetailsState'

export const selectedDetailsReducer = (
  state: SelectedDetailsState = detailsNotSelected,
  action: AppAction
): SelectedDetailsState => {
  switch (action.type) {
    case CLOSE_DETAILS:
      return detailsNotSelected
    case SELECT_BERTH_VISIT_DETAILS:
      return {
        type: SELECTED_BERTH_VISIT,
        selectedDetailsType: SelectedDetailsType.SERVICES_VIEW,
        berthVisitId: action.berthVisitId,
        portcallId: action.portcallId,
        location: shipLocationUnknown,
      }
    case SET_SHIP_LOCATION_FOR_DETAILS:
      if (state.type === SELECTED_NOTHING) {
        return state
      }

      return {
        ...state,
        location: action.shipLocation,
      }
    case SET_DETAILS_CONTENT_TYPE:
      if (state.type === SELECTED_NOTHING) {
        return state
      }

      return {
        ...state,
        selectedDetailsType: action.detailsType,
      }
    default:
      return state
  }
}
