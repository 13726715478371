import { PortcallsPurpose } from '../../ApplicationState/PortcallsPurpose'
import { IPortcall, EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { IAppState } from '../../modules/App/interfaces/IAppState'
import { IExpirable } from '../../modules/CacheQueue/IExpirable'
import { Message } from '../../modules/CacheQueue/Message'
import { QueuedCacheDefinition } from '../../modules/CacheQueue/QueuedCacheDefinition'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'
import { limiterPerPurpose } from '../Limiter/limiterPerPurpose'

export const PORTCALLS_CACHE_ID = 'portcalls'
export const portcallsCacheDefinition = new QueuedCacheDefinition<
  IAppState,
  EventPortcallId,
  IPortcall,
  Message<EventPortcallId, PortcallsPurpose>,
  PortcallsPurpose
>(
  PORTCALLS_CACHE_ID,
  limiterPerPurpose<EventPortcallId[], Array<IExpirable<IPortcall>>, PortcallsPurpose>(
    {
      [PortcallsPurpose.ForFilter]: fixedSizeLimiter(5),
      [PortcallsPurpose.ForFilterInPlanningView]: fixedSizeLimiter(5),
      [PortcallsPurpose.ForSelectedVessel]: fixedSizeLimiter(2),
      [PortcallsPurpose.ForSchedule]: fixedSizeLimiter(2),
    },
    fixedSizeLimiter(5)
  ),
  appState => appState.portcallsCache,
  appState => appState.portcallMessagesQueue,
  p => p.eventId
)
