import React, { Suspense } from 'react'
import { MixpanelProvider } from '@portxchange/mixpanel-utils'
import createDebug from 'debug'
import { BrowserRouter } from 'react-router-dom'

import BrowserUnsupportedPage from '../../components/BrowserUnsupportedPage/BrowserUnsupportedPage'
import { MIXPANEL_ID } from '../../config'
import { isUnsupportedBrowser } from '../../utils/browser'
import { RootComponent } from '../../components/Root/Root'
import { Auth0Provider } from '@auth0/auth0-react'
import { auth0Options } from '../../utils/auth/auth0config'
import { HasAcceptedLegalTerms } from '../LegalTermsPage/HasAcceptedLegalTerms'
import { IsAuthenticated } from '../AuthGate/IsAuthenticated'
import { OnRedirectCallback } from '../AuthGate/OnRedirectCallback'
import { LoadingLoader } from '../../components/Loader/Loader'
import { history } from '../../store/setupStore'

const MainApp = React.lazy(() => import('./MainApp'))

const debugMixpanel = createDebug('mixpanel')

const mixpanelConfig = { debug: debugMixpanel.enabled, api_host: 'https://api-eu.mixpanel.com' }

export const App = () => {
  const debug = createDebug('pronto:index')

  history.listen(his => debug(`The current URL is ${his.location.pathname}${his.location.search}${his.location.hash}`))

  if (isUnsupportedBrowser()) {
    return <BrowserUnsupportedPage />
  }

  return (
    <BrowserRouter>
      <MixpanelProvider mixpanelId={MIXPANEL_ID} config={mixpanelConfig}>
        <OnRedirectCallback>
          {({ onRedirectCallback }) => (
            <Auth0Provider
              onRedirectCallback={onRedirectCallback}
              domain={auth0Options.domain}
              clientId={auth0Options.client_id}
              redirectUri={auth0Options.redirect_uri}
              audience={auth0Options.audience}
              useRefreshTokens={auth0Options.useRefreshTokens}
              cacheLocation={auth0Options.cacheLocation}
            >
              <RootComponent>
                <IsAuthenticated>
                  {({ user, idToken, accessToken }) => (
                    <Suspense fallback={<LoadingLoader />}>
                      <HasAcceptedLegalTerms user={user}>
                        <MainApp user={user} accessToken={accessToken} idToken={idToken} />
                      </HasAcceptedLegalTerms>
                    </Suspense>
                  )}
                </IsAuthenticated>
              </RootComponent>
            </Auth0Provider>
          )}
        </OnRedirectCallback>
      </MixpanelProvider>
    </BrowserRouter>
  )
}
