/**
 * Like `map` for `Array`, but now for key-value pairs in an object.
 * The resulting object will have the same keys as the original object,
 * but with different values.
 */
export function mapValues<O extends Record<string, unknown>, A>(
  o: O,
  fn: (v: O[keyof O], k: keyof O, o: O) => A
): Record<keyof O, A> {
  const acc: Partial<Record<keyof O, A>> = {}
  keys(o).forEach((key: keyof O) => {
    const originalValue = o[key]
    const newValue = fn(originalValue, key, o)
    acc[key] = newValue as any
  })
  return acc as Record<keyof O, A>
}

export function toRecord<E, K extends string | number | symbol, V>(
  es: E[],
  toKey: (e: E) => K,
  toValue: (e: E) => V
): Record<K, V> {
  const record = {} as Record<K, V>
  es.forEach(e => {
    record[toKey(e)] = toValue(e)
  })
  return record
}

export function toKeyValues<O extends Record<string, unknown>>(o: O): Array<{ key: keyof O; value: O[keyof O] }> {
  const acc: Array<{ key: keyof O; value: O[keyof O] }> = []
  keys(o).forEach((key: keyof O) => {
    acc.push({ key, value: o[key] })
  })
  return acc
}

export function entries<K extends string | number | symbol, V>(o: Record<K, V>): Array<[K, V]> {
  return Object.entries(o) as Array<[K, V]>
}

export function keys<O extends Record<string, unknown>>(o: O): Array<keyof O> {
  return Object.keys(o) as Array<keyof O>
}

export function values<O extends Record<string, unknown>>(o: O): Array<O[keyof O]> {
  return Object.values(o) as Array<O[keyof O]>
}

export function getKey<O extends Record<string, unknown>, K extends keyof O>(o: O | undefined | null, k: K) {
  return o && k in o ? o[k] : null
}

export function hasKey<O extends Record<string, unknown>>(o: O, k: string | number | symbol): k is keyof O {
  return k in o
}
