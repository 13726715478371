import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { ScheduleId } from '../../Domain/Schedule/ScheduleId'
import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../lib/Limiter/fixedSizeLimiter'
import { scheduleIdsAreEqual } from '../../shared/interfaces/filters/IFilters'
import { IAppState } from '../App/interfaces/IAppState'

const CACHE_ID = 'portcall-ids-for-schedule'
const MAX_CACHE_SIZE = 5

export const portcallIdsForScheduleCacheDefinition = new CacheDefinition<IAppState, ScheduleId[], EventPortcallId[]>(
  CACHE_ID,
  fixedSizeLimiter(MAX_CACHE_SIZE),
  scheduleIdsAreEqual,
  appState => appState.portcallIdsForScheduleCache
)
