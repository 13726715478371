import { INotificationUpdate } from './NotificationUpdate.interfaces'

export const PROCESS_UPDATE = 'PROCESS_UPDATE'

export interface IProcessUpdateAction {
  type: typeof PROCESS_UPDATE
  notificationUpdates: INotificationUpdate[]
  timeStamp: number
}

export function processUpdate(notificationUpdates: INotificationUpdate[], timeStamp: number): IProcessUpdateAction {
  return {
    type: PROCESS_UPDATE,
    notificationUpdates,
    timeStamp,
  }
}

export const ADD_NOTIFICATION_UPDATES = 'ADD_NOTIFICATION_UPDATES'

export interface IAddNotificationUpdatesAction {
  type: typeof ADD_NOTIFICATION_UPDATES
  updates: INotificationUpdate[]
}

export function addNotificationUpdates(updates: INotificationUpdate[]): IAddNotificationUpdatesAction {
  return {
    type: ADD_NOTIFICATION_UPDATES,
    updates,
  }
}

export const REMOVE_NOTIFICATION_UPDATE = 'REMOVE_NOTIFICATION_UPDATE'

export interface IRemoveNotificationUpdateAction {
  type: typeof REMOVE_NOTIFICATION_UPDATE
  updateId: string
}

export function removeNotificationUpdate(updateId: string): IRemoveNotificationUpdateAction {
  return {
    type: REMOVE_NOTIFICATION_UPDATE,
    updateId,
  }
}

export type NotificationUpdateAction =
  | IProcessUpdateAction
  | IAddNotificationUpdatesAction
  | IRemoveNotificationUpdateAction
