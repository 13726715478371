import { AppState } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { isValidReturnToPath } from '../../constants/pages'

export const OnRedirectCallback = ({ children }: { children: (onRedirectCallback: AppState) => JSX.Element }) => {
  const history = useHistory()

  return children({
    onRedirectCallback: ({ returnTo }: AppState) => {
      if (returnTo) {
        const { pathname, search } = new URL(`${window.location.origin}${returnTo}`)

        if (isValidReturnToPath(pathname)) {
          history.replace(`${pathname}${search}`)
        }
      }
    },
  })
}
