import { IVesselDetails } from '../../Domain/VesselDetails/IVesselDetails'
import { VesselId } from '../../Domain/VesselDetails/VesselId'
import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../lib/Limiter/fixedSizeLimiter'
import { stringsAreEqual } from '../../utils/equals'
import { IAppState } from '../App/interfaces/IAppState'

const VESSEL_DETAILS_FOR_MMSI_ENI_OR_IMO_CACHE_ID = 'vessel-details-for-mmsi-eni-or-imo'

export const vesselDetailsCacheDefinition = new CacheDefinition<IAppState, VesselId, IVesselDetails | 404>(
  VESSEL_DETAILS_FOR_MMSI_ENI_OR_IMO_CACHE_ID,
  fixedSizeLimiter(1, true),
  stringsAreEqual,
  appState => appState.vesselDetailsForVesselIdCache
)
