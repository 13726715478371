import { AnyAction } from 'redux'

import { Action as AsyncResultAction, isAction as isAsyncResultAction } from '../../lib/asyncSelector/Action'

import { IExpirable } from './IExpirable'

export type Action<Key, Result, Meta> =
  | IExpireExpirableAction<Key>
  | IAwaitingResultForExpirablesAction<Key>
  | IResultReceivedForExpirables<Result>
  | INoResultReceivedForExpirables<Key>
  | IRequestCancelledForExpirables<Key>
  | AsyncResultAction<Key[], Array<IExpirable<Result>>, Meta>

export function isAction<Key, Result, Meta>(action: AnyAction): action is Action<Key, Result, Meta> {
  if (isAsyncResultAction(action)) {
    return true
  }

  const actionTypes = [
    EXPIRE_EXPIRABLE,
    AWAITING_RESULT_FOR_EXPIRABLES,
    RESULT_RECEIVED_FOR_EXPIRABLES,
    NO_RESULT_RECEIVED_FOR_EXPIRABLES,
    REQUEST_CANCELLED_FOR_EXPIRABLES,
  ]
  return actionTypes.some(actionType => actionType === action.type)
}

export const EXPIRE_EXPIRABLE = 'EXPIRE_EXPIRABLE'

interface IExpireExpirableAction<Key> {
  type: typeof EXPIRE_EXPIRABLE
  resourceId: string
  keys: Key[]
  timeStamp: number
}

export function expireExpirable<Key>(resourceId: string, keys: Key[], timeStamp: number): IExpireExpirableAction<Key> {
  return {
    type: EXPIRE_EXPIRABLE,
    resourceId,
    keys,
    timeStamp,
  }
}

export const AWAITING_RESULT_FOR_EXPIRABLES = 'AWAITING_RESULT_FOR_EXPIRABLE'

interface IAwaitingResultForExpirablesAction<Key> {
  type: typeof AWAITING_RESULT_FOR_EXPIRABLES
  resourceId: string
  keys: Key[]
  timeStamp: number
}

export function awaitingResultForExpirables<Key>(
  resourceId: string,
  keys: Key[],
  timeStamp: number
): IAwaitingResultForExpirablesAction<Key> {
  return {
    type: AWAITING_RESULT_FOR_EXPIRABLES,
    resourceId,
    keys,
    timeStamp,
  }
}

export const RESULT_RECEIVED_FOR_EXPIRABLES = 'RESULT_RECEIVED_FOR_EXPIRABLES'

interface IResultReceivedForExpirables<Result> {
  type: typeof RESULT_RECEIVED_FOR_EXPIRABLES
  resourceId: string
  results: Array<IExpirable<Result>>
  timeStamp: number
}

export function resultReceivedForExpirables<Result>(
  resourceId: string,
  results: Array<IExpirable<Result>>,
  timeStamp: number
): IResultReceivedForExpirables<Result> {
  return {
    type: RESULT_RECEIVED_FOR_EXPIRABLES,
    resourceId,
    results,
    timeStamp,
  }
}

export const NO_RESULT_RECEIVED_FOR_EXPIRABLES = 'NO_RESULT_RECEIVED_FOR_EXPIRABLES'

interface INoResultReceivedForExpirables<Key> {
  type: typeof NO_RESULT_RECEIVED_FOR_EXPIRABLES
  resourceId: string
  keys: Key[]
  timeStamp: number
}

export function noResultReceivedForExpirables<Key>(
  resourceId: string,
  keys: Key[],
  timeStamp: number
): INoResultReceivedForExpirables<Key> {
  return {
    type: NO_RESULT_RECEIVED_FOR_EXPIRABLES,
    resourceId,
    keys,
    timeStamp,
  }
}

export const REQUEST_CANCELLED_FOR_EXPIRABLES = 'REQUEST_CANCELLED_FOR_EXPIRABLES'

interface IRequestCancelledForExpirables<Key> {
  type: typeof REQUEST_CANCELLED_FOR_EXPIRABLES
  resourceId: string
  keys: Key[]
  timeStamp: number
}

export function requestCancelledForExpirables<Key>(
  resourceId: string,
  keys: Key[],
  timeStamp: number
): IRequestCancelledForExpirables<Key> {
  return {
    type: REQUEST_CANCELLED_FOR_EXPIRABLES,
    resourceId,
    keys,
    timeStamp,
  }
}
