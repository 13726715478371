import { getMostAccurateEnd } from '../../Domain/Events/getMostAccurateEnd'
import { getMostAccurateStart } from '../../Domain/Events/getMostAccurateStart'
import { MostAccurates } from '../../Domain/Events/MostAccurates'
import { ServiceActivity } from '../../Domain/ServiceActivities/ServiceActivity'
import { None, none, StrictUndefined, StrictNull } from '../../utils/strictNull'
import { fromEventJson } from '../Event/fromEventJson'
import { fromMostAccuratesJson } from '../Event/fromMostAccuratesJson'

import { ServiceActivityJson } from './ServiceActivityJson'

export function fromServiceActivityJson(json: ServiceActivityJson): ServiceActivity {
  const mostAccurates: MostAccurates | None = StrictUndefined.fold(json.mostAccurates, fromMostAccuratesJson, none)

  const mostAccurateStartFromMostAccurates = StrictNull.flatMap(mostAccurates, getMostAccurateStart)
  const mostAccurateStart = StrictUndefined.fold(
    json.mostAccurateStart,
    fromEventJson,
    mostAccurateStartFromMostAccurates
  )

  const mostAccurateEndFromMostAccurates = StrictNull.flatMap(mostAccurates, getMostAccurateEnd)
  const mostAccurateEnd = StrictUndefined.fold(json.mostAccurateEnd, fromEventJson, mostAccurateEndFromMostAccurates)

  return {
    serviceType: json.serviceType,
    mostAccurateStart,
    mostAccurateEnd,
  }
}
