import isEqual from 'lodash/isEqual'

import { stableSort } from './arr'
import { keys } from './obj'
import { compareStringsAsc } from './ordering'
import { None, none } from './strictNull'

import type { EventPortcallId } from '../Domain/Portcall/IPortcall'
import type { IShipId, MMSI } from '../Domain/VesselDetails/IVesselDetails'
import type { Equality } from './shared'

/**
 * As a type-safe cast.
 */
export function areEqualWith<A>(equality: Equality<A>): Equality<A> {
  return equality
}

export function areEqualBy<A, B>(selector: (a: A) => B, equality: Equality<B>): Equality<A> {
  return (left, right) => equality(selector(left), selector(right))
}

export function datesAreEqual(left: Date, right: Date): boolean {
  return left.valueOf() === right.valueOf()
}

export function stringsAreEqual(left: string, right: string): boolean {
  return left === right
}

export const tuplesAreEqual =
  <A, B>(fstAreEqual: Equality<A>, sndAreEqual: Equality<B>) =>
  ([leftFst, leftSnd]: [A, B], [rightFst, rightSnd]: [A, B]): boolean => {
    return fstAreEqual(leftFst, rightFst) && sndAreEqual(leftSnd, rightSnd)
  }

export function numbersAreEqual(left: number, right: number): boolean {
  return left === right
}

/**
 * The function `areEqualStrict` compares two objects, property by property.
 * The name "strict" refers to the fact that you have to make a statement about
 * *every* property of the object. The advantage of this is that the type system
 * will remind you to update your equality checks every time you add/remove a
 * property.
 */
export function areEqualStrict<O>(o: { [K in keyof O]: Equality<O[K]> }): Equality<O> {
  return (left: O, right: O): boolean =>
    keys(o).every(key => {
      const equality: Equality<O[keyof O]> = o[key]
      return equality(left[key], right[key])
    })
}

/**
 * The function `areEqual` compares two objects, property by property.
 * Unlike `areEqualStrict`, this function only checks the properties you
 * specify (not all properties need to be specified). This is handy when
 * you're dealing with a large objects and equality is determined simply
 * by checking a few properties (like an "id"). This will not warn you
 * when you have updated your object to include more properties though!
 */
export function areEqual<O>(o: { [K in keyof O]?: Equality<O[K]> }): Equality<O> {
  return (left: O, right: O): boolean => {
    let equal = true
    keys(o).forEach(key => {
      const equality: Equality<O[keyof O]> | undefined = o[key]
      if (equality !== undefined) {
        equal = equal && equality(left[key], right[key])
      }
    })
    return equal
  }
}

export function and<A>(leftEq: Equality<A>, rightEq: Equality<A>): Equality<A> {
  return (left, right) => leftEq(left, right) && rightEq(left, right)
}

export const arraysAreEqual =
  <A>(elementsAreEqual: (left: A, right: A) => boolean) =>
  (left: A[], right: A[]): boolean => {
    if (left.length !== right.length) {
      return false
    }

    return left.every((leftValue, index) => {
      const rightValue = right[index]
      return elementsAreEqual(leftValue, rightValue)
    })
  }

const areBothUndefinedOr =
  <A>(eq: Equality<A>): Equality<A | undefined> =>
  (left, right) => {
    if (left === undefined && right === undefined) {
      return true
    }

    if (left !== undefined && right !== undefined) {
      return eq(left, right)
    }

    return false
  }

export const areBothNoneOr =
  <A>(eq: Equality<A>): Equality<A | None> =>
  (left, right) => {
    if (left === none && right === none) {
      return true
    }

    if (left !== none && right !== none) {
      return eq(left, right)
    }

    return false
  }

export const areBoth404Or =
  <A>(eq: Equality<A>): Equality<A | 404> =>
  (left, right) => {
    if (left === 404 && right === 404) {
      return true
    }

    if (left !== 404 && right !== 404) {
      return eq(left, right)
    }

    return false
  }

export const shipIdsAreEqual: Equality<IShipId> = areEqual<IShipId>({
  imo: areBothUndefinedOr(stringsAreEqual),
  mmsi: areBothUndefinedOr(stringsAreEqual),
  eni: areBothUndefinedOr(stringsAreEqual),
})

export function portcallIdsAreEqual(left: EventPortcallId, right: EventPortcallId): boolean {
  return left === right
}

export function tripleEquals<A>(left: A, right: A): boolean {
  return left === right
}

export const tripleEqualsOr =
  <A>(otherEquality: Equality<A>) =>
  (left: A, right: A) => {
    return tripleEquals(left, right) || otherEquality(left, right)
  }

export const haveSameStringElements = <A extends string>(left: A[], right: A[]) => {
  const sortedLeft = stableSort(left, compareStringsAsc)
  const sortedRight = stableSort(right, compareStringsAsc)

  return isEqual(sortedLeft, sortedRight)
}

export const mmsisAreEqual = (left: MMSI[], right: MMSI[]) => {
  return isEqual(left, right)
}
