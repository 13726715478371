import { first } from 'lodash'

import { none, None } from '../../utils/strictNull'

import { EventWithOptionalEventTime } from './Event'
import { MostAccurates } from './MostAccurates'

export function getMostAccurateEnd(mostAccurates: MostAccurates): EventWithOptionalEventTime | None {
  return first(mostAccurates.end) || none
}
