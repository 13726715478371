import type { NotificationId } from '../../modules/InfoNotifications/InfoNotifications.interfaces'
import type { IInterval } from '../../shared/interfaces/shipvisit/IBerthVisit'
import type { BrandedInvariant } from '../../utils/branded'
import type { None } from '../../utils/strictNull'
import type { EventWithOptionalEventTime } from '../Events/Event'
import type { MostAccurates } from '../Events/MostAccurates'
import type { ServiceActivity } from '../ServiceActivities/ServiceActivity'

export type MovementId = BrandedInvariant<string, 'movement-id'>

export function createMovementId(s: string): MovementId {
  return s as MovementId
}

export const MOVEMENT_TYPE_INBOUND = 'Inbound'
export const MOVEMENT_TYPE_SHIFT = 'Shifting'
const MOVEMENT_TYPE_OUTBOUND = 'Outbound'
const MOVEMENT_TYPE_TRANSIT = 'Transit'

export type MovementType =
  | typeof MOVEMENT_TYPE_INBOUND
  | typeof MOVEMENT_TYPE_SHIFT
  | typeof MOVEMENT_TYPE_OUTBOUND
  | typeof MOVEMENT_TYPE_TRANSIT

export type Movement = Readonly<{
  id: MovementId
  movementType: MovementType
  mostAccurates: MostAccurates | None
  mostAccurateStart: EventWithOptionalEventTime | None
  timeframe: IInterval
  notifications: NotificationId[]
  services: ServiceActivity[]
}>
