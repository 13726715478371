import { difference } from 'lodash'

import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { ScheduleId } from '../../Domain/Schedule/ScheduleId'
import { CacheItem, update } from '../../lib/asyncSelector/CacheItem'
import { WEBSOCKET_VISIT_DELETED_EVENT_IDS } from '../../store/actionTypes'
import { AppAction } from '../App/App.actions'

import { portcallIdsForScheduleCacheDefinition } from './portcallIdsForScheduleCacheDefinition'

export function portcallIdsForScheduleCacheReducer(
  cacheItems: Array<CacheItem<ScheduleId[], EventPortcallId[]>> | undefined,
  action: AppAction
): Array<CacheItem<ScheduleId[], EventPortcallId[]>> {
  const afterCacheActions = portcallIdsForScheduleCacheDefinition.reducer(cacheItems, action)

  switch (action.type) {
    case WEBSOCKET_VISIT_DELETED_EVENT_IDS:
      return update(
        afterCacheActions,
        eventPortcallIds => difference(eventPortcallIds, action.portcallIds),
        action.currentTime,
        portcallIdsForScheduleCacheDefinition.limiter
      )
    default:
      return afterCacheActions
  }
}
