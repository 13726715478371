import { DefaultFilters } from '../../Domain/Users/ClientPreferences/DefaultFilters'
import { SYSTEM_DEFAULT_FILTER_STATE } from '../../modules/Filters/Filters.constants'
import { IUser, Permission } from '../../shared/interfaces/settings/Settings'
import { none } from '../../utils/strictNull'
import { hasPermission } from '../authorizations/permissions.selectors'

// This function is used when the completes the login process and when
// a user resets the filters to the saved default values. In both case, there
// might not be a saved default value, so we need to make a sensible decision
// what to use as the user's default filter in that scenario.
//
// use the default filters of the user (clientPreferences.defaultFilters) or fallback to user default
// user defaults are the predefined agents and/or berths for a user account
export const defaultFiltersForUser = (user: IUser | null): DefaultFilters => {
  if (user) {
    const {
      clientPreferences: { defaultFilters },
      agents,
      berthUuids,
      carriers,
      ports,
      permissions,
    } = user
    if (defaultFilters === none) {
      // Create new DefaultFilters object for new user and apply their permissions
      return applyFilterPermissions(
        {
          ...SYSTEM_DEFAULT_FILTER_STATE,
          ports,
          agents,
          berthUuids,
          carriers,
        },
        permissions
      )
    }
    // Retro-actively apply permissions to active user's DefaultFilters
    return applyFilterPermissions(defaultFilters, permissions)
  }
  return SYSTEM_DEFAULT_FILTER_STATE
}

// Checks user permissions to see if they're allowed to see and use the respective filter properties
const applyFilterPermissions = (filters: DefaultFilters, permissions: Permission[]) => ({
  ...filters,
  agents: hasPermission('filter:agents', permissions) ? filters.agents : [],
  berthUuids: hasPermission('filter:berths', permissions) ? filters.berthUuids : [],
  carriers: hasPermission('filter:carriers', permissions) ? filters.carriers : [],
})
