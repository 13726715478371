import { setLoginExpired } from '../../store/auth/auth.actions'
import { setError } from '../../store/error/error.actions'
import { LazyList, LazyListCons } from '../../utils/lazy'
import { none, None } from '../../utils/strictNull'

import { awaitingResultAction, resultReceivedAction } from './Action'
import { CacheDefinition } from './CacheDefinition'
import { CacheItem, forKey } from './CacheItem'
import { AWAITING_RESULT, RESULT_RECEIVED, RESULT_EXPIRED, REQUEST_CANCELLED } from './consts'
import { getType } from './Results'

import type { AppAction } from '../../modules/App/App.actions'
import type { IAppError } from '../../shared/interfaces/errors/IAppError'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'

let requestId = 0
const REQUEST_PREFIX = 'promiseToThunks-'

function getNextRequestId() {
  return REQUEST_PREFIX + String(requestId++)
}

type ThunkFromPromise<AppState, Result, Meta> = <Key>(
  key: Key,
  cacheItemsForKeyWhenCreatingAction: LazyListCons<CacheItem<Key, Result, Meta>>,
  cacheDefinition: CacheDefinition<AppState, Key, Result, Meta>
) => Array<ThunkAction<Promise<any>, AppState, void, AppAction>>

export function promiseToThunks<AppState, Result>(
  stateToPromise: () => Promise<Result>
): ThunkFromPromise<AppState, Result, None>
export function promiseToThunks<AppState, Result, Meta>(
  stateToPromise: () => Promise<Result>,
  meta: Meta
): ThunkFromPromise<AppState, Result, Meta>
export function promiseToThunks<AppState, Result, Meta>(
  stateToPromise: () => Promise<Result>,
  meta: Meta | None = none
): ThunkFromPromise<AppState, Result, Meta | None> {
  return <Key>(
    key: Key,
    cacheItemsForKeyWhenCreatingAction: LazyListCons<CacheItem<Key, Result, Meta | None>>,
    cacheDefinition: CacheDefinition<AppState, Key, Result, Meta | None>
  ): Array<ThunkAction<Promise<any>, AppState, void, AppAction>> => {
    const action: ThunkAction<Promise<any>, AppState, void, AppAction> = (dispatch, getState) => {
      const state = getState()

      // Early return if the state of the store changed between creating and dispatching the action.
      // This can happen when, for instance, two components both request the same `AsyncResult<..>` and
      // (therefore) dispatch the same actions. The first `AsyncResult<..>` that is handled will change
      // to store state so that the action from the second `AsyncResult<..>` doesn't need to be handled.
      const cacheItemsWhenDispatchingAction = cacheDefinition.cacheSelector(state)
      const cacheItemsForKeyWhenDispatchingAction = forKey(
        cacheItemsWhenDispatchingAction,
        key,
        cacheDefinition.keysAreEqual
      )
      const asyncResultTypeWhenDispatchingAction = getType(cacheItemsForKeyWhenDispatchingAction)
      if (
        asyncResultTypeWhenDispatchingAction === AWAITING_RESULT ||
        asyncResultTypeWhenDispatchingAction === RESULT_RECEIVED
      ) {
        return Promise.resolve(null)
      }

      return runPromise(key, meta, stateToPromise, cacheDefinition, dispatch).catch((error: IAppError) => {
        // @TODO make sure that the `AWAITING_RESULT` in the cache gets cleared as well!
        if (error && error.type === 'login-expired') {
          dispatch(setLoginExpired())
        } else {
          dispatch(setError(error))
        }
      })
    }

    const firstCacheItemForKey = LazyList.head(cacheItemsForKeyWhenCreatingAction)

    // Check if we need to dispatch something. If the result is already present (`RESULT_RECEIVED`)
    // or we already requested the result (`AWAITING_RESULT`) we don't need to do anything.
    if (firstCacheItemForKey === none) {
      return [action]
    }
    const { requestState } = firstCacheItemForKey
    if (requestState.type === AWAITING_RESULT || requestState.type === RESULT_RECEIVED) {
      return []
    }
    if (requestState.type === RESULT_EXPIRED || requestState.type === REQUEST_CANCELLED) {
      return [action]
    }
    const exhaustive: never = requestState
    throw new Error(exhaustive)
  }
}

export function runPromise<AppState, Key, Result, Meta>(
  key: Key,
  meta: Meta,
  lazyPromise: () => Promise<Result>,
  cacheDefinition: CacheDefinition<AppState, Key, Result, Meta>,
  dispatch: ThunkDispatch<AppState, void, AppAction>
): Promise<Result> {
  const currentRequestId = getNextRequestId()
  const resultPromise = lazyPromise()
  dispatch(awaitingResultAction<Key, Meta>(cacheDefinition.cacheId, currentRequestId, key, new Date().valueOf(), meta))
  return resultPromise.then(result => {
    dispatch(
      resultReceivedAction<Key, Result>(cacheDefinition.cacheId, currentRequestId, key, result, new Date().valueOf())
    )
    return result
  })
}
