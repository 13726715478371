import { flatMap } from 'lodash'

import { Maybe, MaybeCons } from '../../utils/Maybe'
import { notFalse } from '../../utils/predicates'
import { none, None } from '../../utils/strictNull'

interface ILeftRight<Left, Right> {
  left: MaybeCons<Left>
  right: MaybeCons<Right>
}

interface ILeftsAndRightsWithSameId<Left, Right> {
  lefts: Left[]
  rights: Right[]
}

type JoinAccumulator<Left, Right, Id> = Map<Id, ILeftsAndRightsWithSameId<Left, Right>>

function permutations<Left, Right>(lefts: Left[], rights: Right[]): Array<ILeftRight<Left, Right>> {
  if (lefts.length > 0 && rights.length > 0) {
    const results: Array<ILeftRight<Left, Right>> = []
    lefts.forEach(left => {
      rights.forEach(right => {
        results.push({ left: Maybe.just(left), right: Maybe.just(right) })
      })
    })
    return results
  }
  if (lefts.length > 0) {
    return lefts.map<ILeftRight<Left, Right>>(left => ({ left: Maybe.just(left), right: none }))
  }
  if (rights.length > 0) {
    return rights.map<ILeftRight<Left, Right>>(right => ({ left: none, right: Maybe.just(right) }))
  }
  return []
}

function getLeftsAndRightsWithSameId<Left, Right, Id>(
  lefts: Left[],
  rights: Right[],
  getLeftId: (left: Left) => Id,
  getRightId: (right: Right) => Id
): Array<ILeftsAndRightsWithSameId<Left, Right>> {
  const acc: JoinAccumulator<Left, Right, Id> = new Map()
  lefts.forEach(left => {
    const leftId = getLeftId(left)
    let leftsAndRightsWithSameId = acc.get(leftId)
    if (leftsAndRightsWithSameId === undefined) {
      leftsAndRightsWithSameId = { lefts: [], rights: [] }
      acc.set(leftId, leftsAndRightsWithSameId)
    }
    leftsAndRightsWithSameId.lefts.push(left)
  })
  rights.forEach(right => {
    const rightId = getRightId(right)
    let leftsAndRightsWithSameId = acc.get(rightId)
    if (leftsAndRightsWithSameId === undefined) {
      leftsAndRightsWithSameId = { lefts: [], rights: [] }
      acc.set(rightId, leftsAndRightsWithSameId)
    }
    leftsAndRightsWithSameId.rights.push(right)
  })
  return Array.from(acc.values())
}

export function leftJoin<Left, Right, Id>(
  lefts: Left[],
  rights: Right[],
  getLeftId: (left: Left) => Id,
  getRightId: (right: Right) => Id
) {
  const leftsAndRightsWithSameId = getLeftsAndRightsWithSameId(lefts, rights, getLeftId, getRightId)
  const leftsAndRightsWithSameIdWithoutOrphanRights = leftsAndRightsWithSameId.filter(o => o.lefts.length > 0)
  return flatMap(leftsAndRightsWithSameIdWithoutOrphanRights, o => o.lefts.map(left => ({ left, rights: o.rights })))
}

export function leftJoinInPairs<Left, Right, Id>(
  lefts: Left[],
  rights: Right[],
  getLeftId: (left: Left) => Id,
  getRightId: (right: Right) => Id
) {
  return fullOuterJoinInPairs(lefts, rights, getLeftId, getRightId)
    .map<{ left: Left; right: MaybeCons<Right> } | None>(({ left, right }) => {
      if (left === none) {
        return none
      }
      return { left: left.value, right }
    })
    .filter(notFalse)
}

export function innerJoinInPairs<Left, Right, Id>(
  lefts: Left[],
  rights: Right[],
  getLeftId: (left: Left) => Id,
  getRightId: (right: Right) => Id
) {
  return fullOuterJoinInPairs(lefts, rights, getLeftId, getRightId)
    .map<{ left: Left; right: Right } | None>(({ left, right }) => {
      if (left === none || right === none) {
        return none
      }
      return { left: left.value, right: right.value }
    })
    .filter(notFalse)
}

export function fullOuterJoin<Left, Right, Id>(
  lefts: Left[],
  rights: Right[],
  getLeftId: (left: Left) => Id,
  getRightId: (right: Right) => Id
) {
  return getLeftsAndRightsWithSameId(lefts, rights, getLeftId, getRightId)
}

export function fullOuterJoinInPairs<Left, Right, Id>(
  lefts: Left[],
  rights: Right[],
  getLeftId: (left: Left) => Id,
  getRightId: (right: Right) => Id
) {
  const joined = getLeftsAndRightsWithSameId(lefts, rights, getLeftId, getRightId)
  return flatMap(joined, o => permutations(o.lefts, o.rights))
}

export const UPDATE_COMMAND = 'UPDATE_COMMAND'
export const ADD_COMMAND = 'ADD_COMMAND'
export const REMOVE_COMMAND = 'REMOVE_COMMAND'

interface IUpdateCommand<Left, Right> {
  type: typeof UPDATE_COMMAND
  left: Left
  right: Right
}

interface IAddCommand<Left> {
  type: typeof ADD_COMMAND
  left: Left
}

interface IRemoveCommand<Right> {
  type: typeof REMOVE_COMMAND
  right: Right
}

type SynchronizeCommand<Left, Right> = IUpdateCommand<Left, Right> | IAddCommand<Left> | IRemoveCommand<Right>
export function synchronize<Left, Right, Id>(
  lefts: Left[],
  rights: Right[],
  getLeftId: (left: Left) => Id,
  getRightId: (right: Right) => Id
): Array<SynchronizeCommand<Left, Right>> {
  const joined = fullOuterJoin(lefts, rights, getLeftId, getRightId)
  return flatMap(joined, o => {
    const leftsLength = o.lefts.length
    const rightsLength = o.rights.length
    const length = Math.max(leftsLength, rightsLength)
    const results: Array<SynchronizeCommand<Left, Right>> = []
    for (let i = 0; i < length; ++i) {
      const left: MaybeCons<Left> = i < leftsLength ? Maybe.just(o.lefts[i]) : none
      const right: MaybeCons<Right> = i < rightsLength ? Maybe.just(o.rights[i]) : none
      if (left !== none && right !== none) {
        results.push({ type: UPDATE_COMMAND, left: left.value, right: right.value })
      } else if (left !== none && right === none) {
        results.push({ type: ADD_COMMAND, left: left.value })
      } else if (left === none && right !== none) {
        results.push({ type: REMOVE_COMMAND, right: right.value })
      } else {
        throw new Error('Should not happen!')
      }
    }
    return results
  })
}
