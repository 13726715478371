import { BrandedCovariant } from '../../utils/branded'

export type TimeZone = BrandedCovariant<string, 'time-zone'>

export function createTimeZone(s: string): TimeZone {
  return s as TimeZone
}

export const timeZoneUTC = createTimeZone('UTC')

// This is not an actual IANA but a flag to use the local formatting.
export const timeZoneLocal = createTimeZone('LOCAL')
