import { QUERY_PARAMETER_PORTCALL, QUERY_PARAMETER_VESSEL, QUERY_PARAMETER_SCHEDULE } from './const'
import { SELECTED_PORTCALL, SELECTED_VESSEL, SELECTED_SCHEDULE, Selection } from './Selected'

export function selectedToQueryParameters(selected: Selection): string {
  type QueryParameter = [string, string]
  const queryParameters: QueryParameter[] = []
  if (selected.type === SELECTED_PORTCALL) {
    queryParameters.push([QUERY_PARAMETER_PORTCALL, selected.portcallId])
  } else if (selected.type === SELECTED_VESSEL) {
    queryParameters.push([QUERY_PARAMETER_VESSEL, selected.vesselId])
  } else if (selected.type === SELECTED_SCHEDULE) {
    queryParameters.push([QUERY_PARAMETER_SCHEDULE, selected.portcallIdForSchedule])
  } else {
    const exhaustive: never = selected
    throw new Error(exhaustive)
  }

  if (queryParameters.length === 0) {
    return ''
  }
  return `?${queryParameters.map(([key, value]) => `${key}=${encodeURIComponent(value)}`).join('&')}`
}
