import { chunk, flatten } from 'lodash'

import { Trampoline, done, running, run } from './trampoline'

function iter<A, B>(
  toProcess: A[],
  batchSize: number,
  numberOfSimultaneousProcesses: number,
  fn: (batchToProcess: A[]) => Promise<B[]>,
  acc: Promise<B[]>
): Trampoline<Promise<B[]>> {
  if (toProcess.length === 0) {
    return done(acc)
  }

  const numberOfItemsToFetchNow = batchSize * numberOfSimultaneousProcesses
  const itemsToFetchNow = toProcess.slice(0, numberOfItemsToFetchNow)
  const itemsToFetchLater = toProcess.slice(numberOfItemsToFetchNow)
  const batches = chunk(itemsToFetchNow, batchSize)
  const nextAcc = acc.then(alreadyProcessed => {
    const fetchedBatchesPromise = Promise.all(batches.map(fn))
    const fetchedItemsPromise = fetchedBatchesPromise.then(fetchedBatches => flatten(fetchedBatches))
    return fetchedItemsPromise.then(fetchedItems => [...alreadyProcessed, ...fetchedItems])
  })
  return running(() => iter(itemsToFetchLater, batchSize, numberOfSimultaneousProcesses, fn, nextAcc))
}

export function batchedProcess<A, B>(
  toProcess: A[],
  batchSize: number,
  numberOfSimultaneousProcesses: number,
  fn: (batchToProcess: A[]) => Promise<B[]>
): Promise<B[]> {
  return run(() => iter(toProcess, batchSize, numberOfSimultaneousProcesses, fn, Promise.resolve([])))
}
