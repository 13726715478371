import { mapStackTrace } from 'sourcemapped-stacktrace/dist/sourcemapped-stacktrace'

import { LOG_FRONTEND_ERRORS, SOURCE_VERSION } from '../config'
import { APP_ERRORS } from '../constants/errors'
import { logError } from '../shared/utils/logError'
import {
  REDUX_STORAGE_SAVE,
  WEBSOCKET_VISIT_UPDATED_EVENT_IDS,
  WEBSOCKET_VISIT_DELETED_EVENT_IDS,
} from '../store/actionTypes'

import type { AppAction } from '../modules/App/App.actions'
import type { Dispatch, Middleware } from 'redux'

let errorLogged = false

// save the last `ACTION_HISTORY_AMOUNT` actions for logging purposes
const ACTION_HISTORY_AMOUNT = 30
let ACTION_HISTORY: string[] = []

// exclude the following actions from logging to the backend in case of an error
const EXCLUDED_ACTION_HISTORY = [
  REDUX_STORAGE_SAVE,
  WEBSOCKET_VISIT_UPDATED_EVENT_IDS,
  WEBSOCKET_VISIT_DELETED_EVENT_IDS,
]
const EXCLUDED_ERRORS_FROM_LOGGING = [APP_ERRORS.LOGIN_EXPIRED]

function stacktraceAsArray(error: Error): Promise<string[]> {
  const { stack } = error

  if (!stack) {
    return Promise.resolve([])
  }

  return new Promise<string[]>(resolve => {
    mapStackTrace(stack, resolve)
  })
}

declare global {
  interface WindowEventMap {
    unhandledrejection: PromiseRejectionEvent
  }
}

export const errorLoggerMiddleware: Middleware = () => (next: Dispatch<AppAction>) => {
  // on global js error -> log it
  window.onerror = (event: Event | string, source?: string, fileno?: number, columnNumber?: number, error?: any) => {
    // Ignore Chrome video internal error: https://crbug.com/809574
    // Also see https://code.forksand.com/odoo/odoo/commit/535da9e
    if (event === 'ResizeObserver loop limit exceeded') {
      return
    }

    let errorMessage: string
    try {
      errorMessage = `${(error && error.message) || JSON.stringify(event)} ${
        error && error.response && error.response.url
      }`
    } catch (err) {
      errorMessage = `Something went wrong while trying to stringify the event in the errorLoggerMiddleware. Response url: ${error?.response?.url}`
    }
    const appErrorMessage: IAppErrorMessage = {
      error: errorMessage,
      reference: 'errorLoggerMiddleware:window.onerror',
    }
    onGlobalError(appErrorMessage, source, fileno, columnNumber, error)
  }

  // Sometimes, errors are hidden deep inside Promises.
  // With this listener we can catch those errors and log them to the backend.
  // These type of errors will result in the grey screen of death in production.
  // In development mode you will see a React Error message.
  window.addEventListener('unhandledrejection', (event: PromiseRejectionEvent) => {
    event.preventDefault()

    // Because React will (potentially) throw these errors hundreds of times, we keep track with a small toggle
    // that determines if an error is already logged. When a lot of rendering is going on it is possible that suchs
    // an error is thrown a lot. So we only log it once to prevent noise in our error log.
    if (!errorLogged) {
      errorLogged = true
      // Only the error callback will give the error.
      // We ignore the success callback with `null`.
      event.promise.then(null, async (error: Error) => {
        const stackTrace = await stacktraceAsArray(error)
        const message = `Message: ${error.message} - Reason: ${event.reason}`
        errorLogger(message, stackTrace, window.navigator.userAgent, ACTION_HISTORY)
      })
    }

    return false
  })

  return (action: any) => {
    // build up a history of previous redux actions to be able to log them later on
    ACTION_HISTORY = logAction(action.type, ACTION_HISTORY)

    // use try/catch to intercept errors from redux actions
    try {
      return next(action)
    } catch (e) {
      const errorMessage = JSON.stringify(e)
      const stackTracePromise = stacktraceAsArray(e)

      stackTracePromise.then(stackTrace =>
        errorLogger(errorMessage, stackTrace, window.navigator.userAgent, ACTION_HISTORY)
      )

      // return the error to let the browser handle it as usual
      return e
    }
  }
}

// remember the last <ACTION_HISTORY_AMOUNT> redux actions for debugging purposes
const logAction = (actionType: string, history: string[]) => {
  const actionNotExcluded = actionType && EXCLUDED_ACTION_HISTORY.indexOf(actionType) === -1

  return actionNotExcluded ? [actionType, ...history].slice(0, ACTION_HISTORY_AMOUNT) : history
}

interface IAppErrorMessage {
  reference: string
  error: any
}

// `errorMsg` could really be any
// we stringify `errorMsg` to get nice error messages
export const onGlobalError = (
  errorMsg: IAppErrorMessage,
  filename?: string,
  lineno?: number,
  colno?: number,
  error?: Error
) => {
  const stackTracePromise = stacktraceAsArray(error || new Error())
  stackTracePromise.then(stackTrace => {
    const errorMessageWithLocation = `${JSON.stringify(
      errorMsg
    )} (in file ${filename} at line ${lineno} and column ${colno})`
    errorLogger(errorMessageWithLocation, stackTrace, window.navigator.userAgent, ACTION_HISTORY)
  })

  // reset action history on error to build up a clean history
  ACTION_HISTORY = []

  // return false to let the browser handle the error as usual
  return false
}

const errorLogger = (message: string, stacktrace: string[], userAgent: string, actionHistory: string[]) => {
  // check if the error is not in our blacklist
  const errorNotExcludedFromLogging =
    EXCLUDED_ERRORS_FROM_LOGGING.find(excludedError => message.indexOf(excludedError) > -1) === undefined
  const url = window.location.href

  if (errorNotExcludedFromLogging) {
    if (LOG_FRONTEND_ERRORS) {
      logError({ message, sourceVersion: SOURCE_VERSION, stacktrace, userAgent, actionHistory, url })
    } else {
      // eslint-disable-next-line no-console
      console.error({ message, sourceVersion: SOURCE_VERSION, stacktrace, userAgent, actionHistory, url })
    }
  }
}
