import { AIS_BACKEND_URL, PRONTO_BACKEND_URL } from '../config'

export const BACKEND_URL = PRONTO_BACKEND_URL
// strip trailing slash
export const AIS_URL = AIS_BACKEND_URL.replace(/\/+$/, '')
export const WEBSOCKET_URL = `${PRONTO_BACKEND_URL.replace(/^http/, 'ws')}/api/websocket`

// MapBox tile set for the map
export const PRONTO_MAPBOX_DEFAULT_PADDING = 80
export const DEFAULT_ZOOM_LEVEL = 8
export const MIN_ZOOM_LEVEL = 0 // Specified in documentation of Mapbox
export const MAX_ZOOM_LEVEL = 24 // Specified in documentation of Mapbox

// We don't want to zoom in too far when fitting to coordinates
// (when the vessels are very close to each other, for example,
// we still want to give the user a sense of his place on the map).
export const FIT_TO_COORDINATES_MAX_ZOOM_LEVEL = 12

export const SECOND = 1000 // second in milliseconds
export const MINUTE = 60 * SECOND // minute in milliseconds
export const HOUR = 60 * MINUTE // hour in milliseconds
export const DAY = 24 * HOUR // day in milliseconds
export const WEEK = 7 * DAY // week in milliseconds
export const MONTH = 30 * DAY // week in milliseconds
export const YEAR = 365 * DAY // week in milliseconds
export const MINUTES_PER_HOUR = HOUR / MINUTE

export const HOURS_PER_YEAR = YEAR / HOUR
export const HOURS_PER_MONTH = MONTH / HOUR
export const AVERAGE_HOURS_PER_MONTH = YEAR / 12 / HOUR
export const HOURS_PER_DAY = DAY / HOUR

export const NOT_AVAILABLE = 'N/A'

export const HISTORIC_DATA_POINT = 'historic'
export const PREDICTED_DATA_POINT = 'predicted'
