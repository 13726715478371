import { defaultPortForFilters } from '../../../ApplicationState/Filters/constants'
import { BerthUuid } from '../../../Domain/NauticalLocations/IBerth'
import { Unlocode } from '../../../Domain/NauticalLocations/IPort'
import { EventPortcallId } from '../../../Domain/Portcall/IPortcall'
import { ScheduleId } from '../../../Domain/Schedule/ScheduleId'
import { TerminalUuid } from '../../../Domain/Terminals/TerminalUuid'
import { DefaultFilters } from '../../../Domain/Users/ClientPreferences/DefaultFilters'
import { nilUuid, NilUuid } from '../../../Domain/Uuid/Uuid'
import { IShipId } from '../../../Domain/VesselDetails/IVesselDetails'
import { Carrier } from '../../../shared/interfaces/filters/Carrier'
import { AgentName } from '../../../shared/interfaces/filters/IAgent'
import { IDateRange } from '../../../shared/interfaces/filters/IFilters'
import { None, none, StrictNull } from '../../../utils/strictNull'

export type DefaultFiltersJson = Readonly<{
  port?: Unlocode // Was replaced by property `ports`. We keep it around for a while for backwards compatibility.
  ports?: Unlocode[] // Optional, because we might be dealing with an old version of this object.
  ship?: IShipId
  terminalUuids: Array<TerminalUuid | NilUuid>
  berthUuids: BerthUuid[]
  agents: AgentName[]
  carriers: Carrier[]
  dateRange: IDateRange
  portcallIds: EventPortcallId[]
  schedules?: ScheduleId[]
}>

function getPortsFromJson(json: DefaultFiltersJson): Unlocode[] {
  if (json.ports !== undefined) {
    return json.ports
  }

  if (json.port !== undefined) {
    return [json.port]
  }

  // Better than nothing:
  return [defaultPortForFilters]
}

export function defaultFiltersFromJson(json: DefaultFiltersJson): DefaultFilters {
  return {
    ports: getPortsFromJson(json),
    ship: json.ship || none,
    terminalUuids: json.terminalUuids.map<TerminalUuid | None>(terminalUuidOrNull =>
      terminalUuidOrNull === nilUuid ? none : terminalUuidOrNull
    ),
    berthUuids: json.berthUuids,
    agents: json.agents,
    carriers: json.carriers,
    dateRange: json.dateRange,
    portcallIds: json.portcallIds,
    schedules: json.schedules || [],
  }
}

export function defaultFiltersToJson(defaultFilters: DefaultFilters): DefaultFiltersJson {
  return {
    ports: defaultFilters.ports,
    ship: defaultFilters.ship || undefined,
    terminalUuids: defaultFilters.terminalUuids.map<TerminalUuid | NilUuid>(terminalUuidOrNone =>
      StrictNull.orElse(terminalUuidOrNone, nilUuid)
    ),
    berthUuids: defaultFilters.berthUuids,
    agents: defaultFilters.agents,
    carriers: defaultFilters.carriers,
    dateRange: defaultFilters.dateRange,
    portcallIds: defaultFilters.portcallIds,
    schedules: defaultFilters.schedules,
  }
}
