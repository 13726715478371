import { notificationsCacheDefinition } from '../lib/asyncSelector/notificationsCacheDefinition'
import { PORTCALLS_CACHE_ID } from '../lib/asyncSelector/portcallsCacheDefinition'
import { pushToQueue } from '../lib/asyncSelector/queueActions'
import { SHIP_LOCATIONS_CACHE_ID } from '../lib/asyncSelector/shipLocationsCacheDefinition'
import { expireAll } from '../modules/CacheQueue/IExpireAll'
import { ACTUAL_HYDROMETEO_OBSERVATIONS_CACHE_ID } from '../modules/HydrometeoObservations/Actual/actualHydrometeoObservationsCacheDefinition'
import { PREDICTED_WIND_DIRECTION_CACHE_KEY } from '../modules/HydrometeoObservations/Predicted/Wind/predictedWindDirectionCacheDefinition'
import { PREDICTED_WIND_SPEED_CACHE_KEY } from '../modules/HydrometeoObservations/Predicted/Wind/predictedWindSpeedCacheDefinition'
import { PORTCALL_IDS_CACHE_ID } from '../modules/portcallIdsCache/portcallIdsCacheDefinition'
import { SECOND, MINUTE } from '../shared/constants'

import { clearEvery, dispatchEvery } from './every'
import { ProntoStore } from './storeTypes'
import { TRACE_CACHE_ID } from './traces/traceCacheDefinition'

export const scheduleCacheUpdates = (store: ProntoStore) => {
  const PREDICTED_WIND_CACHE_CLEAR_RATE = 5 * MINUTE // They should be cleared together

  clearEvery(store, 30 * SECOND)(SHIP_LOCATIONS_CACHE_ID)
  clearEvery(store, 5 * MINUTE)(TRACE_CACHE_ID)
  clearEvery(store, 2 * MINUTE)(ACTUAL_HYDROMETEO_OBSERVATIONS_CACHE_ID)
  clearEvery(store, PREDICTED_WIND_CACHE_CLEAR_RATE)(PREDICTED_WIND_DIRECTION_CACHE_KEY)
  clearEvery(store, PREDICTED_WIND_CACHE_CLEAR_RATE)(PREDICTED_WIND_SPEED_CACHE_KEY)

  // Refresh all portcalls every six minutes
  // Also refresh the portcall id's every six minutes
  // Also refresh the notifications every six minutes
  // Because these two things depend on each other, space those 2 minutes apart,
  // to prevent them from interfering with each other
  clearEvery(store, 6 * MINUTE, 0 * MINUTE)(PORTCALL_IDS_CACHE_ID)

  dispatchEvery(store, 6 * MINUTE, 2 * MINUTE)(pushToQueue(PORTCALLS_CACHE_ID, expireAll(PORTCALLS_CACHE_ID)))
  dispatchEvery(
    store,
    6 * MINUTE,
    4 * MINUTE
  )(pushToQueue(notificationsCacheDefinition.cacheId, expireAll(notificationsCacheDefinition.cacheId)))
}
