import { __DEV__ } from '../../constants/environment'
import { ordering as requestStateOrdering } from '../asyncSelector/RequestState'

import { Limiter } from './Limiter'

export function fixedSizeLimiter<Key, Result, Meta>(
  maxSize: number,
  confirmSingleSizeCache?: boolean
): Limiter<Key, Result, Meta> {
  if (__DEV__ && maxSize < 2 && confirmSingleSizeCache !== true) {
    // If the cache size is set to 1 this might cause showing unwanted spinners and/or unmounting components.
    // If you expect automatic updates the minimum maxSize should be 2

    // 1. This is how the size would look like when it has a result:
    // [RESULT_RECEIVED]

    // 2. When new data is requested, previous data is set to expired, and a new one items is set as awaiting:
    // [RESULT_EXPIRED, AWAITING_RESULT]

    // 3. However, if the cache item size is only 1, the expired cache item will be thrown away, leaving only the awaiting result item
    // [AWAITING_RESULT]

    // 4. And it will stay like this until result is received (showing spinners or unmounting components)
    // [RESULT_RECEIVED]

    throw Error('Please confirm that you understand the consequences of using a single size cache.')
  }

  return cacheItems => {
    return cacheItems
      .sort((left, right) => requestStateOrdering(left.requestState, right.requestState))
      .slice(0, maxSize)
  }
}
