import { AppAction } from '../../modules/App/App.actions'
import { SYSTEM_DEFAULT_FILTER_STATE } from '../../modules/Filters/Filters.constants'
import { IFilters } from '../../shared/interfaces/filters/IFilters'
import { APPLY_FILTERS, RELOAD_FILTERS } from '../actionTypes'

import { getActiveFiltersForUser } from './getActiveFiltersForUser'

const filtersReducers = (state = SYSTEM_DEFAULT_FILTER_STATE, action: AppAction): IFilters => {
  switch (action.type) {
    case RELOAD_FILTERS: {
      const { user } = action
      const activeFilters = getActiveFiltersForUser(user, state)

      return {
        ...activeFilters,
      }
    }
    // Reset filters and apply filters do the same thing reducer wise
    // Apply filters will also reload data in views. Where Reset filters only resets the state
    case APPLY_FILTERS: {
      return {
        ...action.filters,
      }
    }
    default:
      return state
  }
}

export default filtersReducers
