import ReactDOM from 'react-dom'

/*
 * NOTE: Lazy loading and chunks confuse mini-css-plugin
 * Files are imported early to satisfy the plugin
 */

import './components/Tooltip/Tooltip.module.scss'
import './components/Header/HeaderLogo/HeaderLogo.module.scss'
import './components/Header/PrimaryMenu/PrimaryMenu.module.scss'
import './modules/WebsocketStatus/WebsocketStatus.module.scss'

import { App } from './modules/App/App'

ReactDOM.render(<App />, document.getElementById('root'))
