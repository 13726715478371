import { None, none, StrictNull } from '../../utils/strictNull'

import { EventWithOptionalEventTime, Event } from './Event'

export function firstEventWithEventTime(events: EventWithOptionalEventTime[] | undefined): Event | None {
  if (events === undefined) {
    return none
  }

  return events.find(StrictNull.hasProperties('eventTime')) || none
}

type DefinedEventTime = Readonly<{ eventTime: string }>
type UndefinedEventTime = Partial<DefinedEventTime>

const isDefinedEventTime = <B>(a: UndefinedEventTime): a is B => 'eventTime' in a

export function firstEventWithDefinedEventTime<A extends UndefinedEventTime>(events: A[] | undefined) {
  if (events === undefined) {
    return none
  }

  const result = events.find(ev => 'eventTime' in ev)
  return result && isDefinedEventTime<A & DefinedEventTime>(result) ? result : none
}
