import { partition } from 'lodash'

import { IPortcall } from '../Domain/Portcall/IPortcall'
import { portcallStartForVisit, portcallEndForVisit } from '../shared/utils/visit'

import { None, none } from './strictNull'

function partitionPastCurrentFuture<P extends IPortcall>(portcalls: P[], now: Date): [P[], P[], P[]] {
  const [current, nonCurrent] = partition(portcalls, portcall => {
    return (
      portcallStartForVisit(portcall).valueOf() <= now.valueOf() &&
      now.valueOf() <= portcallEndForVisit(portcall).valueOf()
    )
  })

  const [past, future] = partition(nonCurrent, portcall => {
    return portcallStartForVisit(portcall).valueOf() <= now.valueOf()
  })

  return [past, current, future]
}

function sortByEndDescending<P extends IPortcall>(portcalls: P[]): P[] {
  return portcalls.sort((left, right) => {
    const rightEnd = portcallEndForVisit(right)
    const leftEnd = portcallEndForVisit(left)
    return rightEnd && leftEnd ? rightEnd.valueOf() - leftEnd.valueOf() : 0
  })
}

function sortByStartAscending<P extends IPortcall>(portcalls: P[]): P[] {
  return portcalls.sort((left, right) => {
    const leftStart = portcallStartForVisit(left)
    const rightStart = portcallStartForVisit(right)
    return leftStart && rightStart ? leftStart.valueOf() - rightStart.valueOf() : 0
  })
}

// We prefer portcalls that are ongoing.
// If there are none, we prefer the next portcall in the future.
// If there are none, we prefer the most recent portcall in the past.
export function getMostRelevantPortcall<P extends IPortcall>(portcalls: P[], now: Date): P | None {
  const [portcallsInPast, ongoingPortcalls, portcallsInFuture] = partitionPastCurrentFuture(portcalls, now)
  return (
    ongoingPortcalls[0] || sortByStartAscending(portcallsInFuture)[0] || sortByEndDescending(portcallsInPast)[0] || none
  )
}
