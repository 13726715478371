import createDebug from 'debug'
import { Middleware } from 'redux'
import * as storage from 'redux-storage'
import debounce from 'redux-storage-decorator-debounce'
import createEngine from 'redux-storage-engine-localstorage'

import { STORAGE_NAME } from '../constants/storage'
import { onGlobalError } from '../middlewares/errorLogger'
import { IAppState } from '../modules/App/interfaces/IAppState'

export const CURRENT_VERSION = 21

export const debugVersioning = createDebug('pronto:versioning')

const engine = createEngine(STORAGE_NAME)

export const prontoEngine = {
  load: () =>
    engine.load().then(
      stateFromLocalStorage => {
        const versionInLocalStorage = stateFromLocalStorage && stateFromLocalStorage.version
        if (versionInLocalStorage === undefined || versionInLocalStorage < CURRENT_VERSION) {
          debugVersioning('Ignoring state in local storage, because the state shape has changed since then')
          return {}
        }
        // It's the current version.
        return stateFromLocalStorage
      },
      error => {
        onGlobalError({
          error,
          reference: 'versionedEngine.load()',
        })
      }
    ),
  save: ({ version, filters, config }: IAppState) =>
    /*
     * replicating the logic of  https://github.com/react-stack/redux-storage-decorator-filter
     * by monkey patching the engine.save method and return the desired state
     *
     */
    engine.save({
      version,
      filters,
      config,
    }),
}

// Defines the time without actions that would cause the store to be written to local storage.
// If it's a very "busy" time (with loads of actions), nothing is written to local storage until
// it's "quiet" again (`debounceTimeInMilliseconds` without actions).
const debounceTimeInMilliseconds = 1500

// Because most of times in Synchronizer are "busy" times (see comment above), we want a guarantee that
// the store is written to local storage at least once every 5 seconds.
const maximumWaitTimeInMilliseconds = 5000

export const storageMiddleWare: Middleware = storage.createMiddleware(
  debounce(prontoEngine, debounceTimeInMilliseconds, maximumWaitTimeInMilliseconds)
)
