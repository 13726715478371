import { SPIRE_VESSEL_TYPE_UNKNOWN } from '../../Api/VesselMasterData/vesselDetailsFromVesselMasterDataJson'
import { NOT_AVAILABLE } from '../../constants'
import { None, none } from '../../utils/strictNull'
import { NilUuid, nilUuid, Uuid } from '../Uuid/Uuid'

import { ShipType } from './ShipType'

export interface IVesselDetails {
  name: string
  ship: IShipId
  callSign: string | None
  shipType: ShipType | None
  maxDraughtDerived: number | None
  lengthOverall: number | None
  beam: number | None
  aisPositionAllowed: boolean
  outOfService: boolean
  isDeepSeaVessel: boolean
  photoUrl: string | None
  id: Uuid | NilUuid
  imo: IMO | None
  mmsi: MMSI | None
  eni: ENI | None
  uscg: USCG | None
  flag: string | None
  statCodeDerived: string | None
  airDraught: number | None
  aisPositionAllowedReason: string | None
  aisPositionSensitive: boolean | None
  invalid: boolean
  commercialOwner: string | None
  spireVesselType: string
  spireSubtype: string | None
  iceClass: string | None
  vesselNameDate: Date | None
  builtYear: number | None
  builder: string | None
  classSociety: string | None
  dwt: number | None
  grossTonnage: number | None
  netTonnage: number | None
  tpc: number | None
  depth: number | None
  displacement: number | None
  liquidCapacity98Pcnt: number | None
  grainCapacity: number | None
  mainEngineDesigner: string | None
  mainEngine: string | None
  engineCapacity: number | None
  capacityContainers: number | None
}

export type IMO = string
export type ENI = string
export type MMSI = string
export type USCG = string

export interface IShipId {
  // At least one of the following identifiers must be provided, preferably IMO
  imo?: IMO
  eni?: ENI
  mmsi?: MMSI
  uscg?: USCG

  name?: string
}

export const VESSEL_DETAILS_EMPTY = (ship: IShipId | None): IVesselDetails => ({
  name: (ship && ship.name) || NOT_AVAILABLE,
  ship: ship || {},
  callSign: none,
  shipType: none,
  maxDraughtDerived: none,
  lengthOverall: none,
  beam: none,
  aisPositionAllowed: false,
  outOfService: false,
  isDeepSeaVessel: false,
  photoUrl: none,
  id: nilUuid,
  imo: none,
  mmsi: none,
  eni: none,
  uscg: none,
  flag: none,
  statCodeDerived: none,
  airDraught: none,
  aisPositionAllowedReason: none,
  aisPositionSensitive: none,
  invalid: none,
  commercialOwner: none,
  spireVesselType: SPIRE_VESSEL_TYPE_UNKNOWN,
  spireSubtype: none,
  iceClass: none,
  vesselNameDate: none,
  builtYear: none,
  builder: none,
  classSociety: none,
  dwt: none,
  grossTonnage: none,
  netTonnage: none,
  tpc: none,
  depth: none,
  displacement: none,
  liquidCapacity98Pcnt: none,
  grainCapacity: none,
  mainEngineDesigner: none,
  mainEngine: none,
  engineCapacity: none,
  capacityContainers: none,
})

type ShipIdentifiers = 'imo' | 'eni' | 'mmsi' | 'uscg'
const PREFERRED_IDENTIFIERS_ORDER: ShipIdentifiers[] = ['imo', 'eni', 'mmsi', 'uscg']

export const getShipId = (
  shipId: IShipId
): { identifier: ShipIdentifiers; value: Required<IShipId>[ShipIdentifiers] } => {
  const preferredIdentifier = PREFERRED_IDENTIFIERS_ORDER.find(identifier => shipId[identifier])

  if (preferredIdentifier) {
    const id = shipId[preferredIdentifier]

    if (id) {
      return {
        identifier: preferredIdentifier,
        value: id,
      }
    }
  }

  throw new Error('At least one of the ShipId identifiers must be provided')
}
