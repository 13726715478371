import { flow } from 'lodash'

import { showShipName } from '../../Domain/Timelines/utils'
import { always } from '../../utils/functions'
import { StrictNull } from '../../utils/strictNull'

import type { ActivityInfo } from '../../Domain/Timelines/Activities/Activity'
import type { ActivityName } from '../../Domain/Timelines/Activities/ActivityName'
import type { TimelineItemClassName } from '../../Domain/Timelines/TimelineItem'
import type { ActivityJson } from './Activities/ActivityJson'
import type { ITimelineJson, ITimelineServices } from './ITimelineJson'

type ActivityExtractor = (timeline: ITimelineJson) => ActivityJson[]
const fromTimeline =
  <N extends keyof ITimelineJson & ActivityName>(n: N) =>
  (timeline: ITimelineJson): ITimelineJson[N] =>
    timeline[n]
const fromServices =
  <N extends keyof ITimelineServices & ActivityName>(n: N) =>
  (timeline: ITimelineJson) =>
    timeline.services[n]
const fromLocation =
  (s: string) =>
  ({ info }: { info: ActivityInfo }) =>
    StrictNull.fold(info.location, l => `${l} / ${s}`, s)
const oneToMany = <A>(a: A | null | undefined): A[] => (a !== null && a !== undefined ? [a] : [])
const many = <A>(a: A[] | null | undefined): A[] => (a !== null && a !== undefined ? a : [])

/**
 * Determines the logical groups in a services timeline.
 */
export enum ActivityGroup {
  PreviousNextVessel,
  // Includes inbound, alongside, shift and outbound.
  // Also includes cargo because we want to show that
  // as close as possible to the corresponding alongside.
  PlanningAndCargo,
  Pilot,
  Other,
}

export const activityGroupOrdering: Record<ActivityGroup, number> = {
  [ActivityGroup.PreviousNextVessel]: 0,
  [ActivityGroup.PlanningAndCargo]: 1,
  [ActivityGroup.Pilot]: 2,
  [ActivityGroup.Other]: 3,
}

/**
 * Defines a type of activity (like the `inbound` activity).
 */
export interface IActivityDefinition {
  name: ActivityName
  className: TimelineItemClassName
  label: (activity: { info: ActivityInfo }) => string
  extractor: ActivityExtractor

  // Group and priority determine the group in which the services
  // are shown in the services timeline, and the order in which they
  // are stacked.
  group: ActivityGroup
  priority: number
}

/**
 * Defines all possible activities.
 */
export const SERVICES_ACTIVITY_DEFINITIONS: IActivityDefinition[] = [
  {
    name: 'inbound',
    className: 'inbound',
    label: always('Inbound'),
    extractor: flow(fromTimeline('inbound'), oneToMany),
    group: ActivityGroup.PlanningAndCargo,
    priority: 1,
  },
  {
    name: 'inboundAnchorArea',
    className: 'anchoring',
    label: fromLocation('Anchoring'),
    extractor: flow(fromTimeline('inboundAnchorArea'), many),
    group: ActivityGroup.PlanningAndCargo,
    priority: 1,
  },
  {
    name: 'pilotSailing',
    className: 'pilot',
    label: always('Pilot on Board'),
    extractor: flow(fromTimeline('pilotSailing'), many),
    group: ActivityGroup.Pilot,
    priority: 1,
  },
  {
    name: 'tugs',
    className: 'tug',
    label: showShipName('Tugs'),
    extractor: flow(fromTimeline('tugs'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'linesmen',
    className: 'mooring',
    label: always('Mooring'),
    extractor: flow(fromTimeline('linesmen'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'alongside',
    className: 'alongside',
    label: fromLocation('Alongside'),
    extractor: flow(fromTimeline('alongside'), many),
    group: ActivityGroup.PlanningAndCargo,
    priority: 1,
  },
  {
    name: 'shift',
    className: 'shift',
    label: always('Shift'),
    extractor: flow(fromTimeline('shift'), many),
    group: ActivityGroup.PlanningAndCargo,
    priority: 2,
  },
  {
    name: 'surveyorService',
    className: 'surveyor',
    label: showShipName('Surveyor'),
    extractor: flow(fromServices('surveyorService'), many),
    group: ActivityGroup.PlanningAndCargo,
    priority: 3,
  },
  {
    name: 'cargoOperation',
    className: 'cargo',
    label: showShipName('Cargo Operations'),
    extractor: flow(fromTimeline('cargoOperation'), many),
    group: ActivityGroup.PlanningAndCargo,
    priority: 3,
  },
  {
    name: 'bunkerOperation',
    className: 'bunker',
    label: showShipName('Bunkers'),
    extractor: flow(fromTimeline('bunkerOperation'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'wasteService',
    className: 'service',
    label: showShipName('Waste'),
    extractor: flow(fromServices('wasteService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'bunkerPWService',
    className: 'service',
    label: showShipName('Bunker PW'),
    extractor: flow(fromServices('bunkerPWService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'slopsService',
    className: 'service',
    label: showShipName('Slops'),
    extractor: flow(fromServices('slopsService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'tenderService',
    className: 'mooring',
    label: showShipName('Tender'),
    extractor: flow(fromServices('tenderService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'floatingCraneService',
    className: 'service',
    label: showShipName('Floating Crane'),
    extractor: flow(fromServices('floatingCraneService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'provisionService',
    className: 'service',
    label: showShipName('Provision'),
    extractor: flow(fromServices('provisionService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'portAuthorityService',
    className: 'service',
    label: showShipName('Port Authority'),
    extractor: flow(fromServices('portAuthorityService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'customsService',
    className: 'service',
    label: showShipName('Customs'),
    extractor: flow(fromServices('customsService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'immigrationService',
    className: 'service',
    label: showShipName('Immigration'),
    extractor: flow(fromServices('immigrationService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'outbound',
    className: 'outbound',
    label: always('Outbound'),
    extractor: flow(fromTimeline('outbound'), oneToMany),
    group: ActivityGroup.PlanningAndCargo,
    priority: 1,
  },
  {
    name: 'transit',
    className: 'transit',
    label: always('Transit'),
    extractor: flow(fromTimeline('transit'), oneToMany),
    group: ActivityGroup.PlanningAndCargo,
    priority: 1,
  },
  {
    name: 'waterSupplyService',
    className: 'service',
    label: showShipName('Water Supply'),
    extractor: flow(fromServices('waterSupplyService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
  {
    name: 'storesService',
    className: 'service',
    label: showShipName('Stores'),
    extractor: flow(fromServices('storesService'), many),
    group: ActivityGroup.Other,
    priority: 1,
  },
]
