import { subDays, max, min } from 'date-fns'
import { isEmpty } from 'lodash'

import { MAX_NUMBER_OF_VESSELS_IN_REQUESTS, HISTORY_LIMIT_IN_DAYS } from '../../Api/Ais/Constants'
import { fromPortcallsJson } from '../../Api/Portcalls/fromJson'
import { fromPredictedTraceJson, fromHistoricTraceJson } from '../../Api/Traces/fromJson'
import { HistoricTraceDataPointJson } from '../../Api/Traces/HistoricTraceJson'
import { PredictedTraceDataPointJson } from '../../Api/Traces/PredictedTraceJson'
import { PRONTO_BACKEND_URL } from '../../config'
import { IPortcall, EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { getRotation } from '../../Domain/ShipLocations/getRotation'
import { getSpeedOverGround } from '../../Domain/ShipLocations/getSpeedOverGround'
import { MMSI } from '../../Domain/VesselDetails/IVesselDetails'
import { onGlobalError } from '../../middlewares/errorLogger'
import { getTraceGranularityLevel } from '../../store/traces/traceCacheDefinition'
import { getAuthToken } from '../../utils/auth/authClientObservable'
import { batchedProcess } from '../../utils/batchedProcess'
import { None, StrictNull } from '../../utils/strictNull'
import { AIS_URL, BACKEND_URL } from '../constants'
import { IShipLocation } from '../interfaces/shipLocation/IShipLocation'
import { HistoricTraceWithOptionalLocations, PredictedTrace } from '../interfaces/shipvisit/IShipTraceDataPoint'
import { createHeaders } from '../utils/createHeaders'
import { handleJSONResponse } from '../utils/rest'

export async function fetchPortcallsByEventIds(eventPortcallIds: EventPortcallId[]): Promise<IPortcall[]> {
  return batchedProcess(eventPortcallIds, 1000, 3, async batch => {
    const authToken = await getAuthToken()
    const url = `${BACKEND_URL}/api/visits/search/json-without-events-timeline`
    const options = {
      method: 'POST',
      headers: createHeaders(authToken),
      body: JSON.stringify({
        eventPortcalls: batch,
      }),
    }

    return fetch(url, options)
      .then(response => {
        if (response.status === 400) {
          return Promise.reject({ message: "Something went wrong, the requested portcalls couldn't be found" })
        }
        return handleJSONResponse(options, response)
      })
      .then(fromPortcallsJson)
  })
}

function queryParams(parameters: Record<string, string | number | boolean | None>): string {
  const keyValuePairs = Object.keys(parameters).map(key =>
    // If the value of the parameter is none we do not add it to the query parameters
    StrictNull.fold(parameters[key], value => `${encodeURIComponent(key)}=${encodeURIComponent(`${value}`)}`, '')
  )

  return (
    keyValuePairs
      // Remove empty parameters
      .filter(pair => !isEmpty(pair))
      .join('&')
  )
}

export async function fetchPredictedRouteByPortcallIdAndLocation(
  portcallId: EventPortcallId,
  shipLocation: IShipLocation,
  showAllIntermediatePorts: boolean
): Promise<PredictedTrace> {
  if (!shipLocation.location) {
    return Promise.resolve([])
  }

  const authToken = await getAuthToken()
  const [longitude, latitude] = shipLocation.location.coordinates
  const bearing = getRotation(shipLocation)
  const speedOverGround = getSpeedOverGround(shipLocation)
  const queryParamsAsString = queryParams({
    portcallId,
    latitude,
    longitude,
    bearing,
    speedOverGround,
    showAllIntermediatePorts,
  })
  const url = `${PRONTO_BACKEND_URL}/api/routes/predicted?${queryParamsAsString}`
  const options = {
    method: 'GET',
    headers: createHeaders(authToken),
  }

  return fetch(url, options)
    .then(response => {
      if (response.status !== 200) {
        return []
      }

      return handleJSONResponse(options, response)
    })
    .then(json => json as PredictedTraceDataPointJson[])
    .then(fromPredictedTraceJson(shipLocation.mmsi))
}

/**
 * Be sure to take care to prevent traces longer than `HISTORY_LIMIT_IN_DAYS`!
 */
export async function fetchHistoricTraceByMmsi(
  mmsi: string,
  from: Date,
  to: Date,
  zoomLevel: number
): Promise<HistoricTraceWithOptionalLocations> {
  const authToken = await getAuthToken()
  const now = new Date()
  const [limitedFrom, limitedTo] = limitTrace(from, to, now)

  const url = `${AIS_URL}/vessel/${mmsi}/history?from=${limitedFrom.valueOf()}&to=${limitedTo.valueOf()}&exclPointsWithinMeters=${getTraceGranularityLevel(
    zoomLevel
  )}`
  const options = {
    method: 'GET',
    headers: createHeaders(authToken),
  }

  const start = performance.now()

  return makeHistoricTraceRequest(url, options)
    .catch(e => {
      // Log the error separately, to close in on some error that appears
      // in the `catch` below as `{}`:
      onGlobalError({
        error: {
          originalError: e,
          url,
          duration: `${performance.now() - start}ms`,
        },
        reference: 'fetchTraceByMmsi-after-handleJSONResponse',
      })
      return Promise.resolve([])
    })
    .then(fromHistoricTraceJson)
    .catch(e => {
      // Log the error separately, to close in on some error that is
      // logged elsewhere (which makes it hard to find the cause):
      onGlobalError({
        error: {
          originalError: e,
          url,
        },
        reference: 'fetchTraceByMmsi-after-fromHistoricTraceJson',
      })
      return Promise.reject(e)
    })
}

const makeHistoricTraceRequest = async (
  url: RequestInfo,
  options: RequestInit,
  tries = 0
): Promise<HistoricTraceDataPointJson[]> => {
  try {
    const response = await fetch(url, options)

    if (response.status === 404) {
      return await Promise.resolve([])
    }

    if (!response.ok) {
      throw new Error(JSON.stringify(response))
    }

    return await handleJSONResponse(options, response)
  } catch (err) {
    if (tries < 1) {
      tries++
      return makeHistoricTraceRequest(url, options, tries)
    }

    throw new Error(err)
  }
}

// We have to make sure that we don't request _historic_ traces in the _future_
// and that we don't exceed the AIS API limits.
function limitTrace(from: Date, to: Date, now: Date): [Date, Date] {
  // Because we are looking at historic traces, `traceEnd` is assumed to lie closer to
  // the current time than `traceFrom`. For that reason, it seems wise to move `traceFrom`
  // when the limit of historic traces is exceeded and not `traceEnd`.
  const limitedTo = min([to, now])
  const fromWhenMaximumHistory = subDays(limitedTo, HISTORY_LIMIT_IN_DAYS)
  const limitedFrom = max([fromWhenMaximumHistory, from])

  return [limitedFrom, limitedTo]
}

export function fetchHistoricTracesByMmsis(
  mmsis: MMSI[],
  timeframe: { start: Date; end: Date }
): Promise<HistoricTraceWithOptionalLocations> {
  if (mmsis.length === 0) {
    return Promise.resolve([])
  }

  const now = new Date()

  return batchedProcess(
    mmsis,
    MAX_NUMBER_OF_VESSELS_IN_REQUESTS,
    3,
    async (batch): Promise<HistoricTraceWithOptionalLocations> => {
      const authToken = await getAuthToken()
      const [limitedFrom, limitedTo] = limitTrace(timeframe.start, timeframe.end || now, now)

      const url = `${AIS_URL}/vessels/history?from=${limitedFrom.valueOf()}&to=${limitedTo.valueOf()}`
      const options = {
        method: 'POST',
        headers: createHeaders(authToken),
        body: JSON.stringify(batch),
      }

      return fetch(url, options)
        .then(response => handleJSONResponse(options, response))
        .then(json => json as HistoricTraceDataPointJson[])
        .then(points => fromHistoricTraceJson(points))
        .catch(e => {
          // Log the error separately, to close in on some error that is
          // logged elsewhere (which makes it hard to find the cause):
          onGlobalError({
            error: e,
            reference: 'fetchTracesByMmsis',
          })
          return Promise.reject(e)
        })
    }
  )
}
