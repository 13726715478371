import type { IAppState } from '../../modules/App/interfaces/IAppState'
import type { Permission } from '../../shared/interfaces/settings/Settings'

export const permissionsSelectors = (state: IAppState): Permission[] => {
  const { user } = state.auth
  return user === null || !user.permissions ? [] : user.permissions
}

export const hasPermission = (permission: Permission, permissions: Permission[]): boolean =>
  permissions.indexOf(permission) > -1

export const hasPermissions = (permissionsToCheck: Permission[], allPermissions: Permission[]): boolean =>
  permissionsToCheck.every(permissionToCheck => hasPermission(permissionToCheck, allPermissions))
