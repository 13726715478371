import { IFilters } from '../../shared/interfaces/filters/IFilters'
import { none } from '../../utils/strictNull'

export const SYSTEM_DEFAULT_FILTER_STATE: IFilters = {
  ports: [],
  ship: none,
  terminalUuids: [],
  berthUuids: [],
  agents: [],
  carriers: [],
  dateRange: {
    start: 0,
    end: 12,
    unit: 'hour',
  },
  portcallIds: [],
  schedules: [],
}
