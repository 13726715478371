import { BoundingBoxCoordinates } from './BoundingBoxCoordinates'

/**
 * What is a diagonal?
 *
 * It is the length of the diagonal of a bounding box:
 *
 *   +-----+
 *   |\    |
 *   | \<------- diagonal
 *   |  \  |
 *   |   \ |<--- deltaLat
 *   |    \|
 *   +-----+
 *      ^
 *      |
 *  deltaLong
 *
 * It is used as a measure for the size of this bounding box.
 * From the length of this diagonal we calculate an appropriate
 * zoom level for this bounding box.
 */

const MIN_ZOOM = 2
const MAX_ZOOM = 8

// The following constants are two data points for the diagonal
// for the bounding box for Rotterdam and Houston and the bounding
// box Rotterdam and Algeciras and the zoom level I thought was nice
// for these two bounding boxes. These constants are used to fit
// the zoom level curve (zoom level as function of the diagonal).
const DIAGONAL_ROTTERDAM_ALGECIRAS = 18.47621630175885
const ZOOM_LEVEL_ROTTERDAM_ALGECIRAS = 4.4
const DIAGONAL_ROTTERDAM_HOUSTON = 101.72856092055197
const ZOOM_LEVEL_ROTTERDAM_HOUSTON = 3.4

export function getAppropriateZoomLevel({ minLong, maxLong, minLat, maxLat }: BoundingBoxCoordinates): number {
  const deltaLong = Math.abs(maxLong - minLong)
  const deltaLat = Math.abs(maxLat - minLat)
  const diagonal = Math.sqrt(deltaLong * deltaLong + deltaLat * deltaLat)

  // Linear interpolation/extrapolation of the form
  //
  //   zoomLevel = a / diagonal + b
  //
  // which is "plotted" below:
  //
  //   zoomLevel
  //     +
  //     | x
  //     | x <-------- Rotterdam / Algeciras
  //     |  x
  //     |  x
  //     |   x
  //     |    xx
  //     |      xxxxx
  //     |        ^
  //     +--------|--+ diagnonal
  //              |
  //     Rotterdam / Houston
  //
  // The constants a and b are determined by solving
  // the expression above for the combination
  // DIAGONAL_ROTTERDAM_HOUSTON / ZOOM_LEVEL_ROTTERDAM_HOUSTON and
  // DIAGONAL_ROTTERDAM_ALGECIRAS / ZOOM_LEVEL_ROTTERDAM_ALGECIRAS
  // which are two equations for two unknowns.
  const a =
    (DIAGONAL_ROTTERDAM_HOUSTON *
      DIAGONAL_ROTTERDAM_ALGECIRAS *
      (ZOOM_LEVEL_ROTTERDAM_HOUSTON - ZOOM_LEVEL_ROTTERDAM_ALGECIRAS)) /
    (DIAGONAL_ROTTERDAM_ALGECIRAS - DIAGONAL_ROTTERDAM_HOUSTON)
  const b =
    (DIAGONAL_ROTTERDAM_ALGECIRAS * ZOOM_LEVEL_ROTTERDAM_ALGECIRAS -
      DIAGONAL_ROTTERDAM_HOUSTON * ZOOM_LEVEL_ROTTERDAM_HOUSTON) /
    (DIAGONAL_ROTTERDAM_ALGECIRAS - DIAGONAL_ROTTERDAM_HOUSTON)

  const zoomLevel = a / diagonal + b
  return Math.min(Math.max(zoomLevel, MIN_ZOOM), MAX_ZOOM)
}
