import { Dispatch } from 'redux'
import { AppAction } from '../modules/App/App.actions'
import { IAppState } from '../modules/App/interfaces/IAppState'
import { LOGOUT } from '../store/actionTypes'
import { prontoEngine } from '../store/storage'

// Clears localStorage upon user logging out
export const logoutMiddleware = () => (dispatch: Dispatch) => (action: AppAction) => {
  if (action.type === LOGOUT) {
    prontoEngine.save({} as IAppState)
  }

  return dispatch(action)
}
