import createDebug from 'debug'
import { Observable, EMPTY } from 'rxjs'

import { PortcallsPurpose } from '../../ApplicationState/PortcallsPurpose'
import { EventPortcallId, IPortcall } from '../../Domain/Portcall/IPortcall'
import { portcallsCacheDefinition } from '../../lib/asyncSelector/portcallsCacheDefinition'
import { setLoginExpired } from '../../store/auth/auth.actions'
import { setError } from '../../store/error/error.actions'
import { first } from '../../utils/arr'
import { none } from '../../utils/strictNull'
import { throwUnreachable } from '../../utils/throwUnreachable'
import { AppAction } from '../App/App.actions'
import { IAppState } from '../App/interfaces/IAppState'
import { handleMessage } from '../CacheQueue/createHandleMessage'
import { EXPIRE } from '../CacheQueue/IExpire'
import { EXPIRE_ALL } from '../CacheQueue/IExpireAll'
import { REQUEST_ALL } from '../CacheQueue/IRequestAll'

import { PortcallsFetcher } from './PortcallsFetcher'

const debug = createDebug('pronto:portcallQueue')

export const actionsToDispatch = (appState: IAppState): Observable<AppAction> => {
  const queue = appState.portcallMessagesQueue
  const firstMessage = first(queue)
  if (firstMessage === none) {
    return EMPTY
  }

  debug('Handling message %o', firstMessage)
  switch (firstMessage.type) {
    case EXPIRE:
    case EXPIRE_ALL:
    case REQUEST_ALL: {
      const cacheItems = portcallsCacheDefinition.cacheSelector(appState)
      const portcallsFetcher = new PortcallsFetcher()
      return handleMessage<EventPortcallId, IPortcall, AppAction, PortcallsPurpose>(
        firstMessage,
        cacheItems,
        portcallsCacheDefinition.cacheId,
        portcallsFetcher,
        n => n.eventId,
        portcallsCacheDefinition.keysAreEqual,
        error => {
          if (error && error.type === 'login-expired') {
            return [setLoginExpired()]
          }
          return [setError(error)]
        }
      )
    }
    default:
      return throwUnreachable(firstMessage)
  }
}
