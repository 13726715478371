import { Point, Polygon, MultiPolygon } from 'geojson'

import { TimeZone, createTimeZone } from '../../ApplicationState/DateTime/TimeZone'
import { stringsAreEqual, arraysAreEqual } from '../../utils/equals'
import { none, None } from '../../utils/strictNull'

import { LocationPort } from './NauticalLocationType'

import type { INauticalLocation } from './INauticalLocation'
import { PortsBasicQuery } from '../../generated/queries'
import { isGeoJsonPoint } from '../../utils/geo'
import { notFalse } from '../../utils/predicates'
import { getBoundingBoxCoordinates } from '../../utils/Geo/getBoundingBoxCoordinates'
import { getCenterOfBoundingBoxCoordinates } from '../../utils/Geo/getCenterOfBoundingBoxCoordinates'
import { getAppropriateZoomLevel } from '../../utils/Geo/getAppriopriateZoomLevel'

export type Unlocode = string & { brand: 'port-unlocode' }

export function createUnlocode(s: string): Unlocode {
  return s as Unlocode
}

export interface IPort extends INauticalLocation {
  type: typeof LocationPort
  center: Point | None
  geo: Polygon | MultiPolygon
  timeZone: TimeZone | None
}

export const Ports = {
  getTimeZone(port: IPort): TimeZone | None {
    return port.timeZone
  },
}

export const unlocode = {
  equals: stringsAreEqual,
  many: {
    equals: arraysAreEqual(stringsAreEqual),
  },
}

export const fromBasicPort = ({
  name,
  center,
  port,
  timeZone,
}: Required<PortsBasicQuery['ports']>[number]): Pick<IPort, 'name' | 'center' | 'port' | 'timeZone'> => ({
  name,
  center: center && isGeoJsonPoint(center) ? center : none,
  port,
  timeZone: timeZone ? createTimeZone(timeZone) : none,
})

export function getPortsCenter(ports: Pick<IPort, 'center'>[]) {
  if (ports.length === 1 && ports[0].center) {
    const [lon, lat] = ports[0].center.coordinates
    const center: [number, number] = [lon, lat]

    return { center }
  }

  const bounds = getBoundingBoxCoordinates(
    ports.map(({ center }) => (center ? center.coordinates : none)).filter(notFalse)
  )

  if (bounds === none) {
    return none
  }

  return { center: getCenterOfBoundingBoxCoordinates(bounds), zoomLevel: getAppropriateZoomLevel(bounds) }
}

export const portNameWithCode = (port?: Pick<IPort, 'name' | 'port'>) => (port ? `${port.name} (${port.port})` : port)
