import { EventPortcallId } from '../../../Domain/Portcall/IPortcall'
import { VesselId } from '../../../Domain/VesselDetails/VesselId'

export const SELECTED_PORTCALL = 'SELECTED_PORTCALL'
type SelectedPortcall = Readonly<{
  type: typeof SELECTED_PORTCALL
  portcallId: EventPortcallId
}>

export function selectedPortcall(portcallId: EventPortcallId): SelectedPortcall {
  return {
    type: SELECTED_PORTCALL,
    portcallId,
  }
}

export const SELECTED_VESSEL = 'SELECTED_VESSEL'
type SelectedVessel = Readonly<{
  type: typeof SELECTED_VESSEL
  vesselId: VesselId
}>

export function selectedVessel(vesselId: VesselId): SelectedVessel {
  return {
    type: SELECTED_VESSEL,
    vesselId,
  }
}

export const SELECTED_SCHEDULE = 'SELECTED_SCHEDULE'
type SelectedSchedule = Readonly<{
  type: typeof SELECTED_SCHEDULE
  portcallIdForSchedule: EventPortcallId
}>

export function selectedSchedule(portcallIdForSchedule: EventPortcallId): SelectedSchedule {
  return {
    type: SELECTED_SCHEDULE,
    portcallIdForSchedule,
  }
}

export type Selection = SelectedSchedule | SelectedPortcall | SelectedVessel
