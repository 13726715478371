import { uniq } from 'lodash'

import { fetchShipLocationsByMmsis } from '../../rest/fetchShipLocationByMmsi'
import { mmsisAreEqual } from '../../utils/equals'
import { getMostRelevantPortcall } from '../../utils/getMostRelevantPortcall'
import { notFalse } from '../../utils/predicates'
import { None, none, StrictUndefined, StrictNull } from '../../utils/strictNull'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'
import { leftJoin } from '../maps/synchronize'

import { CacheDefinition, SelectorResult } from './CacheDefinition'
import { promiseToThunks } from './promiseToAction'
import { AsyncResult } from './Results'

import type { WarningNotification, WithTimeZone } from '../../Domain/Notifications/INotification'
import type { IPortcall } from '../../Domain/Portcall/IPortcall'
import type { IPortcallWithNotifications } from '../../Domain/Portcall/IPortcallWithNotifications'
import type { MMSI } from '../../Domain/VesselDetails/IVesselDetails'
import type { IAppState } from '../../modules/App/interfaces/IAppState'
import type { IShipLocation } from '../../shared/interfaces/shipLocation/IShipLocation'

export const SHIP_LOCATIONS_CACHE_ID = 'shiplocations'
const MAX_SHIP_LOCATIONS_CACHE_SIZE = 5

export const shipLocationsCacheDefinition = new CacheDefinition<IAppState, MMSI[], IShipLocation[]>(
  SHIP_LOCATIONS_CACHE_ID,
  fixedSizeLimiter(MAX_SHIP_LOCATIONS_CACHE_SIZE),
  mmsisAreEqual,
  appState => appState.shipLocationsCache
)

export interface IShipLocationWithPortcallAndWarningNotifications {
  portcall: IPortcall
  shipLocation: IShipLocation
  warningNotifications: WithTimeZone<WarningNotification>[]
}

export function asyncShipLocationsSelectorFunction(
  portcalls: IPortcall[],
  selectorResult: SelectorResult<IAppState, MMSI[], IShipLocation[]>
): AsyncResult<IAppState, IShipLocation[]> {
  const mmsis = uniq(portcalls.map(portcall => portcall.vesselDetails.ship.mmsi).filter(StrictUndefined.isNotUndefined))

  return selectorResult
    .getFor(mmsis)
    .orDispatch(promiseToThunks(() => fetchShipLocationsByMmsis(mmsis).catch(() => [])))
}

export function getShipLocationFromCache(
  mmsi: MMSI | None,
  selectorResult: SelectorResult<IAppState, MMSI[], IShipLocation[]>
): AsyncResult<IAppState, IShipLocation | None> {
  const mmsis = StrictNull.toArray(mmsi)
  return selectorResult
    .getFor(mmsis)
    .orDispatch(promiseToThunks(() => fetchShipLocationsByMmsis(mmsis).catch(() => [])))
    .map<IShipLocation | None>(shipLocations => {
      const shipLocation = shipLocations[0]
      if (shipLocation === undefined || shipLocation.location === undefined) {
        return none
      }

      return shipLocation
    })
}

export function asyncShipLocationsWithPortcallsAndWarningNotificationsSelectorFunction(
  portcallsWithNotifications: IPortcallWithNotifications[],
  shipLocations: IShipLocation[],
  currentTime: number | None
): IShipLocationWithPortcallAndWarningNotifications[] {
  return leftJoin(
    shipLocations,
    portcallsWithNotifications,
    sl => sl.mmsi,
    o => o.portcall.vesselDetails.ship.mmsi
  )
    .map<IShipLocationWithPortcallAndWarningNotifications | None>(o => {
      const shipLocation = o.left
      const portcalls = o.rights.map(r => r.portcall)
      const mostRelevantPortcall =
        currentTime === none ? portcalls[0] || none : getMostRelevantPortcall(portcalls, new Date(currentTime))
      if (mostRelevantPortcall === none) {
        return none
      }
      const mostRelevantPortcallWithNotifications =
        o.rights.find(r => r.portcall.eventId === mostRelevantPortcall.eventId) || none
      if (mostRelevantPortcallWithNotifications === none) {
        // This is only theoretical. If we found a `mostRelevantPortcall`, we
        // most assuredly will find a `mostRelevantPortcallWithNotifications`.
        return none
      }
      return { shipLocation, ...mostRelevantPortcallWithNotifications }
    })
    .filter(notFalse)
}
