import { EventPortcallId } from '../../Domain/Portcall/IPortcall'

export const DELAY_TERMINAL_DATA_DIALOG_CLOSED = 'DELAY_TERMINAL_DATA_DIALOG_CLOSED'
export const DELAY_TERMINAL_DATA_DIALOG_OPENED = 'DELAY_TERMINAL_DATA_DIALOG_OPENED'
export const DELAY_TERMINAL_DATA_DIALOG_ERROR = 'DELAY_TERMINAL_DATA_DIALOG_SUBMIT_ERROR'

type DelayTerminalDAtaDialogErrorState = Readonly<{
  type: typeof DELAY_TERMINAL_DATA_DIALOG_ERROR
  error: string
}>

type DelayTerminalDAtaDialogClosedState = Readonly<{
  type: typeof DELAY_TERMINAL_DATA_DIALOG_CLOSED
}>

type DelayTerminalDAtaDialogOpenState = Readonly<{
  type: typeof DELAY_TERMINAL_DATA_DIALOG_OPENED
  portcallId: EventPortcallId
}>

export type IDelayTerminalDataDialogState =
  | DelayTerminalDAtaDialogErrorState
  | DelayTerminalDAtaDialogClosedState
  | DelayTerminalDAtaDialogOpenState

export interface ICloseDelayTerminalDataDialogAction {
  type: typeof DELAY_TERMINAL_DATA_DIALOG_CLOSED
}

export interface IOpenDelayTerminalDataDialogAction {
  type: typeof DELAY_TERMINAL_DATA_DIALOG_OPENED
  id: EventPortcallId
}

export interface ISubmitErrorDelayTerminalDataDialogAction {
  type: typeof DELAY_TERMINAL_DATA_DIALOG_ERROR
}
