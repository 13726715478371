import createDebug from 'debug'

import { awaitingResultAction, resultReceivedAction, requestCancelledAction } from '../../lib/asyncSelector/Action'
import { CacheItem } from '../../lib/asyncSelector/CacheItem'
import { leftJoin } from '../../lib/maps/synchronize'
import { identity, compose } from '../../utils/functions'
import { notFalse } from '../../utils/predicates'
import { none } from '../../utils/strictNull'

import { Action } from './Actions'
import { getNonExpiredExpirables } from './getNonExpiredExpirables'
import { IExpirable, getResult } from './IExpirable'
import { IFetchJob } from './IFetchJob'

let requestId = 0
const REQUEST_PREFIX = 'FetchAllJob-'

const debug = createDebug('pronto:FetchAllJob')

/**
 * Fetch all results corresponding to a key.
 */
export class FetchAllJob<Key extends string, Result, Meta> implements IFetchJob<Key, Result, Meta> {
  public keysToFetch: Key[]

  public actionsToDispatchDirectly: Array<Action<Key, Result, Meta>>

  private readonly currentRequestId: string

  private readonly expirablesAlreadyFetched: Array<IExpirable<Result>>

  private getKeyFromExpirable = compose<[IExpirable<Result>], Result, Key>(getResult, this.toKey)

  constructor(
    private readonly keys: Key[],
    meta: Meta,
    cacheItems: Array<CacheItem<Key[], Array<IExpirable<Result>>, Meta>>,
    private readonly cacheId: string,
    private readonly toKey: (result: Result) => Key
  ) {
    // Determine which results we need:
    debug(`Will try to obtain keys [${keys.join(', ')}]`)

    // First check the cache for these results:
    const nonExpiredExpirables = getNonExpiredExpirables(cacheItems)
    const joined = leftJoin(keys, nonExpiredExpirables, identity, this.getKeyFromExpirable)
    this.expirablesAlreadyFetched = joined.map(o => o.rights[0] || none).filter(notFalse)
    debug(`Found notifications [${this.expirablesAlreadyFetched.map(this.getKeyFromExpirable).join(', ')}] in cache`)

    this.keysToFetch = joined.filter(o => o.rights.length === 0).map(o => o.left)
    debug(`Going to fetch [${this.keysToFetch.join(', ')}] from server`)

    this.currentRequestId = REQUEST_PREFIX + requestId++
    this.actionsToDispatchDirectly = [
      awaitingResultAction(this.cacheId, this.currentRequestId, this.keys, Date.now(), meta),
    ]
  }

  public actionsToDispatchWhenResultsArrive(results: Array<IExpirable<Result>>): Array<Action<Key, Result, Meta>> {
    debug(`Received [${results.map(this.getKeyFromExpirable).join(', ')}] notifications from server`)

    return [
      resultReceivedAction(
        this.cacheId,
        this.currentRequestId,
        this.keys,
        results.concat(this.expirablesAlreadyFetched),
        Date.now()
      ),
    ]
  }

  public actionsToDispatchWhenRequestFails() {
    debug(`Something went wrong. Cancelling requests for [${this.keys.join(', ')}]`)
    return [requestCancelledAction(this.cacheId, this.currentRequestId, Date.now())]
  }
}
