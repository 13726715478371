import { unlocode, Unlocode } from '../../Domain/NauticalLocations/IPort'
import { Terminal } from '../../Domain/Terminals/Terminal'
import { IAppState } from '../../modules/App/interfaces/IAppState'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'

import { CacheDefinition } from './CacheDefinition'

const TERMINALS_CACHE_ID = 'terminals'

// be aware that this value needs to be -at least- 2; otherwise you can't load different berths
// for the berth selector in the filter settings while having a different berthslist somewhere else
const MAX_TERMINALS_CACHE_SIZE = 5

export const terminalsCacheDefinition = new CacheDefinition<IAppState, Unlocode[], Terminal[]>(
  TERMINALS_CACHE_ID,
  fixedSizeLimiter(MAX_TERMINALS_CACHE_SIZE),
  unlocode.many.equals,
  appState => appState.terminalsCache
)
