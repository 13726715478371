import { Geometry, Point } from 'geojson'
import { Coordinates } from '../Domain/Coordinates/Coordinates'
import { GeoJsonType } from '../generated/queries'
import { onGlobalError } from '../middlewares/errorLogger'

import { isNumber } from './num'

export const createCoordinates = ([x, y]: number[]): Coordinates => {
  if (![x, y].every(isNumber)) {
    onGlobalError({ reference: 'createCoordinates', error: `Invalid input coordinates: {x: ${x}, y: ${y}}` })
    throw new Error(`createCoordinates - Invalid input coordinates: {x: ${x}, y: ${y}}`)
  }

  return [x, y]
}

export const isGeoJsonPoint = (p: { type: GeoJsonType; coordinates: number[] } | Geometry): p is Point =>
  p.type === 'Point'
