import { IBerth } from '../../Domain/NauticalLocations/IBerth'
import { unlocode, Unlocode } from '../../Domain/NauticalLocations/IPort'
import { IAppState } from '../../modules/App/interfaces/IAppState'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'

import { CacheDefinition } from './CacheDefinition'

const BERTHS_CACHE_ID = 'berths'

// be aware that this value needs to be -at least- 2; otherwise you can't load different berths
// for the berth selector in the filter settings while having a different berthslist somewhere else
const MAX_BERTHS_CACHE_SIZE = 5

export const berthsCacheDefinition = new CacheDefinition<IAppState, Unlocode[], IBerth[]>(
  BERTHS_CACHE_ID,
  fixedSizeLimiter(MAX_BERTHS_CACHE_SIZE),
  unlocode.many.equals,
  appState => appState.berthsCache
)
