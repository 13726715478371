import { AWAITING_RESULT, CLEAR_CACHE, CLEAR_CACHE_ITEM, RESULT_RECEIVED, REQUEST_CANCELLED } from './consts'

export interface IGenericAction {
  type: string
}

interface IAwaitingResultAction<Key, Meta> {
  type: 'AWAITING_RESULT'
  resourceId: string
  requestId: string
  key: Key
  currentTime: number
  meta: Meta
}

export function awaitingResultAction<Key, Meta>(
  resourceId: string,
  requestId: string,
  key: Key,
  currentTime: number,
  meta: Meta
): IAwaitingResultAction<Key, Meta> {
  return {
    type: AWAITING_RESULT,
    resourceId,
    requestId,
    key,
    currentTime,
    meta,
  }
}

export function isAwaitingResultAction<Key, Meta>(action: IGenericAction): action is IAwaitingResultAction<Key, Meta> {
  return action.type === AWAITING_RESULT
}

interface IResultReceivedAction<Key, Result> {
  type: 'RESULT_RECEIVED'
  resourceId: string
  requestId: string
  key: Key
  result: Result
  currentTime: number
}

export function resultReceivedAction<Key, Result>(
  resourceId: string,
  requestId: string,
  key: Key,
  result: Result,
  currentTime: number
): IResultReceivedAction<Key, Result> {
  return {
    type: RESULT_RECEIVED,
    resourceId,
    requestId,
    key,
    result,
    currentTime,
  }
}

export function isResultReceivedAction<Key, Result>(
  action: IGenericAction
): action is IResultReceivedAction<Key, Result> {
  return action.type === RESULT_RECEIVED
}

interface IRequestCancelledAction {
  type: 'REQUEST_CANCELLED'
  resourceId: string
  requestId: string
  currentTime: number
}

export function requestCancelledAction(
  resourceId: string,
  requestId: string,
  currentTime: number
): IRequestCancelledAction {
  return {
    type: REQUEST_CANCELLED,
    resourceId,
    requestId,
    currentTime,
  }
}

export function isRequestCancelledAction(action: IGenericAction): action is IRequestCancelledAction {
  return action.type === REQUEST_CANCELLED
}

interface IClearCacheAction {
  type: 'CLEAR_CACHE'
  resourceId: string
  currentTime: number
}

export function clearCacheAction(resourceId: string, currentTime: number): IClearCacheAction {
  return {
    type: CLEAR_CACHE,
    resourceId,
    currentTime,
  }
}

export function isClearCacheAction(action: IGenericAction): action is IClearCacheAction {
  return action.type === CLEAR_CACHE
}

interface IClearCacheItemAction<Key> {
  type: 'CLEAR_CACHE_ITEM'
  resourceId: string
  key: Key
  currentTime: number
}

export function clearCacheItemAction<Key>(
  resourceId: string,
  key: Key,
  currentTime: number
): IClearCacheItemAction<Key> {
  return {
    type: CLEAR_CACHE_ITEM,
    resourceId,
    key,
    currentTime,
  }
}

export function isClearCacheItemAction<Key>(action: IGenericAction): action is IClearCacheItemAction<Key> {
  return action.type === CLEAR_CACHE_ITEM
}

export type Action<Key, Result, Meta> =
  | IAwaitingResultAction<Key, Meta>
  | IResultReceivedAction<Key, Result>
  | IRequestCancelledAction
  | IClearCacheAction
  | IClearCacheItemAction<Key>

export function isAction<Key, Result, Meta>(action: IGenericAction): action is Action<Key, Result, Meta> {
  const actionTypes = [AWAITING_RESULT, RESULT_RECEIVED, REQUEST_CANCELLED, CLEAR_CACHE, CLEAR_CACHE_ITEM]
  return actionTypes.some(actionType => actionType === action.type)
}
