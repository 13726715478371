import { Preferences } from '../../ApplicationState/Preferences'

export const LOAD_PREFERENCES = 'LOAD_PREFERENCES'

export type LoadPreferencesAction = Readonly<{
  type: typeof LOAD_PREFERENCES
  preferences: Preferences
}>

export function loadPreferencesAction(preferences: Preferences): LoadPreferencesAction {
  return {
    type: LOAD_PREFERENCES,
    preferences,
  }
}
