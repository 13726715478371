import { JitSummaryStatus } from '../../Api/Portcalls/IPortcallJitSummaryJson'
import { none, None } from '../../utils/strictNull'

import type { TimeZone } from '../../ApplicationState/DateTime/TimeZone'
import type { NotificationId } from '../../modules/InfoNotifications/InfoNotifications.interfaces'
import type { IInterval } from '../../shared/interfaces/shipvisit/IBerthVisit'
import type { AnchorAreaVisit } from '../AnchorAreaVisits/AnchorAreaVisit'
import type { BerthVisit } from '../BerthVisits/BerthVisit'
import type { MostAccurates } from '../Events/MostAccurates'
import type { Movement } from '../Movements/Movement'
import type { Unlocode } from '../NauticalLocations/IPort'
import type { IPortcallStatus } from '../PortcallStatus/IPortcallStatus'
import type { ScheduleDescription } from '../Schedule/ScheduleId'
import type { ServiceVessel } from '../ServiceVessels/ServiceVessel'
import type { IVesselDetails } from '../VesselDetails/IVesselDetails'
import { EventTypeId } from '../../shared/interfaces/event/IEvent'
import { EventWithPort } from '../Events/EventWithPort'

export type EventPortcallId = string & { brand: 'event-portcall-id' }
export function createEventPortcallId(s: string): EventPortcallId {
  return s as EventPortcallId
}
export type BerthVisitId = string & { brand: 'berth-visit-id' }
export function createBerthVisitId(s: string): BerthVisitId {
  return s as BerthVisitId
}

export type JitSummary = Readonly<{
  status: JitSummaryStatus
  deviatesTooMuchFromSuggestedSpeed: boolean
}>

export const DEFAULT_JIT_SUMMARY: JitSummary = {
  status: JitSummaryStatus.NOT_AVAILABLE,
  deviatesTooMuchFromSuggestedSpeed: false,
}

export interface IPortcall {
  eventId: EventPortcallId
  arrival?: Partial<{ eventType: EventTypeId; eventTime: Date }>
  departure?: Partial<{ eventType: EventTypeId; eventTime: Date }>
  berthVisits: BerthVisit[]
  anchorAreaVisits: AnchorAreaVisit[]
  localPortcallId?: string
  timeframe?: IInterval // Could be "inconsistent" (have a start before end).
  vesselDetails: IVesselDetails
  mostAccurates: MostAccurates | None
  port: Unlocode
  movements: Movement[]
  vesselAgents: string[]
  backOfficeAgents: string[]
  carriers: string[]
  startDraft?: number
  endDraft?: number

  jitSummary: JitSummary
  portcallStatus: IPortcallStatus
  prevPorts: EventPortcallId[]
  nextPorts: EventPortcallId[]
  prevPortEnd?: EventWithPort
  services: ServiceVessel[]
  notifications: NotificationId[]
  scheduleDescription: ScheduleDescription | None

  timeZone: TimeZone | None
}

export function getVesselDetails(portcall: IPortcall) {
  return portcall.vesselDetails
}

export function getMMSI(portcall: IPortcall) {
  return (portcall.vesselDetails && portcall.vesselDetails.ship.mmsi) || none
}

const shipIdRegex = /PCE-([A-Z]+)-([a-zA-Z0-9_]+)-(.*)/

export function findShipId(eventId: string) {
  const match = eventId.match(shipIdRegex)

  return match === null ? undefined : match[2]
}
