import { ClientPreferences } from '../../../Domain/Users/ClientPreferences/ClientPreferences'
import { none } from '../../../utils/strictNull'

import { ClientPreferencesJson } from './ClientPreferencesJson'
import { defaultFiltersFromJson as fromDefaultFiltersJson } from './DefaultFiltersJson'

export function clientPreferencesFromJson(json: ClientPreferencesJson): ClientPreferences {
  if (json.defaultFilters === undefined) {
    return { defaultFilters: none }
  }
  return { defaultFilters: fromDefaultFiltersJson(json.defaultFilters) }
}
