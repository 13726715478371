import { ActivityInfo } from './Activities/Activity'
import { ActivityName } from './Activities/ActivityName'

export enum TimeFrameKind {
  WithoutStartOrEnd,
  WithoutStart,
  WithoutEnd,
  EndBeforeStart,
  EndEqualsStart,
  ActualTimeframe,
  PlannedTimeframe,
  PredictedTimeframe,
}

interface IActivityTimeFrameWithoutStartOrEnd {
  kind: TimeFrameKind.WithoutStartOrEnd
}
interface IActivityTimeFrameWithoutStart {
  kind: TimeFrameKind.WithoutStart
  start: Date
  end: Date
}
interface IActivityTimeFrameWithoutEnd {
  kind: TimeFrameKind.WithoutEnd
  start: Date
  end: Date
}
interface IActivityTimeFrameEndBeforeStart {
  kind: TimeFrameKind.EndBeforeStart
  start: Date
  end: Date
}
interface IActivityTimeFrameEndEqualsStart {
  kind: TimeFrameKind.EndEqualsStart
  start: Date
  end: Date
}
interface IActualActivityTimeFrame {
  kind: TimeFrameKind.ActualTimeframe
  start: Date
  end: Date
}
interface IPlannedActivityTimeFrame {
  kind: TimeFrameKind.PlannedTimeframe
  start: Date
  end: Date
}
interface IPredictedActivityTimeFrame {
  kind: TimeFrameKind.PredictedTimeframe
  start: Date
  end: Date
  progress: Date
}

export const activityTimeFrameWithoutStartOrEnd: IActivityTimeFrameWithoutStartOrEnd = {
  kind: TimeFrameKind.WithoutStartOrEnd,
}
export const activityTimeFrameWithoutStart = (end: Date): IActivityTimeFrameWithoutStart => ({
  kind: TimeFrameKind.WithoutStart,
  start: end,
  end,
})
export const activityTimeFrameWithoutEnd = (start: Date): IActivityTimeFrameWithoutEnd => ({
  kind: TimeFrameKind.WithoutEnd,
  start,
  end: start,
})
export const activityTimeFrameEndBeforeStart = (start: Date): IActivityTimeFrameEndBeforeStart => ({
  kind: TimeFrameKind.EndBeforeStart,
  start,
  end: start,
})
export const activityTimeFrameEndEqualsStart = (start: Date): IActivityTimeFrameEndEqualsStart => ({
  kind: TimeFrameKind.EndEqualsStart,
  start,
  end: start,
})

export const actualActivityTimeFrame = (start: Date, end: Date): IActualActivityTimeFrame => ({
  kind: TimeFrameKind.ActualTimeframe,
  start,
  end,
})

export const plannedActivityTimeFrame = (start: Date, end: Date): IPlannedActivityTimeFrame => ({
  kind: TimeFrameKind.PlannedTimeframe,
  start,
  end,
})

export const predictedActivityTimeFrame = (start: Date, end: Date, progress: Date): IPredictedActivityTimeFrame => ({
  kind: TimeFrameKind.PredictedTimeframe,
  start,
  end,
  progress,
})

export type ActivityTimeFrameWithStartAndEnd =
  | IActivityTimeFrameWithoutStart
  | IActivityTimeFrameWithoutEnd
  | IActivityTimeFrameEndBeforeStart
  | IActivityTimeFrameEndEqualsStart
  | IActualActivityTimeFrame
  | IPlannedActivityTimeFrame
  | IPredictedActivityTimeFrame

export type ActivityTimeFrame = ActivityTimeFrameWithStartAndEnd | IActivityTimeFrameWithoutStartOrEnd

export type LazyActivityTimeFrame = (d: Date) => ActivityTimeFrame

export type Activity<Timing> = Readonly<{
  type: ActivityName
  info: ActivityInfo
  timingWithProgress: Timing
}>

export type PerPartyItem<ActivityWithTiming> = Readonly<{
  party: string
  source: string
  activities: ActivityWithTiming[]
}>

export type TimelinePerPartyItem = PerPartyItem<Activity<LazyActivityTimeFrame>>

export type TimelinePerPartyItemWithProgress = PerPartyItem<Activity<ActivityTimeFrame>>
