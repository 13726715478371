import { BACKEND_URL } from '../../shared/constants'
import { createHeaders } from '../../shared/utils/createHeaders'
import { handleJSONResponse } from '../../shared/utils/rest'
import { getAuthToken } from '../../utils/auth/authClientObservable'

import { toNotifications } from './fetchNotifications'

import type { IUserNotificationJson } from '../../Api/Subscriptions/IUserNotificationJson'
import type { Unlocode } from '../../Domain/NauticalLocations/IPort'
import type { Notification } from '../../Domain/Notifications/INotification'

export const fetchPortNotifications = async (portIds: Unlocode[]): Promise<Notification[]> => {
  const authToken = await getAuthToken()
  const url = `${BACKEND_URL}/api/users/me/port-notifications`
  const options = {
    method: 'POST',
    headers: createHeaders(authToken),
    body: JSON.stringify(portIds),
  }

  return fetch(url, options)
    .then(response => handleJSONResponse(options, response) as Promise<IUserNotificationJson[]>)
    .then(toNotifications)
    .catch(() => [])
}
