import { NauticalLocation } from '../../Domain/NauticalLocations/INauticalLocation'
import { unlocode, Unlocode } from '../../Domain/NauticalLocations/IPort'
import { IAppState } from '../../modules/App/interfaces/IAppState'
import { fixedSizeLimiter } from '../Limiter/fixedSizeLimiter'

import { CacheDefinition } from './CacheDefinition'

const CACHE_ID = 'nautical_locations'
const MAX_CACHE_SIZE = 3

export const nauticalLocationCacheDefinition = new CacheDefinition<IAppState, Unlocode[], NauticalLocation[]>(
  CACHE_ID,
  fixedSizeLimiter(MAX_CACHE_SIZE),
  unlocode.many.equals,
  state => state.nauticalLocationsCache
)
