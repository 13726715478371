import { Invalid } from './Invalid'
import { entries } from './obj'
import { throwUnreachable } from './throwUnreachable'
import { Valid } from './Valid'

/**
 * A validated value is either valid (`ValidatedOk`) or there is something
 * wrong with the value (`ValidatedError`). When something is wrong, there
 * can be multiple reasons (of type `E`) why.
 */
export type Validated<E, T> = Invalid<E, T> | Valid<E, T>

type ErrorOfValidated<V extends Validated<any, any>> = V['errorType']
type ValueOfValidated<V extends Validated<any, any>> = V['valueType']

function find<T>(predicate: (t: T) => boolean): (haystack: T[]) => Validated<undefined, T>
function find<E, T>(predicate: (t: T) => boolean, error: E): (haystack: T[]) => Validated<E, T>
function find<T>(predicate: (t: T) => boolean, error?: any): (haystack: T[]) => Validated<any, T> {
  return (haystack: T[]): Validated<any, T> => {
    const match = haystack.find(predicate)
    const errors = error === undefined ? [] : [error]
    return match !== undefined ? validated.ok<any, T>(match) : validated.errors<any, T>(errors)
  }
}

function first(): <T>(ts: T[]) => Validated<undefined, T>
function first<E>(error: E): <T>(ts: T[]) => Validated<E, T>
function first(error?: any): <T>(ts: T[]) => Validated<any, T> {
  return ts => (ts.length > 0 ? validated.ok(ts[0]) : validated.error(error))
}

/**
 * Utility methods.
 */
export const validated = {
  /**
   * Construct a validated value for which validation succeeded.
   */
  ok<E, T>(value: T): Valid<E, T> {
    return new Valid<E, T>(value)
  },

  /**
   * Construct a validated value for which validation failed.
   */
  error<E, T>(error: E): Invalid<E, T> {
    return new Invalid<E, T>([error])
  },

  /**
   * Construct a validated value for which validation failed for multiple reasons.
   */
  errors<E, T>(errors: E[]): Invalid<E, T> {
    return new Invalid<E, T>(errors)
  },

  /**
   * This is a cool function, it transforms an object like
   *
   * ```
   * {
   *   prop1: Validated<E, P1>,
   *   prop2: Validated<E, P2>,
   *   ...
   * }
   * ```
   *
   * to a `Validated<E, { prop1: P1, prop2: P2, ... }>`.
   */
  all<O extends AllParamType>(o: O): AllReturnType<O> {
    const errors: any[] = []
    const values: { [K in keyof O]?: Validated<any, any> } = {}
    entries(o).forEach(([key, validatedEntry]: [keyof O, Validated<any, any>]) => {
      if (validatedEntry.type === Valid.type) {
        values[key] = validatedEntry.value
      } else if (validatedEntry.type === Invalid.type) {
        validatedEntry.errors.forEach((e: any) => {
          errors.push(e)
        })
      } else {
        throwUnreachable(validatedEntry)
      }
    })

    if (errors.length > 0) {
      return new Invalid(errors)
    }
    return new Valid(values as any)
  },

  /**
   * Split an array of `Validated<E, T>` into errors (`E[]`) and values (`T[]`).
   */
  split<E, T>(validatedEntries: Array<Validated<E, T>>): { invalid: E[]; valid: T[] } {
    const invalid: E[] = []
    const valid: T[] = []
    validatedEntries.forEach(validatedEntry => {
      if (validatedEntry.type === Valid.type) {
        valid.push(validatedEntry.value)
      } else if (validatedEntry.type === Invalid.type) {
        invalid.push(...validatedEntry.errors)
      } else {
        throwUnreachable(validatedEntry)
      }
    })

    return { valid, invalid }
  },

  withAlternatives<E, T>(
    firstAlternative: Validated<E, T>,
    ...otherAlternatives: Array<Validated<E, T>>
  ): Validated<E, T> {
    return otherAlternatives.reduce((acc, curr) => acc.orElse(curr), firstAlternative)
  },

  /**
   * Finds an item in T[] that statisfies the predicate and returns Valid<T> or Invalid when nothing is found
   */
  find,

  first,
}

type AllParamType = Record<string, Validated<any, any>>
type AllReturnType<O extends AllParamType> = Validated<
  ErrorOfValidated<O[keyof O]>,
  { [K in keyof O]: ValueOfValidated<O[K]> }
>
