import { PortcallStatusType, PortcallStatusRefId, IPortcallStatus } from '../../Domain/PortcallStatus/IPortcallStatus'
import { none } from '../../utils/strictNull'

export interface IPortcallStatusJson {
  statusType: PortcallStatusType
  fromRef?: PortcallStatusRefId
  toRef?: PortcallStatusRefId
}

export function fromPortcallStatusJson({ statusType, fromRef, toRef }: IPortcallStatusJson): IPortcallStatus {
  return {
    statusType,
    fromRef: fromRef || none,
    toRef: toRef || none,
  }
}
