import { differenceInMinutes, isAfter } from 'date-fns'
import { flatMap, head, last } from 'lodash'

import { firstEventWithEventTime } from '../../Domain/Events/firstEventWithEventTime'
import { Activity } from '../../Domain/Timelines/Activities/Activity'
import { ActivityName } from '../../Domain/Timelines/Activities/ActivityName'
import {
  PREVIOUS_VESSEL_TIMELINE_ITEM,
  NEXT_VESSEL_TIMELINE_ITEM,
  TimelineItem,
  TimelineItemCommonProperties,
  BASIC_TIMELINE_ITEM,
  TimelineItemClassName,
  ACTUAL,
  PLANNED,
} from '../../Domain/Timelines/TimelineItem'
import { Timing, toPlannedOrActualEventTime } from '../../Domain/Timelines/Timing'
import { PlannedOrActualEventTime } from '../../Domain/Timelines/TimingWithProgress'
import { many, eventTime, showShipName } from '../../Domain/Timelines/utils'
import { isActual } from '../../shared/utils/utils'
import { stableSort } from '../../utils/arr'
import { composeComparators, compareBy, createComparatorFromRecord, compareNumbersAsc } from '../../utils/ordering'
import { notFalse } from '../../utils/predicates'
import { None, StrictNull } from '../../utils/strictNull'

import { fromActivityJson } from './Activities/fromActivityJson'
import { SERVICES_ACTIVITY_DEFINITIONS, ActivityGroup, activityGroupOrdering } from './activityDefinitions'
import { ITimelineJson } from './ITimelineJson'

export const getPreviousVessels = (timeline: ITimelineJson): TimelineItem[] => {
  const firstAlongsideJson = head(many(timeline.alongside))
  if (firstAlongsideJson === undefined) {
    return []
  }
  const firstAlongside = fromActivityJson(firstAlongsideJson)

  const firstNeighbourJson = head(many(timeline.neighbours))
  if (firstNeighbourJson === undefined) {
    return []
  }
  const firstNeighbour = fromActivityJson(firstNeighbourJson)

  const endOfFirstNeighbour = eventTime(firstNeighbour.end)
  if (!endOfFirstNeighbour) {
    return []
  }

  const startOfFirstAlongside = eventTime(firstAlongside.start)
  if (!startOfFirstAlongside) {
    return []
  }

  if (isAfter(endOfFirstNeighbour, startOfFirstAlongside)) {
    return []
  }

  const exchangeTimeInMinutes = differenceInMinutes(startOfFirstAlongside, endOfFirstNeighbour)
  const previousVessel = toTimelineItem(
    'previousVessel',
    'previousVessel',
    ActivityGroup.PreviousNextVessel,
    1,
    showShipName('Previous vessel')(firstNeighbour),
    'previousVessel',
    firstNeighbour
  )

  const previousVesselItem: TimelineItem = {
    timelineItemType: PREVIOUS_VESSEL_TIMELINE_ITEM,
    exchangeTimeInMinutes,
    ...previousVessel,
  }
  return [previousVesselItem]
}

export const getNextVessels = (timeline: ITimelineJson): TimelineItem[] => {
  const lastAlongsideJson = last(many(timeline.alongside))
  if (lastAlongsideJson === undefined) {
    return []
  }
  const lastAlongside = fromActivityJson(lastAlongsideJson)

  const lastNeighbourJson = last(many(timeline.neighbours))
  if (lastNeighbourJson === undefined) {
    return []
  }
  const lastNeighbour = fromActivityJson(lastNeighbourJson)

  const startOfLastNeighbour = eventTime(lastNeighbour.start)
  if (!startOfLastNeighbour) {
    return []
  }

  const endOfLastAlongside = eventTime(lastAlongside.end)
  if (!endOfLastAlongside) {
    return []
  }

  if (isAfter(endOfLastAlongside, startOfLastNeighbour)) {
    return []
  }

  const exchangeTimeInMinutes = differenceInMinutes(startOfLastNeighbour, endOfLastAlongside)
  const nextVessel = toTimelineItem(
    'nextVessel',
    'nextVessel',
    ActivityGroup.PreviousNextVessel,
    1,
    showShipName('Next vessel')(lastNeighbour),
    'nextVessel',
    lastNeighbour
  )
  const nextVesselItem: TimelineItem = {
    timelineItemType: NEXT_VESSEL_TIMELINE_ITEM,
    exchangeTimeInMinutes,
    ...nextVessel,
  }

  return [nextVesselItem]
}

export function toTimelineItem(
  id: string,
  name: ActivityName,
  activityGroup: ActivityGroup,
  priority: number,
  content: string,
  className: TimelineItemClassName,
  activity: Activity
): TimelineItemCommonProperties & Timing {
  const startEventWithEventTime = firstEventWithEventTime(activity.start)
  const endEventWithEventTime = firstEventWithEventTime(activity.end)
  const start = StrictNull.map(startEventWithEventTime, e => toPlannedOrActualEventTime(e.eventTime, isActual(e)))
  const end = StrictNull.map(endEventWithEventTime, e => toPlannedOrActualEventTime(e.eventTime, isActual(e)))
  const timelineItem: TimelineItemCommonProperties & Timing = {
    id,
    name,
    activityGroup,
    priority,
    activity,
    start,
    end,
    content,
    className,
  }

  return timelineItem
}

export function toTimelineItems(timeline: ITimelineJson): TimelineItem[] {
  const previousVessels = getPreviousVessels(timeline)
  const nextVessels = getNextVessels(timeline)

  // Sort the activity definitions, to ensure that
  // the resulting time line items are in the right
  // order too.
  const servicesActivityDefinitionsInOrder = stableSort(
    SERVICES_ACTIVITY_DEFINITIONS,
    composeComparators(
      compareBy(ad => ad.group, createComparatorFromRecord(activityGroupOrdering)),
      compareBy(ad => ad.priority, compareNumbersAsc)
    )
  )
  return flatMap(servicesActivityDefinitionsInOrder, (activityDefinition, activityDefinitionIndex) => {
    // Extract the activities for the given `ActivityDefintion` from the timeline:
    const activitieJsons = activityDefinition.extractor(timeline)
    const activities = activitieJsons.map(fromActivityJson)

    // Transform each activity to a `IProntoTimelineItem`:
    return activities.map((activity, activityIndex): TimelineItem => {
      const item = toTimelineItem(
        `${activityDefinitionIndex}-${activityIndex}`,
        activityDefinition.name,
        activityDefinition.group,
        activityDefinition.priority,
        activityDefinition.label(activity),
        activityDefinition.className,
        activity
      )
      return {
        timelineItemType: BASIC_TIMELINE_ITEM,
        ...item,
      }
    })
  }).concat(previousVessels, nextVessels)
}

type ActivityTimeFrame = Readonly<{
  start: PlannedOrActualEventTime
  end: PlannedOrActualEventTime
}>

export function toActivityTimeFrame(activities: Activity[]): ActivityTimeFrame[] {
  return activities
    .map((a: Activity): ActivityTimeFrame | None => {
      const startEventWithEventTime = firstEventWithEventTime(a.start)
      const endEventWithEventTime = firstEventWithEventTime(a.end)

      return StrictNull.mapMany(
        (start, end) => ({
          start: isActual(start) ? { type: ACTUAL, value: start.eventTime } : { type: PLANNED, value: start.eventTime },
          end: isActual(end) ? { type: ACTUAL, value: end.eventTime } : { type: PLANNED, value: end.eventTime },
        }),
        startEventWithEventTime,
        endEventWithEventTime
      )
    })
    .filter(notFalse)
}
