/*
The contents of this files are used by the pronto-templates repository (https://github.com/portxchange/pronto-templates)
to check if the translations object contains the proper keys for translations. Every notification type in this file should have a key
in the translation object. The CI of pronto-templates will fail if there is a key defined in this file that is not present/translated there.
*/

export const INBOUND_VLCC = 'InboundVLCC'

export const ARRIVED_AT_PORT = 'ArrivedAtPort'
export const ARRIVED_AT_ANCHOR_AREA = 'ArrivedAtAnchorArea'
export const ARRIVED_AT_BERTH = 'ArrivedAtBerth'
export const BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED = 'BunkerBargeNotArrivedWhenExpected'
export const BUNKER_PLANNED_TOO_CLOSE_TO_DEPARTURE = 'BunkerPlannedTooCloseToDeparture'
export const CARGO_STARTED = 'CargoStarted'
export const CARGO_COMPLETION_UPDATED = 'CargoCompletionUpdated'
export const CARGO_NOT_STARTED_WHEN_EXPECTED = 'CargoNotStartedWhenExpected'
export const CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE = 'CargoPlannedTooCloseToDeparture'
export const DEPARTED_FROM_BERTH = 'DepartedFromBerth'
export const DEPARTURE_NOT_PLANNED = 'DepartureNotPlanned'
export const DEPARTURE_PLANNED = 'DeparturePlanned'
export const MOORING_TERMINAL_AND_AGENT_NOT_SAME = 'MooringTerminalAndAgentNotSame'
export const NO_SPACE_ON_BERTH_SHORT_BEFORE_ARRIVAL = 'NoSpaceOnBerthShortBeforeArrival'
export const NOT_DEPARTED_WHEN_EXPECTED = 'NotDepartedWhenExpected'
export const ONGOING_BUNKER_WHEN_VESSEL_IS_PLANNED_TO_DEPART = 'OngoingBunkerWhenVesselIsPlannedToDepart'
export const OVERLAPPING_BERTH_WINDOWS = 'OverlappingBerthWindows'
export const DEPRECATED_PILOT_PLANNED_FOR_DEPARTURE = 'PilotPlannedForDeparture' // Deprecated, replaced by `DEPARTURE_PLANNED`
export const PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME = 'PlanningEndTerminalAndAgentAreNotSame'
export const PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART = 'PlanningEndTerminalAndAgentAreFarApart'
export const PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME = 'PlanningStartTerminalAndAgentAreNotSame'
export const PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART = 'PlanningStartTerminalAndAgentAreFarApart'
export const PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME = 'PlanningEndTerminalAndPortAuthorityAreNotSame'
export const SKIPPER_UPDATED_ARRIVAL = 'SkipperUpdatedArrival'
export const VESSEL_CAN_BE_EARLIER_AT_BERTH = 'VesselCanBeEarlierAtBerth'
export const VESSEL_CAN_BE_LATER_AT_BERTH = 'VesselCanBeLaterAtBerth'
export const TERMINAL_UPDATED_DEPARTURE = 'TerminalUpdatedDeparture'
export const TERMINAL_UPDATED_ARRIVAL = 'TerminalUpdatedArrival'

export const LAST_LINE_SECURED = 'LastLineSecured'
export const DEPRECATED_PILOT_HAS_ARRIVED = 'PilotHasArrived' // Deprecated, replaced by `PILOT_ON_BOARD`
export const PILOT_NOT_ON_BOARD_WHEN_EXPECTED = 'PilotNotOnBoardWhenExpected'
export const PILOT_ON_BOARD = 'PilotOnBoard'
export const PILOT_PLANNED = 'PilotPlanned'
export const DEPRECATED_PILOT_UPDATED_ARRIVAL = 'PilotUpdatedArrival' // Deprecated, replaced by `PILOT_PLANNED`

export const AGENT_NOT_DECLARED_VISIT = 'AgentNotDeclaredVisit'
export const AGENT_NOT_DECLARED_BERTH_VISIT = 'AgentNotDeclaredBerthVisit'
export const VESSEL_DEPARTED_FROM_PREVIOUS_PORT = 'VesselDepartedFromPreviousPort'
export const ARRIVED_AT_PILOT_BOARDING_PLACE = 'ArrivedAtPilotBoardingPlace'
export const VESSEL_EARLY_AT_PILOT_BOARDING_PLACE = 'VesselEarlyAtPilotBoardingPlace'
export const VESSEL_LATE_AT_PILOT_BOARDING_PLACE = 'VesselLateAtPilotBoardingPlace'
export const VESSEL_LATER_AT_BERTH_THAN_TERMINAL = 'VesselLaterAtBerthThanTerminal'
export const VESSEL_NOT_DEPARTED_FROM_PREVIOUS_PORT = 'VesselNotDepartedFromPreviousPort'
export const TRAVEL_TIME_TOO_SHORT = 'TravelTimeTooShort'

export const PILOTS_CAPACITY_ARRANGEMENT = 'PilotsCapacityArrangement'
export const PORT_STATUS = 'PortStatus'
