export const count = <A>(a: A[], fn: (acc: number, a: A) => number, initialValue = 0) => {
  return a.reduce(fn, initialValue)
}
export const isInBetweenOrEqual = (value: number, min: number, max: number): boolean => value >= min && value <= max

export const isZero = (n: number): boolean => n === 0
//  Zero is not positive neither negative,
//  In some Math scenarios it can be both but we ignore that for simplicity
export const isNegative = (n: number) => n < 0
export const isPositive = (n: number): boolean => n > 0

export const isNumber = (a: any) => typeof a === 'number' && !Number.isNaN(a)
