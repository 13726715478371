import { PredictedWindSpeedObservation } from '../../../../Domain/HydrometeoObservation/Observation'
import { Uuid } from '../../../../Domain/Uuid/Uuid'
import { CacheDefinition } from '../../../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../../../lib/Limiter/fixedSizeLimiter'
import { tripleEquals } from '../../../../utils/equals'
import { IAppState } from '../../../App/interfaces/IAppState'

export const PREDICTED_WIND_SPEED_CACHE_KEY = 'predictedWindSpeed'
export const predictedWindSpeedCacheDefinition = new CacheDefinition<IAppState, Uuid, PredictedWindSpeedObservation[]>(
  PREDICTED_WIND_SPEED_CACHE_KEY,
  fixedSizeLimiter(2),
  tripleEquals,
  appState => appState.predictedWindSpeedCache
)
