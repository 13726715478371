/**
 * Describes why portcalls are stored in the store.
 * Should help in cleaning the right cache items from the store
 * when the maximum space is exceeded.
 */
export enum PortcallsPurpose {
  // For the portcall id's that result from the filter:
  ForFilter,

  // The portcall id's that result from the filter, augmented
  // by the portcalls that share a berth with those portcalls.
  // (ignores selected agent and selected portcall id's)
  ForFilterInPlanningView,

  // For the selected vessel (probably selected through
  // the quick ship finder) for which we try to find some
  // portcalls:
  ForSelectedVessel,

  // For the schedule of a given portcall (previous- and
  // next portcalls):
  ForSchedule,
}
