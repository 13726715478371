import { notificationTypesBySubscriptionType } from '../../../Domain/Subscriptions/SubscriptionConverter'
import { clearCacheAction } from '../../../lib/asyncSelector/Action'
import { notificationsCacheDefinition } from '../../../lib/asyncSelector/notificationsCacheDefinition'
import { saveSubscriptionsNotifications } from '../../../rest/subscriptionsNotifications'
import { userSelector } from '../../../store/authorizations/user.selectors'
import { userEmailSelector } from '../../../store/authorizations/userEmailSelector'
import { saveFiltersAsDefault } from '../../../store/filters/filters.actions'
import { filtersSelector } from '../../../store/filters/filtersSelector'
import { selectedPortIdsSelector } from '../../../store/filters/selectedPortIdsSelector'
import { loadPortNotificationsForPorts } from '../../../store/PortNotifications/portNotifications.thunks'
import { None, StrictNull, none } from '../../../utils/strictNull'

import type { IBackendFacingSubscription } from '../../../Domain/Subscriptions/IBackendFacingSubscription'
import type { ISubscription } from '../../../Domain/Subscriptions/ISubscription'
import type { ISubscriptionConfiguration } from '../../../Domain/Subscriptions/ISubscriptionConfiguration'
import type { SubscriptionType } from '../../../Domain/Subscriptions/SubscriptionType'
import type { ProntoDispatch } from '../../../store/storeTypes'
import type { IAppState } from '../../App/interfaces/IAppState'

export const SAVE_CONFIGURATION = 'SAVE_CONFIGURATION'

export interface ISetConfigurationAction {
  type: typeof SAVE_CONFIGURATION
  configuration: ISubscription[]
}

export function setConfiguration(configuration: ISubscription[]): ISetConfigurationAction {
  return {
    type: SAVE_CONFIGURATION,
    configuration,
  }
}

export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION'

export interface IUpdateConfigurationAction {
  type: typeof UPDATE_CONFIGURATION
  subscriptionType: SubscriptionType
  update: ISubscriptionConfiguration
}

export function updateConfiguration(
  subscriptionType: SubscriptionType,
  update: ISubscriptionConfiguration
): IUpdateConfigurationAction {
  return {
    type: UPDATE_CONFIGURATION,
    subscriptionType,
    update,
  }
}

export const postSubscriptionConfiguration =
  (subscriptionType: SubscriptionType) => async (dispatch: ProntoDispatch, getState: () => IAppState) => {
    const appState = getState()
    const userEmail = userEmailSelector(appState)
    const user = userSelector(appState)
    const { defaultFilters } = user.clientPreferences
    const filters = StrictNull.orElse(defaultFilters, filtersSelector(appState))
    const selectedPorts = selectedPortIdsSelector(appState)

    await Promise.all(
      notificationTypesBySubscriptionType[subscriptionType].map<Promise<IBackendFacingSubscription | None>>(
        notificationType => {
          const subscription = appState.subscriptionConfiguration[subscriptionType] || none
          if (subscription === none) {
            return Promise.resolve(none)
          }

          return saveSubscriptionsNotifications(
            {
              type: notificationType,
              config: subscription,
            },
            userEmail
          )
        }
      )
    )

    // @TODO @WernerG Don't update directly! Go through queue!
    dispatch(clearCacheAction(notificationsCacheDefinition.cacheId, new Date().valueOf()))
    dispatch(loadPortNotificationsForPorts(selectedPorts))

    // Currently, the notification service isn't automatically aware of the user's default filters.
    // We need the user to save (or re-save) the default filters at least once to enable e-mail notifications.
    // To make sure this happens at least once, we save it every time we change the notification configuration.
    dispatch(saveFiltersAsDefault(filters))
  }
