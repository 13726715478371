import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../lib/Limiter/fixedSizeLimiter'
import { IFilters, filtersAreEqual } from '../../shared/interfaces/filters/IFilters'
import { IAppState } from '../App/interfaces/IAppState'

export const PORTCALL_IDS_CACHE_ID = 'portcall-ids'
const MAX_PORTCALL_IDS_CACHE_SIZE = 5

export const portcallIdsCacheDefinition = new CacheDefinition<IAppState, IFilters, EventPortcallId[]>(
  PORTCALL_IDS_CACHE_ID,
  fixedSizeLimiter(MAX_PORTCALL_IDS_CACHE_SIZE),
  filtersAreEqual,
  appState => appState.portcallIdsCache
)
