import { IPortcall } from '../../Domain/Portcall/IPortcall'

import { IPortbaseVisitStatus } from './interfaces/IPortbaseVisitStatus'

export const PORTBASE_DIALOG_CLOSED = 'PORTBASE_DIALOG_CLOSED'
export const PORTBASE_DIALOG_OPENED = 'PORTBASE_DIALOG_OPENED'
export const PORTBASE_DIALOG_LOADING = 'PORTBASE_DIALOG_LOADING'
export const PORTBASE_DIALOG_FEEDBACK = 'PORTBASE_DIALOG_FEEDBACK'
export const PORTBASE_DIALOG_SET_DATA = 'PORTBASE_DIALOG_SET_DATA'

type DialogType = typeof PORTBASE_DIALOG_CLOSED | typeof PORTBASE_DIALOG_OPENED

export interface IDialogMessage {
  message: string
  type: 'success' | 'error'
}

export interface IClosePortbaseDialogAction {
  type: typeof PORTBASE_DIALOG_CLOSED
}

export interface IOpenPortbaseDialogAction {
  type: typeof PORTBASE_DIALOG_OPENED
}

export interface ISetPortbaseDialogLoadingAction {
  type: typeof PORTBASE_DIALOG_LOADING
  loading: boolean
}

export interface ISetPortbaseDialogFeedbackMessageAction {
  type: typeof PORTBASE_DIALOG_FEEDBACK
  message: IDialogMessage
}

export interface ISetPortbaseDialogDataAction {
  type: typeof PORTBASE_DIALOG_SET_DATA
  visit: IPortcall
  portbaseVisitStatus: IPortbaseVisitStatus
}

export interface IPortbaseInputDialogState {
  type: DialogType
  isLoading: boolean
  feedbackMessage: IDialogMessage | undefined
  data?: {
    visit: IPortcall
    portbaseVisitStatus: IPortbaseVisitStatus
  }
}

export interface IBasicBerthProspectsTextParams {
  berthVistsText: string[][]
}

export interface IBerthProspectsTextParams extends IBasicBerthProspectsTextParams {
  pilotBoardingPlaceArrival: string
}

export interface IBerhProspectsExchangeTextParams extends IBasicBerthProspectsTextParams {
  exchange: {
    vesselName: string
    dateTime: string
  }
}
