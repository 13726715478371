import type { ActivityGroup } from '../../Api/Timelines/activityDefinitions'
import type { Activity } from './Activities/Activity'
import type { ActivityName } from './Activities/ActivityName'
import type { Timing } from './Timing'
import type { TimingWithProgress } from './TimingWithProgress'

export const BASIC_TIMELINE_ITEM = 'BASIC_TIMELINE_ITEM'
export const PREVIOUS_VESSEL_TIMELINE_ITEM = 'PREVIOUS_VESSEL_TIMELINE_ITEM'
export const NEXT_VESSEL_TIMELINE_ITEM = 'NEXT_VESSEL_TIMELINE_ITEM'

// The following type of `TimelineItem` knows
// exactly what the back-end knows (no knowledge
// of the current time, etc.)
export type TimelineItem = (BasicTimelineItem | ExchangeVesselTimelineItem) & TimelineItemCommonProperties & Timing

// With the following type of `TimelineItem`
// we can draw the items as a progress bar:
export type TimelineItemWithProgress = (BasicTimelineItem | ExchangeVesselTimelineItem) &
  TimelineItemCommonProperties &
  TimingWithProgress

type BasicTimelineItem = Readonly<{
  timelineItemType: typeof BASIC_TIMELINE_ITEM
}>

type ExchangeVesselTimelineItem = Readonly<{
  timelineItemType: typeof PREVIOUS_VESSEL_TIMELINE_ITEM | typeof NEXT_VESSEL_TIMELINE_ITEM
  exchangeTimeInMinutes: number
}>

export type TimelineItemClassName =
  | ''
  | 'inbound'
  | 'anchoring'
  | 'pilot'
  | 'tug'
  | 'mooring'
  | 'alongside'
  | 'shift'
  | 'surveyor'
  | 'cargo'
  | 'bunker'
  | 'service'
  | 'outbound'
  | 'nextVessel'
  | 'previousVessel'
  | 'transit'

export const ACTUAL = 'ACTUAL'

export const PLANNED = 'PLANNED'

export const NONE = 'NONE'

export const END_BEFORE_START = 'END_BEFORE_START'

export const END_EQUAL_TO_START = 'END_EQUAL_TO_START'

export type TimelineItemCommonProperties = Readonly<{
  id: string
  name: ActivityName
  activity: Activity
  content: string
  className: TimelineItemClassName
  activityGroup: ActivityGroup
  priority: number
}>
