import {
  AGENT_NOT_DECLARED_VISIT,
  BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED,
  CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE,
  CARGO_NOT_STARTED_WHEN_EXPECTED,
  DEPARTURE_NOT_PLANNED,
  MOORING_TERMINAL_AND_AGENT_NOT_SAME,
  NO_SPACE_ON_BERTH_SHORT_BEFORE_ARRIVAL,
  NOT_DEPARTED_WHEN_EXPECTED,
  OVERLAPPING_BERTH_WINDOWS,
  PILOT_NOT_ON_BOARD_WHEN_EXPECTED,
  PILOTS_CAPACITY_ARRANGEMENT,
  PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART,
  PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME,
  PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART,
  VESSEL_CAN_BE_EARLIER_AT_BERTH,
  VESSEL_CAN_BE_LATER_AT_BERTH,
  VESSEL_EARLY_AT_PILOT_BOARDING_PLACE,
  VESSEL_LATE_AT_PILOT_BOARDING_PLACE,
  VESSEL_NOT_DEPARTED_FROM_PREVIOUS_PORT,
  VESSEL_LATER_AT_BERTH_THAN_TERMINAL,
  TRAVEL_TIME_TOO_SHORT,
  AGENT_NOT_DECLARED_BERTH_VISIT,
} from '../Notifications/NotificationType'

// This is a WarningNotification _Subscription_ Type, not to be confused with a WarningNotificationType.
// There is a mapping between this subscription type and the actual warning/notification types in
// src/Domain/Subscriptions/SubscriptionConverter.ts
export const BUNKERS_TOO_CLOSE_TO_DEPARTURE = 'BunkersTooCloseToDeparture'

export const ALL_WARNINGS = [
  BUNKER_BARGE_NOT_ARRIVED_WHEN_EXPECTED,
  BUNKERS_TOO_CLOSE_TO_DEPARTURE,
  CARGO_NOT_STARTED_WHEN_EXPECTED,
  CARGO_PLANNED_TOO_CLOSE_TO_DEPARTURE,
  MOORING_TERMINAL_AND_AGENT_NOT_SAME,
  PLANNING_END_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  PLANNING_END_TERMINAL_AND_AGENT_ARE_FAR_APART,
  PLANNING_END_TERMINAL_AND_PORT_AUTHORITY_ARE_NOT_SAME,
  PLANNING_START_TERMINAL_AND_AGENT_ARE_NOT_SAME,
  PLANNING_START_TERMINAL_AND_AGENT_ARE_FAR_APART,
  VESSEL_CAN_BE_EARLIER_AT_BERTH,
  VESSEL_CAN_BE_LATER_AT_BERTH,
  VESSEL_EARLY_AT_PILOT_BOARDING_PLACE,
  VESSEL_LATE_AT_PILOT_BOARDING_PLACE,
  AGENT_NOT_DECLARED_VISIT,
  AGENT_NOT_DECLARED_BERTH_VISIT,
  DEPARTURE_NOT_PLANNED,
  NOT_DEPARTED_WHEN_EXPECTED,
  PILOT_NOT_ON_BOARD_WHEN_EXPECTED,
  OVERLAPPING_BERTH_WINDOWS,
  NO_SPACE_ON_BERTH_SHORT_BEFORE_ARRIVAL,
  VESSEL_NOT_DEPARTED_FROM_PREVIOUS_PORT,
  VESSEL_LATER_AT_BERTH_THAN_TERMINAL,
  TRAVEL_TIME_TOO_SHORT,
  PILOTS_CAPACITY_ARRANGEMENT,
] as const

type WarningNotificationSubscriptionTypeTuple = typeof ALL_WARNINGS
export type WarningNotificationSubscriptionType = WarningNotificationSubscriptionTypeTuple[number]
