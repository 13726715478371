import { notificationFromJson, ErrorLogger } from '../../Api/Subscriptions/notificationFromJson'
import { onGlobalError } from '../../middlewares/errorLogger'
import { BACKEND_URL, SECOND } from '../../shared/constants'
import { createHeaders } from '../../shared/utils/createHeaders'
import { handleJSONResponse } from '../../shared/utils/rest'
import { getAuthToken } from '../../utils/auth/authClientObservable'
import { batchedProcess } from '../../utils/batchedProcess'
import { exponentialBackOff } from '../../utils/exponentialBackOff'
import { notFalse } from '../../utils/predicates'

import { NotificationId } from './InfoNotifications.interfaces'

import type { IUserNotificationJson } from '../../Api/Subscriptions/IUserNotificationJson'
import type { Notification } from '../../Domain/Notifications/INotification'

const STATUS_TIME_OUT = 408

// Will try again 4 times over a period of about 1.5 minutes.
const INITIAL_WAIT_TIME_BACK_OFF = 5 * SECOND
const NUMBER_OF_RETRIES_BACK_OFF = 4

export function fetchNotifications(ids: NotificationId[], params = '&isSnoozed=false'): Promise<Notification[]> {
  async function fetchBatch(idsInBatch: NotificationId[]): Promise<IUserNotificationJson[]> {
    const authToken = await getAuthToken()
    const url = `${BACKEND_URL}/api/users/me/notifications?isClosed=false${params}`
    const options = {
      method: 'POST',
      headers: createHeaders(authToken),
      body: JSON.stringify(idsInBatch),
    }

    return exponentialBackOff(
      () => fetch(url, options),
      { retryOnSuccess: response => response.status === STATUS_TIME_OUT },
      INITIAL_WAIT_TIME_BACK_OFF,
      NUMBER_OF_RETRIES_BACK_OFF
    ).then(response => handleJSONResponse(options, response) as Promise<IUserNotificationJson[]>)
  }

  return batchedProcess(ids, 100, 3, batch => {
    return fetchBatch(batch)
      .then(toNotifications)
      .catch(() => [])
  })
}

export function toNotifications(json: IUserNotificationJson[]): Notification[] {
  const errorLogger: ErrorLogger = (description, payload, error) => {
    onGlobalError({ error: { description, payload }, reference: '' }, undefined, undefined, undefined, error)
  }
  return json.map(notificationFromJson(errorLogger)).filter(notFalse)
}
