import { getTime } from 'date-fns'

import { MMSI } from '../../Domain/VesselDetails/IVesselDetails'
import { PREDICTED_DATA_POINT, HISTORIC_DATA_POINT } from '../../shared/constants'
import {
  IPredictedTraceDataPoint,
  IHistoricTraceDataPoint,
} from '../../shared/interfaces/shipvisit/IShipTraceDataPoint'
import { parseBackendISO } from '../../shared/utils/datetime'
import * as Arrays from '../../utils/arr'

import { HistoricTraceDataPointJson } from './HistoricTraceJson'
import { PredictedTraceDataPointJson } from './PredictedTraceJson'

export const fromPredictedTraceJson = (mmsi: MMSI) =>
  Arrays.lift((point: PredictedTraceDataPointJson) => fromPredictedTraceDataPointJson(mmsi, point))

const fromPredictedTraceDataPointJson = (
  mmsi: MMSI,
  { courseOverGround, waypoint, speed, time }: PredictedTraceDataPointJson
): IPredictedTraceDataPoint => {
  return {
    type: PREDICTED_DATA_POINT,
    courseOverGround,
    location: { coordinates: [waypoint.lng, waypoint.lat] },
    predicted: true,
    speedOverGround: speed,
    timeLastUpdate: getTime(parseBackendISO(time).datetime),
    mmsi,
  }
}

export const fromHistoricTraceJson = Arrays.lift(
  (point: HistoricTraceDataPointJson): IHistoricTraceDataPoint<typeof point.location> => ({
    type: HISTORIC_DATA_POINT,
    ...point,
    timeLastUpdate: getTime(parseBackendISO(point.recordTime).datetime),
  })
)
