import { BACKEND_URL } from '../shared/constants'
import { createHeaders } from '../shared/utils/createHeaders'
import { handleJSONResponse } from '../shared/utils/rest'
import { getAuthToken } from '../utils/auth/authClientObservable'
import { always } from '../utils/functions'
import { none, None } from '../utils/strictNull'

import type { ILocalDate } from '../shared/utils/datetime'

export async function acceptLegalTerms(version: ILocalDate): Promise<None> {
  const authToken = await getAuthToken()
  const url = `${BACKEND_URL}/api/users/me/terms/${version}/accept`
  const options = {
    method: 'PUT',
    headers: createHeaders(authToken),
  }
  return fetch(url, options).then(response => {
    if (response.status === 204) {
      return Promise.resolve(none)
    }
    return handleJSONResponse(options, response).then(always(none))
  })
}
