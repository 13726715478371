import {
  SET_PORT_NOTIFICATIONS,
  ADD_PORT_NOTIFICATION,
  UPDATE_PORT_NOTIFICATION,
  REMOVE_PORT_NOTIFICATION,
  LOADING_PORT_NOTIFICATIONS,
  SET_PORT_NOTIFICATIONS_ERROR,
  MARK_PORT_NOTIFICATIONS_READ,
} from './portNotifications.actions'

import type { PortNotification } from '../../Domain/Notifications/INotification'
import type { AppAction } from '../../modules/App/App.actions'

export type PortNotificationsState = Readonly<{
  isLoading: boolean
  error: null | string
  notifications?: PortNotification[]
}>
const initialState: PortNotificationsState = {
  isLoading: false,
  error: null,
  notifications: undefined,
}

export const portNotificationsReducer = (
  state: PortNotificationsState = initialState,
  action: AppAction
): PortNotificationsState => {
  switch (action.type) {
    case LOADING_PORT_NOTIFICATIONS:
      return { ...state, isLoading: true, notifications: undefined }
    case SET_PORT_NOTIFICATIONS:
      return { ...state, isLoading: false, notifications: action.notifications }
    case ADD_PORT_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications ? [...state.notifications, action.notification] : [action.notification],
      }
    case UPDATE_PORT_NOTIFICATION: {
      const updatedNotifications = state.notifications
        ? state.notifications.filter(n => n.id !== action.notification.id).concat(action.notification)
        : undefined
      return { ...state, notifications: updatedNotifications }
    }
    case REMOVE_PORT_NOTIFICATION: {
      const filteredNotifications = state.notifications
        ? state.notifications.filter(n => n.id !== action.id)
        : undefined
      return { ...state, notifications: filteredNotifications }
    }
    case MARK_PORT_NOTIFICATIONS_READ:
      return {
        ...state,
        notifications: state.notifications ? state.notifications.map(n => ({ ...n, isRead: true })) : undefined,
      }
    case SET_PORT_NOTIFICATIONS_ERROR:
      return { ...state, error: action.error }
    default:
      return state
  }
}
