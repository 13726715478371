import { Reducer } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { createSelector } from 'reselect'

import { LazyListCons } from '../../utils/lazy'
import { None } from '../../utils/strictNull'
import { Limiter } from '../Limiter/Limiter'

import { Action } from './Action'
import { CacheItem, forKey } from './CacheItem'
import { createCacheItemsReducer } from './createCacheItemsReducer'
import { asyncResult, AsyncResult } from './Results'

class GetForResult<AppState, Key, Result, Meta> {
  constructor(
    private readonly cacheDefinition: CacheDefinition<AppState, Key, Result, Meta>,
    private readonly key: Key,
    private readonly cache: Array<CacheItem<Key, Result, Meta>>
  ) {}

  public orDispatch(
    actionCreator: (
      key: Key,
      cacheItemsForKeyWhenCreatingAction: LazyListCons<CacheItem<Key, Result, Meta>>,
      cacheDefinition: CacheDefinition<AppState, Key, Result, Meta>
    ) => Array<ThunkAction<Promise<any>, AppState, void, Action<any, any, any>>>
  ) {
    const cacheItemsForKey = forKey(this.cache, this.key, this.cacheDefinition.keysAreEqual)
    const actions = actionCreator(this.key, cacheItemsForKey, this.cacheDefinition)
    const result: AsyncResult<AppState, Result> = asyncResult(cacheItemsForKey, actions)
    return result
  }
}

export class SelectorResult<AppState, Key, Result, Meta = None> {
  constructor(
    private readonly cacheDefinition: CacheDefinition<AppState, Key, Result, Meta>,
    private readonly cache: Array<CacheItem<Key, Result, Meta>>
  ) {}

  public getFor(key: Key): GetForResult<AppState, Key, Result, Meta> {
    return new GetForResult(this.cacheDefinition, key, this.cache)
  }
}

export class CacheDefinition<AppState, Key, Result, Meta = None> {
  public readonly reducer: Reducer<Array<CacheItem<Key, Result, Meta>>>

  public readonly selector = createSelector(
    this.cacheSelector,
    (cache): SelectorResult<AppState, Key, Result, Meta> => {
      return new SelectorResult(this, cache)
    }
  )

  constructor(
    public readonly cacheId: string,
    public readonly limiter: Limiter<Key, Result, Meta>,
    public readonly keysAreEqual: (left: Key, right: Key) => boolean,
    public readonly cacheSelector: (state: AppState) => Array<CacheItem<Key, Result, Meta>>
  ) {
    this.reducer = createCacheItemsReducer<Key, Result, Meta>(this.cacheId, this.keysAreEqual, this.limiter)
  }
}
