import { IFuelSavings } from '../../Domain/FuelSavings/IFuelSavings'
import { EventPortcallId } from '../../Domain/Portcall/IPortcall'
import { CacheDefinition } from '../../lib/asyncSelector/CacheDefinition'
import { fixedSizeLimiter } from '../../lib/Limiter/fixedSizeLimiter'
import { stringsAreEqual } from '../../utils/equals'
import { IAppState } from '../App/interfaces/IAppState'

const FUEL_SAVINGS_CACHE_ID = 'fuel-savings'
const FUEL_SAVINGS_CACHE_SIZE = 5

export const fuelSavingsCacheDefinition = new CacheDefinition<IAppState, EventPortcallId, IFuelSavings[]>(
  FUEL_SAVINGS_CACHE_ID,
  fixedSizeLimiter(FUEL_SAVINGS_CACHE_SIZE),
  stringsAreEqual,
  appState => appState.fuelSavingsCache
)
