export enum ServiceType {
  Pilot,
  Tug,
  Linesman,
  PreviousNeighbour,
  NextNeighbour,
  Other,
}

export type Neighbour = ServiceType.PreviousNeighbour | ServiceType.NextNeighbour
